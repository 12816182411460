import { LanguageContext, messages } from '@translations'
import { useContext } from 'react'

interface IVariables {
    [key: string] : string | number
}

export function useTranslation(componentName?: string) {
    const { language } = useContext(LanguageContext)
    
    function translate(message: string, variables?: IVariables) {
        if (componentName === undefined) {
            throw new Error('Argument componentName is missing')
        }

        const translationKey = `${joinAndPascalCase(componentName)}.${joinAndPascalCase(message)}`
        const translatedMessages = messages[language]
        let translatedMessage = translatedMessages && translatedMessages[translationKey] as string | undefined

        // If translated message is not found, display warning and return original messages as fallback value.
        if(!translatedMessage){
            console.warn(`Missing ${language} translation for key: ${translationKey}`)
            return message
        }
        
        // Replace variables in translated messages.
        // The variables are defined as key wrapped in curly braces like: {variableKey}.
        if(variables){
            for(const key of Object.keys(variables)){
                const variableName = `{${key}}`
                const variableValue = String(variables[key])
                translatedMessage = translatedMessage.replaceAll(variableName, variableValue)
            }
        }

        return translatedMessage
    }

    return translate
}

export function joinAndPascalCase(input: string) {
    return input.split(/\s+/g).map(w => w.substring(0, 1).toUpperCase() + w.substring(1)).join('')
}