import { ReactNode, createContext, useContext, useState } from "react";

interface NavigationContextType {
    open: boolean,
    setOpen: (value: boolean) => void,
}

export const NavigationContext = createContext<NavigationContextType | undefined>(undefined);

export function NavigationProvider(props: { children: ReactNode }) {
    const [open, setOpen] = useState(false);
    return (
        <NavigationContext.Provider value={{ open, setOpen }}>
            {props.children}
        </NavigationContext.Provider>
    )
}

export function useNavigation(){
    const context = useContext(NavigationContext);
    if(!context){
        throw new Error("Navigation provider not found.")
    }

    return context;
}