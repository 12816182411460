import { dateToDanishDate } from 'shared/dateTools'

interface IProps {
    value: string | undefined | null
}

/**
 * Component formats date and returns it as a string wrapped inside a <span /> element.
 * Using this component allows us to keep date formatting in single place.
 * In case date (value) is null or undefined, empty span is returned.
 * @param param0 Date to present.
 */
export function DateSpan({ value } : IProps){
    if(value === undefined || value === null){
        return null;
    }
    const formatted = dateToDanishDate(new Date(value));
    return <span>{formatted}</span>
}