import { IDropdownOption } from 'model'
import { ApplicationCustomerFinancialInformation } from './ApplicationCustomerFinancialInformation'

export interface ApplicationCustomer {
  firstName?: string
  lastName?: string
  street: string
  city: string
  zip: string
  phoneNumber: string
  email: string
  idNumber?: string
  isCompany: boolean
  signingRule?: string
  financialInformation?: ApplicationCustomerFinancialInformation
  beneficialOwner1?: string
  beneficialOwner2?: string
  beneficialOwner3?: string
  beneficialOwner4?: string
  isSoleProprietorship?: boolean
  authorizedSignatory: string
  warning?: string
  version?: number
}

export const MonthlyIncomeAfterExpencesCodes: Array<IDropdownOption<string>> = [
  {
    key: '1',
    value: '1',
    text: '3.000 - 6.000',
  },
  {
    key: '2',
    value: '2',
    text: '6.001 - 10.000',
  },
  {
    key: '3',
    value: '3',
    text: '10.001',
  },
]

export const HousingCodes: Array<IDropdownOption<string>> = [
  {
    key: 'O1',
    value: 'O1',
    text: 'Ejerbolig',
  },
  {
    key: 'R1',
    value: 'R1',
    text: 'Lejebolig',
  },
  {
    key: 'O2',
    value: 'O2',
    text: 'Andelsbolig',
  },
  {
    key: 'R2',
    value: 'R2',
    text: 'Andet',
  },
]

export const CivilStatusCodes: Array<IDropdownOption<string>> = [
  {
    key: 'M',
    value: 'M',
    text: 'Gift',
  },
  {
    key: 'S',
    value: 'S',
    text: 'Enlig',
  },
  {
    key: 'C',
    value: 'C',
    text: 'Samlevende',
  },
]

export const AdultCodes: Array<IDropdownOption<number>> = [
  {
    key: '1',
    value: 1,
    text: '1',
  },
  {
    key: '2',
    value: 2,
    text: '2',
  },
]

export const ChildrenCodes: Array<IDropdownOption<number>> = [
  {
    key: '0',
    value: 0,
    text: '0',
  },
  {
    key: '1',
    value: 1,
    text: '1',
  },
  {
    key: '2',
    value: 2,
    text: '2',
  },
  {
    key: '3',
    value: 3,
    text: '3',
  },
  {
    key: '4',
    value: 4,
    text: '3+',
  },
]
