import React from 'react'
import classes from './DescriptionList.module.css'

export function DescriptionList(props:{
  children:  React.ReactNode,
  className?: string
}){
    const className = `${classes.descriptionList} ${props.className ?? ''}`
    return <dl className={className}>{props.children}</dl>
}

export function DescriptionListItem(props: { label: string, dataQa?: string, children:  React.ReactNode}){
    return (
      <>
        <dt>{props.label}</dt>
        <dd data-qa={props.dataQa}>{props.children}</dd>
      </>
    )
  }