import { IUserName } from 'api/UserApiClient'
import { DPFormField, DPInputDropdown } from '@framework'
import { useTranslation } from '@hooks'
import React, { useMemo } from 'react'
import { IDropdownOption } from 'model'

interface ISellersDropDownComponentProps {
    sellers: IUserName[],
    disabled?: boolean,
}

export function SellersDropDownComponent({ sellers, disabled }: ISellersDropDownComponentProps) {
    const $t = useTranslation('SellersDropDownComponent')
    const options = useMemo(() => {
        let counter = 0
        const sellersOptions =
            sellers.map(p => ({
                key: (counter++).toString(),
                text: p.fullName,
                value: p.id
            }) as IDropdownOption<number>)
        sellersOptions.unshift({
            key: '-1',
            text: $t('All'),
            value: 0
        })

        return sellersOptions
    }, [sellers])

    return (
        <DPFormField name="salesPersonId" label={$t('Seller')} inline={false}>
            <DPInputDropdown
                name="salesPersonId"
                options={options}
                disabled={disabled}
                search
            />
        </DPFormField>
    )
}