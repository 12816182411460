import React from 'react'
import './Header.css'
import { DealerSelector } from './DealerSelector'
import { ProfileMenu } from './ProfileMenu'
import { Link } from 'react-router-dom'
import { routes } from 'routing'
import { useNavigation } from '.'
import { classNames } from 'shared'

export function Header() {
    const navigation = useNavigation()
    
    return (
        <div className="header">
            <div className={classNames('navigation-container', navigation.open ? 'open' : undefined)}>
                <Link to={routes.homePage()} className={'logo-container'}>
                    <div /> { /* Do not remove this div */}
                </Link>

                <div className={'menu-button-container'} onClick={() => navigation.setOpen(!navigation.open)}>
                    <MenuButtonIcon />
                </div>
            </div>

            <div className={'content-container'}>
                <DealerSelector />
                <ProfileMenu />
            </div>
        </div>
    )
}

function MenuButtonIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
    )
}