import { IKycApplicationModel, KycApplicationApiClient } from 'api'
import { BusyIndicator, DateTimeSpan, DescriptionList, DescriptionListItem, PageHeader, PageSectionHeader  } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import { formatNumber } from 'shared/utils'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import { KycBuyerComponent } from './components/KycBuyerComponent'
import styles from './KycApplicationPage.module.css'

export function KycApplicationPage() {
  const $t = useTranslation('KycApplicationPage')
  const { id: applicationId } = useParams()

  const apiClient = useApiClient(p => new KycApplicationApiClient(p))
  const [model, isDownloadingModel, getModel] = useApiCall(apiClient.getApplication)
  const session = useSession()
  const dealerId = session.currentDealer?.id

  useEffect(() => {
    if (applicationId && dealerId) {
      getModel(dealerId, applicationId)
    }
  }, [dealerId, applicationId])

  return (
    <article>
      <PageHeader text={$t('pendingApplication')} />
      <BusyIndicator busy={isDownloadingModel}>
      {
        isDownloadingModel
          ? <></>
          :
          <>
            <PageSectionHeader>
              {$t('Applicants')}
            </PageSectionHeader>
            <KycBuyerComponent model={model?.buyer} />
            {model?.coBuyer &&
              <KycBuyerComponent model={model?.coBuyer} />
            }

            <PageSectionHeader>
              {$t('Link to customer information')}
            </PageSectionHeader>
            <p className="mb-5">
              <a className={styles['client-link']} target="_blank" href={model?.clientLink}>{model?.clientLink}</a>
            </p>
            <PageSectionHeader>
              {$t('Details')}
            </PageSectionHeader>
            { model && model.isLeasing && <DetailsOfLeasing model={model} /> }
            { model && !model.isLeasing && <DetailsOfLoan model={model} /> }
          </>
      }
      </BusyIndicator>
    </article>
  )
}

function DetailsOfLoan({ model }: { model: IKycApplicationModel }){
  const $t = useTranslation('KycApplicationPage')
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <DescriptionList>
            <DescriptionListItem label={$t('Date')}>
              <DateTimeSpan value={model?.createdDate}/>
            </DescriptionListItem>

            <DescriptionListItem label={$t('Price')}>
              {`kr. ${formatNumber(model?.objectPrice)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Loan amount')}>
              {`kr. ${formatNumber(model?.financedAmount)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('aopBeforeTaxRaw')}>
              {`${formatNumber(model?.aopBeforeTaxRaw)} %`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('DebtorInterest')}>
              {`${formatNumber(model?.nominalInterest)} %`}
            </DescriptionListItem>
          </DescriptionList>
        </Grid.Column>

        <Grid.Column>
          <DescriptionList>
            <DescriptionListItem label={$t('Make')}>
              {model?.maker}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Down payment')}>
              {`kr. ${formatNumber(model?.downPayment)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Monthly payment')}>
              {`kr. ${formatNumber(model?.paymentPerMonth)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Loan terms')}>
              {`${model?.paymentTerms} ${$t('months')}`}
            </DescriptionListItem>
          </DescriptionList>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

function DetailsOfLeasing({ model }: { model: IKycApplicationModel }){
  const $t = useTranslation('KycApplicationPage')
  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <DescriptionList>
            <DescriptionListItem label={$t('Down payment')}>
              {`kr. ${formatNumber(model?.downPayment)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Monthly payment')}>
              {`kr. ${formatNumber(model?.paymentPerMonth)}`}
            </DescriptionListItem>

            <DescriptionListItem label={$t('Loan terms')}>
              {`${model?.paymentTerms} ${$t('months')}`}
            </DescriptionListItem>
          </DescriptionList>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}