import { ApiClient } from '@api'

export interface IUserProfileModel {
    id: number
    userName: string
    firstName: string
    lastName: string
    title: string
    email: string
    phone: string
    defaultDealerId: number // TODO should be nullable otherwise converts to 0?
    defaultDealerNumber: number // TODO should be nullable otherwise converts to 0?
    dealers: IDealerModel[]
    smsNotifications: boolean
    sellerNumber: number | null
    sellerAgentNumber: string,
    nextTitleConfirmation: string,
    skipMFA: boolean,
  }
  
  export interface IDealerModel {
    id: number
    name: string
    officialName: string
    number: number
    brandCode: number,
    address: string,
  }

interface IChangeUserDealerCommand {
    defaultDealerId: number
}

export class UserProfileApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getUserProfile = () => {
        return this.apiClient.get<IUserProfileModel>('api/userprofile/').then(x => x.data)
    }

    public updateUser = (user: IUserProfileModel) => {
        return this.apiClient.put('api/userprofile', user)
    }

    public changeDefaultDealer = (dealerId: number) => {
        return this.apiClient.patch('api/userprofile/',
            { defaultDealerId: dealerId } as IChangeUserDealerCommand)
    }

    public userPromptUpdate = (title: string, phone: string, email: string) => {
        return this.apiClient.put('api/userprofile/prompt-update', { title, phone, email });
    }
}