import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import styles from './AccessDenied.module.css'

export default function AccessDeniedPage() {
  const $t = useTranslation('AccessDeniedPage')

  return (
    <article className={styles.page}>
      <Header as="h2" icon>
        <Icon name="lock" />
        {$t('Access Denied!')}
        <Header.Subheader>
          {$t('You are not authorized to view this page.')}
        </Header.Subheader>
      </Header>
    </article>
  )
}
