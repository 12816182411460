import { emptyGrid, IGridResult } from '@api'
import { AgreementApiClient } from 'api/AgreementApiClient'
import { IIncomingApplication, IncomingApplicationApiClient } from 'api/IncomingApplicationApiClient'
import { routes } from 'routing'
import { Agreement } from 'model'
import { DPDataTable, IDataTableColumn, PageHeader } from '@framework'
import { useApiCall, useApiClient, useModal, useSession, useTranslation } from '@hooks'
import { localDateToDanishDateTimeString, dateTimeStringToLocalDate } from 'shared/dateTools'
import { formatNumber } from 'shared/utils'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { useIncomingApplicationCounter } from 'shared/incomingApplicationCounter'

export default function SearchIncomingPage() {
    const $t = useTranslation('SearchIncomingPage')
    const session = useSession()
    const navigate = useNavigate()
    const modal = useModal()
    const incomingApplicationCounterContext = useIncomingApplicationCounter()

    const incomingApplicationApi = useApiClient((x) => new IncomingApplicationApiClient(x))
    const agreementApiClient = useApiClient(x => new AgreementApiClient(x))
    const [agreements, isLoadingAgreements, getAgreements] = useApiCall(agreementApiClient.getAgreements, [] as Agreement[])

    const [isLoadingApplications, setIsLoadingApplications] = useState(false)
    const [isCancelingApplications, setIsCancelingApplications] = useState(false)
    const [applications, setApplications] = useState<IGridResult<IIncomingApplication>>(emptyGrid)

    const dealerId = session.currentDealer?.id

    useEffect(() => {
        if(!dealerId){
            return
        }

        getAgreements(dealerId)
    }, [dealerId])

    // Load applications
    useEffect(() => {
        refresh()
    }, [dealerId])

    const refresh = () => {
        if (dealerId === undefined) {
            setApplications(emptyGrid)
            return
        }

        setIsLoadingApplications(true)
        incomingApplicationCounterContext.refresh()
        incomingApplicationApi
            .getIncomingApplications(dealerId)
            .then((response) => {
                if (response === undefined) {
                    setApplications(emptyGrid)
                    return
                }

                setApplications(response)
            })
            .finally(() => setIsLoadingApplications(false))
    }

    const cancelApplication = (id: string) => {
        setIsCancelingApplications(true)
        incomingApplicationApi.moveToStateDeleted(dealerId!, id)
            .then(() => refresh())
            .finally(() => setIsCancelingApplications(false))
    }

    const columns = [
        {
            key: 'updated',
            name: $t('Date'),
            onRender: ({ updated }: IIncomingApplication) => localDateToDanishDateTimeString(dateTimeStringToLocalDate(updated))
        },
        {
            key: 'customerName',
            name: $t('Name')
        },
        {
            key: 'customerPhone',
            name: $t('Phone')
        },
        {
            key: 'agreement',
            name: $t('Agreement'),
            onRender: ({ agreementCode }: IIncomingApplication) =>
                agreements.filter((x) => x.code === agreementCode).map((x) => x.name)[0] ||
                agreementCode
        },
        {
            key: 'make',
            name: $t('Make')
        },
        {
            key: 'model',
            name: $t('Model')
        },
        {
            key: 'price',
            name: $t('Price'),
            onRender: ({ price }: IIncomingApplication) => 'kr. ' + formatNumber(price)
        },
        {
            key: '',
            name: '',
            textAlign: 'right',
            onRender: (item: IIncomingApplication) => {
              return (
                <Icon
                    link
                    name="cancel"
                    onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
            
                        modal.confirm({
                            message: $t('DeleteModalMsg'),
                            okButtonLabel: $t('DeleteModalOkLabel'),
                            cancelButtonLabel: $t('DeleteModalCancelLabel'),
                            onOk: () => cancelApplication(item.key),
                        })
                    }} 
                />
              )
            }
          }
    ] as Array<IDataTableColumn<IIncomingApplication>>

    return (
        <article>
            <PageHeader text={$t('Search incoming')} />
      
            <DPDataTable
                columns={columns}
                data={applications}
                isLoading={isLoadingApplications || isLoadingAgreements || isCancelingApplications}
                onRowSelect={({ key }) =>
                    navigate(routes.privateApplication({ tab: 'object', timestamp: Date.now().toString(), incomingApplicationId: key }))
                }
            />
        </article>
    )
}
