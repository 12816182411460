import { Formik, useFormikContext } from 'formik'
import { ReactNode, useEffect, useMemo } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import { useDebounce } from '@hooks'

interface Props<T> {
    initialValues: T
    column1?: ReactNode,
    column2?: ReactNode,
    column3?: ReactNode,
    column4?: ReactNode,
    column5?: ReactNode,
    onSubmit(values: T): void,
    debounce?: { keys: Array<keyof T>, delay: number, },
}

/**
 * Renders 3 columns form, where you can place your inputs.
 * All inputs are automatically wrapped inside form elements.
 * It also adds search button below inputs.
 */
export function DPTableSearchForm<T>(props: Props<T>){
    return (
        <Formik
            initialValues={props.initialValues}
            onSubmit={() => {}}
        >
            {formikProps => {
                return (
                    <Form onSubmit={formikProps.handleSubmit}>
                        <Grid columns={5} doubling>
                            <Grid.Row>
                                <Grid.Column>{props.column1}</Grid.Column>
                                <Grid.Column>{props.column2}</Grid.Column>
                                <Grid.Column>{props.column3}</Grid.Column>
                                <Grid.Column>{props.column4}</Grid.Column>
                                <Grid.Column>{props.column5}</Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <FormBehaviors {...props} />
                    </Form>
                )
            }}
        </Formik>
    )
}

function FormBehaviors<T>(props: Props<T>){
    const context = useFormikContext<T>();
    const debouncedOnSubmit = useDebounce(props.onSubmit, props.debounce?.delay ?? 0);

    const debouncedValues = useMemo(() => {
        const debouncedKeys = props.debounce?.keys ?? [];
        return debouncedKeys.map(x => context.values[x] ?? "");
    }, [props.debounce?.keys, context.values]);

    const notDebouncedValues = useMemo(() => {
        const debouncedKeys = props.debounce?.keys ?? [];
        const allKeys = Object.keys(context.values as any) as Array<keyof T>
        const notDebouncedKeys = allKeys.filter(x => debouncedKeys.indexOf(x) === -1);
        return notDebouncedKeys.map(x => context.values[x] ?? "");
    },
    [props.debounce?.keys, context.values]);

    useEffect(() => {
        debouncedOnSubmit(context.values);
    }, [...debouncedValues])
    
    useEffect(() => {
        props.onSubmit(context.values);
    }, [...notDebouncedValues])
    return null;
}