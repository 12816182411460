type Feature =
  'language-switch' |
  'personnel-loan' |
  'corporate-loan'|
  'statistics' |
  'payment-plan' |
  'update-pricing-from-preview'

interface Config {
  webUrl: string,
  bffUrl: string,
  environment: string
  locale: string
  moveUrl: string,
  hiddenFeatures?: Feature[],
  gtmId?: string,
  /**
   * Collection of agreement codes for leasing cases for which,
   * contract will be queued for generation using RoadRunner.
  */
  queueContractGenerationForLeasingAgreementCodes: number[],
}

const config = (window as any).config as Config

/** The function returns if given feature is enabled or not based on configuration. */
function isFeatureEnabled(feature: Feature){
  return !config.hiddenFeatures || config.hiddenFeatures.indexOf(feature) === -1;
}

export { config, isFeatureEnabled };