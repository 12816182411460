import * as yup from 'yup'
import { AnyObject, Maybe } from 'yup/lib/types'
import { ICustomLocaleObject } from './ICustomLocaleObject'
import { dateStringToLocalDate, dateToDanishDate } from 'shared/dateTools'

// Add custom methods to existing schemas.
// It's called module augmentation.
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module 'yup' {
  // tslint:disable-next-line: interface-name
  export interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType> {
      phone(): StringSchema<TType, TContext>
      vin(): StringSchema<TType, TContext>
      cpr(): StringSchema<TType, TContext>
      cvr(): StringSchema<TType, TContext>
      registrationNumber(): StringSchema<TType, TContext>
      zipCode(): StringSchema<TType, TContext>
      minDate(minDate: string): StringSchema<TType, TContext>
      maxDate(maxDate: string): StringSchema<TType, TContext>
  }
}

export function addMethods(locale: ICustomLocaleObject){
  yup.addMethod(yup.string, 'phone', () => {
    return yup.string().nullable(true).test(
      'phone-test',
      locale.string.phone,
      (value) => (value?.length || 0) === 0 || /^\d{8}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'vin', () => {
    return yup.string().nullable(true).test(
      'vin-test',
      locale.string.vin,
      (value) => (value?.length || 0) === 0 || /^[A-Z0-9]{0,17}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'cpr', () => {
    return yup.string().nullable(true).test(
      'cpr-test',
      locale.string.cpr,
      (value) => (value?.length || 0) === 0 || /^\d{10}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'cvr', () => {
    return yup.string().nullable(true).test(
      'cvr-test',
      locale.string.cvr,
      (value) => (value?.length || 0) === 0 || /^\d{8}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'registrationNumber', () => {
    return yup.string().nullable(true).test(
      'registration-number-test',
      locale.string.registrationNumber,
      (value) => (value?.length || 0) === 0 || /^[A-Z]{2}[0-9]{0,5}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'zipCode', () => {
    return yup.string().nullable(true).test(
      'zip-code-test',
      locale.string.zipCode,
      (value) => (value?.length || 0) === 0 || /^\d{4}$/.test(value || ''))
  })

  yup.addMethod(yup.string, 'minDate', function(minDate: string){
    return this.test({
      name: 'min-date',
      message: locale.string.minDate,
      test: value => value === null || value === undefined || dateStringToLocalDate(value) >= dateStringToLocalDate(minDate),
      params: { minDate: minDate },
    });
  });

  yup.addMethod(yup.string, 'maxDate', function(maxDate: string){
    return this.test({
      name: 'max-date',
      message: locale.string.maxDate,
      test: value => value === null || value === undefined || dateStringToLocalDate(value) <= dateStringToLocalDate(maxDate),
      params: { maxDate: maxDate },
    })
  });
}