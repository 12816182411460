import { BrandModelCatalogApiClient, IBrandModel } from 'api'
import { Agreement, ApplicationObject } from 'model'
import { DPFormField, DPInputDate, DPInputDropdown, DPInputNumber, DPInputSearch, DPInputText, DPInputTextarea, DPInputTextReadOnly, DPTextCapitalizeBehavior } from '@framework'
import { useApiClient, useSession, useTranslation } from '@hooks'
import { addYears, currentDateString, dateStringToLocalDate, startOfMonth } from 'shared/dateTools'
import { Formik, useFormikContext } from 'formik'
import React, { useContext, useEffect, useMemo } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import { useObjectValidationSchema } from '../..'
import { IApplicationContext } from '../../model/IApplicationContext'
import { ApplicationContext } from '../../state'
import { ObjectSearchedBy, ObjectSearchMode } from '../../state/useSearchObjectMode'
import ClearApplication from './components/ClearApplication'
import { FavoriteModelsApiClient, IFavoriteModel } from './components/favoriteModels/FavoriteModelsApiClient'
import { FavoriteModelsComponent } from './components/favoriteModels/FavoriteModelsComponent'
import { AvailableObjectType } from 'pages/application/dataProviders/useAvailableObjectTypes'
import { ObjectType } from 'shared'

interface IProps {
  objectTypes: AvailableObjectType[],
  isIncomingApplication: boolean,
  favoriteModels: IFavoriteModel[],
  loadingFavoriteModels: boolean,
  saveFavoriteModels: (favoriteModels: IFavoriteModel[]) => void,
  getFavoriteModels: () => void,
  searchMode: ObjectSearchMode,
  setSearchMode: (value: ObjectSearchMode) => void,
  searchedBy: ObjectSearchedBy | undefined,
  setSearchedBy: (value: ObjectSearchedBy | undefined) => void,
  setIsLoadingBrandModel: (value: boolean) => void
  agreements: Agreement[],
  clearApplication: () => void,
}

interface IForm {
  objectType: number,
  registrationNumber: string,
  vin: string,
  make: string,
  model: string,
  variant: string,
  variantId: number | null | undefined,
  // TODO Rename it to makeModelVariant, it hold combination of them.
  modelVariant: string,
  modelYear?: number | null | undefined,
  firstRegistrationDate: string | null,
  mileage: number | null,
  extraEquipmentNote: string,
  deliveryDate: string | null,
  firstUseLessThanOneYearAgo: boolean | null,
}

export function ObjectTab(props: IProps) {
  const $t = useTranslation('ObjectTab')
  const context = useContext(ApplicationContext)
  const session = useSession()
  const brandModelCatalogApiClient = useApiClient(x => new BrandModelCatalogApiClient(x))
  const favoriteModelsApi = useApiClient((a) => new FavoriteModelsApiClient(a))
  const showFavorites =
    props.searchMode === 'automatic' &&
    (props.searchedBy === undefined || props.searchedBy === 'model-variant')
  const { object, calculation } = context.application
  
  const isBoat = () => {
    return context.application.objectType == ObjectType.Boat
  }
  const isBoatAccessories = () => {
    return context.application.objectType == ObjectType.BoatAccessories
  }
  
  const initialValues: IForm = {
    objectType: context.application.objectType,
    registrationNumber: object.registrationNumber || '',
    vin: object.vin || '',
    make: object.make || '',
    model: object.model || '',
    variant: object.variant || '',
    variantId: object.variantId,
    modelVariant: `${object.make ?? ''} ${object.model ?? ''} ${object.variant ?? ''}`,
    modelYear: context.application.object.modelYear,
    firstRegistrationDate: object.firstRegistrationDate,
    mileage: object.mileage,
    extraEquipmentNote: object.extraEquipmentNote || '',
    deliveryDate: object.deliveryDate,
    firstUseLessThanOneYearAgo: calculation.isNewObject,
  }

  const objectTypeOptions = useMemo(
    () => props.objectTypes.map(x => {
      return {
        key: x.id.toString(),
        value: x.id,
        text: x.name,
      }
    }),
    [props.objectTypes])

  const addObjectToFavorites = (result: IBrandModel) => {
    const favoriteModel = {
        brandName: result.make,
        isFavorite: false,
        modelName: result.model,
        variantName: result.variant!,
        variantId: result.variantId,
        modelVariantIdExternalReference: result.modelVariantIdExternalReference
    }

    favoriteModelsApi.addNewToMy(
        session.currentDealer!.id,
        favoriteModel as IFavoriteModel,
        context.application.objectType)
        .then(() => props.getFavoriteModels())
  }
  const onModelVariantSelected = (value: IBrandModel | undefined, searchedBy: ObjectSearchedBy | undefined) => {
    if (!value) {
      context.setObject({ ...context.defaultApplication.object })
    } else {
      context.setObject({
        registrationNumber: value.registrationNumber,
        vin: value.vin,
        make: value.make,
        model: value.model,
        variant: value.variant,
        variantId: value.variantId,
        modelYear: value.modelYear,
        modelVariantIdExternalReference: value.modelVariantIdExternalReference,
        firstRegistrationDate: value.firstRegistrationDate,
        firstRegistrationDateFromExternalSource: !!value.firstRegistrationDate,
        mileage: context.application.object.mileage,
        pricePerKm: context.application.object.pricePerKm,
        extraEquipmentNote: context.application.object.extraEquipmentNote,
        deliveryDate: context.application.object.deliveryDate,
      })
    }

    if(value && searchedBy === 'model-variant'){
      addObjectToFavorites(value)
    }

    props.setSearchedBy(searchedBy)
  }
  const onModelVariantIdSelected = (variantId: number) => {
    props.setIsLoadingBrandModel(true)
    brandModelCatalogApiClient.searchByVariantId(
        variantId,
        context.application.objectType,
        context.application.object.mileage || null,
        context.application.object.firstRegistrationDate || null)
          .then(x => {
              const model = x && x[0]
              onModelVariantSelected(model, undefined)
          })
          .finally(() => props.setIsLoadingBrandModel(false))
  }
  const selectObjectByRegistrationNumber = (result?: IBrandModel) => {
      onModelVariantSelected(result, 'registration number')
  }
  const selectObjectByVin = (result?: IBrandModel) => {
      onModelVariantSelected(result, 'vin')
  }
  const selectObjectByVariant = (result?: IBrandModel) => {
      onModelVariantSelected(result, 'model-variant')
  }
  const toModelVariant = (value: IBrandModel) =>
      `${value.make} ${value.model} ${value.variant} (${value.modelYear})`

  const setManualSearchMode = () => {
    props.setSearchMode('manual')

    context.setObject({
      ...context.application.object,
      modelYear: null,
    })
  }

  const validationSchema = useObjectValidationSchema('form', props.searchMode)

  return (
    <Grid relaxed stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Formik
              initialValues={initialValues}
              onSubmit={() => { return }}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {formikProps => (
                <Form>
                  <ClearApplication
                    onButtonClick={props.clearApplication}
                    buttonText={$t('Clear application')}
                  />

                  <DPFormField label={$t('Object type')} name="objectType" required>
                    <DPInputDropdown 
                      name="objectType"
                      options={objectTypeOptions}
                      disabled={props.objectTypes.length === 0}
                    />
                  </DPFormField>

                  { props.searchMode === 'automatic' &&
                    <>
                        { context.formConfiguration.hasRegistrationNumber && 
                          <DPFormField
                              name="registrationNumber"
                              label={$t('Registration number')}
                          >
                              <DPInputSearch
                                  name="registrationNumber"
                                  titleSelector={(item: IBrandModel) => toModelVariant(item)}
                                  minCharacters={7}
                                  onSearchChange={search =>
                                    brandModelCatalogApiClient.searchByRegNum(
                                          search,
                                          context.application.objectType,
                                          object.mileage || null,
                                          object.firstRegistrationDate || null)
                                          .then(x => x ?? [])
                                  }
                                  onResultSelect={item => selectObjectByRegistrationNumber(item)}
                                  noResultsMessage={$t('NoResultsFound')}
                                  readOnly={props.searchedBy === 'vin'}
                              />
                          </DPFormField>
                        }

                        <DPFormField
                            name="vin"
                            label={$t('VIN')}
                        >
                            <DPInputSearch
                                name="vin"
                                titleSelector={(item: IBrandModel) => toModelVariant(item)}
                                minCharacters={17}
                                onSearchChange={search =>
                                  brandModelCatalogApiClient.searchByVin(
                                        search,
                                        context.application.objectType,
                                        object.mileage || null,
                                        object.firstRegistrationDate || null)
                                        .then(x => x ?? [])
                                }
                                onResultSelect={item => selectObjectByVin(item)}
                                noResultsMessage={$t('NoResultsFound')}
                                readOnly={props.searchedBy === 'registration number'}
                            />
                        </DPFormField>

                        <DPFormField
                            name="modelVariant"
                            label={$t('Model variant')}
                        >
                            <DPInputSearch
                                name="modelVariant"
                                titleSelector={(item: IBrandModel) => toModelVariant(item)}
                                minCharacters={3}
                                onSearchChange={search =>
                                  brandModelCatalogApiClient.searchBrands(
                                        search,
                                        context.application.objectType,
                                        object.mileage || null,
                                        object.firstRegistrationDate || null)
                                        .then(x => x ?? [])
                                }
                                onResultSelect={item => selectObjectByVariant(item)}
                                noResultsMessage={
                                    <p>
                                        {$t('NoResultsFound,WouldYouLikeTo')}{' '}
                                        <a onClick={setManualSearchMode}>{$t('EnterDataManually')}</a>?
                                    </p>
                                }
                                // For external application present matching search results,
                                // so user can immediately select correct variant.
                                defaultOpen={props.isIncomingApplication}
                            />
                        </DPFormField>

                        <DPFormField
                            name="variantId"
                            label={$t('Variant ID')}
                        >
                            <DPInputTextReadOnly
                              value={formikProps.values.variantId?.toString() ?? ''}
                            />
                        </DPFormField>
                    </>
                  }

                  { props.searchMode === 'manual' &&
                    <>
                      { !isBoat() && !isBoatAccessories() && 
                        <DPFormField
                          name="registrationNumber"
                          label={$t('Registration number')}
                        >
                          <DPInputText
                            name="registrationNumber"
                          />
                        </DPFormField> 
                      }

                      { !isBoatAccessories() &&
                        <DPFormField
                          name="vin"
                          label={$t(isBoat() ? 'CIN':'VIN')}
                        >
                          <DPInputText
                            name="vin"
                          />
                        </DPFormField>
                      }

                      <DPFormField
                        name="make"
                        label={$t('Make')}
                      >
                        <DPInputText
                          name="make"
                        />
                      </DPFormField>

                      <DPFormField
                        name="model"
                        label={$t('Model')}
                      >
                        <DPInputText
                          name="model"
                        />
                      </DPFormField>

                      <DPFormField
                        name="variant"
                        label={$t('Variant')}
                      >
                        <DPInputText
                          name="variant"
                        />
                      </DPFormField>
                    </>
                  }

                  { context.formConfiguration.hasFirstRegistrationDate &&
                    <DPFormField
                      name="firstRegistrationDate"
                      label={$t('First registration date')}
                      required
                    >
                      <DPInputDate
                        name="firstRegistrationDate"
                        disabled={context.application.object.firstRegistrationDateFromExternalSource}
                        maxDate={currentDateString()}
                      />
                    </DPFormField>
                  }
                  { context.formConfiguration.hasMileage && 
                    <DPFormField
                      name="mileage"
                      label={$t('Mileage (km)')}
                    >
                      <DPInputNumber
                        name="mileage"
                        precision={0}
                      />
                    </DPFormField>
                  }
                  { (context.formConfiguration.hasModelYear || props.searchMode === 'manual') && 
                    <DPFormField
                      name="modelYear"
                      label={$t('Model year')}
                    >
                      <DPInputNumber
                        name="modelYear"
                        integer={true}
                        maxLength={4}
                      />
                    </DPFormField>
                  }
                  { context.formConfiguration.hasIsNewObject &&
                    <DPFormField
                      name="firstUseLessThanOneYearAgo"
                      label={$t('firstUse')}
                    >
                      <DPInputDropdown
                        name="firstUseLessThanOneYearAgo"
                        options={[
                          {key: "true", text: $t('lessThan1YearAgo'), value: true},
                          {key: "false", text: $t('moreThanOrEqualTo1YearAgo'), value: false},
                        ]}
                      />
                    </DPFormField>
                  }
                  { isBoatAccessories() && 
                    <DPFormField
                      name="registrationNumber"
                      label={$t('Identification number')}
                    >
                      <DPInputText
                        name="registrationNumber"
                      />
                    </DPFormField> 
                  }
                  <DPFormField
                    name="extraEquipmentNote"
                    label={$t('Extra equipment notes')}
                    inline={false}
                  >
                    <DPInputTextarea
                      name="extraEquipmentNote"
                    />
                  </DPFormField>

                  <DPFormField
                    name="deliveryDate"
                    label={$t('Delivery date')}
                    required
                  >
                    <DPInputDate
                      name="deliveryDate"
                      minDate={currentDateString()}
                    />
                  </DPFormField>

                  <DPTextCapitalizeBehavior
                    name="vin"
                  />
                  <DPTextCapitalizeBehavior
                    name="registrationNumber"
                  />
                  <FormBehaviors
                    context={context}
                    searchedBy={props.searchedBy}
                    agreements={props.agreements}
                    setSearchedBy={props.setSearchedBy}
                  />
                  </Form>
                )}              
            </Formik>
          </Grid.Column>
          <Grid.Column>
            {showFavorites &&
              <FavoriteModelsComponent
                loadingFavoriteModels={props.loadingFavoriteModels}
                favoriteModels={props.favoriteModels}
                saveFavoriteModels={props.saveFavoriteModels}
                onModelVariantIdSelected={onModelVariantIdSelected}
              />
            }
          </Grid.Column>
        </Grid.Row>
    </Grid>
  )
}

function FormBehaviors(props: { 
    context: IApplicationContext, 
    searchedBy: ObjectSearchedBy | undefined,
    agreements: Agreement[],
    setSearchedBy: (value: ObjectSearchedBy | undefined) => void,
  }){
  const formik = useFormikContext<IForm>()
  const { dirty, values, setValues } = formik
  const context = useContext(ApplicationContext)

  // Recalculate when was first use of the object
  useEffect(() => {
    const firstUseLessThanOneYearAgo =
      (() => {
        switch(values.objectType){
          case ObjectType.Camping:
            if(!values.firstRegistrationDate){
              return values.firstUseLessThanOneYearAgo;
            }
            const registrationMonth = startOfMonth(values.firstRegistrationDate)
            const oneYearAgo = addYears(startOfMonth(currentDateString()), -1)
            return dateStringToLocalDate(registrationMonth) >= dateStringToLocalDate(oneYearAgo);
          case ObjectType.Boat:
          case ObjectType.BoatAccessories:
            return values.firstUseLessThanOneYearAgo ?? true;
          default:
            return null
        }
      })();

      if(values.firstUseLessThanOneYearAgo !== firstUseLessThanOneYearAgo){
        setValues({...values, firstUseLessThanOneYearAgo });
      }

  }, [values.objectType, values.firstRegistrationDate, values.firstUseLessThanOneYearAgo])
  
  // Clear selection when user remove all characters from the filed, he/she searched by.
  useEffect(() => {
    if(!dirty){
        return
    }

    const clearSelection = 
      (props.searchedBy === 'registration number' && values.registrationNumber.length === 0) ||
      (props.searchedBy === 'vin' && values.vin.length === 0) ||
      (props.searchedBy === 'model-variant' && values.modelVariant.length === 0)

    if(!clearSelection){
      return;
    }

    props.setSearchedBy(undefined)
    setValues(x => ({
      ...x,
      registrationNumber: '',
      vin: '',
      variant: '',
      variantId: null,
      firstRegistrationDateFromExternalSource: false,
    }))
  }, [values.registrationNumber, values.vin, values.modelVariant])

  // Update application model on object type change.
  useEffect(() => {
    if(!dirty){
        return
    }

    if(values.objectType === props.context.application.objectType){
      return
    }

    // If selected object type doesn't match selected agreement, then adjust agreement.
    const currentAgreementCode =
      props.context.application.calculation.agreementCode
    const matchingAgreements =
      props.agreements.filter(x => x.objectTypes.indexOf(values.objectType) !== -1)
    const doesCurrentAgreementMatch =
      matchingAgreements.filter(x => x.code === currentAgreementCode).length > 0
    
    if(matchingAgreements.length === 0)
    {
      return
    }

    context.setObject({
      ...context.application.object,
      modelYear: null,
    })

    const agreementCode =
      doesCurrentAgreementMatch
        ? currentAgreementCode
        : matchingAgreements[0].code

    props.context.setCalculation({
      ...props.context.defaultApplication.calculation,
      objectType: values.objectType,
      agreementCode
    },
    values.objectType)

  }, [values.objectType])

  // Update application model on form change (different than object type).
  useEffect(() => {
    if(!dirty){
      return
    }

    if(values.objectType !== props.context.application.objectType){
      return
    }

    // Update object
    const updatedObject = {
      registrationNumber: values.registrationNumber,
      vin: values.vin,
      make: values.make,
      model: values.model,
      variant: values.variant,
      variantId: values.variantId,
      modelYear: values.modelYear,
      modelVariantIdExternalReference: context.application.object.modelVariantIdExternalReference,
      firstRegistrationDate: values.firstRegistrationDate,
      firstRegistrationDateFromExternalSource: context.application.object.firstRegistrationDateFromExternalSource,
      mileage: values.mileage,
      pricePerKm: context.application.object.pricePerKm,
      extraEquipmentNote: values.extraEquipmentNote,
      deliveryDate: values.deliveryDate,
    } as ApplicationObject
    context.setObject(updatedObject)
    
    // Reset calculations, change agreement code to matching one.
    props.context.setCalculation({
      ...props.context.application.calculation,
      isNewObject: values.firstUseLessThanOneYearAgo,
      mileage: values.mileage,
      firstRegistrationDate: values.firstRegistrationDate,
      deliveryDate: values.deliveryDate,
    },
    values.objectType)
  }, [values])

  return null
}