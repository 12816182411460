import { useContext } from "react";
import { IncomingApplicationCounterContext } from "./IncomingApplicationCounterProvider";

export function useIncomingApplicationCounter(){
    const context = useContext(IncomingApplicationCounterContext)

    if(!context){
        throw new Error("Incoming case counter context not found")
    }

    return context
}