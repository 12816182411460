import { PageHeader } from "@framework";
import styles from "./PlusLoanPage.module.css"

// Piotr C.: I decided to not translate this page to english. It's pointless, it's static page.
export function PlusLoanPage(){
    return (
        <div className={styles.page}>
            <PageHeader text="PLUSLÅN" />

            <p>
                <b>Finansiering uden udbetaling - 100% digitalt</b>
            </p>

            <p>
                Pr. 01.02.2019 kræver det en særskilt aftale med Santander for at kunne tilbyde PLUSLÅN. 
                Kontakt din Santander Key Account Manager hvis du ønsker at indgå en aftale.
                Læs om alle låntyperne i produktarket <a href="https://dealer.santander24.dk/Documents/PLUSL%C3%85N/PLUSL%C3%85N%20-%20L%C3%A5ntyper.pdf">her</a>
            </p>

            <p>
                Det er pr. 01.02.2019 blevet endnu nemmere at søge om et PLUSLÅN hos Santander. 
                Kunden skal bruge NemID til ansøgningsprocessen og underskrift. Ansøgningsprocessen er 100% digital 
                og der skal ikke ikke printes noget ud. Læs mere om fakta <a href="https://dealer.santander24.dk/Documents/PLUSL%C3%85N/PLUSL%C3%85N%20-%20FAKTA%20og%20QA.pdf">her</a>
            </p>

            <p className={styles.separator}>Du kan se en video om ansøgningsprocessen <a href="https://dealer.santander24.dk/Documents/PLUSL%C3%85N/PLUSL%C3%85N%20-%20Video.mp4">her</a></p>

            <p>Har du spørgsmål til Pluslånet, kontakt da venligst din Key Account Manager fra Santander</p>

            <p>
                <b>Ansøg om nyt lån og se status på alle dine pluslån her:</b>
            </p>

            <div className={styles.center}>
                <a className={styles.button} href="https://salesfinance.santanderconsumer.dk/Layouts/SCBDK.SalesFinance.PartnerPortal.New/setaccesspoint.aspx">GÅ TIL PLUSLÅN</a>
            </div>

            <p>
                <b>Spørgsmål til login</b><br/>
                Salgssupport: 70 27 02 92<br/>
                Dit login og pinkode har du modtaget pr. mail.<br/>
            </p>
        </div>
    )
}