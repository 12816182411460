import { FileFromResponse } from "@api/models";
import { BusyIndicator, DPDataTable, IDataTableColumn } from "@framework";
import { useApiClient } from "@hooks/useApiClient";
import { useTranslation } from "@hooks/useTranslation";
import { formatMoney } from "shared/utils";
import { CalculatorApiClient } from "api";
import { CalculationInput, Payment } from "model";
import { useEffect, useMemo, useState } from "react";
import { Icon, Modal } from "semantic-ui-react";
import { DPButton } from "shared";

interface Props {
    open: boolean,
    setOpen: (value: boolean) => void,
    dealerId: number,
    calculationInput: CalculationInput,
}

export function PaymentPlanModal(props: Props){
    const api = useApiClient(x => new CalculatorApiClient(x));
    const [payments, setPayments] = useState<Payment[]>([]);
    const [loading, setLoading] = useState(false);
    const [generating, setGenerating] = useState(false);
    const $t = useTranslation("PaymentPlanModal");
    const busy = loading || generating;

    const getPaymentPlanPrintout = () => {
        setGenerating(true);
        api.getPaymentPlanPrintout(props.dealerId, {...props.calculationInput, includePayments: true})
            .then(x => {
                if(!x.success){
                    return
                }
                return new FileFromResponse(x.response!).download()
            })
            .finally(() => setGenerating(false));
    }

    useEffect(() => {
        if(!props.open){
            setPayments([])
            return
        }

        setLoading(true);
        api.getCalculation(props.dealerId, {...props.calculationInput, includePayments: true})
            .then(x => {
                const payments = x?.summary?.payments;
                if(!payments){
                    return
                }
                setPayments(payments);
            })
            .finally(() => setLoading(false));

    }, [props.open]);

    const columns = useMemo(() => [
        { key: 'date', name: $t('date'), onRender: x => x.date },
        { key: 'startingBalance', name: $t('startingBalance'), textAlign: 'right', onRender: x => formatMoney(x.startingBalance) },
        { key: 'installment', name: $t('installment'), textAlign: 'right', onRender: x => formatMoney(x.installment) },
        { key: 'interest', name: $t('interest'), textAlign: 'right', onRender: x => formatMoney(x.interest) },
        { key: 'fees', name: $t('fees'), textAlign: 'right', onRender: x => formatMoney(x.fees) },
        { key: 'payment', name: $t('payment'), textAlign: 'right', onRender: x => formatMoney(x.paymentValue) },
        { key: 'endingBalance', name: $t('endingBalance'), textAlign: 'right', onRender: x => formatMoney(x.endingBalance) },
    ] as IDataTableColumn<Payment>[], [])

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            size='large'
            closeIcon
        >
            <Modal.Header>
                {$t('paymentPlan')}
            </Modal.Header>
            <Modal.Content scrolling>
                <BusyIndicator busy={busy}>
                    <DPDataTable
                        columns={columns}
                        data={{ items: payments, total: payments.length }}
                    />
                </BusyIndicator>
            </Modal.Content>
            <Modal.Actions>
                <DPButton onClick={getPaymentPlanPrintout} disabled={generating}>
                    <Icon name="download" /> {$t('download')} 
                </DPButton>
                <DPButton onClick={() => props.setOpen(false)}>
                    <Icon name="close" /> {$t('close')} 
                </DPButton>
            </Modal.Actions>
        </Modal>
    );
}