import {  Field } from 'formik'
import React from 'react'

interface IProps {
    name: string,
    label?: string,
    value?: string | number
    className?: string | undefined,
    disabled?: boolean,
}

export function DPInputCheckbox(props: IProps) {
    const rootClassName = 'dp-input dp-input-checkbox' + (props.className ? ' ' + props.className : '')
    return (
        <div className={rootClassName}>
            <label>
                <Field
                    type="checkbox"
                    name={props.name}
                    value={props.value}
                    disabled={props.disabled}
                />
                {props.label}
            </label>
        </div>
    )
}
