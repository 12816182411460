export class FileFromResponse{
    constructor(private response: Response){
    }

    public async download(){
        const disposition =
            this.response.headers.get('Content-Disposition')

        const filename =
            this.fileName(disposition)

        const blob = await this.response.blob()
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = filename
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove()
        URL.revokeObjectURL(url)
    }

    private fileName(contentDisposition: string | null) {
        const fileName =
            (contentDisposition ?? '')
                .split(';')
                .map(x => x.trim())
                .filter(x => x.startsWith('filename='))
                .map(x => x.replace('filename=', ''))
                // When file name contains space, it's wrapped in additional quotes, remove them.
                .map(x => x.replaceAll('"', '')) 
                [0]
        return fileName || 'file.txt'
    }
}