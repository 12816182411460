import { Field } from 'formik'
import React from 'react'

interface IProps {
    name: string,
    disabled?: boolean
}

export function DPInputPassword(props: IProps) {
    return <Field
        name={props.name}
        disabled={props.disabled}
        type="password"
        className="dp-input dp-input-password"
    />
}
