import { ReactNode } from 'react'
import styles from './PageSectionHeader.module.css'

interface IProps {
    children: ReactNode,
    className?: string,
    dataQa?: string,
    onClick?: () => void,
}



export function PageSectionHeader({ children, className, dataQa, onClick }: IProps) {
    return (
        <h3
            className={`${styles.header} ${className}`}
            onClick={onClick}
            data-qa={dataQa}
        >
            { children }
        </h3>
    )
}