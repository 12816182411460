import { useTranslation } from '@hooks/useTranslation'
import { DPInputDropdown } from './DPInputDropdown'

interface IProps {
    name: string,
}

export function DPInputUserTitleDropdown(props: IProps){
    const $t = useTranslation('DPInputUserTitleDropdown')

    const options = [
        {
            key: 'Beneficial Owner',
            text: $t('BeneficialOwner'),
            value: 'Beneficial Owner',
        },
        {
            key: 'Sales manager',
            text: $t('SalesManager'),
            value: 'Sales manager',
        },
        {
            key: 'Salesperson',
            text: $t('Salesperson'),
            value: 'Salesperson',
        },
        {
            key: 'Accountant',
            text: $t('Accountant'),
            value: 'Accountant',
        },
    ]

    return (
        <DPInputDropdown
            name={props.name}
            options={options}
            clearable
        />
    )
}