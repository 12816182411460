import { DescriptionList, DescriptionListItem, DPFormField, DPInputTextarea, PageSectionHeader, ProgressModal } from '@framework'
import { useTranslation } from '@hooks'
import { formatNumber } from 'shared/utils'
import { Formik, useFormikContext } from 'formik'
import React, { useContext, useEffect, useMemo } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import { ObjectSchema, ValidationError } from 'yup'
import { useCalculationValidationSchema, useCoBuyerValidationSchema, useCustomerValidationSchema, useObjectValidationSchema } from '../../ApplicationValidationSchema'
import { ApplicationContext } from '../../state'
import StatusMessages from './StatusMessages'
import { ObjectSearchMode } from '../../state/useSearchObjectMode'
import { AvailableObjectType } from 'pages/application/dataProviders/useAvailableObjectTypes'
import { Agreement } from 'model'

interface INotesModel {
  notes: string
}

interface IProps{
  agreements: Agreement[],
  objectTypes: AvailableObjectType[],
  setIsValid: (value: boolean | undefined) => void,
  isSubmitting: boolean,
  searchMode: ObjectSearchMode
}

const validate = (value: any, schema: ObjectSchema<any, any, any, any>) => {
  const errors: string[] = []

  try {
    schema.validateSync(value, { abortEarly: false })
  }
  catch (e: any) {
    const error = e as ValidationError
    errors.push(...error.errors)
  }

  return errors
}

export function SummaryTab(props: IProps) {
  const $t = useTranslation('SummaryTab')

  const context = useContext(ApplicationContext)
  const { application, calculationResult } = context
  const { object, calculation, customer, coBuyer } = application
  const agreement = props.agreements.find(x => x.code === calculation.agreementCode)

  const ojectTypeName = useMemo(() => {
    return props.objectTypes.find((x) => x.id === application.objectType)?.name || ''
  }, [props.objectTypes, application.objectType])

  const hasServiceAgreement =
    calculation?.serviceAgreementPrMonth !== null &&
    calculation?.serviceAgreementPrMonth > 0

  const objectValidationSchema = useObjectValidationSchema('summary', props.searchMode)
  const objectErrors = validate(application.object, objectValidationSchema)

  const calculationValidationSchema = useCalculationValidationSchema()
  const calculationErrorsExternal = (calculationResult?.validationErrors ?? []).map(x => x.text);
  const calculationErrorsInternal = validate(application.calculation, calculationValidationSchema)
  const calculationErrors = [...calculationErrorsExternal, ...calculationErrorsInternal]

  const customerValidationSchema = useCustomerValidationSchema(context.application)
  const customerErrors = validate(application.customer, customerValidationSchema)

  const coBuyerValidationSchema = useCoBuyerValidationSchema(context.application)
  const coBuyerErrors = validate(application.coBuyer, coBuyerValidationSchema)

  const isValid =
    objectErrors.length === 0 &&
    calculationErrors.length === 0 &&
    customerErrors.length === 0 &&
    coBuyerErrors.length === 0

  useEffect(() => {
    props.setIsValid(isValid)
    return () => props.setIsValid(undefined)
  }, [isValid])

  return (<>
    <ProgressModal
      open={props.isSubmitting}
      header={$t('Application is being send')}
      message={$t('It can take some time to send the application')}
    />

    {/* Client information */}
    <Grid relaxed stackable>
      <Grid.Row columns="2">
        <Grid.Column>
          <PageSectionHeader>
            {$t('Client information')}
          </PageSectionHeader>

          <DescriptionList>
            <DescriptionListItem label={$t('Name')} dataQa="summary_tab_customer_name">
              {customer?.firstName} {customer?.lastName}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Address')} dataQa="summary_tab_customer_address">
              {customer?.street} {customer?.zip} {customer?.city}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Mobile phone')} dataQa="summary_tab_customer_phone_number">
              {customer?.phoneNumber}
            </DescriptionListItem>
            <DescriptionListItem label={$t('E-mail')} dataQa="summary_tab_customer_email">
              {customer?.email}
            </DescriptionListItem>
            {coBuyer &&
              <DescriptionListItem label={$t('Co-Buyer')} dataQa="summary_tab_co_buyer">
                {coBuyer.firstName} {coBuyer.lastName}
                {coBuyer.street && <><br />{coBuyer.street}</>}
                {(coBuyer.zip || coBuyer.city) && <><br />{coBuyer.zip} {coBuyer.city}</>}
                {coBuyer.phoneNumber && <><br />{coBuyer.phoneNumber}</>}
                {coBuyer.email && <><br />{coBuyer.email}</>}
              </DescriptionListItem>
            }
          </DescriptionList>
        </Grid.Column>
        <Grid.Column>
          <PageSectionHeader>
            {$t('Status')}
          </PageSectionHeader>
          <StatusMessages messages={[...customerErrors, ...coBuyerErrors]} dataQa="summary_tab_client_status"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {/* Object information */}
    <Grid relaxed stackable>
      <Grid.Row columns="2">
        <Grid.Column>
          <PageSectionHeader>
            {$t('Object information')}
          </PageSectionHeader>
          <DescriptionList>
            <DescriptionListItem label={$t('Object type')} dataQa="summary_tab_object_type">
              {ojectTypeName}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Make')} dataQa="summary_tab_make">
              {object?.make}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Model')} dataQa="summary_tab_model">
              {object?.model}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Variant')} dataQa="summary_tab_variant">
              {object?.variant}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Price')} dataQa="summary_tab_price">
              {`kr. ${formatNumber(calculation?.objectPrice)}`}
            </DescriptionListItem>
          </DescriptionList>
        </Grid.Column>
        <Grid.Column>
          <PageSectionHeader>
            {$t('Status')}
          </PageSectionHeader>
          <StatusMessages messages={objectErrors} dataQa="summary_tab_object_status"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {/* Financial information */}
    <Grid relaxed stackable>
      <Grid.Row columns="2">
        <Grid.Column>
          <PageSectionHeader>
            {$t('Financial information')}
          </PageSectionHeader>
          <DescriptionList>
            <DescriptionListItem label={$t('Type of financing')} dataQa="summary_tab_agreement_name">
              {agreement?.name}
            </DescriptionListItem>
            {context.formConfiguration.hasServiceAgreements &&
              <DescriptionListItem label={$t('Service agreement')} dataQa="summary_tab_service_agreement">
                {hasServiceAgreement ? $t('Yes') : $t('No')}
              </DescriptionListItem>
            }

            <DescriptionListItem label={$t('Interest rate')} dataQa="summary_tab_interest_rate">
              {formatNumber(calculation?.interestRate || 0)} %
            </DescriptionListItem>
            <DescriptionListItem label={$t('Monthly payment')} dataQa="summary_tab_payment_per_month">
              kr {formatNumber(calculation?.paymentPerMonth || 0)}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Total credit costs')} dataQa="summary_tab_total_loan_costs">
              kr {formatNumber(calculationResult?.summary?.totalLoanCosts || 0)}
            </DescriptionListItem>
            <DescriptionListItem label={$t('Number of months')} dataQa="summary_tab_payment_terms">
              {formatNumber(calculation?.paymentTerms || 0)}
            </DescriptionListItem>
          </DescriptionList>
        </Grid.Column>
        <Grid.Column>
          <PageSectionHeader>
            {$t('Status')}
          </PageSectionHeader>
          <StatusMessages messages={calculationErrors} dataQa="summary_tab_calculation_status"/>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Formik
      initialValues={{ notes: context.application.notes }}
      onSubmit={(changes: INotesModel) => { context.setNotes(changes.notes) }}
      autoSubmit
    >
      {formikProps => 
        <Form onSubmit={formikProps.handleSubmit}>
          <PageSectionHeader>
            {$t('Comments')}
          </PageSectionHeader>
          <DPFormField
            name="notes"
            inline={false}
          >
            <DPInputTextarea
              name="notes"
              rows={5}
            />
          </DPFormField>
          <FormBehaviors />
        </Form>
      }
    </Formik>
  </>)
}

function FormBehaviors(){
  const context = useContext(ApplicationContext)
  const formik = useFormikContext<{ notes: string }>()
  const { notes } = formik.values

  useEffect(() => {
    context.setNotes(notes)
  }, [notes])

  return null
}