import { IPricing, OwnPricingApiClient } from 'api/OwnPricingApiClient'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect, useState } from 'react'

export default function usePurchaseAgreementPricing(
        dealerId: number | undefined,
        agreementType: 'purchase' | 'unsecure' | 'leasing'){
    const ownPricingApi = useApiClient(x => new OwnPricingApiClient(x))
    const [pricing, setPricing] = useState<IPricing>()
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const refresh = () => {
        setIsLoading(true)
        return ownPricingApi.getAgreementPricing(dealerId!, agreementType)
            .then(response => {
                if(!response){
                    return
                }

                setPricing(response)
            })
            .finally(() => setIsLoading(false))
    }

    const update = (pricing: IPricing) => {
        setIsUpdating(true)
        return ownPricingApi.updateAgreementPricing(dealerId!, agreementType, pricing)
            .then(response => {
                if(!response?.success){
                    return
                }

                return refresh()
            })
            .finally(() => setIsUpdating(false))
    }

    useEffect(() => {
        if(!dealerId){
            return
        }

        refresh()
    }, [dealerId])

    const isBusy = isLoading || isUpdating
    const isInitialized = pricing !== undefined
    return { pricing, isInitialized, isBusy, update }
}