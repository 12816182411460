import { routes } from 'routing'
import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

export function LogOutComponent() {
    const $t = useTranslation('Shared.Layout')

    return (
        <Link to={routes.logOut()}>
            <Icon link name="log out" />
            {$t('Log Out')}
        </Link>
    )
}
