import { CalculatorApiClient } from 'api'
import { IApplicationContext } from 'pages/application/model'
import { ApplicationContext } from 'pages/application/state'
import { Agreement } from 'model'
import { DPFormField, DPInputDropdown, PageSectionHeader } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import { Formik, FormikProps } from 'formik'
import React, { useContext, useEffect, useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { ApplicationType, ObjectType } from 'shared'
import { AvailableObjectType } from 'pages/application/dataProviders/useAvailableObjectTypes'

interface IForm {
    agreementCode: number,
    objectType: number,
    objectAgeMonths: number | null,
}

export interface IProductComponentProps {
    agreements: Agreement[]
    objectTypes: AvailableObjectType[]
    onTitleClick(): void
}

export function ProductComponent({
    onTitleClick,
    agreements,
    objectTypes
}: IProductComponentProps) {
    const $t = useTranslation('ProductComponent')
    const session = useSession()
    const currentDealerId = session.currentDealer!.id
    const context = useContext(ApplicationContext)
    const objectType = context.application.objectType
    const calculatorApiClient = useApiClient((a) => new CalculatorApiClient(a))
    const [ageCategories, , getAgeCategories] = useApiCall(calculatorApiClient.getAgeCategories, [])
    const agreementCode = context.application.calculation.agreementCode

    useEffect(() => {
        getAgeCategories(currentDealerId, objectType)
    }, [currentDealerId, objectType])

    // List of available object types based on currently selected agreement.
    const objectTypeOptions = useMemo(() => {
        const agreement = agreements.find(x => x.code === agreementCode)
        if(agreement === undefined){
            return []
        }

        return objectTypes
            .filter(x => agreement.objectTypes.indexOf(x.id) >= 0)
            .map((x) => {
                return {
                    key: '' + x.id,
                    value: x.id,
                    text: x.name
                }
            })
    }, [objectTypes, agreements, agreementCode])

    const agreementCodeOptions = useMemo(() => {
        return agreements
            .filter(x => {
                switch(context.application.type){
                    case ApplicationType.Private:
                        return x.isPrivate && !x.isPersonnel;
                    case ApplicationType.Corporate:
                        return x.isCorporate;
                    case ApplicationType.Personnel:
                        return x.isPrivate && x.isPersonnel;
                    case ApplicationType.Leasing:
                        return false;
                }
            })
            .filter(
                x => context.formConfiguration.hasObjectTypeOnCalculationTab
                    ? true
                    : x.objectTypes.indexOf(context.application.objectType) !== -1)
            .map((x) => {
                return {
                    key: '' + x.code,
                    value: x.code,
                    text: x.name
                }
            })
    }, [agreements, context.application.type, context.formConfiguration.hasObjectTypeOnCalculationTab])

    const initialValues : IForm = {
        agreementCode,
        objectType: context.application.objectType,
        objectAgeMonths: context.application.calculation.objectAgeMonths,
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={() => { return }}
            validateOnMount={true}
        >
            { props => 
                <Form onSubmit={props.handleSubmit}>
                    <PageSectionHeader
                        onClick={() => onTitleClick()}
                        dataQa="calculation_product_header"
                    >
                        {$t('Product')}
                    </PageSectionHeader>
                    <DPFormField
                        key="agreementCode"
                        name="agreementCode"
                        label={$t('Agreement')}
                    >
                        <DPInputDropdown
                            name="agreementCode"
                            options={agreementCodeOptions}
                            dataQa="product_agreement"
                        />
                    </DPFormField>
                    { context.formConfiguration.hasObjectTypeOnCalculationTab && (
                        <DPFormField
                            key="objectType"
                            name="objectType"
                            label={$t('Object type')}
                        >
                            <DPInputDropdown
                                name="objectType"
                                options={objectTypeOptions}
                                disabled={objectTypeOptions.length <= 1}
                                dataQa="product_object_type"
                                />
                        </DPFormField>
                    )}
                    { context.formConfiguration.hasObjectAgeMonthsOnCalculationTab &&
                        <DPFormField
                            name="objectAgeMonths"
                            label={$t('Age')}
                        >
                            <DPInputDropdown
                                name="objectAgeMonths"
                                options={ageCategories}
                                dataQa="product_age"
                                />
                        </DPFormField>
                    }

                    <FormBehaviors
                        formik={props}
                        context={context}
                        agreements={agreements}
                    />
                </Form>
            }
        </Formik>
    )
}

function FormBehaviors(props: { formik: FormikProps<IForm>, context: IApplicationContext, agreements: Agreement[] }){
    const { dirty, values } = props.formik

    const isNewObject =
      (objectType: ObjectType, objectAgeMonths: number | null) => {
        switch(objectType){
          case ObjectType.Camping:
          case ObjectType.Boat:
          case ObjectType.BoatAccessories:
            return objectAgeMonths === 0;
          default:
            return null
        }
      };

    useEffect(() => {
        if(!dirty){
            return
        }

        // Mileage has to be set to at least 500, when car is older than one year.
        // In other case error is "En brugt bil skal have kørt mere end 500 km" is returned.
        const mileage  = values.objectAgeMonths && values.objectAgeMonths > 12 ? 500 : 0
        props.context.setCalculation({
            ...props.context.application.calculation, 
            objectAgeMonths: values.objectAgeMonths,
            mileage,
            firstRegistrationDate: null,
            isNewObject: isNewObject(values.objectType, values.objectAgeMonths),
            // Clear properties from calculation section except object price.
            downPayment: null,
            downPaymentPct: null,
            paymentPerMonth: null,
            paymentTerms: null,
            interestType: null,
            interestRate: null,
            documentFee: null,
            commitmentFee: null,
        },
        values.objectType)
    }, [values.objectAgeMonths, values.objectType])

    useEffect(() => {
        if(!dirty){
            return
        }

        const agreement =
            props.agreements.find(x => x.code === values.agreementCode)!
        const objectType =
            agreement.objectTypes.indexOf(values.objectType) === -1 || agreement.objectTypes.length === 1
                ? agreement.objectTypes[0]
                : values.objectType

        props.context.setCalculation({
            ...props.context.application.calculation,
            agreementCode: values.agreementCode,
            isNewObject: isNewObject(objectType, values.objectAgeMonths),
            // Clear properties from calculation section except object price.
            downPayment: null,
            downPaymentPct: null,
            paymentPerMonth: null,
            paymentTerms: null,
            interestType: null,
            interestRate: null,
            documentFee: null,
            commitmentFee: null,
        },
        objectType)
    }, [values.agreementCode])

    return null
}
