import { useApiClient } from "@hooks/useApiClient"
import { useInterval } from "@hooks/useInterval"
import { useSession } from "@hooks/useSession"
import { IncomingApplicationApiClient } from "api"
import { ReactNode, createContext, useEffect, useState } from "react"

interface IncomingApplicationCounterContextType {
    count?: number,
    refresh: () => void,
}

export const IncomingApplicationCounterContext = createContext<IncomingApplicationCounterContextType | undefined>(undefined)

export function IncomingCaseCounterProvider(props: { children: ReactNode }){
    const api = useApiClient(x => new IncomingApplicationApiClient(x))
    const [count, setCount] = useState<number>()
    const session = useSession()
    const dealerId = session.currentDealer?.id

    const refresh = () => {
        if(!dealerId){
            return
        }

        api.getIncomingApplications(dealerId, { suppressError: true })
            .then(x => setCount(x?.total))
    }

    useInterval({
        callback: refresh,
        delay: 3 * 60 * 1000,
    })

    useEffect(() => {
        refresh()
    }, [dealerId])

    return (
        <IncomingApplicationCounterContext.Provider value={{ count, refresh }}>
            {props.children}
        </IncomingApplicationCounterContext.Provider>)
}