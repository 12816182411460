import { SubmittedApplicationApiClient } from 'api'
import { routes } from 'routing'
import { BusyIndicator, DPFormField, DPInputTextReadOnly, FormikForm, FormSubmit, PageHeader } from '@framework'
import { useApiClient, useSession, useTranslation } from '@hooks'
import { DPInputCheckbox } from 'infrastructure/framework/DPForms/DPInputCheckbox'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import styles from './ObjectDeliveredPage.module.css'

interface IForm {
    vehicleRegistrationNumber: string,
    overrideRegistrationNumberCheck: boolean,
}

export default function ObjectDeliveredPage(){
    const $t = useTranslation('ObjectDeliveredPage')
    const navigate = useNavigate()
    const { referenceId } = useParams()
    const apiClient = useApiClient(x => new SubmittedApplicationApiClient(x))
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [regNumberIsInvalidAccordingETL, setRegNumberIsInvalidAccordingETL] = useState(false)
    const session = useSession()
    const dealerId = session.currentDealer?.id
    const [initialValues, setInitialValues] = useState<IForm | undefined>(undefined)
    const [vin, setVin] = useState<string | undefined>(undefined)

    useEffect(() => {
        setIsLoading(true)
        apiClient.getApplication(dealerId!, referenceId!)
            .then(response => {
                if(!response){
                    return
                }

                setInitialValues({
                    vehicleRegistrationNumber: response.objectRegistrationNumber ?? '',
                    overrideRegistrationNumberCheck: false,
                })
                setVin(response.objectSerialNumber)
            })
            .finally(() => setIsLoading(false))
    }, [referenceId])

    const validationSchema = Yup.object().shape({
        vehicleRegistrationNumber: Yup.string().required().label($t('Registration No.')),
        overrideRegistrationNumberCheck: Yup.boolean(),
    })

    const submit = (value: IForm) => {
        setIsSubmitting(true)
        apiClient.objectDelivered(
            session.currentDealer?.id!,
            {
                referenceId: referenceId!,
                ...value
            })
        .then(response => {
            if(!response.success){
                return
            }

            if(!response.data){
                return
            }

            setRegNumberIsInvalidAccordingETL(response.data?.regNumberIsInvalidAccordingETL)

            // If success and registration number is valid, redirect to application details page.
            if(!response.data.regNumberIsInvalidAccordingETL){
                navigate(routes.applicationSubmittedApplication(referenceId!))
            }
        })
        .finally(() => setIsSubmitting(false))
    }

    return (
        <div className={styles.page}>
            <PageHeader text={$t('Object delivered')} />
            <BusyIndicator busy={isSubmitting || isLoading}>
                { initialValues &&
                    <FormikForm
                        initialValues={initialValues}
                        onSubmit={submit}
                        validationSchema={validationSchema}
                    >
                        <p>
                            { $t('enterOrConfirmRegistrationNumber') }
                        </p>

                        <DPFormField
                            label={$t('Registration No.')}
                            name="vehicleRegistrationNumber"
                            required
                        >
                            <DPInputText name="vehicleRegistrationNumber" />
                        </DPFormField>

                        <DPFormField
                            label={$t('Chassis No.')}
                            name="vehicleIdentificationNumber"
                            required
                        >
                            <DPInputTextReadOnly
                                value={vin ?? ''}
                            />
                        </DPFormField>

                        <p className={styles.bold}>
                            { $t('chassisNumberWarning') }
                        </p>

                        <p>
                            { $t('registrationNumberWarning') }
                        </p>

                        <ol>
                            <li>
                                { $t('registrationNumberWarningReason1') }
                            </li>
                            <li>
                                { $t('registrationNumberWarningReason2') }
                            </li>
                        </ol>

                        {regNumberIsInvalidAccordingETL &&
                            <>
                                <div className={styles.warning}>
                                    <p className={styles.bold}>
                                        { $t('registrationNumberDoesNotMatchParagraph1') }
                                    </p>

                                    <p className={styles.bold}>
                                        { $t('registrationNumberDoesNotMatchParagraph2') }
                                    </p>

                                    <p>
                                        { $t('registrationNumberDoesNotMatchParagraph3') }
                                    </p>
                                </div>

                                <DPInputCheckbox
                                    name="overrideRegistrationNumberCheck"
                                    label={$t('Both registration number and chassis number are correct')}
                                    className={styles.checkbox}
                                />
                            </>
                        }

                        <FormSubmit submitName={$t('Save')} />
                    </FormikForm>
                }
            </BusyIndicator>
        </div>
    )
}