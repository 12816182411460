import { useTranslation } from '@hooks/useTranslation'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, SemanticCOLORS } from 'semantic-ui-react'
import styles from './FormSubmit.module.css'
import { DPButton } from 'shared'

interface IFormSubmitProps {
    disableIfEmpty?: boolean
    isSubmitting?: boolean
    submitName: string
    backUrl?: string
    color?: SemanticCOLORS
    withReset?: boolean
    style?: any
    onBack?(): void
    onReset?(): void
}

export function FormSubmit({
    isSubmitting,
    submitName,
    backUrl,
    onBack,
    withReset,
    onReset,
    disableIfEmpty,
    style
}: IFormSubmitProps) {
    const $t = useTranslation('FormSubmit')

    const { resetForm, values } = useFormikContext()
    const [formIsEmpty, setFormIsEmpty] = useState(isEmpty(values))

    useEffect(() => {
        setFormIsEmpty(isEmpty(values))
    }, [values])

    const handleReset = () => {
        resetForm()
        if (onReset) {
            onReset()
        }
    }
    // const { currentApp } = useRouter()
    // const canSubmit = (currentApp === AppNames.Core && AuthorizationHelper.HasCorePermissions())
    //     || (currentApp === AppNames.CaseManagement && AuthorizationHelper.HasCaseManagementPermissions())
    const canSubmit = true

    return (
        <div className={styles.wrapper} style={style}>
            <Form.Group>
                <DPButton
                    size="mini"
                    type="submit"
                    disabled={!canSubmit || isSubmitting || (disableIfEmpty && formIsEmpty)}
                >
                    {submitName}
                </DPButton>
                {backUrl && (
                    <Link className={styles["back-button"]} to={backUrl}>
                        {$t('Back')}
                    </Link>
                )}
                {onBack && (
                    <a className={styles["back-button"]} onClick={onBack}>
                        {$t('Back')}
                    </a>
                )}
                {(withReset || onReset) && (
                    <a className={styles["back-button"]} onClick={handleReset}>
                        {$t('Reset')}
                    </a>
                )}
            </Form.Group>
        </div>
    )
}

function isEmpty(obj: any) {
    for (const key in obj) {
        if (obj.hasOwnProperty && obj.hasOwnProperty(key)) {
            if (obj[key]) {
                return false
            }
        }
    }
    return true
}
