import { Formik, FormikErrors, FormikTouched } from 'formik'
import React, { ReactChild, ReactNode } from 'react'
import { Form } from 'semantic-ui-react'

interface IFormikFormProps<T> {
    initialValues: T
    children: ReactChild | ReactChild[] | ReactNode
    validationSchema?: any | (() => any)
    onSubmit(values: T): void    
}

export function FormikForm<T>({
    children,
    initialValues,
    validationSchema,
    onSubmit,

}: IFormikFormProps<T>) {
    return (
        <Formik
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange={false}
            initialValues={initialValues || ({} as T)}
            validationSchema={validationSchema}
            onSubmit={(v) => onSubmit(v)}
        >
            {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
                {children}
            </Form>
            )}
        </Formik>
    )
}
