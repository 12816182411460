import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
// TODO Check what can we do to get rid of this bunch of CSS.
import './DPInputDate.module.css'

const toIsoDateStringFromLocalTime = (value: Date | null | undefined) =>
    !value ? null : new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())).toISOString().substring(0, 10);

/**
 * React DatePicker works on local time.
 * When you convert ISO date string to Date object in JS, it's converted to local time, so it's ready to use.
 * I assume here that "2023-08-07" converted at 1 PM is converted to 2023-08-07 01:00 PM of local time.
 * */
const toLocalDate = (value: string | null | undefined) => {
    if(!value){
        return null
    }
    return new Date(value);
}

interface Props {
    name: string,
    disabled?: boolean,
    minDate?: string,
    maxDate?: string,
}

/**
 * DPInputDate is a date picker input that doesn't support time part.
 */
export function DPInputDate({ name, disabled, minDate, maxDate }: Props) {
    const { setFieldValue, setFieldTouched } = useFormikContext()
    const [field] = useField(name)
    const localValue = toLocalDate(field.value as string)

    return (
        <div className='dp-input dp-input-date'>
            <DatePicker
                name={name}
                id={name}
                dateFormat={'dd-MM-yyyy'}
                selected={localValue}
                onChange={async (date) => {
                    setFieldValue(name, toIsoDateStringFromLocalTime(date))
                    await Promise.resolve() // https://github.com/jaredpalmer/formik/issues/529#issuecomment-400832225
                    setFieldTouched(name, true)
                }}
                onBlur={e => field.onBlur(e)}
                autoComplete="off"
                disabled={disabled}
                minDate={toLocalDate(minDate)}
                maxDate={toLocalDate(maxDate)}
            />
            <div className="dp-input-icon">
                <CalendarIcon />
            </div>
        </div>
    )
}

function CalendarIcon(){
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
        </svg>
    )
}