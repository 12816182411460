import { FileModel } from '@api/models'
import { DisplayImageModal, ImageData } from '@framework'
import { useField, useFormikContext } from 'formik'
import { ChangeEvent, useRef, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from '@hooks/useTranslation'
import styles from './DPFileInput.module.css'
import { DPButton } from 'shared'
import DPTable from './DPTable'

interface IProps {
    downloadFileCallback?: (id: number) => Promise<void>
    name: string
    readonly?: boolean
}

interface ImageLinkProps {
    downloadFileCallback?: (fileId: number) => Promise<void>
    file: FileModel
    showImage: (f: FileModel) => void
}

function toBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = (error) => reject(error)
    })
}

/**
 * Internal component for rendering attachment links and navigation handling
 */
function AttachmentLink({ downloadFileCallback, file, showImage }: ImageLinkProps) {
    const extension = file.name.split(".").pop()?.toLocaleLowerCase() ?? ''
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].indexOf(extension) !== -1
    return (
        <>
            { isImage ? (
                <button className="link" type="button" onClick={() => showImage(file)}>
                    <Icon name="file alternate outline" />
                    {file.name}
                </button>
            ) : file.id ? (
                downloadFileCallback && (
                    <button className="link" type="button" onClick={() => downloadFileCallback(file.id!)}>
                        <Icon name="file alternate outline" />
                        {file.name}
                    </button>
                )
            ) : (
                <a href={file.data} download={file.name}>
                    <Icon name="file alternate outline" />
                    {file.name}
                </a>
            )}
        </>
    )
}

export function DPFileInput({ 
    downloadFileCallback,
    name,
    readonly = false
}: IProps) {
    const { setFieldValue } = useFormikContext<FileModel[]>()
    const [field] = useField<FileModel[]>(name)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const $t = useTranslation('FileList')
    const [imageModalOpen, setImageModalOpen] = useState(false)
    const [imageData, setImageData] = useState<ImageData>({src: '', fileName: ''})
    const allowedExtensions = ['.pdf', '.xls', '.xlsx', '.xlsm', '.csv', '.jpg', '.jpeg', '.png', '.gif'];

    const addFilesButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click()
        }
    }

    const changeFiles = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files) {
            const fileList = event.currentTarget.files
            const f: FileModel[] = []
            for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]
                f.push({
                    name: file.name,
                    size: file.size,
                    data: await toBase64(file)
                })
            }
            const replaced = replaceFiles(field.value, f)
            setFieldValue(name, replaced)
        }
    }

    const removeFile = (file: FileModel) => {
        const filteredOut = field.value.filter((x) => x.name !== file.name)
        setFieldValue(name, filteredOut)
    }

    /**
     * Produces new files collection which is concatenation of "files" and "newFiles".
     * If file with the same name exists in "files" and "newFiles" then version from files gets replaced with the one from "newFiles".
     */
    const replaceFiles = (files: FileModel[], newFiles: FileModel[]): FileModel[] => {
        for (let i = 0; i < newFiles.length; i++) {
            const found = files.find((x) => x.name === newFiles[i].name)
            if (found) {
                found.data = newFiles[i].data
                found.size = newFiles[i].size
            }
        }
        const toAdd = newFiles.filter((x) => !files.find((y) => x.name === y.name))
        const afterConcat = files.concat(toAdd)
        return afterConcat
    }

    const showImage = async (file: FileModel) => {
        setImageData({
            id: file.id,
            fileName: file.name,
            src: file.data.startsWith('data:image') ? file.data : `data:image/png;base64,${file.data}`
        })
        setImageModalOpen(true)
    }

    return (
        <div className="mt-1">
            {!readonly && (
                <DPButton type="button" size='small' onClick={addFilesButtonClick}>
                    {$t('AddFiles')}
                </DPButton>
            )}
            <input
                type="file"
                multiple
                accept={allowedExtensions.join(',')}
                ref={fileInputRef}
                className={styles['file-input-fixed-postion']}
                onChange={changeFiles}
            />
            <DPTable compact>
                <thead>
                    <tr>
                        <th>{$t('File')}</th>
                        <th>{$t('SizeInBytes')}</th>
                        {!readonly && <th />}
                    </tr>
                </thead>
                <tbody>
                    {field.value.map((x, idx) => {
                        return (
                            <tr key={idx}>
                                <td className="selectable">
                                    <AttachmentLink
                                        downloadFileCallback={downloadFileCallback}
                                        file={x}
                                        showImage={showImage}
                                    />
                                </td>
                                <td>{x.size}</td>
                                {!readonly && (
                                    <td>
                                        <DPButton
                                            compact
                                            color="grey"
                                            type="button"
                                            onClick={() => removeFile(x)}
                                            children={<Icon name="trash alternate outline" />}
                                        />
                                    </td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </DPTable>
            <DisplayImageModal
                imageData={imageData}
                open={imageModalOpen}
                closeCallback={setImageModalOpen}
                downloadCallback={downloadFileCallback}
            />
        </div>
    )
}
