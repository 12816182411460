import { useField, useFormikContext } from 'formik'
import JoditEditor from 'jodit-react'
import React, {useMemo, useRef} from 'react'
import './DPRichTextInput.css'

const toolbarButtonsLargeScreenConfig = [
  'bold', 'italic', 'underline', 'strikethrough', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'paragraph', 'lineHeight', '---', 'superscript', 'subscript', '|', 'image', 'video', '\n',
  'cut', 'copy', 'paste', 'selectall', '|', 'hr', 'table', 'link', 'symbol', '|', 'indent', 'outdent', 'left', '|', 'brush', '---', 'undo', 'redo', '|', 'preview'
]

const toolbarButtonsCompactConfig = [
  'bold', 'italic', 'underline', 'strikethrough', '|', 'ul', 'ol', '|', 'font', 'fontsize', 'paragraph', 'lineHeight', '|', 'superscript', 'subscript', '|', 'image', 'video', '\n',
  'cut', 'copy', 'paste', 'selectall', '|', 'hr', 'table', 'link', 'symbol', '|', 'indent', 'outdent', 'left', '|', 'brush', '|', 'undo', 'redo', '|', 'preview'  
]

export function DPRichTextInput({ name }: { name: string }) {
  const { setFieldValue, setFieldTouched } = useFormikContext()
  const [field] = useField(name)
  const editor = useRef(null)

  const getConfig = () => {
    const cfg = {
      uploader: {
        insertImageAsBase64URI: true
      },
      readonly: false,
      toolbarButtonSize: 'large',
      minHeight: '500px',
      askBeforePasteHTML: false,
      defaultActionOnPaste: 'insert_only_text',
      buttons: [ ... toolbarButtonsLargeScreenConfig ],
      buttonsMD: [ ...toolbarButtonsCompactConfig ],  // need to configure medium and small screen separately otherwise default limited button set is used
      buttonsSM: [ ...toolbarButtonsCompactConfig ],
      language: 'en', // It's agreed with business that we don't translate this component.
      i18n: {
        en: {
          'Type something': ' ' // It's a hack to hide default placeholder.
        }
      }
    }

    // Type definition of Jodit's plugins has been changed between versions 3.19.5 and 3.24.7, which brakes TS type check.
    // In the future we can try to remove casting to 'any' type, there is a PBI for it:
    // https://dev.azure.com/scbnordic/Auto%20ART/_boards/board/t/HoneyBadgers/Stories/?workitem=194234
    return cfg as any
  }

  const handleBlur = (value: string) => {
    setFieldValue(name, value)
    setFieldTouched(name, true)
  }

  const config = useMemo(getConfig, [])

  return (
    <JoditEditor
      ref={editor}
      value={field.value}
      config={config}
      onBlur={handleBlur}
    />
  )
}