import { ApiClient } from '@api/ApiClient'

export interface IPricing {
    variableInterest: number,
    fixedInterest: number,
    dealerCommitmentCommissionMin: number,
    dealerCommitmentCommissionPct: number,
    documentFee: number,
}

export class OwnPricingApiClient{
    constructor(protected apiClient: ApiClient) { }

    public getAgreementPricing(dealerId: number, agreementType: 'purchase' | 'unsecure' | 'leasing'){
        return this.apiClient.get<IPricing>(`api/dealers/${dealerId}/own-pricing/${agreementType}/`).then(x => x.data)
    }

    public updateAgreementPricing(dealerId: number, agreementType: 'purchase' | 'unsecure' | 'leasing', pricing: IPricing){
        return this.apiClient.post<IPricing>(`api/dealers/${dealerId}/own-pricing/${agreementType}/`, pricing)
    }
}