import './ErrorToast.css'

interface IProps {
  visible: boolean
  errors: {[key: string]: string}
}

/**
 * Display list of errors as a toast which fades out over time.
 * Visibility is controlled parent component.
 */
export function ErrorToast({
  visible,
  errors
}: IProps)  {
  return (
    <>
    {
      <div className={`error-summary${visible ? ' show': ''}`}>
      {
        Object.keys(errors).map((key, idx) =>
        <div key={idx}>{errors[key]}</div>
        )
      }
      </div>
    }
    </>
  )
}