import { ApiClient } from '@api'

export class PublicApiClient {
    constructor(protected apiClient: ApiClient) { }

    public resetPasswordRequest = (request: { username: string | null }) => {
        return this.apiClient.post(`api/public/reset-password-request`, request)
    }

    public resetPassword = (request: {
        username: string | null,
        token: string | null,
        password: string | null
    }) => {
        return this.apiClient.post(`api/public/reset-password`, request)
    }

    public resetUsernameRequest = (request: { email: string | null }) => {
        return this.apiClient.post(`api/public/reset-username-request`, request)
    }

    public resetUsername = (request: {
        email: string | null,
        username: string | null,
        token: string | null,
    }) => {
        return this.apiClient.post(`api/public/reset-username`, request)
    }

    public newUserRequest = (request: { email: string | null }) => {
        return this.apiClient.post(`api/public/new-user-request`, request)
    }

    public newUser = (request: {
        email: string | null,
        token: string | null,
        username: string | null,
        password: string | null,
    }) => {
        return this.apiClient.post(`api/public/new-user`, request)
    }
}