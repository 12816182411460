import { readCookie, setInfiniteCookie } from './CookieHelper'

export interface ILanguageStore {
  getLanguage(): string,
  setLanguage(language: string): void
}

export class LanguageCookieStore implements ILanguageStore {
  private readonly languageKey = 'language'
  private readonly defaultLanguage = 'da'

  public setLanguage(language: string): void {
    setInfiniteCookie(this.languageKey, language)
  }

  public getLanguage(): string {
    return readCookie(this.languageKey) || this.defaultLanguage
  }
}