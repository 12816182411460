import { IGridResult, emptyGrid, IPagination, defaultQuery } from '@api/models'
import { DPDataTable, DPFormField, DPInputDropdown, DPInputText, DPTableSearchForm, IDataTableColumn, PageHeader } from '@framework'
import { useApiCall } from '@hooks/useApiCall'
import { useApiClient } from '@hooks/useApiClient'
import { useTranslation } from '@hooks/useTranslation'
import { UserApiClient, DealerApiClient, UserOverview, GetUsersQuery } from 'api'
import { IDropdownOption } from 'model'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { routes } from 'routing'
import { dateToDanishDate } from 'shared/dateTools'

interface ISearchParameters {
    userName: string
    dealerId: number | null
}

const initialValues: ISearchParameters = {
    userName: '',
    dealerId: null
}

export function ShowUsersPage() {
    const $t = useTranslation('Administration.ShowUsers')
    const userApiClient = useApiClient(p => new UserApiClient(p))
    const dealerApiClient = useApiClient(p => new DealerApiClient(p))
    const [users, setUsers] = useState<IGridResult<UserOverview>>(emptyGrid)
    const [dealers, , getDealers] = useApiCall<Array<IDropdownOption<number>>>(dealerApiClient.getDealersDropdown, [])
    const [usersTableState, setUsersTableState] = useState<IPagination | undefined>({ pageNumber: 1, pageSize: 10})
    const [searchParams, setSearchParams] = useState<ISearchParameters>({ ...initialValues })
    const navigate = useNavigate()
    const [isLoadingUsers, setIsLoadingUsers] = useState(false)

    useEffect(() => {
        getUserList()
    }, [usersTableState, searchParams])

    useEffect(() => {
        getDealers()
    }, [])

    const getUserList = () => {
        setIsLoadingUsers(true)
        userApiClient
            .getUsers({ ...usersTableState, ...searchParams } as GetUsersQuery)
            .then(data => {
                if (data === undefined) {
                    setUsers(emptyGrid)
                    return
                }

                setUsers(data)
            })
            .finally(() => setIsLoadingUsers(false))
    }

    function handleSubmit(values: ISearchParameters) {
        setSearchParams(values)
    }

    return (
        <article>
            <PageHeader text={$t('Dealer portal users')} />

            <DPTableSearchForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                debounce={{keys: ["userName"], delay: 500}}
                column1={
                    <DPFormField name="userName" label={$t('User')} inline={false}>
                        <DPInputText name="userName"/>
                    </DPFormField>
                }
                column2 = {
                    <DPFormField name="dealerId" label={$t('Dealer')} inline={false}>
                        <DPInputDropdown name="dealerId" options={dealers} clearable search />
                    </DPFormField>
                }
            />

            <div className='mt-4'>
                <DPDataTable
                    columns={columns($t)}
                    onRowSelect={({ id }) => navigate(routes.administrationUserEdit(id))}
                    data={users}
                    initialPagination={defaultQuery}
                    isLoading={isLoadingUsers}
                    onStateChange={setUsersTableState}
                />
            </div>
        </article>
    )
}

const columns = ($t: any): Array<IDataTableColumn<UserOverview>> => {
    return [
        {
            key: 'userName',
            name: $t('UserName'),
            onRender: ({ id, userName }) => <Link to={routes.administrationUserEdit(id)}>{userName}</Link>
        },
        {
            key: 'firstName',
            name: $t('FirstName')
        },
        {
            key: 'lastName',
            name: $t('LastName')
        },
        {
            key: 'email',
            name: $t('Email')
        },
        {
            key: 'sellerNo',
            name: $t('SellerNo')
        },
        {
            key: 'sellerAgentNo',
            name: $t('SellerAgentNo')
        },
        {
            key: 'active',
            name: $t('Active'),
            textAlign: 'right'
        },
        {
            key: 'blocked',
            name: $t('Blocked')
        },
        {
            key: 'creationDate',
            name: $t('CreationDate'),
            onRender: ({ creationDate }) => dateToDanishDate(creationDate)
        },
        {
            key: 'lastLoggedIn',
            name: $t('LastLoggedIn'),
            onRender: ({ lastLoggedIn }) => dateToDanishDate(lastLoggedIn)
        }
    ]
}
