import { CalculationInput } from 'model'
import { IApplicationContextState } from '../model/IApplicationContextState'
import { ApplicationAction, 
  IHandleValidationOfCalculationInputAction, 
  ISetCalculationInputAction, 
  ISetCalculationResultAction, 
  ISetCoBuyerAction, ISetCustomerAction, 
  ISetFirstRegistrationDateAction, 
  ISetNotesAction,
  ISetObjectAction
   } from './ApplicationAction'

export function applicationReducer(
  state: IApplicationContextState,
  action: ApplicationAction
): IApplicationContextState {

  switch (action.type) {
    case 'setCalculationInput':
      return onSetCalculationInput(state, action)
    case 'setCalculationResult':
      return onSetCalculationResult(state, action)
    case 'handleValidationOfCalculationInput':
      return onHandleValidationOfCalculationInput(state, action)
    case 'setCoBuyer':
      return onSetCoBuyer(state, action)
    case 'setCustomer':
      return onSetCustomer(state, action)
    case 'setObject':
      return onSetObject(state, action)
    case 'setFirstRegistrationDate':
      return onSetFirstRegistrationDate(state, action)
    case 'setNotes':
      return onSetNotes(state, action)
  }
}

function onSetCalculationInput(
  state: IApplicationContextState,
  action: ISetCalculationInputAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
      calculation: {
        ...action.input,
      },
      objectType: action.objectType,
    },
  }
}

function onSetCalculationResult(
  state: IApplicationContextState,
  action: ISetCalculationResultAction
): IApplicationContextState {
  // this input has to be same reference in calculationResult and application.calculationInput to avoid infinite recalculation loop
  const newInput: CalculationInput = {
    ...(action.result.input || action.default.calculation),
    objectAgeMonths: action?.result?.input?.objectAgeMonths || 0,
    firstRegistrationDate: action?.result?.input?.firstRegistrationDate || state.application.object.firstRegistrationDate || null,
    // Common calculator has a bug and doesn't round downPaymentPct to 2 decimal places.
    downPaymentPct:
      action.result.input?.downPaymentPct !== null
        ? Number(action.result.input!.downPaymentPct.toFixed(2))
        : null
  }

  return {
    ...state,
    calculationResult: {
      ...action.result,
      input: newInput
    },
    application: {
      ...state.application,
      calculation: newInput
    }
  }
}

function onHandleValidationOfCalculationInput(
  state: IApplicationContextState,
  action: IHandleValidationOfCalculationInputAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
    },
    calculationResult: {
      ...state.calculationResult!,
      input: state.application.calculation,
      validationErrors: action.result.validationErrors,
      hasInternalError: action.result.hasInternalError,
    }
  }
}

function onSetCoBuyer(
  state: IApplicationContextState,
  action: ISetCoBuyerAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
      coBuyer: action.coBuyer
    }
  }
}

function onSetCustomer(
  state: IApplicationContextState,
  action: ISetCustomerAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
      customer: action.customer
    }
  }
}

function onSetObject(
  state: IApplicationContextState,
  action: ISetObjectAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
      object: {
        ...action.object,
        deliveryDate: action.object.deliveryDate || state.application.object.deliveryDate,
        firstRegistrationDate: action.object.firstRegistrationDate || state.application.object.firstRegistrationDate,
      },
      calculation: {
        ...state.application.calculation,
        deliveryDate: action.object.deliveryDate || state.application.object.deliveryDate || null,
        firstRegistrationDate: action.object.firstRegistrationDate || state.application.object.firstRegistrationDate || null,
        mileage: isNullOrUndefined(action.object.mileage) ? null : action.object.mileage!,
      }
    }
  }
}

function onSetFirstRegistrationDate(
  state: IApplicationContextState,
  action: ISetFirstRegistrationDateAction
): IApplicationContextState {

  return {
    ...state,
    application: {
      ...state.application,
      object: {
        ...action.object,
        deliveryDate: action.object.deliveryDate,
        firstRegistrationDate: action.object.firstRegistrationDate
      },
      calculation: {
        ...state.application.calculation,
        deliveryDate: action.object.deliveryDate || null,
        firstRegistrationDate: action.object.firstRegistrationDate || null,
        mileage: isNullOrUndefined(action.object.mileage) ? null : action.object.mileage!,
      }
    }
  }
}

function onSetNotes(
  state: IApplicationContextState,
  action: ISetNotesAction
): IApplicationContextState {
  return {
    ...state,
    application: {
      ...state.application,
      notes: action.notes
    }
  }
}

function isNullOrUndefined(obj: number | boolean | string | undefined | null): boolean {
  if (obj === undefined) {
    return true
  }

  if (obj === null) {
    return true
  }

  return false
}
