import { CalculationInput, CalculationSummary } from 'model'
import { AgreementCode } from 'shared'
import { DescriptionList, DescriptionListItem } from '@framework'
import { useTranslation } from '@hooks'
import { formatNumber } from 'shared/utils'
import React, { useMemo } from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import styles from './SellDetailsComponent.module.css'

export interface ISellDetailsComponent {
  summary?: CalculationSummary | null
  input?: CalculationInput | null,
  agreementCode: number,
  onClose(): void
}

type FieldName =
  'commitment fee' |
  'administration fee' |
  'document fee' |
  'registration fee' | 
  'insurance admin fee' |
  'termination fee' |
  'referral commission'

function getFields(agreementCode: number): FieldName[] {
  switch(agreementCode){
    case AgreementCode.PurchaseContract100:
      return ['commitment fee', 'administration fee', 'registration fee', 'insurance admin fee', 'termination fee']
    case AgreementCode.UnsecuredContract:
      return ['commitment fee', 'administration fee', 'document fee']
    case AgreementCode.PurchaseContractWithoutSalesCommission:
      return ['registration fee', 'insurance admin fee', 'referral commission', 'termination fee']
    default:
      return ['commitment fee', 'administration fee', 'document fee', 'registration fee', 'insurance admin fee', 'termination fee']
  }
}

type FieldDefinitions = {
  [key in FieldName] : () => JSX.Element
}

export function SellDetailsComponent({ summary, input, agreementCode, onClose }: ISellDetailsComponent) {
  const $t = useTranslation('SellDetailsComponent')
  const fields = getFields(agreementCode)

  const descriptionListItemDefinitions : FieldDefinitions = {
    'commitment fee': () =>
      <DescriptionListItem
        key="commitment fee"
        label={$t('Commitment fee')}
        dataQa="sell_details_commitment_fee"
      >
        {renderPrice(input?.commitmentFee)}
      </DescriptionListItem>,
    'administration fee': () =>
      <DescriptionListItem
        key="administration fee"
        label={$t('Administration fee')}
        dataQa="sell_details_administration_fee"
      >
        {renderPrice(summary?.administrationFee)}
      </DescriptionListItem>,
    'document fee': () =>
      <DescriptionListItem
        key="document fee"
        label={$t('Document fee')}
        dataQa="sell_details_document_fee"
      >
        {renderPrice(input?.documentFee)}
      </DescriptionListItem>,
    'registration fee': () =>
      <DescriptionListItem
        key="registration fee"
        label={$t('Registration fee')}
        dataQa="sell_details_registration_fee"
      >
        {renderPrice(summary?.stempelTinglysning)}
      </DescriptionListItem>,
    'insurance admin fee': () =>
      <DescriptionListItem
        key="insurance admin fee"
        label={$t('Insurance admin fee')}
        dataQa="sell_details_insurance_administration_fee"
      >
        {renderPrice(summary?.insuranceAdminFee)}
      </DescriptionListItem>,
    'termination fee': () =>
      <DescriptionListItem
        key="termination fee"
        label={$t('Termination fee')}
        dataQa="sell_details_termination_fee"
      >
        {renderPrice(summary?.terminationFee)}
      </DescriptionListItem>,
    'referral commission': () =>
      <DescriptionListItem
        key="referral commission"
        label={$t('Referral commission')}
        dataQa="sell_details_referral_commission"
      >
        {renderPrice(summary?.referralCommission)}
      </DescriptionListItem>,
  }

  const descriptionListItems =
    useMemo(
      () => fields.map(field => descriptionListItemDefinitions[field]).map(definition => definition()),
      [fields])

  return (
    <div>
      <DescriptionList className="mt-0">
        { descriptionListItems }
      </DescriptionList>
      <div
        className={styles['close-button']}
        onClick={() => onClose()}
      >
        <Icon name="close" />
      </div>
      <Grid>
        <Grid.Row
          columns={4}
          // TODO Change it to pt-0 when Bootstrap like utilities are added.
          style={{ paddingTop: '0px' }}
        >
          <Grid.Column >
            <div>
              <b>{$t('Total commission')}</b>
            </div>
            <div data-qa="sell_details_total_commission">
              {renderPrice(summary?.commission)}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div>
              <b>{$t('More interest')}</b>
            </div>
            <div data-qa="sell_details_commission">
              {renderPrice(summary?.totalInterestKick)}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div>
              <b>{$t('Subsidy')}</b>
            </div>
            <div data-qa="sell_details_subsidy">
              {renderPrice(summary?.subsidy)}
            </div>
          </Grid.Column>
          <Grid.Column>
            <div>
              <b>{$t('Total earnings')}</b>
            </div>
            <div data-qa="sell_details_total_earnings">
              {renderPrice(summary?.totalEarnings)}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

function renderPrice(value: number | undefined | null) {
  return value ? formatNumber(value) + ' kr' : '0 kr'
}