import React from 'react'
import styles from './PageHeader.module.css'

interface IProps {
    text: string,
}
const PageHeader =
    React.forwardRef<HTMLHeadingElement, IProps>((props, ref) => (
        <h1
            className={styles.header}
            ref={ref}
        >
            {props.text}
        </h1>
    ))

export { PageHeader }