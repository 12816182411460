import { FileModel } from '@api/models'
import { INews, NewsApiClient } from 'api/NewsApiClient'
import { routes } from 'routing'
import { useApiClient, useTranslation } from '@hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NewsForm } from './NewsForm'
import { currentDateString } from 'shared/dateTools'

const emptyNewsEdit = {
  id: 0,
  title: '',
  newsTeaser: '',
  content: '',
  publishDate: currentDateString(),
  isPopup: false,
  attachments: [] as FileModel[]
} as INews

export function EditNews() {
  const { id } = useParams()
  const $t = useTranslation('News')
  const navigate = useNavigate()
  const newsApiClient = useApiClient((x) => new NewsApiClient(x))
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [news, setNews] = useState<INews>(emptyNewsEdit)
  const isBusy = isLoadingData || isSaving

  const loadNews = () => {
    setIsLoadingData(true)
    newsApiClient
      .getNews(id!)
      .then((result) => {
            if (result === undefined) {
                return
            }

            setNews({ ...news, ...result })
        })
        .finally(() => setIsLoadingData(false))
  }

  const save = (form: INews) => {
    setIsSaving(true)
    newsApiClient
        .editNews(form)
        .then((response) => {
          if (!response.success) {
            return
          }

          navigate(form.isArchived ? routes.newsArchive() : routes.homePage())
        })
        .finally(() => setIsSaving(false))
  }

  const downloadFileCallback = async (fileId: number): Promise<void> => {
    setIsLoadingData(true)
    const newsId = Number(id)
    await newsApiClient
      .getAttachment(newsId, fileId)
      .finally(() => setIsLoadingData(false))
  }

  useEffect(loadNews, [])

  return (
    <NewsForm
      downloadFileCallback={downloadFileCallback}
      headerText={$t('EditNews')}
      initialValues={news}
      isBusy={isBusy}
      submit={save}
      submitText={$t('Save')}
    />
  )
}