import { IGetInformationResponse, IGetInformationResponseConsentStatus, IGetInformationResponseObject, IRequestConsentRequest, RemainingDebtApiClient } from 'api/RemainingDebtApiClient'
import { BusyIndicator, DPFormField, DPInputCheckbox, DPInputDate, DPInputText, DPInputTextareaReadOnly, DPInputTextReadOnly, PageHeader, PageSectionHeader } from '@framework'
import { useApiClient } from '@hooks/useApiClient'
import { useInterval } from '@hooks/useInterval'
import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import { dateToDanishDate } from 'shared/dateTools'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, Grid, Message } from 'semantic-ui-react'
import * as Yup from 'yup'
import styles from './RemainingDebtPage.module.css'
import { currentDateString } from 'shared/dateTools'
import { DPButton } from 'shared'

export function RemainingDebtPage(){
    const $t = useTranslation('RemainingDebtPage')
    const api = useApiClient(x => new RemainingDebtApiClient(x))
    const session = useSession()
    const [information, setInformation] = useState<IGetInformationResponse | undefined>()
    const [informationForm, setInformationForm] = useState<IInformationForm | undefined>()
    const [informationNotFound, setInformationNotFound] = useState(false)
    const [loadingInformation, setLoadingInformation] = useState<boolean>(false)
    const [requestingConsent, setRequestingConsent] = useState<boolean>(false)

    const busy = loadingInformation || requestingConsent
    const delay = getDelay(information?.consentStatus)

    useInterval({
        callback: () => getInformation(),
        delay,
    })

    useEffect(() => {
        getInformation()
    }, [informationForm])

    const getInformation = () => {
        if(!informationForm){
            return
        }

        const dealerId = session.currentDealer?.id
        const request = {
            redemptionDate: informationForm.redemptionDate,
            isNewFinancing: informationForm.isNewFinancing,
            registrationOrVinNumber: informationForm.registrationOrVinNumber,
        }
        setLoadingInformation(true)
        api.getInformation(dealerId!, request)
            .then(x => {
                setInformation(x.data)
                setInformationNotFound(x.data === undefined)
            })
            .finally(() => setLoadingInformation(false))
    }

    const getPdf = () => {
        if(!informationForm){
            return
        }

        const dealerId = session.currentDealer?.id
        const request = {
            redemptionDate: informationForm.redemptionDate,
            isNewFinancing: informationForm.isNewFinancing,
            registrationOrVinNumber: informationForm.registrationOrVinNumber,
        }

        setLoadingInformation(true)
        api.generatePdf(dealerId!, request).finally(() => setLoadingInformation(false))
    }

    

    const requestConsent = (form: IConsentForm) => {
        const dealerId = session.currentDealer?.id
        const request: IRequestConsentRequest = {
            redemptionDate: informationForm!.redemptionDate,
            isNewFinancing: informationForm!.isNewFinancing,
            registrationOrVinNumber: informationForm!.registrationOrVinNumber,
            customerEmail: form.customerEmail,
            coBuyerEmail: form.coBuyerEmail,
        }
        setRequestingConsent(true)
        api.requestConsent(dealerId!, request)
            .then(x => {
                if(x.success){
                    getInformation()
                }
            })
            .finally(() => setRequestingConsent(false))
    }

    return (
        <div style={{ width: "700px"}}>
            <PageHeader text={$t('remainingDebt')} />
            
            {/* 1st section */}
            <PageSectionHeader>
                <span className={styles['section-number']}>1.</span>
                {$t('inquiryAboutRedemptionAmount')}
            </PageSectionHeader>

            <div className={styles['section-content']}>
                <InformationForm
                    informationFetched={information !== undefined}
                    informationNotFound={informationNotFound}
                    busy={busy}
                    setInformation={setInformation}
                    setInformationForm={setInformationForm}
                />
            </div>

            <hr className={styles.hr} />

            {/* 2nd section */}
            <PageSectionHeader>
                <span className={styles['section-number']}>2.</span>
                {$t('customerConsent')}            
            </PageSectionHeader>
            <div className={styles['section-content']}>
                {information &&
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                            <ConsentForm
                                consentStatus={information.consentStatus}
                                applicationHasCoBuyer={information!.applicationHasCoBuyer}
                                busy={busy}
                                onSubmit={requestConsent}
                            />
                            </Grid.Column>
                            <Grid.Column>
                                <ObjectForm
                                    object={information?.object}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
                {!information &&
                    <FinishPreviousStepMessage />
                }
            </div>
            {information?.consentStatus.created &&
                <Grid>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <span className={styles['section-content']}>
                                {$t('GiveConsent')}:
                            </span>
                            <p className={styles['section-content']}>
                                <a href={information.consentStatus.consentUrl}>{information.consentStatus.consentUrl}</a>
                            </p>                                                      
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }

            <hr className={styles.hr} />

            {/* 3rd section */}
            <PageSectionHeader>
                <span className={styles['section-number']}>3.</span>
                {$t('amount')}
            </PageSectionHeader>

            <div className={styles['section-content']}>
                
            {information?.consentStatus?.given &&
                <DPButton 
                size="mini" 
                onClick={getPdf} 
                disabled={false}                 
                >
                    PDF
                </DPButton>
            }
            {information?.consentStatus?.given &&
                <ForecastedOutstanding information={information} />
            }
            {!information?.consentStatus?.given &&
                <FinishPreviousStepMessage />
            }
            </div>

            <hr className={styles.hr} />

            <Footer />
        </div>
    )
}

interface IInformationForm {
    seller: string,
    dealer: string,
    redemptionDate: string,
    isNewFinancing: boolean,
    registrationOrVinNumber: string,
}

function InformationForm(props: {
    busy: boolean,
    informationFetched: boolean,
    informationNotFound: boolean,
    setInformation: (information: IGetInformationResponse | undefined) => void,
    setInformationForm: (value: IInformationForm) => void
}){
    const $t = useTranslation('RemainingDebtPage')
    const session = useSession()

    const onSubmit = (form: IInformationForm) => {
        props.setInformationForm({...form})
    }

    const initialValues : IInformationForm = {
        seller: `${session.currentUser.firstName} ${session.currentUser.lastName}`,
        dealer: session.currentDealer!.name,
        redemptionDate: currentDateString(),
        isNewFinancing: false,
        registrationOrVinNumber: '',
    }

    const validationSchema = Yup.object().shape({
        redemptionDate: Yup.string().required().label($t('redemptionDate')),
        isNewFinancing: Yup.boolean().label($t('isNewFinancing')),
        registrationOrVinNumber: Yup.string().required().label($t('registrationOrVinNumber')),
    })

    return (
        <BusyIndicator busy={props.busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps =>
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField
                            label={$t('seller')}
                            name="seller"
                        >
                            <DPInputText name="seller" disabled />
                        </DPFormField>

                        <DPFormField
                            label={$t('dealer')}
                            name="dealer"
                        >
                            <DPInputText name="dealer" disabled />
                        </DPFormField>

                        <DPFormField
                            label={$t('redemptionDate')}
                            name="redemptionDate"
                            required
                        >
                            <DPInputDate
                                name="redemptionDate"
                                minDate={currentDateString()}
                            />
                        </DPFormField>


                        <DPFormField
                            label={$t('registrationOrVinNumber')}
                            name="registrationOrVinNumber"
                            required
                        >
                            <DPInputText
                                name="registrationOrVinNumber"
                            />
                        </DPFormField>

                        <DPButton
                            size="mini"
                            type="submit"
                            basic={props.informationFetched}
                        >
                            {$t('getInformation')}
                        </DPButton>
                    </Form>
                }
            </Formik>

            {props.informationNotFound &&
                <InformationNotFoundMessage />
            }
        </BusyIndicator>
    )
}

function ObjectForm(props: { object: IGetInformationResponseObject }){
    const $t = useTranslation('RemainingDebtPage')
    const makeAndModel = `${props.object.make} ${props.object.model}`
    
    return (
        <Formik
            onSubmit={() => { return }}
            initialValues={{}}
        >
            {formikProps =>
                <Form onSubmit={formikProps.handleSubmit}>
                    <DPFormField
                        name="contractId"
                        label={$t('contract')}
                    >
                        <DPInputTextReadOnly value={props.object.contractId} />
                    </DPFormField>

                    <DPFormField
                        name="registrationNumber"
                        label={$t('registrationNumber')}
                    >
                        <DPInputTextReadOnly value={props.object.registrationNumber} />
                    </DPFormField>

                    <DPFormField
                        name="chassisNumber"
                        label={$t('chassisNumber')}
                    >
                        <DPInputTextReadOnly value={props.object.chassisNumber} />
                    </DPFormField>

                    <DPFormField
                        name="makeAndModel"
                        label={$t('makeAndModel')}
                    >
                        <DPInputTextReadOnly value={makeAndModel} />
                    </DPFormField>                
                </Form>
            }
        </Formik>
    )
}

interface IConsentForm {
    customerEmail: string,
    coBuyerEmail: string,
}

function ConsentForm(props: {
    consentStatus: IGetInformationResponseConsentStatus,
    applicationHasCoBuyer: boolean,
    busy: boolean,
    onSubmit: (form: IConsentForm) => void
}){
    const $t = useTranslation('RemainingDebtPage')
    const initialValues: IConsentForm = {
        customerEmail: '',
        coBuyerEmail: ''
    }
    const validationSchema = Yup.object().shape({
        customerEmail: Yup.string().required().email().label($t('customerEmail')),
        coBuyerEmail:
            props.applicationHasCoBuyer
                ? Yup.string().required().email().label($t('coBuyerEmail'))
                : Yup.string().nullable(),
    })

    const getConsentStatus = (consentStatus: IGetInformationResponseConsentStatus) => {
        const { created, answered, given } = consentStatus

        if(!created){
            return {
                status: $t('notCreatedYetStatus'),
                description: $t('notCreatedYetDescription'),
            }
        }

        if(!answered){
            return {
                status: $t('pendingConsentStatus'),
                description: $t('pendingConsentDescription')
            }
        }

        if(!given){
            return {
                status: $t('consentNotGivenStatus'),
                description: $t('consentNotGivenDescription'),
            }
        }

        return {
            status: $t('consentGivenStatus'),
            description: $t('consentGivenDescription')
        }

    }
    const { status, description } = getConsentStatus(props.consentStatus)
    return (
        <BusyIndicator busy={props.busy}>
            <Formik
                onSubmit={props.onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {formikProps =>
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField
                            name="customerEmail"
                            label={$t('customerEmail')}
                            required
                        >
                            <DPInputText name="customerEmail" />
                        </DPFormField>

                        {props.applicationHasCoBuyer &&
                            <DPFormField
                                name="coBuyerEmail"
                                label={$t('coBuyerEmail')}
                                required
                            >
                                <DPInputText name="coBuyerEmail" />
                            </DPFormField>
                        }

                        <DPFormField
                            name="status"
                            label={$t('Status')}
                        >
                            <DPInputTextReadOnly value={status} />
                        </DPFormField>

                        <DPFormField
                            name="description"
                            label={undefined}
                        >
                            <DPInputTextareaReadOnly value={description} />
                        </DPFormField>
                       
                        <DPButton
                            size="mini"
                            type="submit"
                            basic={props.consentStatus.created}
                            className="mb-3"
                        >
                            {props.consentStatus.created ? $t('resendRequest') : $t('sendRequest')}
                        </DPButton>
                
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}

function ForecastedOutstanding(props: { information: IGetInformationResponse}){
    const contractId = props.information.object!.contractId
    const requestDate = dateToDanishDate(props.information.requestDate)
    const forecastOutstanding = props.information.forecastOutstanding.toLocaleString('da-DK')

    return (       
        <Message>
            <Message.Content>
            <p>
                Aftalen nr. <b>{contractId}</b> kan pr. d. <b>{requestDate}</b> indfries med i alt kr. <b>{forecastOutstanding}</b> under forudsætning af at beløbet er modtaget af Santander Consumer Bank på denne dato.
                Beløbet bedes indbetalt til reg. nr. 5295 konto nr. 0010017009 med aftale nr. <b>{contractId}</b> som reference.
            </p>
            <p>
                OBS:
            </p>
            <p>
                Det er altid ovenstående beløb, som er det gældende indfrielsesbeløb. Skulle der være en indbetaling, som endnu ikke er regnet med i ovenstående beløb, vil beløbet blive refunderet til kunden. Skulle kunden trække en betaling tilbage, vil beløbet blive opkrævet pr. girokort.
            </p>
            </Message.Content>
        </Message>
    )
}

function FinishPreviousStepMessage() {
    const $t = useTranslation('RemainingDebtPage')
    return <p className={styles['finish-previous-step']}>{$t('finishPreviousStepToSeeTheContent')}</p>
}

function InformationNotFoundMessage() {
    return(
        <Message error>
            <p>Vi har ikke umiddelbart nogle data på de indtastede oplysninger. Prøv igen eller kontakt os på tlf. nr. 70 20 72 82.</p>
        </Message>
    )
}

function Footer(){
    return (
        <>
            <p className={styles.footer}>
            <b>Leasing indfrielse</b><br/>
            Ved indfrielse af leasingkontrakter bedes du rette henvendelse til vores leasing team på <br />
            Telefon: <a href="tel:70-23-58-58">70 23 58 58</a> - Hverdage 10:00 - 16:00 <br />
            Email: <a href="mailto:leasing@santanderconsumer.dk">leasing@santanderconsumer.dk</a>
            </p>
        </>
    )
}

function getDelay(consentStatus: IGetInformationResponseConsentStatus | undefined){
    // Set delay to 30 seconds when request is created but not answered, to start pooling.
    // In other cases set delay to 24 hours, so basically it's turned off.
    return consentStatus?.created === true && consentStatus?.answered !== true
            ? 30 * 1000
            : 24 * 60 * 60 * 1000
}