import { ApiClient } from '@api'
import { Agreement } from 'model'

export interface SignatureType {
    id: number,
    name: string,
}

export class AgreementApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getAgreements = (dealerId: number) => {
        return this.apiClient.get<Agreement[]>(`api/dealers/${dealerId}/agreements`).then(x => x.data)
    }

    public getSignatureTypes = (dealerId: number, agreementCode: number) => {
        return this.apiClient.get<SignatureType[]>(`api/dealers/${dealerId}/agreements/${agreementCode}/signature-types`)
    }
}