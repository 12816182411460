import { useMemo } from 'react'
import { Message } from 'semantic-ui-react'

export function WarningMessage(props: { customerWarning?: string, coBuyerWarning?: string }){
    const warnings = useMemo(() => {
      const result = []
  
      if (props.customerWarning) {
        result.push(props.customerWarning)
      }
  
      if (props.coBuyerWarning) {
        result.push(props.coBuyerWarning)
      }
  
      return result
    }, [props.customerWarning, props.coBuyerWarning])
  
    return warnings.length > 0
      ? <Message warning list={warnings} />
      : null
  }