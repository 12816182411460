import { useTranslation } from '@hooks/useTranslation'
import { Image, Modal } from 'semantic-ui-react'
import styles from './DisplayImageModal.module.css'
import { DPButton } from 'shared'

export type DownloadCallback = (imageId: number) => Promise<void>

export interface ImageData {
  id?: number,
  fileName: string,
  src: string,   
}

interface IProps {  
  imageData: ImageData,  
  open: boolean,
  closeCallback: (close: boolean) => void,
  downloadCallback?: DownloadCallback,
}

export function DisplayImageModal({
  imageData,
  open,
  closeCallback,
  downloadCallback,
}:IProps) {

  const $t = useTranslation('DisplayImageModal')

  return (
    <Modal
      open={open}
      onClose={() => closeCallback(false)}
      closeIcon>
      <Modal.Header>{imageData.fileName}</Modal.Header>
      <Modal.Content>
        <Image alt={imageData.fileName} src={imageData.src} size="massive"/>
      </Modal.Content>
      <Modal.Actions>
        <div className={styles['button-bar']}>
          {
            downloadCallback && imageData?.id && (
              <DPButton className={styles['download-button']} children={$t('Download')} onClick={() => downloadCallback(imageData.id!)} color="green" />
            )
          }
          <DPButton className={styles['close-button']} children={$t('Close')} onClick={() => closeCallback(false)}/>
        </div>
      </Modal.Actions>
    </Modal>
  )
}