import { useApiClient } from '@hooks/useApiClient'
import { AgreementApiClient, SignatureType } from 'api'
import { useEffect, useState } from 'react';

export function useSignatureTypes(dealerId: number | undefined, agreementCode: number | undefined) : [SignatureType[], boolean]{
    const api = useApiClient(x => new AgreementApiClient(x));
    const [signatureTypes, setSignatureTypes] = useState([] as SignatureType[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!dealerId || !agreementCode){
            setSignatureTypes([]);
            return
        }

        setLoading(true)
        api.getSignatureTypes(dealerId, agreementCode)
            .then(x => {
                if(!x.success){
                    setSignatureTypes([]);
                    return
                }
                setSignatureTypes(x.data!)
            })
            .finally(() => setLoading(false))
    }, [dealerId, agreementCode])

    return [signatureTypes, loading]
}