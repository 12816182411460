import { ReactNode } from "react";
import { Button } from "semantic-ui-react";

interface Props {
    onClick?: () => void,
    children: ReactNode,
    disabled?: boolean,
    type?: "button" | "submit",
    size?: "small" | "mini" | "tiny",
    color?: "grey" | "green",
    className?: string,
    compact?: boolean,
    basic?: boolean,
    id?: string,
}

export function DPButton(props: Props){
    return (
        <Button
            onClick={props.onClick}
            disabled={props.disabled}
            size={props.size}
            type={props.type}
            color={props.color}
            className={props.className}
            compact={props.compact}
            basic={props.basic}
            id={props.id}
        >
            {props.children}
        </Button>
    )
}