import { ApiClient } from '@api'

export interface IPersonModel {
    address: IAddressModel
    cpr: string
    firstName: string
    lastName: string
    phone: string
    email: string
    warning?: string
}

export interface IAddressModel {
    city: string
    zip: string
    street: string,
    hidden: boolean,
}

export interface ICompanyModel {
    cvr: string
    address: string
    city: string
    zipCode: string
    companyName: string
    isSoleProprietorship: boolean
    beneficialOwners?: IBeneficialOwnerDto[]
    authorizedSignatory: string
    warning?: string
}

interface IBeneficialOwnerDto {
    personName: string
    share: number
}

export class CustomerApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getPerson = (cpr: string) => {
        const url = `api/customer/person/${cpr}`
        return this.apiClient.get<IPersonModel>(url, undefined, { suppressError: true})
    }

    public getCompany = (cvr: string) => {
        const url = `api/customer/company/${cvr}`
        return this.apiClient.get<ICompanyModel>(url)
    }
}