import React, { Fragment, ReactChild } from 'react'
import { useLocation } from 'react-router'

/**
 * This wrapper ensures that child component is rendered again every time user clicks on menu item.
 * By default React Router doesn't refresh the page when it's already open.
 * Notice that location key is changed every time user clicks on link element, even when URL is not changed.
 */
export function RefreshAlways(props: { children: ReactChild }){
    const location = useLocation()
    return (
        <Fragment key={location.key}>
            {props.children}
        </Fragment>
    )
}