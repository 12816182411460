import React from 'react'
import { Loader, Modal } from 'semantic-ui-react'

interface IProps {
  open: boolean
  header: string
  message?: string
}

export function ProgressModal({ open, header, message }: IProps) {
  return (
    <Modal
      open={open}
      dimmer="inverted"
      size="mini"
    >
      <Modal.Header>
        <div style={{ textAlign: 'center' }}>
          {header}
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Loader active inline="centered" size="small" />
          {message &&
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <p>{message}</p>
            </div>
          }
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

