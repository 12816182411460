import { routes } from 'routing'
import { AuthenticationContext } from '@authentication'
import { useApiClient } from '@hooks/useApiClient'
import { useQuery } from '@hooks/useQuery'
import { AuthApiClient } from 'infrastructure/authentication/AuthApiClient'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FullPageLoader from './FullPageLoader'

export default function SignInCallbackPage() {
    const query = useQuery()
    const code = query.get('code')
    const navigate = useNavigate()

    const authApiClient = useApiClient((x) => new AuthApiClient(x))
    const authenticationContext = useContext(AuthenticationContext)

    useEffect(() => {
        if (!code) {
            return
        }

        authApiClient.sendCode(code)
            .then((x) => {
                if (x === undefined) {
                    return Promise.reject()
                }

                authenticationContext.logIn()
            })
            .finally(() => {
                navigate(routes.homePage({ popups: ['lastUnreadNews', 'incomingApplications'] }))
            })
    }, [])

    return <FullPageLoader />
}
