import { isFeatureEnabled } from '@config'
import React from 'react'
import { LanguageSwitcher } from '../language'
import styles from './Footer.module.css'
import { useContact } from 'contact'
import { useSession } from '@hooks/useSession'
import { KnownBrandCode } from 'shared/KnownBrandCode'
import { useTranslation } from '@hooks/useTranslation'

export function Footer() {
    const $t = useTranslation('Footer');
    const session = useSession();
    const contact = useContact(session.currentDealer?.brandCode ?? KnownBrandCode.Santander);

    return (
        <div className={styles.container}>
            <div>
                Copyright by Santander
            </div>

            <div>
                <h5>{$t('address')}</h5>
                <div>{contact.company.name}</div>
                {contact.company.address.map((x, index) => <div key={index}>{x}</div>)}
                <div>CVR: {contact.company.CVR}</div>
            </div>

            {/* Front office */}
            <div>
                <h5>{$t('frontOffice')}</h5>
                <div>
                    <a href={`tel:${contact.frontOffice.phone}}`}>{contact.frontOffice.phone}</a>
                </div>
                <div>
                    <a href={`mailto:${contact.frontOffice.email}}`}>{contact.frontOffice.email}</a>
                </div>
            </div>

            <div>
                <h5>{$t('customerService')}</h5>
                <div>
                    <a href={`tel:${contact.customerService.phone}}`}>{contact.customerService.phone}</a>
                </div>
                <div>
                    <a href={`mailto:${contact.customerService.emailLoan}}`}>{contact.customerService.emailLoan}</a>
                </div>
                <div>
                    <a href={`mailto:${contact.customerService.emailLeasing}}`}>{contact.customerService.emailLeasing}</a>
                </div>
            </div>

            {isFeatureEnabled('language-switch') && (
                <div>
                    <h5>{$t('language')}</h5>
                    <LanguageSwitcher />
                </div>
            ) }
        </div>
    )
}