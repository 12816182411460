import { useTranslation } from '@hooks/useTranslation'

export type PendingApplicationStatus = 
    'Processing' |
    'WaitingForSantander' |
    'ApplicationFailed' |
    'IncorrectInformationInApplication' |
    'Cancelled'

export function usePendingApplicationStatus(){
    const $t = useTranslation('PendingApplicationStatus')
    const description = (status: PendingApplicationStatus | string) => {
        switch(status){
            case 'Processing':
                return $t('Processing')
            case 'WaitingForSantander':
                return $t('WaitingForSantander')
            case 'ApplicationFailed':
                return $t('ApplicationFailed')
            case 'IncorrectInformationInApplication':
                return $t('IncorrectInformationInApplication')
            case 'Cancelled':
                return $t('Cancelled')
        }

        return status
    }

    return { description }
}