import React, { ReactElement } from 'react'
import classNames from 'classnames'
import './DPTable.scss'

interface IProps {
    celled?: boolean
    compact?: boolean
    selectable?: boolean
    children: ReactElement | ReactElement[]
}

export default function DPTable(props: IProps) {
    return <table className={
            classNames(
                'dp-table',
                {
                    'celled': props.celled,
                    'compact': props.compact,
                    'selectable': props.selectable
                })
        }>
        {props.children}
    </table>
}