import { Loader } from "semantic-ui-react";
import React from 'react'
import styles from './FullPageLoader.module.css'

export default function FullPageLoader(){
    return (
        <div className={styles.loader}>
            <Loader active inline="centered" size="large" />
        </div>
    )
}