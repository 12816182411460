import { ApplicationContext } from 'pages/application/state'
import { CalculationInput, CalculationResult, CalculationSummary } from 'model'
import { DPFormField, DPInputTextReadOnly, PageSectionHeader } from '@framework'
import { useTranslation } from '@hooks'
import { formatNumber } from 'shared/utils'
import React, { useContext } from 'react'
import { ApplicationType } from 'shared'

type FieldName =
  'paymentPerMonth' |
  'installmentFreePaymentPerMonth' |
  'installmentFreePeriods' |
  'baseAmount' |
  'financedAmount' |
  'nominalInterest' | 
  'aopBeforeTax' |
  'totalLoanCosts' |
  'totalRepay' |
  'serviceAgreementPrMonth'

function getFields(applicationType: ApplicationType): FieldName[] {
  switch(applicationType){
    case ApplicationType.Personnel:
      return ['paymentPerMonth', 'installmentFreePaymentPerMonth', 'installmentFreePeriods', 'serviceAgreementPrMonth', 'baseAmount', 'financedAmount', 'nominalInterest', 'aopBeforeTax', 'totalLoanCosts', 'totalRepay']
    default:
      return ['paymentPerMonth', 'serviceAgreementPrMonth', 'baseAmount', 'financedAmount', 'nominalInterest', 'aopBeforeTax', 'totalLoanCosts', 'totalRepay']
  }
}

type FieldDefinitions = {
  [key in FieldName] : () => JSX.Element | null
}

export function LoanInformationComponent() {
  const $t = useTranslation('LoanInformationComponent')
  const context = useContext(ApplicationContext)
  const fields = getFields(context.application.type)
  const { summary: calcResultSummary, baseAmount, input: calculationInput } = context?.calculationResult || {} as CalculationResult
  const summary = calcResultSummary || {} as CalculationSummary
  const calculation = calculationInput || {} as CalculationInput

  const fieldDefinitions : FieldDefinitions = {
    'paymentPerMonth': () =>
      <DPFormField
        key="paymentPerMonth"
        name="paymentPerMonth"
        label={$t('Monthly payment')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.paymentPerMonth)}
          label="kr"
          dataQa="information_payment_per_month"
        />
      </DPFormField>,
    'installmentFreePaymentPerMonth': () =>
      <DPFormField
        key="installmentFreePaymentPerMonth"
        name="installmentFreePaymentPerMonth"
        label={$t('installmentFreePaymentPerMonth')}
      >
        <DPInputTextReadOnly
          value={formatNumber(calculation.installmentFreePaymentPerMonth)}
          label="kr"
          dataQa="information_installment_free_payment_per_month"
        />
      </DPFormField>,
    'installmentFreePeriods': () =>
      <DPFormField
        key="installmentFreePeriods"
        name="installmentFreePeriods"
        label={$t('installmentFreePeriods')}
      >
        <DPInputTextReadOnly
          value={summary.installmentFreePeriods?.toString()}
          dataQa="information_installment_free_periods"
        />
      </DPFormField>,
    'baseAmount': () =>
      <DPFormField
        key="baseAmount"
        name="baseAmount"
        label={$t('Total credit amount')}
      >
        <DPInputTextReadOnly
          value={formatNumber(baseAmount)}
          label="kr"
          dataQa="information_base_amount"
        />
      </DPFormField>,
    'financedAmount': () =>
      <DPFormField
        key="financedAmount"
        name="financedAmount"
        label={$t('Loan amount')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.financedAmount)}
          label="kr"
          dataQa="information_financed_amount"
        />
      </DPFormField>,
    'nominalInterest': () =>
      <DPFormField
        key="nominalInterest"
        name="nominalInterest"
        label={$t('Debtor interest')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.nominalInterest)}
          label="%"
          dataQa="information_nominal_interest_rate"
        />
      </DPFormField>,
    'aopBeforeTax': () =>
      <DPFormField
        key="aopBeforeTax"
        name="aopBeforeTax"
        label={$t('ARP before tax')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.aopBeforeTax)}
          label="%"
          dataQa="information_aop_before_tax"
        />
      </DPFormField>,
    'totalLoanCosts': () =>
      <DPFormField
        key="totalLoanCosts"
        name="totalLoanCosts"
        label={$t('Total credit costs')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.totalLoanCosts)}
          label="kr"
          dataQa="information_total_loan_cost"
        />
      </DPFormField>,
    'totalRepay': () =>
      <DPFormField
        key="totalRepay"
        name="totalRepay"
        label={$t('Total to be paid')}
      >
        <DPInputTextReadOnly
          value={formatNumber(summary.totalRepay)}
          label="kr"
          dataQa="information_total_repay"
        />
      </DPFormField>,
    'serviceAgreementPrMonth': () =>
      !calculation.serviceAgreementPrMonth
        ? null
        : (
            <DPFormField
              key="serviceAgreementPrMonth"
              name="serviceAgreementPrMonth"
              label={$t('Service agreement per month incl.')}
            >
              <DPInputTextReadOnly
                value={formatNumber(calculation.serviceAgreementPrMonth)}
                label="kr"
                dataQa="information_service_agreement_per_month"
              />
            </DPFormField>
        ),
  }

  const fieldComponents =
    fields
      .map(field => fieldDefinitions[field]())

  return (
    <>
      <PageSectionHeader>
        {$t('Loan information')}
      </PageSectionHeader>
      {fieldComponents}
    </>
  )
}