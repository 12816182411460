import { ModalContext } from '@modal'
import { useContext } from 'react'

export function useModal() {
    const context = useContext(ModalContext)
    if(context === undefined){
        throw new Error('ModalContext must be used within a ModalProvider.')
    }
    return context
}
