import { BusyIndicator, DPFormField, DPInputText, DPInputUserTitleDropdown, FormikForm } from '@framework'
import { useApiClient } from '@hooks/useApiClient'
import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import * as Yup from 'yup'
import { UserProfileApiClient } from 'api'
import styles from './UserTitleConfirmationModal.module.css'
import { DPButton } from 'shared'

interface IForm {
    title: string,
    phone: string,
    email: string
}

export function UserTitleConfirmationModal(){
    const session = useSession()
    const { nextTitleConfirmation, title, phone, email } = session.currentUser
    const $t = useTranslation('UserTitleConfirmationModal')
    const show = nextTitleConfirmation != undefined && new Date(nextTitleConfirmation).getTime() < Date.now()
    const [open, setOpen] = useState(show)
    const [busy, setBusy] = useState(false)
    const api = useApiClient(x => new UserProfileApiClient(x))

    const initialValues: IForm = {
        title,
        phone,
        email
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required($t('ValidationTitleRequired')),
        phone: Yup.string().required($t('ValidationPhoneRequired')).length(8, $t('ValidationPhoneLength')),
        email: Yup.string().required($t('ValidationEmailRequired')).email($t('ValidationEmailValid'))
    })

    const onSubmit = async (values: IForm) => {
        setBusy(true)
        try {
            const changeTitleResponse = await api.userPromptUpdate(values.title!, values.phone!, values.email!)
            if(!changeTitleResponse.success){
                return
            }
    
            // Refresh session to fetch new value of next title confirmation
            await session.refresh()
            setOpen(false)
        } finally {
            setBusy(false)
        }
    }

    return (
        <Modal
            open={open}
            size="mini"
        >
            <Modal.Header>
                { $t('TitleConfirmation') }
            </Modal.Header>
            <Modal.Content>
                <BusyIndicator busy={busy}>
                    <p>
                        { $t('InfoMessage') }
                    </p>
                    <FormikForm
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {/* Title */}
                        <DPFormField
                            required
                            name="title"
                            label={$t('Title')}
                            inline={false}
                        >
                            <DPInputUserTitleDropdown
                                name="title"
                            />
                        </DPFormField>

                        {/* Phone number */}
                        <DPFormField
                            required
                            name="phone"
                            label={$t('Phone')}
                            inline={false}
                        >
                            <DPInputText
                                name="phone"
                            />
                        </DPFormField>

                        {/* E-mail address */}
                        <DPFormField
                            required
                            name="email"
                            label={$t('Email')}
                            inline={false}
                        >
                            <DPInputText
                                name="email"
                            />
                        </DPFormField>

                        {/* Submit */}
                        <div className={styles['text-right']}>
                            <DPButton>
                                { $t('Confirm') }
                            </DPButton>
                        </div>
                    </FormikForm>
                </BusyIndicator>
            </Modal.Content>
        </Modal>
    )
}