import { ApiClient, IGridResult } from '@api'
import { Application } from 'model'

export interface IIncomingApplication{
  key: string
  updated: string,
  customerName: string,
  customerPhone: string,
  agreementCode: number,
  make: string,
  model: string,
  price: number,
}

export class IncomingApplicationApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getIncomingApplications = (dealerId: number, configuration?: { suppressError?: boolean }) => {
    const url = `api/dealers/${dealerId}/incoming-applications`
    return this.apiClient.get<IGridResult<IIncomingApplication>>(url, undefined, configuration).then(x => x.data)
  }

  public getIncomingApplication = (id: string, dealerId: number) => {
    const url = `api/dealers/${dealerId}/incoming-applications/${id}`
    return this.apiClient.get<Application>(url).then(x => x.data)
  }

  public moveToStateDeleted = (dealerId: number, id: string) => {
    const url = `api/dealers/${dealerId}/incoming-applications/${id}/state/deleted`
    return this.apiClient.post<Application>(url).then(x => x.data)
  }

  public moveToStateDone = (dealerId: number, id: string) => {
    const url = `api/dealers/${dealerId}/incoming-applications/${id}/state/done`
    return this.apiClient.post<Application>(url).then(x => x.data)
  }
}