import { useState } from 'react'

export function useApiCall<TResult>(
  apiMethod: () => Promise<TResult | undefined>,
  defaultData: TResult
): [TResult, boolean, () => Promise<TResult>]

export function useApiCall<T1, TResult>(
  apiMethod: (arg1: T1) => Promise<TResult | undefined>,
  defaultData: TResult
): [TResult, boolean, (arg1: T1) => Promise<TResult>]

export function useApiCall<T1, T2, TResult>(
  apiMethod: (arg1: T1, arg2: T2) => Promise<TResult | undefined>,
  defaultData: TResult
): [TResult, boolean, (arg1: T1, arg2: T2) => Promise<TResult>]

export function useApiCall<T1, T2, T3, TResult>(
  apiMethod: (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult | undefined>,
  defaultData: TResult
): [TResult, boolean, (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult>]

export function useApiCall<TResult>(
  apiMethod: () => Promise<TResult>
): [TResult, boolean, () => Promise<TResult>]

export function useApiCall<T1, TResult>(
  apiMethod: (arg1: T1) => Promise<TResult>
): [TResult, boolean, (arg1: T1) => Promise<TResult>]

export function useApiCall<T1, T2, TResult>(
  apiMethod: (arg1: T1, arg2: T2) => Promise<TResult>
): [TResult, boolean, (arg1: T1, arg2: T2) => Promise<TResult>]

export function useApiCall<T1, T2, T3, TResult>(
  apiMethod: (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult>
): [TResult, boolean, (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult>]

export function useApiCall<TResult>(
  apiMethod: () => Promise<TResult | undefined>
): [TResult | undefined, boolean, () => Promise<TResult>]

export function useApiCall<T1, TResult>(
  apiMethod: (arg1: T1) => Promise<TResult | undefined>
): [TResult | undefined, boolean, (arg1: T1) => Promise<TResult>]

export function useApiCall<T1, T2, TResult>(
  apiMethod: (arg1: T1, arg2: T2) => Promise<TResult | undefined>
): [TResult | undefined, boolean, (arg1: T1, arg2: T2) => Promise<TResult>]

export function useApiCall<T1, T2, T3, TResult>(
  apiMethod: (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult | undefined>
): [TResult | undefined, boolean, (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult>]

export function useApiCall<T1, T2, T3, TResult>(
  apiMethod: (...args: any[]) => Promise<TResult>,
  defaultData?: TResult
): [TResult | undefined, boolean, (arg1: T1, arg2: T2, arg3: T3) => Promise<TResult>] {

  const [data, setData] = useState(defaultData)
  const [loading, setLoading] = useState(false)

  function apiCall(...args: any[]) {
    setLoading(true)

    function resolver(result: TResult) {
      // TODO Simplify it.
      if (result === undefined && defaultData !== undefined) {
        result = defaultData
      }

      setLoading(false)
      setData(result)

      return new Promise<TResult>((resolve) => resolve(result))
    }

    if (args.length === 0) {
      return apiMethod().then(resolver)
    } else if (args.length === 1) {
      return apiMethod(args[0] as T1).then(resolver)
    } else if (args.length === 2) {
      return apiMethod(args[0] as T1, args[1] as T2).then(resolver)
    } else if (args.length === 3) {
      return apiMethod(args[0] as T1, args[1] as T2, args[2] as T3).then(resolver)
    } else {
      throw new Error('Unhandled number of arguments')
    }
  }

  return [data, loading, apiCall]
}