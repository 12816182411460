import { SubmittedApplicationStatus as Status } from 'api'
import { useSubmittedApplicationStatus } from './useSubmittedApplicationStatus'

export function SubmittedApplicationStatus(props: { status: Status }){
    const submittedApplicationStatus = useSubmittedApplicationStatus()

    return (
        <span>
            { submittedApplicationStatus.description(props.status) }
        </span>
    )
}