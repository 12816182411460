import { DealerApiClient } from 'api/DealerApiClient'
import { IUser, UserApiClient } from 'api/UserApiClient'
import { routes } from 'routing'
import { BusyIndicator, DPFormField, DPInputTextareaReadOnly, DPInputUserTitleDropdown, PageHeader, useToast } from '@framework'
import { useApiCall } from '@hooks/useApiCall'
import { useApiClient } from '@hooks/useApiClient'
import { useModal } from '@hooks/useModal'
import { useTranslation } from '@hooks/useTranslation'
import { Formik } from 'formik'
import { DPInputCheckbox } from 'infrastructure/framework/DPForms/DPInputCheckbox'
import { DPInputDropdown } from 'infrastructure/framework/DPForms/DPInputDropdown'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'semantic-ui-react'
import * as Yup from 'yup'
import styles from './EditUserPage.module.css'
import { DPButton } from 'shared'
import { PublicApiClient } from 'api'
import { IDropdownOption } from 'model'

interface IForm {
    id: number,
    userName: string,
    firstName: string,
    lastName: string,
    title: string,
    email: string,
    phone: string,
    isActive: boolean,
    skipMFA: boolean,
    roleIds: number[],
    dealerIds: number[],
}

export default function EditUserPage(){
    const { id } = useParams()
    const $t = useTranslation('EditUserPage')
    const navigate = useNavigate()
    const modal = useModal()
    const toast = useToast()
    const userApiClient = useApiClient(x => new UserApiClient(x))
    const publicApiClient = useApiClient(x => new PublicApiClient(x))
    const dealerApiClient = useApiClient(x => new DealerApiClient(x))
    const [user, setUser] = useState<IUser | undefined>(undefined)
    const [dealers, setDealers] = useState<Array<IDropdownOption<number>>>([])
    const [isLoadingDealers, setIsLoadingDealers] = useState(false)
    const [roles, setRoles] = useState<Array<IDropdownOption<number>>>([])
    const [isLoadingRoles, setIsLoadingRoles] = useState(false)
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isUpdatingFromPreview, setIsUpdatingFromPreview] = useState(false)
    const [isUnlocking, setIsUnlocking] = useState(false)
    const [isResettingPassword, setIsResettingPassword] = useState(false)
    const [initialValues, setInitialValues] = useState<IForm | undefined>(undefined)
    const [coreViewRoles, isLoadingCoreViewRoles, getCoreViewRoles] = useApiCall(userApiClient.getCoreViewRoles)

    const coreViewRolesFormatted =
        useMemo(() => {
            if(!coreViewRoles){
                return ''
            }

            const lines = []
            for(const dealer of coreViewRoles){
                lines.push(dealer.dealerName)

                for(const roleName of dealer.roleNames){
                    lines.push(`- ${roleName}`)
                }

                lines.push('') // empty line 
            }

            return lines.join('\n')
        }, [coreViewRoles])

    const loadUser = () => {
        setIsLoadingUser(true)
        userApiClient.getUser(+id!)
            .then(result => setUser(result))
            .finally(() => setIsLoadingUser(false))
    }

    const loadDealers = () => {
        setIsLoadingDealers(true)
        dealerApiClient.getDealersDropdown()
            .then(response => {
                if(response === undefined){
                    setDealers([])
                    return
                }

                setDealers(response)
            })
            .finally(() => setIsLoadingDealers(false))
    }

    const loadRoles = () => {
        setIsLoadingRoles(true)
        userApiClient.getRoles()
            .then(async response => {
                if(response === undefined){
                    setRoles([])
                    return
                }

                setRoles(response.map(role => {
                    return {
                        key: role.id.toString(),
                        text: role.name,
                        value: role.id
                    }
                }))
            })
            .finally(() => setIsLoadingRoles(false))
    }

    const cancel = () => {
        navigate(routes.administrationUsers())
    }

    const unlockUser = () => {
        setIsUnlocking(true)
        userApiClient.unlock(+id!)
            .then(() => loadUser())
            .finally(() => setIsUnlocking(false))
    }

    const resetPassword = () => {
        if (!user) {
            return
        }

        modal.confirm({
            message: $t('passwordResetConfirmationMessage'),
            onOk: () => {
                setIsResettingPassword(true)
                publicApiClient.resetPasswordRequest({ username: user.userName })
                    .then(() => {
                        loadUser()
                        toast.success($t('passwordResetSuccessMessage'))
                    })
                    .finally(() => setIsResettingPassword(false))
            }
        })
    }

    const updateFromPreview = () => {
        setIsUpdatingFromPreview(true)
        userApiClient.updateFromPreview(+id!)
            .then(() => loadUser())
            .finally(() => setIsUpdatingFromPreview(false))
    }

    const submit = (form: IForm) => {
        setIsSaving(true)
        userApiClient.edit(form)
            .then(response => {
                if(response === undefined){
                    return
                }

                navigate(routes.administrationUsers())
            })
            .finally(() => setIsSaving(false))
    }

    useEffect(loadUser, [])
    useEffect(loadDealers, [])
    useEffect(loadRoles, [])
    useEffect(() => {
        if(user === undefined){
            setInitialValues(undefined)
            return
        }

        setInitialValues({
            id: user.id,
            userName: user.userName,
            firstName: user.firstName,
            lastName: user.lastName,
            title: user.title,
            email: user.email,
            phone: user.phone,
            isActive: user.isActive,
            skipMFA: user.skipMFA,
            roleIds: user.roles.map(x => x.id),
            dealerIds: user.dealers.map(x => x.id),
        })
    }, [user])
    useEffect(() => {
        getCoreViewRoles(Number(id))
    }, [])

    const isBusy =
        isLoadingUser ||
        isLoadingDealers ||
        isLoadingRoles ||
        isSaving ||
        isUpdatingFromPreview ||
        isUnlocking ||
        isResettingPassword ||
        isLoadingCoreViewRoles

    return (
        <div style={{ width: '700px'}}>
            <PageHeader text={$t('editUser')}/>
            <BusyIndicator busy={isBusy}>
            { initialValues &&
                <UserForm
                    initialValues={initialValues}
                    roles={roles}
                    coreViewRolesFormatted={coreViewRolesFormatted}
                    dealers={dealers}
                    isLockedOut={!user?.isLockedOut}
                    submit={submit}
                    cancel={cancel}
                    unlockUser={unlockUser}
                    resetPassword={resetPassword}
                    updateFromPreview={updateFromPreview}
                />
            }
            </BusyIndicator>
        </div>
    )
}

interface IFormProps {
    initialValues: IForm,
    roles: Array<IDropdownOption<number>>,
    coreViewRolesFormatted: string,
    dealers: Array<IDropdownOption<number>>,
    isLockedOut: boolean,
    submit: (form: IForm) => void,
    cancel: () => void,
    unlockUser: () => void,
    resetPassword: () => void,
    updateFromPreview: () => void,
}

function UserForm(props: IFormProps){
    const $t = useTranslation('EditUserPage')

    const validationSchema = Yup.object().shape({
        title: Yup.string().required(),
        email: Yup.string().email().required(),
        phone: Yup.string().phone().required(),
    })

    return (
        <Formik
            initialValues={props.initialValues || ({} as any)}
            onSubmit={props.submit}
            validationSchema={validationSchema}
        >
            {formikProps =>
                <Form onSubmit={formikProps.handleSubmit}>
                    <DPFormField
                        label={$t('userName')}
                        name="userName"
                    >
                        <DPInputText
                            name="userName"
                            disabled={true}
                        />
                    </DPFormField>

                    <DPFormField
                        label={$t('firstName')}
                        name="firstName"
                    >
                        <DPInputText
                            name="firstName"
                            disabled={true}
                        />
                    </DPFormField>

                    <DPFormField
                        label={$t('lastName')}
                        name="lastName"
                        required
                    >
                        <DPInputText
                            name="lastName"
                            disabled={true}
                        />
                    </DPFormField>

                    <DPFormField
                        name="title"
                        label={$t('Title')}
                        required
                    >
                        <DPInputUserTitleDropdown name="title" />
                    </DPFormField>

                    <DPFormField
                        label={$t('email')}
                        name="email"
                        required
                    >
                        <DPInputText name="email" />
                    </DPFormField>


                    <DPFormField
                        label={$t('phone')}
                        name="phone"
                        required
                    >
                        <DPInputText name="phone" />
                    </DPFormField>

                    <DPFormField
                        label={$t('isActive')}
                        name="isActive"
                    >
                        <DPInputCheckbox
                            name="isActive"
                            label={$t('isActiveLabel')}
                        />
                    </DPFormField>

                    <DPFormField
                        name="skipMFA"
                        label={$t('SkipMFA')}
                    >
                        <DPInputCheckbox name="skipMFA" />
                    </DPFormField>

                    <DPFormField
                        label={$t('roles')}
                        name="roleIds"
                    >
                        <DPInputDropdown
                            name="roleIds"
                            options={props.roles}
                            multiple
                            search
                        />
                    </DPFormField>

                    <DPFormField
                        label={$t('coreViewRoles')}
                        name="coreViewRoles"
                    >
                        <DPInputTextareaReadOnly
                            value={props.coreViewRolesFormatted}
                        />
                    </DPFormField>

                    <DPFormField
                        label={$t('dealers')}
                        name="dealerIds"
                    >
                        <DPInputDropdown
                            name="dealerIds"
                            options={props.dealers}
                            multiple
                            search
                        />
                    </DPFormField>

                    <div className={styles.buttons}>
                        <div className={styles.left}>
                            <DPButton
                                type="button"
                                size="mini"
                                onClick={props.cancel}
                            >
                                {$t('cancel')}
                            </DPButton>

                            <DPButton
                                type="button"
                                size="mini"
                                onClick={props.resetPassword}
                            >
                                {$t('newPassword')}
                            </DPButton>

                            <DPButton
                                type="button"
                                size="mini"
                                onClick={props.unlockUser}
                                disabled={ props.isLockedOut }
                            >
                                {$t('unlockUser')}
                            </DPButton>

                            <DPButton
                                type="button"
                                size="mini"
                                onClick={props.updateFromPreview}
                            >
                                {$t('updateFromPreview')}
                            </DPButton>
                        </div>

                        <div className={styles.right}>
                            <DPButton
                                type="submit"
                                size="mini"
                            >
                                {$t('save')}
                            </DPButton>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    )
}
