import { DealerApiClient, UpdateDealerRequest } from 'api/DealerApiClient'
import { routes } from 'routing'
import { BusyIndicator, DPFormField, DPInputTextReadOnly, FormikForm, PageHeader } from '@framework'
import { useApiClient } from '@hooks/useApiClient'
import { useTranslation } from '@hooks/useTranslation'
import { DPInputCheckbox } from 'infrastructure/framework/DPForms/DPInputCheckbox'
import { DPInputNumber } from 'infrastructure/framework/DPForms/DPInputNumber'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import styles from './EditDealerPage.module.css'
import { useFormikContext } from 'formik'
import { DPButton } from 'shared'

interface FormModel extends UpdateDealerRequest {
    name: string,
    cvr: string,
    dealerNumber: string,
    brandCode: number,
}

export function EditDealerPage() {
    const { id } = useParams()
    const $t = useTranslation('EditDealerPage')
    const navigate = useNavigate()
    const dealerApiClient = useApiClient((x) => new DealerApiClient(x))
    const [isLoadingDealer, setIsLoadingDealer] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [isUpdatingFromPreview, setIsUpdatingFromPreview] = useState(false)
    const [initialValues, setInitialValues] = useState<FormModel>()

    const loadDealer = () => {
        setIsLoadingDealer(true)
        dealerApiClient
            .getDealer(id || '')
            .then((result) => {
                if (result === undefined) {
                    return
                }

                setInitialValues({
                    id: result.id,
                    name: result.name,
                    cvr: result.cvr,
                    salesClub: result.salesClub,
                    commissionPeriod: result.merrentePeriod ?? 0,
                    hasServicePrMonthWithoutVat: result.vatOnServicePricePerMonth,
                    dealerNumber: result.dealerNumber,
                    brandCode: result.brandCode,
                } as FormModel)
            })
            .finally(() => setIsLoadingDealer(false))
    }

    const cancel = () => {
        navigate(routes.administrationDealers())
    }

    const submit = (form: FormModel) => {
        setIsSaving(true)
        const {dealerNumber, brandCode, name, cvr, ...updateDealerRequest} = form;
        dealerApiClient
            .updateDealer(updateDealerRequest)
            .then((response) => {
                if (response === undefined) {
                    return
                }

                navigate(routes.administrationDealers())
            })
            .finally(() => setIsSaving(false))
    }

    const updateFromPreview = () => {
        setIsUpdatingFromPreview(true)
        dealerApiClient
            .updateFromPreview(id || '')
            .then(() => loadDealer())
            .finally(() => setIsUpdatingFromPreview(false))
    }

    const validationSchema = Yup.object().shape({
        commissionPeriod: Yup.string().default(null).required().label($t('commissionPeriod')),
    })

    const isBusy = isLoadingDealer || isSaving || isUpdatingFromPreview

    useEffect(loadDealer, [])

    return (
        <div style={{ width: '700px'}}>
            <PageHeader text={$t('editDealer')}/>
            <BusyIndicator busy={isBusy}>
                <FormikForm
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                >
                    <FormFields />

                    <div className={styles.buttons}>
                        <div className={styles.left}>
                            <DPButton type="button" size="mini" onClick={cancel}>
                                {$t('cancel')}
                            </DPButton>

                            <DPButton type="button" size="mini" onClick={updateFromPreview}>
                                {$t('updateFromPreview')}
                            </DPButton>
                        </div>

                        <div className={styles.right}>
                            <DPButton type="submit" size="mini">
                                {$t('save')}
                            </DPButton>
                        </div>
                    </div>
                </FormikForm>
            </BusyIndicator>
        </div>
    )
}

function FormFields() {
    const $t = useTranslation('EditDealerPage')
    const { values } = useFormikContext<FormModel>()
    const { brandCode } = values

    return (
        <>
            <DPFormField label={$t('name')} name="name">
                <DPInputTextReadOnly value={values.name} />
            </DPFormField>

            <DPFormField label={$t('dealerNumber')} name="dealerNumber">
                <DPInputTextReadOnly value={values.dealerNumber} />
            </DPFormField>

            <DPFormField label={$t('cvr')} name="cvr">
                <DPInputTextReadOnly value={values.cvr} />
            </DPFormField>

            <DPFormField name="salesClub" label={$t('salesClub')}>
                <DPInputCheckbox name="salesClub" label={$t('salesClubCheckboxLabel')} />
            </DPFormField>

            <DPFormField label={$t('commissionPeriod')} name="commissionPeriod" required>
                <DPInputNumber name="commissionPeriod" />
            </DPFormField>

            <DPFormField name="hasServicePrMonthWithoutVat" label={$t('hasServicePrMonthWithoutVat')}>
                <DPInputCheckbox name="hasServicePrMonthWithoutVat" label={$t('hasServicePrMonthWithoutVatCheckboxLabel')} />
            </DPFormField>

            <DPFormField label={$t('brandCode')} name="brandCode">
                <DPInputTextReadOnly value={brandCode?.toString()} />
            </DPFormField>
        </>
    )
}
