import { IKycBuyer, KycStatus } from 'api/KycApplicationApiClient'
import { DescriptionList, DescriptionListItem } from '@framework'
import { useTranslation } from '@hooks'
import React, { useMemo } from 'react'
import { Grid } from 'semantic-ui-react'

interface IKycBuyerProps {
    model: IKycBuyer | undefined
}

export function KycBuyerComponent({ model }: IKycBuyerProps) {
    const $t = useTranslation('KycBuyerComponent')
    const getStatusText = (status: KycStatus): string => {
        switch (status) {
            case KycStatus.unknown: return ''
            case KycStatus.awaitingCobuyer: return $t('Application completed by customer. Awaiting co-applicant.')
            case KycStatus.awaitingCustomer: return $t('Application sent to customer. Awaiting customer to complete application flow.')
            case KycStatus.completeAwaitingSantander: return $t('Application completed by customer. Awaiting treatment at Santander.')
            case KycStatus.submitError: return $t('An error occurred while submitting.')
        }
    }
    const statusText = useMemo(() => {
        if (model?.status) {
            return getStatusText(model?.status)
        } else { return '' }
    }, [model?.status])
    return (
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column>
                    <DescriptionList>
                        <DescriptionListItem label={$t('Name')}>
                            {model?.name}
                        </DescriptionListItem>

                        <DescriptionListItem label={$t('Phone')}>
                            {model?.phoneNumber}
                        </DescriptionListItem>
                    </DescriptionList>
                </Grid.Column>

                <Grid.Column>
                    <DescriptionList>
                        <DescriptionListItem label={$t('E-mail')}>
                            {model?.email}
                        </DescriptionListItem>

                        <DescriptionListItem label={$t('Status')}>
                            {statusText}
                        </DescriptionListItem>
                    </DescriptionList>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}