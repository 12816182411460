import { ApiClient } from '@api/ApiClient'
import { IGridResult } from '@api/models'

export interface IUser {
    id: number,
    userName: string,
    firstName: string,
    lastName: string,
    title: string,
    email: string,
    phone: string,
    isActive: boolean,
    skipMFA: boolean,
    roles: Array<{ id: number }>,
    dealers: Array<{ id: number }>,
    isLockedOut: boolean
}

export interface IEditUserRequest{
    id: number,
    title: string,
    email: string,
    phone: string,
    isActive: boolean,
    roleIds: number[],
    dealerIds: number[],
}

export interface IExistsResponse{
    exists: boolean
}

export interface IUserName {
    id: number
    sellerAgentNo: string
    firstName: string
    lastName: string
    fullName: string
}

export interface ICoreViewRoleForDealer {
    dealerName: string,
    roleNames: string[]
}

export interface UserOverview {
    id: number
    userName: string
    firstName: string
    lastName: string
    email: string
    sellerNo?: number
    sellerAgentNo: string
    active: boolean
    blocked: boolean
    creationDate: string
    lastLoggedIn: string
}

export interface GetUsersQuery {
    skip: number
    take: number
    dealerId: number | undefined
    userName: string | undefined
    orderBy: string | undefined
}

export class UserApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getRoles(){
        return this.apiClient.get<Array<{ id: number, name: string }>>('api/user/roles').then(x => x.data)
    }

    public getCoreViewRoles = (id: number) => {
        const url = `api/user/${id}/core-view-roles`
        return this.apiClient.get<ICoreViewRoleForDealer[]>(url).then(x => x.data)
    }

    public getUser(id: number){
        return this.apiClient.get<IUser>(`api/user/user/${id}`).then(x => x.data)
    }

    public getUserRoles(){
        return this.apiClient.get<string[]>('api/userprofile/roles').then(x => x.data)
    }

    public exists(username: string){
        return this.apiClient.get<IExistsResponse>('api/user/exists', { username })
    }

    public edit(request: IEditUserRequest){
        return this.apiClient.post('api/user/edit', request).then(x => x.data)
    }

    public updateFromPreview(id: number){
        return this.apiClient.post(`api/user/update-from-preview/${id}`).then(x => x.data)
    }

    public unlock(id: number){
        return this.apiClient.post(`api/user/unlock/${id}`).then(x => x.data)
    }

    public getUsersForDealer = (dealerId: number) =>
        this.apiClient.get<IUserName[]>(`api/userprofile/${dealerId}/users`).then(x => x.data)

    public getUsers = (query: GetUsersQuery) =>
        this.apiClient.get<IGridResult<UserOverview>>('api/user/getUsers', query).then(x => x.data)
}