import { BusyIndicator, DPFormField, DPInputText, PageSectionHeader } from "@framework";
import { useApiClient } from "@hooks/useApiClient";
import { PublicApiClient } from "api";
import { Formik } from "formik";
import { useState } from "react";
import { Form, Message } from "semantic-ui-react";
import { DPButton } from "shared";
import { PublicCardLayout } from "shared/layout";
import * as Yup from 'yup'

interface Props {
    /**
     * For some reason, in old DP the reset username functionality is used for both:
     * - resetting username,
     * - setting username and password for new user.
     * To not break this functionality and remove code duplication,
     * I've decided to reuse ResetUsernamePage component.
     * Maybe in the future, business decide to change this behavior and new page will be needed.
     */
    newUser?: boolean,
}

export function ResetUsernameRequestPage(props: Props){
    const [sent, setSent] = useState(false);

    return (
        <PublicCardLayout>
            <PageSectionHeader children="Skift brugernavn/ny bruger" />
            { sent &&
                <Message positive>
                    Hvis din e-mailadresse findes i systemet, vil du om kort tid modtage en e-mail med instruktioner. Hvis du ikke modtager en e-mail bedes du kontakte salgssupport på telefon: 70 22 58 22.
                </Message>
            }
            <ResetUsernameRequestForm newUser={props.newUser} sent={sent} setSent={setSent} />
        </PublicCardLayout>
    )
}

interface FormModel {
    email: string | null,
}

function ResetUsernameRequestForm(props: {
    newUser?: boolean,
    sent: boolean,
    setSent: (value: boolean) => void,
}){
    const api = useApiClient(x => new PublicApiClient(x));
    const [busy, setBusy] = useState(false);

    const onSubmit = (model: FormModel) => {
        setBusy(true);
        const request =
            props.newUser
                ? api.newUserRequest(model)
                : api.resetUsernameRequest(model)
        request
            .then(x => {
                if(x.success){
                    props.setSent(true)
                }
            })
            .finally(() => setBusy(false));
    };

    const initialValues = {
        email: ""
    } as FormModel;

    const validationSchema = Yup.object().shape({
        email: Yup.string().required().label("E-mail"),
    });

    return (
        <BusyIndicator busy={busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps => 
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField label="E-mail" name="email" inline={false} >
                            <DPInputText name="email" disabled={props.sent} />
                        </DPFormField>

                        <DPButton type="submit" children="Send e-mail" disabled={props.sent} />
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}