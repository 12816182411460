import { useMemo } from 'react'
import { Agreement } from 'model'
import { ObjectType } from 'shared/ObjectType'

const objectTypeNameLookup = {
    1: "Hvidpladebiler",
    2: "Gulepladebiler",
    3: "Motorcykler",
    4: "Campingvogne",
    5: "Både",
    6: "Bådtilbehør",
    7: "Papegøjepladebiler",
    16: "Bådtilbehør",
} as { [id: number] : string | undefined}

export interface AvailableObjectType {
    id: number,
    name: string,
}

export default function useAvailableObjectTypes(agreements: Agreement[]) {
    const objectTypes = useMemo(() => {
        const distinctObjectTypes = Array.from(new Set(agreements.map(x => x.objectTypes).reduce((x, y) => [...x, ...y], [])))
        return distinctObjectTypes.map(x => ({
            id: x,
            name: objectTypeNameLookup[x] ?? x.toString(),
        } as AvailableObjectType))
    }, [agreements])

    return objectTypes
}