import { useSearchParams } from 'react-router-dom'
import { HomePagePopup } from 'routing';

const SearchParam = "popup";

export function usePopupsOnHomePage(){
    const [searchParams, setSearchParams] = useSearchParams()

    const popupSearchParams = searchParams.getAll(SearchParam);
    const popups = [] as HomePagePopup[]

    // To it this way to ensure correct order of popups.
    for(const popup of ['lastUnreadNews', 'incomingApplications'] as HomePagePopup[]){
        if(popupSearchParams.indexOf(popup) === -1){
            continue;
        }
        popups.push(popup)
    }

    const current = popups[0]
    const dismiss = (value: HomePagePopup) => {
        const copy = new URLSearchParams(searchParams.toString());

        // Do not use `delete` method accepting value as second parameter.
        // It has compatibility issue.
        // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/delete#browser_compatibility
        copy.delete(SearchParam);
        for(const popup of popups.filter(x => x !== value)){
            copy.append(SearchParam, popup)
        }
        
        setSearchParams(copy, { replace: true })
    }

    return {
        current,
        dismiss,
    }
}

