import { CalculatorApiClient } from 'api'
import { ApplicationContext } from 'pages/application/state'
import { DPFormField, DPInputDropdown, DPInputNumber, PageSectionHeader } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import { formatNumber } from 'shared/utils'
import React, { useContext, useEffect, useMemo } from 'react'

type FieldName =
  'serviceMonths' |
  'pricePerKm' |
  'serviceAgreementPrMonth' |
  'kmPerYear' |
  'serviceGuarantor'

function getFields(agreementCode: number): FieldName[] {
  switch(agreementCode){
    default:
      return ['serviceMonths', 'pricePerKm', 'serviceAgreementPrMonth', 'kmPerYear', 'serviceGuarantor']
  }
}

type FieldDefinitions = {
    [key in FieldName] : () => JSX.Element | null
}

export function ServiceAgreementComponent() {
    const $t = useTranslation('ServiceAgreementComponent')
    const session = useSession()
    const context = useContext(ApplicationContext)
    const fields = getFields(context.application.calculation.agreementCode)
    const calculatorApiClient = useApiClient(a => new CalculatorApiClient(a))
    const [kmPerYear, , getKmPerYearCategories] = useApiCall(calculatorApiClient.getKmPerYearCategories, [])
    const [serviceGuarantorCategories, , getServiceGuarantorCategories] = useApiCall(calculatorApiClient.getServiceGuarantorCategories, [])

    useEffect(() => {
        getKmPerYearCategories(session.currentDealer?.id!)
        getServiceGuarantorCategories(session.currentDealer?.id!)
    }, [])

    const formattedKmPerYearCategory = useMemo(() => {
        return [...kmPerYear].map(p => ({ ...p, text: formatNumber(p.value) }))
    }, [kmPerYear])

    const fieldDefinitions : FieldDefinitions = {

      'serviceMonths': () =>
        <DPFormField
          key="serviceMonths"
          label={$t('Number of months')}
          name="serviceMonths"
        >
          <DPInputNumber
            name="serviceMonths"
            dataQa="service_service_months"
          />
        </DPFormField>,

      'pricePerKm': () => 
          !context.application.calculation.serviceAgreementPrMonth ?
            <DPFormField
              key="pricePerKm"
              label={$t('PricePerKm')}
              name="pricePerKm"
            >
              <DPInputNumber
                name="pricePerKm"
                precision={3}
                dataQa="service_price_per_km"
                label="kr"
              />
            </DPFormField> : null,

      'serviceAgreementPrMonth': () =>
        <DPFormField
          key="serviceAgreementPrMonth"
          label={$t('Service agreement per month')}
          name="serviceAgreementPrMonth"
        >
          <DPInputNumber
            name="serviceAgreementPrMonth"
            dataQa="service_service_agreement_per_month"
            label="kr"
          />
        </DPFormField>,

      'kmPerYear': () =>
        <DPFormField
          key="kmPerYear"
          label={$t('Mileage per year (km)')}
          name="kmPerYear"
        >
          <DPInputDropdown
            name="kmPerYear"
            options={formattedKmPerYearCategory}
            placeholder={$t('Select')}
            dataQa="service_km_per_year"
          />
        </DPFormField>,

      'serviceGuarantor': () =>
        <DPFormField
          key="serviceGuarantor"
          label={$t('Service guarantor')}
          name="serviceGuarantor"
        >
          <DPInputDropdown
            name="serviceGuarantor"
            options={serviceGuarantorCategories}
            dataQa="service_service_guarantor"
          />
        </DPFormField>,
    }

    const fieldComponents =
      fields
        .map(field => fieldDefinitions[field]())

        return <>
            <PageSectionHeader>
              {$t('Service agreement')}
            </PageSectionHeader>
            {fieldComponents}
        </>
}