import { PageHeader } from '@framework'
import { useSession, useTranslation } from '@hooks'
import React from 'react'
import { SearchSavedComponent } from './SearchSavedComponent'

export function SearchSavedPage() {
  const $t = useTranslation('SearchSavedPage')
  const session = useSession()

  return (
    <article>
      <PageHeader text={$t('SearchSaved')} />

      <SearchSavedComponent
        dealerId={session.currentDealer?.id!}
      />
    </article>
  )
}