import { defaultQuery, IPagination } from '@api'
import { ApplicationApiClient, IKycApplicationOverviewModel, ISubmittedApplicationOverview } from 'api'
import { routes } from 'routing'
import { useAuthorization } from '@authentication'
import { DPDataTable, DPFormField, DPInputDate, DPInputDropdown, DPInputText, DPTableSearchForm, IDataTableColumn, PageSectionHeader } from '@framework'
import { useApiClient, useInterval, useModal, useTranslation } from '@hooks'
import { addDays, addMonths, dateToDanishDate } from 'shared/dateTools'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { SellersDropDownComponent } from './components/SellersDropDownComponent'
import usePendingApplications from './dataProviders/usePendingApplications'
import useSellers from './dataProviders/useSellers'
import useStatuses from './dataProviders/useStatuses'
import useSubmittedApplications, { ISearchParameters as ISubmittedApplicationsSearchParameters } from './dataProviders/useSubmittedApplications'
import { SubmittedApplicationStatus } from './SubmittedApplicationStatus'
import { usePendingApplicationStatus } from './usePendingApplicationStatus'
import { currentDateString } from 'shared/dateTools'

interface ISearchComponentProps {
    dealerId: number
}

type ISearchParameters =
    ISubmittedApplicationsSearchParameters

const initialValues: ISearchParameters = {
    salesPersonId: 0,
    status: 0,
    customer: '',
    from: addMonths(currentDateString(), -1),
    to: currentDateString()
}

export function SearchSubmittedComponent({
    dealerId,
}: ISearchComponentProps) {
    const $t = useTranslation('SearchSubmittedComponent')

    const applicationApi = useApiClient(x => new ApplicationApiClient(x))

    const [searchParams, setSearchParams] = useState<ISearchParameters>({
        ...initialValues,
        to: addDays(initialValues.to, 1)
    })
    const navigate = useNavigate()
    const [isCancelingPendingApplication, setIsCancelingPendingApplication] = useState(false)
    const [isCancelingSubmittedApplication, setIsCancelingSubmittedApplication] = useState(false)
    const [pendingApplicationsTableState, setPendingApplicationsTableState] = useState<IPagination | undefined>(defaultQuery)
    const [submittedApplicationsTableState, setSubmittedApplicationsTableState] = useState<IPagination | undefined>(defaultQuery)
    const [statuses] = useStatuses(dealerId)
    const [sellers] = useSellers(dealerId)
    const pendingApplications = usePendingApplications(dealerId, pendingApplicationsTableState)
    const submittedApplications = useSubmittedApplications(dealerId, submittedApplicationsTableState, searchParams, sellers)
    const { hasAccessToAllSubmittedCases: hasAccessToAllSubmittedApplicationsOfDealer } = useAuthorization()

    /* Refresh pending application after 30 seconds of last change to applications list */
    useInterval({
        callback: () => pendingApplications.refresh({ suppressError: true }),
        delay: 30 * 1000,
        resetSignal: pendingApplications.applications
    })

    /* Refresh submitted application after 30 seconds of last change to applications list */
    useInterval({
        callback: () => submittedApplications.refresh({ suppressError: true }),
        delay: 30 * 1000,
        resetSignal: submittedApplications.applications
    })

    const cancelPendingApplication = (referenceId: string) => {
        setIsCancelingPendingApplication(true)
        applicationApi.cancelApplication(dealerId, referenceId)
            .then(x => {
                if(!x.success){
                    return
                }

                pendingApplications.refresh()
            })
            .finally(() => setIsCancelingPendingApplication(false))
    }

    const cancelSubmittedApplication = (referenceId: string) => {
        setIsCancelingSubmittedApplication(true)
        applicationApi.cancelApplication(dealerId, referenceId)
            .then(x => {
                if(!x.success){
                    return
                }
                
                submittedApplications.refresh()
            })
            .finally(() => setIsCancelingSubmittedApplication(false))
    }

    const previewColumns = usePreviewColumns(cancelSubmittedApplication)
    const kycColumns = useKycColumns(cancelPendingApplication)

    return (
        <>
            <DPTableSearchForm
                initialValues={initialValues}
                onSubmit={(values: ISearchParameters) => setSearchParams(values)}
                debounce={{keys: ["customer"], delay: 500}}
                column1={
                    <DPFormField name="status" label={$t('Status')} inline={false}>
                        <DPInputDropdown name="status" options={statuses} />
                    </DPFormField>
                }
                column2={
                    <SellersDropDownComponent
                        sellers={sellers}
                        disabled={!hasAccessToAllSubmittedApplicationsOfDealer}
                    />
                }
                column3={
                    <DPFormField name="customer" label={$t('Customer')} inline={false}>
                        <DPInputText name="customer" />
                    </DPFormField>
                }
                column4 ={
                    <DPFormField name="from" label={$t('Start date')} inline={false}>
                        <DPInputDate name="from" />
                    </DPFormField>
                }
                column5={
                    <DPFormField name="to" label={$t('End date')} inline={false}>
                        <DPInputDate name="to" />
                    </DPFormField>
                }
            />
            <div>
                <PageSectionHeader className="mt-4">
                    {$t('Pending applications')}
                </PageSectionHeader>
                <DPDataTable
                    columns={kycColumns}
                    onRowSelect={({ referenceId }) =>
                        navigate(routes.applicationKycApplication(referenceId))
                    }
                    data={pendingApplications.applications}
                    initialPagination={defaultQuery}
                    isLoading={isCancelingPendingApplication || pendingApplications.loading}
                    onStateChange={setPendingApplicationsTableState}
                />
                <PageSectionHeader className="mt-4">
                    {$t('Submitted applications')}
                </PageSectionHeader>
                <DPDataTable
                    columns={previewColumns}
                    onRowSelect={({ referenceId }) =>
                        navigate(routes.applicationSubmittedApplication(referenceId))
                    }
                    data={submittedApplications.applications}
                    initialPagination={defaultQuery}
                    isLoading={isCancelingSubmittedApplication || submittedApplications.loading}
                    onStateChange={setSubmittedApplicationsTableState}
                />
            </div>
        </>
    )
}

const usePreviewColumns = (cancelApplication: (referenceId: string) => void): Array<IDataTableColumn<ISubmittedApplicationOverview>> => {
    const $t = useTranslation('SearchSubmittedComponent')
    const modal = useModal()
    return [
        {
            key: 'caseNumber',
            name: $t('caseNumber'),
            onRender: ({ caseNumber }: ISubmittedApplicationOverview) => caseNumber,
        },
        {
            key: 'dateReceived',
            name: $t('Date'),
            onRender: ({ dateReceived }: ISubmittedApplicationOverview) => dateToDanishDate(new Date(dateReceived))
        },
        {
            key: 'contractName',
            name: $t('Contract No.')
        },
        {
            key: 'customerName',
            name: $t('Name')
        },
        {
            key: 'agreementName',
            name: $t('Product')
        },
        {
            key: 'statusName',
            name: $t('Status'),
            onRender: (item: ISubmittedApplicationOverview) => <SubmittedApplicationStatus status={item.status} />
        },
        {
            key: 'salesPerson',
            name: $t('Seller'),
            textAlign: 'right',
            onRender: (item: ISubmittedApplicationOverview) => item.salesPerson,
        },
        {
            key: 'vin',
            name: $t('VIN')
        },
        {
            key: '',
            name: '',
            textAlign: 'right',
            onRender: (item: ISubmittedApplicationOverview) => {
              return (
                  <>
                    <Link
                        to={routes.applicationSubmittedApplication(item.referenceId)}
                        onClick={e => e.stopPropagation()} // Prevent click event propagation so onClick at row level is not triggered (#148697)
                    >
                        <Icon name="external alternate" />
                    </Link>
                    <Icon
                        link
                        name="delete"
                        className={item.cancellable ? '' : 'invisible'}
                        onClick={(e: React.MouseEvent) => {
                            e.stopPropagation()
                
                            modal.confirm({
                                message: $t('DeleteModalMsg'),
                                okButtonLabel: $t('DeleteModalOkLabel'),
                                cancelButtonLabel: $t('DeleteModalCancelLabel'),
                                onOk: () => cancelApplication(item.referenceId)
                            })
                        }} 
                    />
                </>
              )
            }
          }
    ]
}

const useKycColumns = (cancelApplication: (referenceId: string) => void): Array<IDataTableColumn<IKycApplicationOverviewModel>> => {
    const $t = useTranslation('SearchSubmittedComponent')
    const pendingApplicationStatus = usePendingApplicationStatus()
    const modal = useModal()

    return [
        {
            key: 'caseNumber',
            name: 'Id',
            onRender: ({ caseId }: IKycApplicationOverviewModel) => caseId,
        },
        {
            key: 'dateReceived',
            name: $t('Date'),
            // TODO: Investigate on why do we do not use format
            // format: DataFormat.Date
            onRender: ({ dateReceived }: IKycApplicationOverviewModel) => dateToDanishDate(new Date(dateReceived))
        },
        {
            key: 'customerName',
            name: $t('Name')
        },
        {
            key: 'agreementName',
            name: $t('Product')
        },
        {
            key: 'status',
            name: $t('Status'),
            onRender: (caseItem: IKycApplicationOverviewModel) => pendingApplicationStatus.description(caseItem.status)
        },
        {
            key: 'salesPerson',
            name: $t('Seller')
        },
        {
            key: 'vin',
            name: $t('VIN')
        },
        {
            key: '',
            name: '',
            textAlign: 'right',
            onRender: (item: IKycApplicationOverviewModel) => {
              return (
                <>
                    <Link
                        to={routes.applicationKycApplication(item.referenceId)}
                        onClick={e => e.stopPropagation()} // Prevent click event propagation so onClick at row level is not triggered (#148697)
                    >
                        <Icon name="external alternate" />
                    </Link>
                    <Icon link name="delete" onClick={(e: React.MouseEvent) => {
                        e.stopPropagation()
            
                        modal.confirm({
                            message: $t('DeleteModalMsg'),
                            okButtonLabel: $t('DeleteModalOkLabel'),
                            cancelButtonLabel: $t('DeleteModalCancelLabel'),
                            onOk: () => cancelApplication(item.referenceId)
                        })
                    }}
                    />
                </>
              )
            }
          }
    ]
}
