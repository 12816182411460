import { routes } from 'routing'
import { AuthenticationContext, IAuthenticationContext } from '@authentication'
import { config } from '@config'
import { useTranslation } from '@hooks/useTranslation'
import { IModalContext, ModalContext } from '@modal'
import React, { createContext, ReactChild, ReactElement, useContext, useMemo } from 'react'
import { ApiClient, IApiErrorHandler, IValidationError } from '.'

export const ApiClientContext = createContext<ApiClient | undefined>(undefined)

interface IApiClientProviderProps {
  children: ReactChild[] | ReactChild,
}

export function ApiClientProvider({ children }: IApiClientProviderProps) {
  const modalContext = useContext(ModalContext)
  const authenticationContext = useContext(AuthenticationContext)
  const $t = useTranslation('ApiErrorHandler')
  const cachedApiClient = useMemo(() => {
    const client =
      new ApiClient(
        config.bffUrl,
        createApiErrorHandler(
          modalContext!,
          authenticationContext,
          $t))
    return client
  }, [])

  return (
    <ApiClientContext.Provider value={cachedApiClient}>
      {children}
    </ApiClientContext.Provider>
  )
}

function Http404({errorData}: {errorData: IValidationError}): ReactElement {
  return (
    !errorData.errors
    ? 
      <pre>
        {JSON.stringify(errorData, null, 2)}
      </pre>
    :
      <ul>
      {
        Object.values(errorData.errors)
          .reduce((newErrors, errors) => [...errors, ...newErrors])
          .map((error, index) => <li key={index}>{error}</li>)
      }
      </ul>
  )
}

export function createApiErrorHandler(
  modalContext: IModalContext,
  authenticationContext: IAuthenticationContext,
  $t: (key: string) => string) {
  const apiErrorHandler: IApiErrorHandler = {
      handle400BadRequest: data => {
        const content =
          typeof(data) === 'string'
          ?
            <>
              { data ? data : $t('Unexpected error occurred. Please send the information below to our support.') }
            </>
          :
            <>
              <p>{data.title}</p>
              <Http404 errorData={data} />
            </>
            
        modalContext.error({ content, errorData: JSON.stringify(data, null, 2) })
      },
      handleError: (responseUrl?: string, responseData?: string) => {
        const pageUrl = window.location.href
        const responseDataFormatted = (() => {
            try {
              return JSON.stringify(JSON.parse(responseData ?? ""), null, 2)
            } catch (e) {
              return responseData;
            }
        } )();
        const urlPreStyle = {
          background: '#f3f3f3',
          padding: '1rem',
          wordBreak: 'break-all',
          whiteSpace: 'normal'
        } as React.CSSProperties
        const dataPreStyle = {
          background: '#f3f3f3',
          padding: '1rem',
          overflowX: 'auto',
        } as React.CSSProperties
        const content = (
          <>
            <p>
              {$t('Unexpected error occurred. Please send the information below to our support.')}
            </p>

            {responseUrl &&
              <p>
                Response url:<br />
                <pre style={urlPreStyle}>{responseUrl}</pre>
              </p>
            }

            {responseData &&
              <p>
                Response data:<br/>
                <pre style={dataPreStyle}>{responseDataFormatted}</pre>
              </p>
            }

            <p>
              Page url:<br/>
              <pre style={urlPreStyle}>{pageUrl}</pre>
            </p>
          </>
        );

        const formattedErrorData = [
          ...(responseUrl ? ["Response url", responseUrl] : []),
          "",
          ...(responseData ? ["Response data", responseDataFormatted] : []),
          "",
          "Page url", pageUrl
        ].join("\r\n");
        modalContext.error({ content, errorData: formattedErrorData })
      },
      handleUnauthenticated: () => {
        // TODO Authentication context should be modified inside the "Access denied" page.
        // Currently it's not possible because we uses 'location.href' directly (it triggers full page refresh).
        // During page loading the components make own API calls (all unauthenticated), what leads us to infinite redirect loop.
        // As a hack it's possible to change authentication context here, so during next page loading user is unknown.
        authenticationContext.logOut()
        window.location.href = process.env.PUBLIC_URL + routes.accessDenied()
      },
      handleUnauthorized: () => {
        authenticationContext.logOut()
        window.location.href = process.env.PUBLIC_URL + routes.welcomePage()
      }
  }
  return apiErrorHandler
}