import { SubmittedApplicationStatus } from 'api'
import { useTranslation } from '@hooks/useTranslation'

export function useSubmittedApplicationStatus(){
    const $t = useTranslation('SubmittedApplicationStatus')
    const description = (status: SubmittedApplicationStatus | string) => {
        switch(status){
            case 'All':
                return $t('All')
            case 'New':
                return $t('New')
            case 'Rejected':
                return $t('Rejected')
            case 'Cancelled':
                return $t('Cancelled')
            case 'Handled':
                return $t('Handled')
            case 'Granted':
                return $t('Granted')
            case 'Paid':
                return $t('Paid') 
        }

        return status
    }

    return { description }
}