import { PageHeader, PageSectionHeader } from "@framework";
import { useSession } from "@hooks/useSession";
import { useTranslation } from "@hooks/useTranslation";
import { useContact } from "contact"
import { KnownBrandCode } from "shared";
import "./ContactPage.css"

export function ContactPage() {
    const $t = useTranslation('ContactPage');
    const session = useSession()
    const contact = useContact(session?.currentDealer?.brandCode ?? KnownBrandCode.Santander);

    return (
        <div>
            <PageHeader text={$t('contact')} />

            {/* Address */}
            <div className="contact-page-section">
                <PageSectionHeader>{$t('address')}</PageSectionHeader>
                <div>{contact.company.name}</div>
                {contact.company.address.map((x, index) => <div key={index}>{x}</div>)}
                <div>CVR: {contact.company.CVR}</div>
            </div>

            {/* Front office */}
            <div className="contact-page-section">
                <PageSectionHeader>{$t('frontOffice')}</PageSectionHeader>
                <div>
                    <div className="label">{$t('phone')}:</div> <a href={`tel:${contact.frontOffice.phone}}`}>{contact.frontOffice.phone}</a>
                </div>
                <div>
                    <div className="label">{$t('email')}:</div> <a href={`mailto:${contact.frontOffice.email}}`}>{contact.frontOffice.email}</a>
                </div>

                <p>{$t('openingHours')}</p>
                {contact.frontOffice.openingHours.map((x, index) => (
                    <div key={index}>
                        <div className="label">{x[0]}:</div> {x[1]} - {x[2]}
                    </div>
                ))}
                {contact.frontOffice.openingHoursException?.length > 0 &&
                    <div>
                        <br />
                        <p>{$t('exceptionally ')}</p>
                        <ol>
                            {contact.frontOffice.openingHoursException.map((x, index) => <li key={index}>{x}</li>)}
                        </ol>
                    </div>
                }   
            </div>

            {/* Customer service */}
            <div className="contact-page-section">
                <PageSectionHeader>{$t('customerService')}</PageSectionHeader>

                <div>
                    <div className="label">{$t('phone')}:</div> <a href={`tel:${contact.customerService.phone}}`}>{contact.customerService.phone}</a>
                </div>
                <div>
                    <div className="label">{$t('emailLoan')}:</div> <a href={`mailto:${contact.customerService.emailLoan}}`}>{contact.customerService.emailLoan}</a>
                </div>
                <div>
                    <div className="label">{$t('emailLeasing')}:</div> <a href={`mailto:${contact.customerService.emailLeasing}}`}>{contact.customerService.emailLeasing}</a>
                </div>

                <p>{$t('openingHours')}</p>
                {contact.customerService.openingHours.map((x, index) => (
                    <div key={index}>
                        <div className="label">{x[0]}:</div> {x[1]} - {x[2]}
                    </div>
                ))}
            </div>

            {/* Sales support */}
            <div className="contact-page-section">
                <PageSectionHeader>{$t('salesSupport')}</PageSectionHeader>
                <div>
                    <div className="label">{$t('phone')}:</div> <a href={`tel:${contact.salesSupport.phone}}`}>{contact.salesSupport.phone}</a>
                </div>
                <div>
                    <div className="label">{$t('email')}:</div> <a href={`mailto:${contact.salesSupport.email}}`}>{contact.salesSupport.email}</a>
                </div>

                <p>{$t('openingHours')}</p>
                {contact.salesSupport.openingHours.map((x, index) => (
                    <div key={index}>
                        <div className="label">{x[0]}:</div> {x[1]} - {x[2]}
                    </div>
                ))}
            </div>
        </div>
    )
}