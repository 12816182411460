import { useState } from "react";

export function useDebounce<T>(func: (value: T) => void, delay: number){
    const [timer, setTimer] = useState<number>();

    const debounce = (value: T) => {
        timer && window.clearTimeout(timer);
        const newTimer = window.setTimeout(() => func(value), delay);
        setTimer(newTimer);
    }

    return debounce;
}