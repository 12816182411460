import { PageHeader } from '@framework'
import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import { UserProfileComponent } from './UserProfileComponent'

export function UserProfilePage() {

    const $t = useTranslation('UserProfilePage')

    return (
        <article style={{ width: '700px'}}>
            <PageHeader text={$t('Edit profile')} />

            <UserProfileComponent />
        </article>
    )
}