import { FileModel } from '@api/models'
import { INews } from 'api/NewsApiClient'
import { routes } from 'routing'
import { BusyIndicator, DPFileInput, DPFormField, DPInputCheckbox, DPInputDate, DPInputText, DPInputTextarea, DPRichTextInput, FormErrorSummary, FormikForm, PageHeader } from '@framework'
import { useTranslation } from '@hooks'
import { addDays, currentDateString } from 'shared/dateTools'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'

import './NewsForm.css'
import styles from './NewsForm.module.css'
import { DPButton } from 'shared'

interface INewsFormProps {
  // callbacks below are optional because they're used only in edit news scenarios; adding news doesn't require them
  downloadFileCallback?: (id: number) => Promise<void>,
  buildImageUrlCallback?: (file: FileModel) => string,
  headerText: string,
  initialValues: INews,
  isBusy: boolean,
  submit: (form: INews) => void,
  submitText: string
}

export function NewsForm({
    downloadFileCallback,
    headerText,
    initialValues,
    isBusy,
    submit,
    submitText
  }: INewsFormProps) {

  const $t = useTranslation('News')
  const navigate = useNavigate()

  const validationSchema = Yup.object().shape({
    title: Yup.string().required().min(5).max(200).label($t('Title')),
    newsTeaser: Yup.string().required().min(20).max(500).label($t('NewsTeaser')),
    content: Yup.string().required().min(20).label($t('Content')),
    publishDate: Yup.string().required().minDate(currentDateString()).label($t('PublishDate')),
    expirationDate: Yup.string().nullable().when(
      'publishDate',
      (publishDate: string) => Yup.string().minDate(addDays(publishDate, 1)).label($t('ExpirationDate')))
  })

  const cancel = (): void => {
    navigate(initialValues.isArchived ? routes.newsArchive() : routes.homePage())
  }

  const size = 'small'

  const headerRef = useRef<HTMLHeadingElement>(null)

  return (
    <article>
      <BusyIndicator busy={isBusy}>
        <PageHeader
          text={headerText}
          ref={headerRef}
        />

        <FormikForm
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
        >
          <FormErrorSummary scrollToElement={headerRef}/>

          <DPFormField label={$t('Title')} name="title" required inline={false}>
            <DPInputText name="title"/>
          </DPFormField>

          <DPFormField label={$t('NewsTeaser')} name="newsTeaser" tooltip={$t('NewsTeaserDesc')} required inline={false}>
            <DPInputTextarea name="newsTeaser" rows={4}/>
          </DPFormField>

          <DPFormField label={$t('Content')} name="content" required inline={false}>
            <DPRichTextInput name="content" />
          </DPFormField>

          <div className={styles['news-properites']}>
            <DPFormField label={$t('PublishDate')} name="publishDate" tooltip={$t('PublishDateDesc')} required inline={false} className={'news-properties__item'}>
              <DPInputDate name="publishDate" />
            </DPFormField>

            <DPFormField label={$t('ExpirationDate')} name="expirationDate" tooltip={$t('ExpirationDateDesc')} inline={false} className={'news-properties__item'}>
              <DPInputDate name="expirationDate"/>
            </DPFormField>

            <DPFormField label={$t('Popup')} name="isPopup" tooltip={$t('PopupDesc')} inline={false}>
              <DPInputCheckbox name="isPopup" />
            </DPFormField>
          </div>

          <DPFormField label={$t('Attachments')} name="attachments" className="mt-1" inline={false}>
            <DPFileInput downloadFileCallback={downloadFileCallback} name="attachments" />
          </DPFormField>

          <div className={styles.buttons}>
              <div className={styles.left}>
                  <DPButton type="button" onClick={cancel} size={size}>
                      {$t('Cancel')}
                  </DPButton>
              </div>

              <div className={styles.right}>
                  <DPButton type="submit" size={size}>
                      {submitText}
                  </DPButton>
              </div>
          </div>
        </FormikForm>
      </BusyIndicator>
    </article>
  )
}