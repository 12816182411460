import { routes } from 'routing'
import { AuthenticationContext } from '@authentication'
import { useApiClient } from '@hooks/useApiClient'
import { AuthApiClient } from 'infrastructure/authentication/AuthApiClient'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import FullPageLoader from './FullPageLoader'

export default function LogOutPage() {
    const navigate = useNavigate()
    const authenticationContext = useContext(AuthenticationContext)
    const authApiClient = useApiClient(x => new AuthApiClient(x))

    useEffect(() => {
        authApiClient.logout()
            .then(() => {
                authenticationContext.logOut()
                navigate(routes.welcomePage())
            })
    }, [])

    return <FullPageLoader />
}
