import { routes } from 'routing'
import { BusyIndicator, DPFormField, DPInputCheckbox, DPInputText, DPInputUserTitleDropdown, FormikForm, FormSubmit } from '@framework'
import { useApiCall } from '@hooks/useApiCall'
import { useApiClient } from '@hooks/useApiClient'
import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import { DPInputDropdown } from 'infrastructure/framework/DPForms/DPInputDropdown'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { UserProfileApiClient, IUserProfileModel } from 'api'

export function UserProfileComponent() {

  const $t = useTranslation('UserProfileComponent')

  const navigate = useNavigate()
  const session = useSession()
  const userProfileApiClient = useApiClient(a => new UserProfileApiClient(a))
  const [user, isLoading, getCurrentUser] = useApiCall(userProfileApiClient.getUserProfile, {} as IUserProfileModel)
  const [, isUpdating, updateUser] = useApiCall(userProfileApiClient.updateUser)

  useEffect(() => {
    getCurrentUser()
  }, [])

  const dealers = useMemo(() => {
    return (user.dealers || [])
      .map(x => ({
        key: x.id.toString(),
        value: x.id,
        text: x.name || x.officialName
      }))
      .filter(x => x.text !== '')
      .sort((a, b) => a.text > b.text ? 1 : -1)
  }, [user])

  const userSchema = Yup.object().strict(true).shape({
    title: Yup.string().required(),
    email: Yup.string().email().required(),
    phone: Yup.string().required().phone(),
  })

  return (
    <BusyIndicator busy={isLoading || isUpdating}>
      <FormikForm
        initialValues={user}
        onSubmit={async (values) => {
          const result = await updateUser(values)
          if (result.success) {
            await session.refresh()
            navigate(routes.homePage())
          }
        }}
        validationSchema={userSchema}
      >
        {/* User name */}
        <DPFormField
          name="userName"
          label={$t('User Name')}
        >
          <DPInputText
            name="userName"
            disabled
          />
        </DPFormField>

        {/* First name */}
        <DPFormField
          name="firstName"
          label={$t('First Name')}
        >
          <DPInputText
            name="firstName"
            disabled
          />
        </DPFormField>

        {/* Last name */}
        <DPFormField
          name="lastName"
          label={$t('Last Name')}
        >
          <DPInputText
            name="lastName"
            disabled
          />
        </DPFormField>

        {/* Title */}
        <DPFormField
          name="title"
          label={$t('Title')}
          required
        >
          <DPInputUserTitleDropdown name="title" />
        </DPFormField>

        {/* Email */}
        <DPFormField
          name="email"
          label={$t('E-mail')}
          required
        >
          <DPInputText name="email" />
        </DPFormField>

        {/* Phone */}
        <DPFormField
          name="phone"
          label={$t('Mobile phone')}
          required
        >
          <DPInputText name="phone" />
        </DPFormField>

        {/* Default dealer */}
        <DPFormField
          name="defaultDealerId"
          label={$t('Default dealer')}
        >
          <DPInputDropdown
            name="defaultDealerId"
            options={dealers}
          />
        </DPFormField>

        {/* SMS notification */}
        <DPFormField
          name="smsNotifications"
          label={$t('SMSNotifications')}
        >
          <DPInputCheckbox name="smsNotifications" />
        </DPFormField>

        {/* SMS notification */}
        <DPFormField
          name="skipMFA"
          label={$t('SkipMFA')}
        >
          <DPInputCheckbox name="skipMFA" />
        </DPFormField>

        {/* Submit */}
        <FormSubmit
          submitName={$t('Save')}
        />
      </FormikForm>
    </BusyIndicator>
  )
}