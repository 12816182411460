import { routes } from 'routing'
import { AuthenticationContext, AuthorizationContext, IAuthorizationContext } from '@authentication'
import React, { ReactNode, useContext } from 'react'
import { Navigate } from 'react-router'

interface IProps {
    children: ReactNode,
    condition?: (authorization: IAuthorizationContext) => boolean,
}

export function ProtectedRoute(props: IProps) {
    const authenticationContext = useContext(AuthenticationContext)
    // TODO At this moment it's not possible to use useAuthorization hook here,
    // the ProtectedRoute component is not covered by SessionGuard and the context may be undefined.
    const authorization = useContext(AuthorizationContext)

    if (!authenticationContext.authenticated) {
        return <Navigate to={routes.welcomePage()} />
    }

    if (authorization && props.condition && !props.condition(authorization)) {
        return <Navigate to={routes.accessDenied()} />
    }

    return <>{props.children}</>
}