import { ApplicationApiClient, IOfferDocumentRequest } from 'api'
import { Agreement, Application, CalculationResult } from 'model'
import { useApiClient, useSession, useTranslation } from '@hooks'
import { Icon } from 'semantic-ui-react'
import { currentDateString } from 'shared/dateTools'
import { DPButton } from 'shared'

interface IProps {
  application: Application,
  agreement: Agreement | undefined,
  calculationResult: CalculationResult | undefined,
  disabled: boolean,
  setBusy: (value: boolean) => void,
}

export function OfferButton(props: IProps) {
  const session = useSession()
  const applicationApi = useApiClient(x => new ApplicationApiClient(x))
  const { application, calculationResult } = props

  function mapToRequest(): IOfferDocumentRequest {
    const customer = application.customer
    const object = application.object
    const calculation = application.calculation
    const summary = calculationResult?.summary
    return {
      calculation: {
        additionalKm: 0,          // REQUIRED,
        administrationFee: summary?.administrationFee ?? 0, // REQUIRED
        agreementCode: props.agreement!.code,  // REQUIRED
        aop: summary?.aopBeforeTax?? 0,    // REQUIRED, see BaseCalculation.calculateAOP(false) in old Portal solution
        aopBeforeTaxRaw: summary?.aopBeforeTaxRaw ?? 0, // REQUIRED, but doesn't seem to be used in in new dealer portal ui, bacause "Safe" agreements aren't supported
        calculateEarnings: calculation?.calculateEarnings, // REQUIRED, needed to save loan calculation before offer
        changedParameter: calculation?.changedParameter,  // REQUIRED, needed to save loan calculation before offer
        commitmentFee: calculation?.commitmentFee ?? 0, // REQUIRED
        deliveryDate: calculation?.deliveryDate,  // REQUIRED
        documentFee: calculation?.documentFee ?? 0, // REQUIRED
        downPayment: calculation?.downPayment ?? 0, // REQUIRED
        financedAmount: summary?.financedAmount ?? 0, // REQUIRED
        firstRegistrationDate: calculation?.firstRegistrationDate,  // REQUIRED, needed to save loan calculation before offer
        includePayments: calculation?.includePayments ?? false, // REQUIRED, needed to save loan calculation before offer
        inspectionFee: summary?.inspectionFee ?? 0, // REQUIRED
        insuranceAdminFee: summary?.insuranceAdminFee ?? 0, // REQUIRED
        interestRate: calculation?.interestRate ?? 0, // REQUIRED
        interestType: calculation?.interestType ?? 0, // REQUIRED
        invoiceFee: summary?.invoiceFee ?? 0, // REQUIRED
        kmPerYear: calculation?.kmPerYear ?? 0, // REQUIRED
        mileage: calculation?.mileage ?? 0,  // REQUIRED
        nomInterest: summary?.nominalInterest ?? 0, // REQUIRED
        objectAgeMonths: calculation?.objectAgeMonths, // REQUIRED, needed to save loan calculation before offer
        objectPrice: calculation?.objectPrice,  // REQUIRED
        objectType: application.objectType, // REQUIRED
        paymentFreePeriods: calculation?.paymentFreePeriods,  // REQUIRED, needed to save loan calculation before offer
        paymentPerMonth: calculation?.paymentPerMonth ?? 0, // REQUIRED
        paymentTerms: calculation?.paymentTerms ?? 0, // REQUIRED
        residualValue: calculation?.residualValue ?? 0, // REQUIRED
        salesPersonId: calculation?.salesPersonId, // REQUIRED, needed to save loan calculation before offer
        serviceAgreementPrMonth: calculation?.serviceAgreementPrMonth ?? 0, // REQUIRED
        serviceGuarantor: calculation?.serviceGuarantor, // REQUIRED, needed to save loan calculation before offer
        serviceMonths: calculation?.serviceMonths, // REQUIRED, needed to save loan calculation before offer
        showMoneyLine: false,     // REQUIRED, TODO: indicate if SellDetailsComponent is visible after clicking on PRODUCT label
        stempelTinglysning: summary?.stempelTinglysning!, // REQUIRED
        terminationFee: summary?.terminationFee!, // REQUIRED
        totalLoanCosts: summary?.totalLoanCosts!, // REQUIRED
        totalRepay: summary?.totalRepay!, // REQUIRED
      },  
      customer: {
        address: customer.street || null,
        city: customer.city || null,
        email: customer.email || null,
        firstName: customer.firstName || null,
        lastName: customer.lastName || null,
        zip: customer.zip || null
      },
      financialObject: {
        brandName: object?.make ?? '',
        deliveryDate: object?.deliveryDate ?? currentDateString(),
        milage: object?.mileage ?? 0,
        modelName: object?.model ?? '' ,
        objectPrice: calculationResult?.input?.objectPrice ?? 0,
        objectType: application.objectType,
        variantName: object?.variant ?? ''
      },
      isLoan: true,   // hardcoded for the time being, because "type of financing" is displayed as "Loan" in ProductComponent.tsx
      isPrivate: props.agreement!.isPrivate,
      isUnSecure: props.agreement!.isUnsecure,
      serviceAgreementPrMonth: calculation?.serviceAgreementPrMonth ?? 0,
    }
  }

  async function downloadOfferFile() {
    props.setBusy(true)

    const request = mapToRequest()

    await applicationApi.generateOfferDocument(session.currentDealer?.id!, request)
          .finally(() => props.setBusy(false))
  }

  const $t = useTranslation('OfferButton')
  return (
    <>
      <DPButton
        size="mini"
        onClick={() => downloadOfferFile()}
        basic
        disabled={props.calculationResult === undefined || props.disabled}>
        <Icon name="tag" /> {$t('Offer')} 
      </DPButton>
    </>
  )
}
