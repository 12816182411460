import { useTranslation } from '@hooks/useTranslation'
import { keyCodes } from 'shared/keyCodes'
import React, { useEffect } from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import { DPButton } from 'shared'

interface IConfirmModalProps {
    message: string,
    okButtonLabel?: string,
    cancelButtonLabel?: string,
    onOk(): void
    onCancel(): void
}

export function ConfirmModal({ message, onOk, onCancel, okButtonLabel, cancelButtonLabel }: IConfirmModalProps) {

    const $t = useTranslation('Modal')

    const keyPressHandler = (e: KeyboardEvent) => {
        if (e.keyCode === keyCodes.enter) {
            onOk()
        }
    }

    useEffect(() => {
        window.addEventListener('keypress', keyPressHandler)
        return () => {
            window.removeEventListener('keypress', keyPressHandler)
        }
    }, [])

    return (
        <Modal open centered size="small" dimmer="inverted" onClose={onCancel}>
            <Modal.Content>
                <p>
                    <Icon name="question circle" size="big" />
                    {message}
                </p>
            </Modal.Content>
            <Modal.Actions>
                <DPButton onClick={onOk}>
                    <Icon name="checkmark" /> { okButtonLabel ?? $t('OK')}
                </DPButton>
                <DPButton color="grey" onClick={onCancel}>
                    <Icon name="remove" /> { cancelButtonLabel ?? $t('Cancel')}
                </DPButton>
            </Modal.Actions>
        </Modal>
    )
}
