import React, { ReactNode, useState } from 'react'
import { createContext, ReactChild } from 'react'
import { ConfirmModal } from './ConfirmModal'
import { ErrorModal } from './ErrorModal'
import { InfoModal } from './InfoModal'

interface IErrorModalConfiguration {
    content: JSX.Element,
    errorData: string
}

interface IConfirmModalConfiguration {
    message: string,
    okButtonLabel?: string,
    cancelButtonLabel?: string,
    onOk: () => void,
    onCancel?: () => void
}

interface IInfoModalConfiguration {
    title: string,
    message: string,
    buttonContent?: ReactNode,
    onClose?: () => void
}

export interface IModalContext {
    error(configuration: IErrorModalConfiguration): void
    confirm(configuration: IConfirmModalConfiguration): void
    info(configuration: IInfoModalConfiguration): void
}

export const ModalContext = createContext<IModalContext | undefined>(undefined)

export function ModalProvider({ children }: { children: ReactChild[] | ReactChild }) {
    
    const errorMessagesStack = useStack<IErrorModalConfiguration>()
    const confirmMessagesStack = useStack<IConfirmModalConfiguration>()
    const infoMessageStack = useStack<IInfoModalConfiguration>()

    const modalContext: IModalContext = {
        error(configuration: IErrorModalConfiguration) {
            errorMessagesStack.push(configuration)
        },
        confirm(configuration: IConfirmModalConfiguration) {
            confirmMessagesStack.push(configuration)
        },
        info(configuration: IInfoModalConfiguration) {
            infoMessageStack.push(configuration)
        },
    }

    return (
        <ModalContext.Provider value={modalContext}>
            {
                errorMessagesStack.current &&
                <ErrorModal
                    {...errorMessagesStack.current }
                    onOk={() => errorMessagesStack.pop()}
                />
            }
            {
                confirmMessagesStack.current &&
                <ConfirmModal
                    message={confirmMessagesStack.current.message}
                    okButtonLabel={confirmMessagesStack.current.okButtonLabel}
                    cancelButtonLabel={confirmMessagesStack.current.cancelButtonLabel}
                    onOk={() => {
                        confirmMessagesStack.current.onOk()
                        confirmMessagesStack.pop()
                    }}
                    onCancel={() => {
                        if(confirmMessagesStack.current.onCancel){
                            confirmMessagesStack.current.onCancel()
                        }
                        confirmMessagesStack.pop()
                    }}
                />
            }
            {
                infoMessageStack.current &&
                <InfoModal
                    title={infoMessageStack.current.title}
                    message={infoMessageStack.current.message}
                    buttonContent={infoMessageStack.current.buttonContent}
                    onClose={() => {
                        if(infoMessageStack.current.onClose){
                            infoMessageStack.current.onClose()
                        }
                        infoMessageStack.pop()
                    }}
                />
            }
            {children}
        </ModalContext.Provider>
    )
}

function useStack<T>() {
    const [stack, setStack] = useState<T[]>([])

    const current = stack[0]
    const push = (item: T) => setStack(x => [item, ...x])
    const pop = () => setStack(x => [...x.filter((_, i) => i > 0)])

    return {
        /** The item at the top of the stack or undefined when stack is empty. */
        current,
        /** Adds the item at the top of the stack. */
        push,
        /** Discard the current item (the top one), from the stack. */
        pop
    }
}