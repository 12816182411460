import { BusyIndicator, DPFormField, DPInputText, PageHeader, PageSectionHeader } from "@framework";
import { useApiClient } from "@hooks/useApiClient"
import { PublicApiClient } from "api"
import { Formik } from "formik";
import { useState } from "react";
import { Form, Message } from "semantic-ui-react";
import { DPButton } from "shared";
import { PublicCardLayout } from "shared/layout";
import * as Yup from 'yup'

export function ResetPasswordRequestPage(){
    const [sent, setSent] = useState(false);

    return (
        <PublicCardLayout>
            <PageSectionHeader children="Nulstil kodeord" />
            { sent &&
                <Message positive>
                    Hvis dit brugernavn findes i systemet, vil du om kort tid modtage en e-mail med instruktioner. Hvis du ikke modtager en e-mail bedes du kontakte salgssupport på telefon: 70 22 58 22.
                </Message>
            }
            <ResetPasswordRequestForm sent={sent} setSent={setSent} />
        </PublicCardLayout>
    )
}

interface FormModel {
    username: string | null,
}

function ResetPasswordRequestForm(props: { sent: boolean, setSent: (value: boolean) => void}){
    const api = useApiClient(x => new PublicApiClient(x));
    const [busy, setBusy] = useState(false);

    const onSubmit = (model: FormModel) => {
        setBusy(true);
        api.resetPasswordRequest(model)
            .then(x => {
                if(x.success){
                    props.setSent(true)
                }
            })
            .finally(() => setBusy(false));
    };

    const initialValues = {
        username: ""
    } as FormModel;

    const validationSchema = Yup.object().shape({
        username: Yup.string().required().label("Brugernavn"),
    });

    return (
        <BusyIndicator busy={busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps => 
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField
                            label="Brugernavn"
                            name="username"
                            inline={false}
                        >
                            <DPInputText name="username" disabled={props.sent} />
                        </DPFormField>

                        <DPButton type="submit" children="Nulstil kodeord" disabled={props.sent} />
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}