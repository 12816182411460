import { useModal } from '@hooks/useModal'
import { useTranslation } from '@hooks/useTranslation'
import { NewsApiClient } from 'api/NewsApiClient'
import { useApiClient } from '@hooks/useApiClient'
import styles from './DeleteNews.module.css'

interface IProps {
  news: { id: number, title: string },
  navigateCallback: () => void
}

export function DeleteNews(props: IProps) {
  const { news, navigateCallback } = props;
  const $t = useTranslation('HomePage')
  const modal = useModal()
  const newsApiClient = useApiClient((x) => new NewsApiClient(x))

  const confirmDelete = async () => {
    newsApiClient
      .deleteNews(news.id)
      .then(navigateCallback)
  }

  const deleteNews = () => {
    modal.confirm({
      message: `${$t('ConfirmDelete')} "${news.title}"?`,
      onOk: confirmDelete
    })
  }

  return (
    <a className={styles['left-margin']} onClick={() => deleteNews()}>{$t('Delete')}</a>
  )
}