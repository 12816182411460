import { useField, useFormikContext } from 'formik'
import React, { useEffect, useRef } from 'react'

// TODO Consider taking it from settings or from js locale.
// For now it's fine to assume it's always danish separators.
const locale = 'da-DK'
const decimalSeparator = ','
const thousandSeparator = '.'

interface IProps {
    name: string
    disabled?: boolean,
    precision?: number,
    label?: string,
    dataQa?: string,
    integer?: boolean,
    maxLength?: number,
}

const toNumber = (value: string | undefined | null) => {
    if(value === null){
        return null
    }

    if(value === undefined){
        return null
    }

    const sanitized = value.trim()
    if(sanitized === ''){
        return null
    }

    const normalize = sanitized.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.')
    const result = Number(normalize)
    return result
}

/** Converts given value to the text presented inside input element. */
const toString = (value: number | null, precision?: number) => {
    // We don't want to present 'null' in case value is null. Convert it to empty string instead.
    if(value === null){
        return ''
    }

    // We don't want to present 'undefined' in case value is undefined. Convert it to empty string instead.
    if(value === undefined){
        return ''
    }

    if(!precision) {
        return value.toString()
    }

    return value.toLocaleString(locale, {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
    })
}

export function DPInputNumber(props: IProps) {
    const context = useFormikContext()
    const [field] = useField(props.name)
    const inputElement = useRef<HTMLInputElement>(null)

    // Update input when value or precision change.
    useEffect(() => {
        if(!inputElement.current){
            return
        }

        if((props.integer ?? false) && field.value) {
            inputElement.current.value = field.value.toString().replace(/\D/, '')
            return
        }

        inputElement.current.value = toString(field.value, props.precision)
    }, [field.value, props.precision])

    const labelClass = props.label ? 'right labeled' : ''

    return (
        // right labeled 
        <div className={`ui input dp-input dp-input-number ${labelClass}`}>
            <input
                ref={inputElement}
                name={props.name}
                disabled={props.disabled}
                type="text"
                onBlur={x => {
                    const rawValue = x.currentTarget.value
                    let numericValue = toNumber(rawValue)
                    const textValue = toString(numericValue, props.precision)
                    
                    // Update input value on blur to not leave input with incorrectly formatted value.
                    // For example when value was 2,00 and user removed zeros from the end,
                    // numeric value doesn't change but we don't want to have 2, inside input. 
                    x.currentTarget.value = textValue
                    field.onBlur(x)
                    
                    context.setFieldValue(props.name, numericValue)
                }}
                onChange={x => {
                    let rawValue = x.currentTarget.value

                    //Forbid to type in more digits than the maxLength value if specified
                    if(props.maxLength !== undefined && rawValue.replace('.', '').replace(',', '').length > props.maxLength){
                        const dotsAndCommasCount = rawValue.length - rawValue.replace('.', '').replace(',', '').length
                        rawValue = rawValue.substring(0, props.maxLength + dotsAndCommasCount)
                    }

                    //Forbid to use non-digit characters
                    rawValue = rawValue.replace(/[^0-9\.,-]/g, '');
                    
                    x.currentTarget.value = rawValue
                }}
                data-qa={props.dataQa}
            />
            { props.label && <div className="ui basic label">{props.label}</div> }
        </div>
    )
}
