import { useState } from "react";
import { DPButton } from "shared";
import { PaymentPlanModal } from ".";
import { Icon } from "semantic-ui-react";
import { CalculationInput } from "model";
import { useTranslation } from "@hooks/useTranslation";

interface Props {
    dealerId: number | undefined,
    calculationInput: CalculationInput,
    hasCalculationResult: boolean,
}

export function PaymentPlanModalButton(props: Props){
    const [open, setOpen] = useState(false);
    const $t = useTranslation("PaymentPlanModal");
    const disabled = !props.hasCalculationResult || !props.dealerId;
    return (
        <>
            <DPButton
                size="mini"
                basic
                onClick={() => setOpen(true)}
                disabled={disabled}
            >
                <Icon name="tasks" />  {$t('paymentPlan')}
            </DPButton>
            {
                <PaymentPlanModal
                    open={open}
                    setOpen={setOpen}
                    calculationInput={props.calculationInput}
                    dealerId={props.dealerId ?? 0}
                />
            }
        </>
    );
}