import { ApplicationType } from 'shared'
import { CompanyCustomerForm, PersonCustomerForm } from '.'

interface ICustomerComponentProps {
  applicationType: ApplicationType,
}

export function CustomerTab({ applicationType }: ICustomerComponentProps) {
  const isCorporate = applicationType === ApplicationType.Corporate
  return (
    <>
      {
        isCorporate && <CompanyCustomerForm />
      }
      {
        !isCorporate && <PersonCustomerForm />
      }
    </>
  )
}