import { ApiClient } from '@api'
import { FileFromResponse } from '@api/models'
import { FileModel } from '@api/models'

export interface INewsList {
    totalRecords: number
    pageNumber: number
    pageSize: number
    skip: number
    take: number
    pageCount: number
    data: INews[]
}

export interface INews {
    id: number,
    title: string,
    newsTeaser: string,
    content: string,
    publishDate: string,
    expirationDate?: string,
    isPopup: boolean,
    createdAt: string,
    lastUpdatedDate: string,
    createdBy: string,
    updatedBy: string,
    attachments: FileModel[],
    isArchived: boolean | null,
    isFuture: boolean | null,
    isCurrent: boolean | null,
}

export interface INewsSearchQuery {
    pageNumber: number
    pageSize: number
}


// before uploading data to server make sure each data array is proper base64 string (without metadata which prevents deserialization on backend side)
function base64encode(files: FileModel[]): FileModel[] {
    if(!files) {
        throw Error('missing files parameter')
    }

    const encoded: FileModel[] = files.map(x => {
        x.data = toBase64(x.data)
        return x
    })

    return encoded
}

function toBase64(data: string): string {
    let encoded = data.replace(/^data:(.*,)?/, '')
    if ((encoded.length % 4) > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
    }

    return encoded
}

export class NewsApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getCurrentNews = async (query: INewsSearchQuery): Promise<INewsList | undefined> => {
        const x = await this.apiClient.get<INewsList>('api/news/current', query)
        return x.data
    }

    public getArchivedNews = async (query: INewsSearchQuery): Promise<INewsList | undefined> => {
        const x = await this.apiClient.get<INewsList>('api/news/archived', query)
        return x.data
    }

    public getNews = async (id: string): Promise<INews | undefined> => {
        const x = await this.apiClient.get<INews>(`api/news/${id}`)
        return x.data
    }

    public getLatestUnread = async (): Promise<INews | undefined> => {
        const x = await this.apiClient.get<INews>('api/news/latestUnread')
        return x.data
    }

    public addNews = async (model: INews) => {
        model.attachments = base64encode(model.attachments)
        return await this.apiClient.post<INews>('api/news', model)
    }

    public editNews = async (model: INews) => {
        model.attachments = base64encode(model.attachments)
        return await this.apiClient.put<INews>('api/news', model)
    }

    public deleteNews = async (id: number) => {
        return await this.apiClient.delete(`api/news/${id}`)
    }

    public getAttachment = (newsId: number, fileId: number): Promise<void> => {
        return this.apiClient.get(`api/news/${newsId}/files/${fileId}`)
            .then(x => {
                if(!x.success) {
                    return
                }

                return new FileFromResponse(x.response!).download()
            })
    }

    public seen = async (userId: number, newsId: number) =>
        this.apiClient.post(`api/news/${newsId}/seen/${userId}`)
}