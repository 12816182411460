import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import styles from './ErrorBoundary.module.css'

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {

  public static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  public componentDidCatch(error: any, info: any) {
    console.error(error, info)
  }

  public render() {
    if (this.state.hasError) {
      return <DisplayError />
    }
    return this.props.children
  }
}

function DisplayError() {

  const $t = useTranslation('Error')
  window.onpopstate = (e: any) => {
    window.location = document.location
  }

  return (
    <article className={styles['error-page']}>
      <Header as="h2" icon>
        <Icon name="warning circle" />
        {$t('Oops! Something went wrong.')}
        <Header.Subheader>
          {$t('Try to refresh page ')}
          <a onClick={(e: any) => window.history.back()}>
            {$t('or click here to go back')}
          </a>
        </Header.Subheader>
      </Header>
    </article>
  )
}
