import { NewsApiClient } from 'api/NewsApiClient'
import { BusyIndicator, PageHeader } from '@framework'
import { useApiClient, useModal, useSession, useTranslation } from '@hooks'
import { useEffect, useState } from 'react'
import { UserTitleConfirmationModal } from 'shared'
import { usePopupsOnHomePage } from 'infrastructure/hooks'
import { HomePageNews } from './HomePageNews'
import { useIncomingApplicationCounter } from 'shared/incomingApplicationCounter'

interface IProps {
    archive?: boolean
}

export function HomePage({ archive = false }: IProps) {
    const $t = useTranslation('HomePage')
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div key={archive ? 'archive' : 'news'}>
            <PageHeader text={archive ? $t('NewsArchive') : $t('News')} />
            <BusyIndicator busy={isLoading}>
                <HomePageNews archive={archive} setIsLoading={setIsLoading} />
                <UserTitleConfirmationModal />
            </BusyIndicator>

            <LastUnreadNewsPopup />
            <IncomingApplicationsPopup />
        </div>
    )
}

function LastUnreadNewsPopup(){
    const { current, dismiss } = usePopupsOnHomePage();
    const newsApi = useApiClient(x => new NewsApiClient(x))
    const session = useSession()
    const modal = useModal()
    const $t = useTranslation('HomePage')
    // In case when both popups should be presented,
    // ensure that popup about unread news is render first.
    const show = current === 'lastUnreadNews';

    useEffect(() => {
        if(!show){
            return
        }

        newsApi.getLatestUnread()
            .then(x => {
                if (x === undefined) {
                    dismiss(current);
                    return
                }

                const newsPopup = () => {
                    modal.info({
                        title: x.title,
                        message: x.content,
                        buttonContent: $t('read'),
                        onClose: () => {
                            dismiss(current);
                            newsApi.seen(session.currentUser.id, x.id!)
                        },
                    })
                }

                newsPopup.call(true)
            })
    }, [show])

    return null;
}

function IncomingApplicationsPopup(){
    const { count } = useIncomingApplicationCounter()
    const modal = useModal()
    const { current, dismiss } = usePopupsOnHomePage();
    const show = current === 'incomingApplications';
    const $t = useTranslation('HomePage')

    useEffect(() => {
        if(!show){
            return
        }

        if(!count){
            return
        }

        const newsPopup = () => {
            modal.info({
                title: $t('IncomingApplications'),
                message:
                    `<b>Du har ${count} nye eller ubehandlede sager</b>.<br>` + 
                    'Du skal enten indsende ansøgningen til kreditvurdering eller annullere sagen ved at klikke på krydset.<br>' +
                    'Du finder sagerne under "Søg indkomne" i menuen til venstre.<br>',
                buttonContent: $t('Close'),
                onClose: () => {
                    dismiss(current);
                }
            })
        }

        newsPopup.call(true)
    }, [show, count]);

    return null;
}