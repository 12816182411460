import { BusyIndicator, DPFormField, DPInputText, PageSectionHeader } from "@framework";
import { useApiClient } from "@hooks/useApiClient";
import { PublicApiClient } from "api";
import { Formik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Message } from "semantic-ui-react";
import { DPButton } from "shared";
import { PublicCardLayout } from "shared/layout";
import * as Yup from 'yup'

export function ResetUsernamePage(){
    const [searchParams] = useSearchParams();
    const [sent, setSent] = useState(false);

    const email = searchParams.get('email')
    const token = searchParams.get('token')

    return (
        <PublicCardLayout>
            <PageSectionHeader children="Skift brugernavn/ny bruger" />
            { sent && <SuccessMessage /> }
            <ResetUsernameForm email={email} token={token} sent={sent} setSent={setSent} />
            <UsernameRequirements />
        </PublicCardLayout>
    )
}

interface FormModel {
    email: string | null,
    username: string | null,
    usernameConfirmation: string | null,
}

function ResetUsernameForm(props: {
    email: string | null,
    token: string | null,
    sent: boolean,
    setSent: (value: boolean) => void
}){
    const api = useApiClient(x => new PublicApiClient(x));
    const [busy, setBusy] = useState(false);

    const onSubmit = (model: FormModel) => {
        setBusy(true);
        api.resetUsername({ email: props.email, token: props.token, username: model.username })
            .then(x => {
                if(x.success){
                    props.setSent(true)
                }
            })
            .finally(() => setBusy(false));
    };

    const initialValues = {
        email: props.email ?? "",
        username: "",
        usernameConfirmation: "",
    } as FormModel;

    const validationSchema = () => Yup.object().shape({
        email: Yup.string(),
        username: Yup.string().required().label("Nyt brugernavn"),
        usernameConfirmation: Yup
            .string()
            .required()
            .label("Genindtast nyt brugernavn")
            .oneOf([Yup.ref("username")], "Brugernavn matcher ikke"),
    });

    return (
        <BusyIndicator busy={busy}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formikProps => 
                    <Form onSubmit={formikProps.handleSubmit}>
                        <DPFormField label="E-mail" name="email" inline={false}>
                            <DPInputText name="email" disabled={true} />
                        </DPFormField>

                        <DPFormField label="Nyt brugernavn" name="username" inline={false}>
                            <DPInputText name="username" disabled={props.sent} />
                        </DPFormField>

                        <DPFormField label="Genindtast nyt brugernavn" name="usernameConfirmation" inline={false}>
                            <DPInputText name="usernameConfirmation" disabled={props.sent} />
                        </DPFormField>

                        <DPButton type="submit" children="Gem" disabled={props.sent} />
                    </Form>
                }
            </Formik>
        </BusyIndicator>
    )
}

function SuccessMessage(){
    return (
        <Message positive>
            Ændring af dit brugernavn er fuldført.
        </Message>
    )
}

function UsernameRequirements(){
    return (
        <div style={{ marginTop: "2rem" }}>
            <p>Regler for brugernavn:</p>
            <ul>
                <li>Skal være længere end 4 tegn.</li>
                <li>Må ikke være dit cpr nummer.</li>
                <li>Må ikke være det samme som din adgangskode.</li>
            </ul>
            <p>Ved ændring af brugernavn, er din adgangskode fortsat uændret. Ønsker du at ændre din adgangskode, vælg da ændring af adgangskode i menuen til venstre efter du har gemt dit brugernavn.</p>
            <p>
                Har du spørgsmål?
                Kontakt Salessupport på:
                <ul>
                    <li>telefon: <a href="tel:33 76 88 73" className="text-nowrap">33 76 88 73</a>,<br/></li>
                    <li>mail: <a href="mailto:salessupport@santanderconsumer.dk" className="text-nowrap">salessupport@santanderconsumer.dk</a>.</li>
                </ul>
            </p>
        </div>
    )
}