import { IGridQuery, IGridResult } from '@api'
import { emptyGrid } from '@api'
import { IKycApplicationOverviewModel, KycApplicationApiClient } from 'api'
import { useAuthorization } from '@authentication'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect, useState } from 'react'

export default function usePendingApplications(
    dealerId: number,
    pendingApplicationsTableState: IGridQuery | undefined){
    const api = useApiClient(p => new KycApplicationApiClient(p))
    const { hasAccessToAllPendingCases: hasAccessToAllPendingApplicationsOfDealer } = useAuthorization()
    
    const [applications, setApplications] = useState<IGridResult<IKycApplicationOverviewModel>>(emptyGrid)
    const [loading, setLoading] = useState(false)

    const refresh = (configuration?: { suppressError: boolean }) => {
        if(pendingApplicationsTableState === undefined){
            setApplications(emptyGrid)
            return
        }

        setLoading(true)
        const request =
            hasAccessToAllPendingApplicationsOfDealer
                ? api.getDealerApplications(dealerId, pendingApplicationsTableState!, configuration)
                : api.getMyApplications(dealerId, pendingApplicationsTableState!, configuration)

        request
            .then(x => {
                if(x.success){
                    setApplications(x.data!)
                    return
                }

                if(configuration?.suppressError){
                    return
                }

                setApplications(emptyGrid)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        refresh()
    }, [pendingApplicationsTableState, dealerId])

    return { applications, loading, refresh }
}