import { SavedApplicationsApiClient } from 'api/SavedApplicationsApiClient'
import { Application } from 'model'
import { useApiClient } from '@hooks'
import { useEffect, useState } from 'react'

export default function useSavedApplication(
    savedApplicationId: number | undefined,
    dealerId: number | undefined) {
    const savedApplicationsApiClient = useApiClient(x => new SavedApplicationsApiClient(x))
    const [application, setApplication] = useState<Application>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(savedApplicationId === undefined){
            return
        }

        setLoading(true)
        savedApplicationsApiClient.get(dealerId!, savedApplicationId)
            .then(x => setApplication(x!))
            .finally(() => setLoading(false))
        }, [])


    return [ application, loading ] as [ Application | undefined, boolean ]
}