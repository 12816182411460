import { IDealerModel } from 'api'
import { createContext } from 'react'

export interface ISession {
  dealers: IDealerModel[],
  currentUser: ICurrentUser,
  currentDealer: ICurrentDealer | undefined
  isInitialized: boolean,
  changeDealer: (dealerId: number) => void,
  refresh: () => Promise<void>
}

export interface ICurrentUser {
  id: number,
  firstName: string,
  lastName: string,
  sellerNumber: number | null,
  sellerAgentNumber: string,
  title: string,
  phone: string,
  email: string,
  nextTitleConfirmation: string | undefined,
}

export interface ICurrentDealer{
  id: number,
  number: number,
  brandCode: number,
  name: string,
  address: string,
}

export const emptySession: ISession = {
  dealers: [],
  currentUser: {
    id: 0,
    firstName: '',
    lastName: '',
    sellerNumber: null,
    sellerAgentNumber: '',
    title: '',
    phone: '',
    email: '',
    nextTitleConfirmation: undefined
  },
  currentDealer: undefined,
  isInitialized: false,
  changeDealer: (_: number) => { return },
  refresh: () => Promise.resolve(),
}

export const SessionContext = createContext<ISession>(emptySession)