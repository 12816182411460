import { IDealerModel, IUserProfileModel, UserProfileApiClient } from 'api'
import { useApiClient } from '@hooks/useApiClient'
import { emptySession, SessionContext } from '@session'
import React, { ReactChild, useEffect, useReducer } from 'react'
import { ICurrentDealer, ICurrentUser } from './SessionContext'

type Action =
  { type: 'login', user: IUserProfileModel } |
  { type: 'refresh', user: IUserProfileModel } |
  { type: 'logout' } |
  { type: 'changeCurrentDealer', dealerId: number }

type State = {
  isInitialized: boolean,
  currentUser: ICurrentUser | undefined,
  currentDealer: ICurrentDealer | undefined,
  dealers: IDealerModel[] | undefined
}

const initialState: State = {
  isInitialized: false,
  currentUser: undefined,
  dealers: undefined,
  currentDealer: undefined,
}

const reducer = (state: State, action: Action) : State => {
  switch(action.type){
    case 'refresh':
    case 'login':
      const currentDealer = action.user.dealers.find(x => x.id === action.user.defaultDealerId)
      return {
        isInitialized: true,
        currentUser: {... action.user},
        dealers: action.user.dealers,
        currentDealer
      }
    case 'logout':
      return initialState
    case 'changeCurrentDealer':{
      const currentDealer = state.dealers?.find(x => x.id === action.dealerId)
      return {
        ...state,
        currentDealer,
      }
    }
    default:
      return state
  }
}

export function SessionContextProvider({ children, isAuthenticated }: { children: ReactChild[] | ReactChild, isAuthenticated: boolean }) {
  const userProfileApiClient = useApiClient(a => new UserProfileApiClient(a))
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({ type: 'logout'})
      return
    }

    userProfileApiClient.getUserProfile()
      .then(x => {
        if(x === undefined){
          return
        }

        dispatch({ type: 'login', user: x!})
      })
  }, [isAuthenticated])

  const changeDealer = function(dealerId: number){
    dispatch({ type: 'changeCurrentDealer', dealerId })
    userProfileApiClient.changeDefaultDealer(dealerId)
  }

  const refresh = () =>
    userProfileApiClient.getUserProfile()
      .then(x => {
        if(x === undefined){
          return
        }

        dispatch({ type: 'refresh', user: x!})
      })

  const sessionContextValue =
    state.isInitialized
      ? {
          isInitialized: state.isInitialized as boolean,
          dealers: state.dealers || [],
          currentUser: state.currentUser!,
          currentDealer: state.currentDealer,
          changeDealer,
          refresh,
        }
      : emptySession

  return (
    <SessionContext.Provider value={sessionContextValue}>
      {children}
    </SessionContext.Provider>
  )
}