import { AuthorizationContext } from '@authentication'
import { useContext } from 'react'

export function useAuthorization(){
    const context = useContext(AuthorizationContext)
    if (context === undefined) {
        throw new Error('useAuthorization must be used within a AuthorizationProvider')
    }

    return context!
}