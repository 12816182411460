import { Field } from 'formik'
import React from 'react'

interface IProps {
    name: string,
    rows?: number,
}

export function DPInputTextarea(props: IProps) {
    return <Field
        name={props.name}
        as="textarea"
        rows={props.rows}
        className="dp-input dp-input-textarea"
    />
}
