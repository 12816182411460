import { ApiClient } from '@api'
import { IRedirectData } from './models'

export class AuthApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getAuthUri = () => {
    return this.apiClient.get<IRedirectData>('login/start').then(x => x.data)
  }

  public sendCode = (code: string) => {
    return this.apiClient.post('login/code', { code })
  }

  public logout = () => {
    return this.apiClient.get('logout')
  }
}