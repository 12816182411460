import { useTranslation } from '@hooks/useTranslation'
import { IMessages } from '@translations'
import { Transition, TransitionGroup } from 'semantic-ui-react'
import { IToastMessage } from './IToastMessage'
import styles from './ToastMessages.module.css'

interface IProps {
    messages: IToastMessage[]
}

function messageClassName(message: IMessages){
    switch(message.type){
        case 'error':
            return `${styles.message} ${styles.error}`
        case 'success':
            return `${styles.message} ${styles.success}`
        default:
            return styles.message
    }
}

function messageHeader(message: IMessages, $t: any){
    switch(message.type){
        case 'error':
            return $t('error')
        case 'success':
            return $t('success')
        default:
            return ''
    }
}

function ToastMessages(props: IProps){
    const $t = useTranslation('ToastMessages')

    return (
        <div className={styles.container}>
            <TransitionGroup>
                {props.messages.map(message => {
                    const className = messageClassName(message)
                    const header = messageHeader(message, $t)
                    return (
                        <div
                            key={undefined}
                            className={className}
                        >
                            <h4>{header}</h4>
                            <p>{message.content}</p>
                        </div>
                    )
                })}
            </TransitionGroup>
        </div>
    )
}

export { ToastMessages }