import { ILanguageStore } from 'shared/LanguageStore'
import React, { ReactChild, useState } from 'react'

interface ILanguageContext {
    language: string,
    setLanguage(language: string): void
}

interface IProps {
    languageStore: ILanguageStore,
    children: ReactChild[] | ReactChild,
}

export const LanguageContext = React.createContext<ILanguageContext>({
    language: 'da',
    setLanguage: (x) => { return }
})

export function LanguageProvider(props: IProps){
    const [language, setLanguage] = useState('da')

    const context = {
        language,
        setLanguage,
    }

    return (
        <LanguageContext.Provider value={context}>
            {/* Use key property to ensure that on language change all children components are rendered again. */}
            <div key={context.language}>
                {props.children}
            </div>
        </LanguageContext.Provider>
    )
}
