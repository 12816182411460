import { createContext, ReactNode, useState } from 'react'
import { IToastMessage } from './IToastMessage'
import { ToastMessages } from './ToastMessages'


interface IToastContext {
    success: (content: ReactNode) => void,
    error: (content: ReactNode) => void,
}

const ToastContext = createContext<IToastContext | undefined>(undefined)

function ToastProvider({ children }: { children: ReactNode }) {
    const [messages, setMessages] = useState<IToastMessage[]>([])

    const show = (message: IToastMessage) => {
        setMessages(x => [...x, message])

        // Automatically hide toast after 4 seconds.
        setTimeout(
            () => setMessages(x => x.filter(y => y !== message)),
            4 * 1000)
    }

    const context = {
        success: (content: ReactNode) => show({ content, type: 'success'}),
        error: (content: ReactNode) => show({ content, type: 'error'}),
    }

    return (
        <ToastContext.Provider value={context}>
            <ToastMessages messages={messages} />
            {children}
        </ToastContext.Provider>
    )
}

export {ToastProvider, ToastContext}