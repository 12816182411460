import React from 'react'

interface IProps {
    value: string,
    label?: string,
    dataQa?: string,
}

export function DPInputTextReadOnly(props: IProps) {
    const labelClass = props.label ? 'right labeled' : ''

    return (
        <div className={`ui input dp-input dp-input-text-read-only ${labelClass}`}>
            <input 
                value={props.value}
                readOnly
                data-qa={props.dataQa}
            />
            { props.label && <div className="ui basic label">{props.label}</div> }
        </div>
    )
}
