import { INewsList, INewsSearchQuery, NewsApiClient } from 'api/NewsApiClient'
import { useApiClient } from '@hooks'
import { useEffect, useMemo, useState } from 'react'

export function useNewsFeed(pageNumber: number, archive: boolean) {
    const newsApi = useApiClient((a) => new NewsApiClient(a))
    const [newsFeed, setNewsFeed] = useState({ pageCount: 1 } as INewsList)
    const [isLoading, setIsLoading] = useState(false)
    const searchQuery =
        useMemo(() => {
            return {
                pageNumber,
                pageSize: 10,
            } as INewsSearchQuery
        }, [pageNumber, archive])

    const refresh = (): void => {
        setIsLoading(true)
        
        const apiCall =
            archive
                ? newsApi.getArchivedNews(searchQuery)
                : newsApi.getCurrentNews(searchQuery);
        apiCall
            .then((data) => {
                if (data !== undefined) {
                    setNewsFeed(data)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {
        refresh()
    }, [searchQuery])

    return { newsFeed, isLoading, refresh }
}
