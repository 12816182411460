import { ApplicationTab } from 'pages/application/ApplicationPage'

export type HomePagePopup = "lastUnreadNews" | "incomingApplications"

export const routes = {
  homePage: (options?: { popups?: HomePagePopup[] }) =>{
    const params = new URLSearchParams();

    for(var popup of options?.popups ?? [])
    {
      params.append("popup", popup)
    }

    var paramsString = params.toString();
    return paramsString ? `/home?${paramsString}` : '/home'
  },
  newsDetailsPage: (id: number) => `/news/${id}`,
  administrationUserProfile: () => '/administration/user-profile',
  administrationOwnPricing: () => '/administration/own-pricing',
  administrationUsers: () => '/administration/users/show-users',
  administrationUserEdit: (id: number) => `/administration/users/edit-user/${id}`,
  administrationDealers: () => '/administration/dealers/show-dealers',
  administrationDealerEdit: (dealerNumber: string) => `/administration/dealers/edit-dealer/${dealerNumber}`,
  administrationMasterData: () => "/administration/master-data",
  privateApplication: (parameters: { tab: ApplicationTab, timestamp: string, applicationId?: number, incomingApplicationId?: string }) =>
    '/private-loan' +
      `${parameters.applicationId ? '/' + parameters.applicationId : ''}`+
      `?tab=${parameters.tab}`+
      `${parameters.incomingApplicationId ? '&incomingApplicationId=' + parameters.incomingApplicationId : ''}` +
      `${parameters.timestamp ? '&timestamp=' + parameters.timestamp : ''}`,
  corporateApplication: (parameters: { tab: ApplicationTab, timestamp: string, applicationId?: number }) =>
    '/corporate-loan' +
      `${parameters.applicationId ? '/' + parameters.applicationId : ''}` +
      `?tab=${parameters.tab}` +
      `${parameters.timestamp ? '&timestamp=' + parameters.timestamp : ''}`,
  personnelApplication: (parameters: { tab: ApplicationTab, timestamp: string, applicationId?: number }) =>
    '/personnel-loan' +
      `${parameters.applicationId ? '/' + parameters.applicationId : ''}` +
      `?tab=${parameters.tab}` +
      `${parameters.timestamp ? '&timestamp=' + parameters.timestamp : ''}`,
  generateContract: (referenceId: string) => `/application/generate-contract/${referenceId !== undefined ? referenceId : ''}`,
  applicationSearchSaved: () => '/application/search-saved',
  applicationSearchSubmitted: () => '/application/search-submitted',
  applicationSearchIncoming: () => '/application/search-incoming',
  applicationKycApplication: (referenceId: string) => '/application/kyc/' + referenceId,
  applicationSubmittedApplication: (referenceId: string) => '/application/submitted/' + referenceId,
  accessDenied: () => '/access-denied',
  notImplemented: () => '/not-implemented',
  logOut: () => '/log-out',
  /**
   * Routes to internal /log-in page.
   * The page initialize login process and redirect user to Curity.
   * Please consider usages of welcome page route before using this route.
   */
  logIn: () => '/log-in',
  signInCallback: () => '/signin-callback',
  objectDeliveredPage: (referenceId: string) => `/application/object-delivered/${referenceId}`,
  addNews: () => '/administration/news/add',
  editNews: (id: number) => `/administration/news/${id}/edit`,
  newsArchive: () => '/news-archive',
  contact: () => '/contact',
  remainingDebt: () => '/remaining-debt',
  statistics: () => '/reports/statistics',
  /**
   * The route to welcome page containing application logo and login button.
   * Please use this route as redirection target for not authenticated users,
   * to avoid problem with expiration of login link on Curity side.
   */
  welcomePage: () => '/',
  plusLoan: () => '/plus-loan',
  resetPasswordRequest: () => "/reset-password-request",
  resetPassword: () => "/reset-password",
  resetUsernameRequest: () => "/reset-username-request",
  resetUsername: () => "/reset-username",
  newUserRequest: () => "/new-user-request",
  newUser: () => "/new-user",
}