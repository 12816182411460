import { defaultQuery, emptyGrid, IGridResult, IPagination } from '@api'
import { ISavedApplicationOverview } from 'api'
import { SavedApplicationsApiClient } from 'api/SavedApplicationsApiClient'
import { routes } from 'routing'
import { useAuthorization } from '@authentication'
import { DPFormField, DPInputDate, DPInputText, DPTableSearchForm, IDataTableColumn } from '@framework'
import { useApiCall, useApiClient, useModal, useTranslation } from '@hooks'
import { addDays, addMonths, localDateToDanishDateTimeString, dateTimeStringToLocalDate } from 'shared/dateTools'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'
import { SellersDropDownComponent } from './components/SellersDropDownComponent'
import useSellers from './dataProviders/useSellers'
import { ApplicationType } from 'shared'
import { currentDateString } from 'shared/dateTools'
import { DPDataTable } from 'infrastructure/framework/DPForms/DPDataTable'

interface ISearchComponentProps {
  dealerId: number
}

interface ISearchParameters {
  salesPersonId: number
  customer: string
  from: string
  to: string
}

const initialValues: ISearchParameters = {
  from: addMonths(currentDateString(), -1),
  to: currentDateString(),
  customer: '',
  salesPersonId: 0
}

export function SearchSavedComponent({ dealerId }: ISearchComponentProps) {
  const $t = useTranslation('SearchSavedComponent')
  const navigate = useNavigate()
  const savedApplicationsApiClient = useApiClient(p => new SavedApplicationsApiClient(p))
  const [sellers] = useSellers(dealerId)
  const [applicationDeleted, , deleteSavedApplication] = useApiCall(savedApplicationsApiClient.delete)
  const [searchParams, setSearchParams] = useState<ISearchParameters>({ ...initialValues, to: addDays(initialValues.to, 1) })
  const [tableState, setTableState] = useState<IPagination>(defaultQuery)
  const [applications, setApplications] = useState<IGridResult<ISavedApplicationOverview>>(emptyGrid)
  const [isLoadingApplications, setIsLoadingApplications] = useState(false)
  const { hasAccessToAllSavedCases: hasAccessToAllSavedApplicationsOfDealer } = useAuthorization()

  const columns = useColumns($t, (id) => deleteSavedApplication(dealerId, id))

  useEffect(() => {
    setIsLoadingApplications(true)
    const request =
      hasAccessToAllSavedApplicationsOfDealer
          ? savedApplicationsApiClient.getDealerApplications(dealerId, {...searchParams, ...tableState})
          : savedApplicationsApiClient.getMyApplications(dealerId, {...searchParams, ...tableState})
    request
      .then(data => {
        if(data ===undefined){
          setApplications(emptyGrid)
          return
        }

        setApplications(data)
      })
      .finally(() => setIsLoadingApplications(false))
  }, [tableState, searchParams, applicationDeleted, dealerId])

  return (
    <div>
      <DPTableSearchForm
        initialValues={initialValues}
        onSubmit={(values: ISearchParameters) => setSearchParams(values)}
        debounce={{keys: ["customer"], delay: 500}}
        column1={
          <SellersDropDownComponent
            sellers={sellers}
            disabled={!hasAccessToAllSavedApplicationsOfDealer}
          />
        }
        column2={
          <DPFormField name="customer" label={$t('Customer')} inline={false}>
            <DPInputText name="customer" />
          </DPFormField>
        }
        column3 = {
          <DPFormField name="from" label={$t('Start date')} inline={false}>
            <DPInputDate name="from" />
          </DPFormField>
        }
        column4={
          <DPFormField name="to" label={$t('End date')} inline={false}>
            <DPInputDate name="to" />
          </DPFormField>
        }
      />

      <div className='mt-4'>
        <DPDataTable
          columns={columns}
          data={applications}
          initialPagination={defaultQuery}
          isLoading={isLoadingApplications}
          onRowSelect={(item: ISavedApplicationOverview) => {
            switch(item.type){
              case ApplicationType.Private:
                navigate(routes.privateApplication({ tab: 'object', timestamp: Date.now().toString(), applicationId: item.id}))
                return
              case ApplicationType.Corporate:
                navigate(routes.corporateApplication({ tab: 'object', timestamp: Date.now().toString(), applicationId: item.id}))
                return
              case ApplicationType.Personnel:
                navigate(routes.personnelApplication({ tab: 'object', timestamp: Date.now().toString(), applicationId: item.id}))
                return
            }
          }}
          onStateChange={setTableState}
        />
      </div>
    </div>
  )
}

const useColumns = (
  $t: (message: string) => string,
  onDelete: (id: number) => void)
  : Array<IDataTableColumn<ISavedApplicationOverview>> => {
  const modal = useModal()

  return [
    {
      key: 'date',
      name: $t('Date'),
      onRender: ({ date }: ISavedApplicationOverview) => localDateToDanishDateTimeString(dateTimeStringToLocalDate(date))
    },
    {
      key: 'name',
      name: $t('Name'),
    },
    {
      key: 'agreementName',
      name: $t('Product'),
    },
    {
      key: 'make',
      name: $t('Make'),
    },
    {
      key: 'model',
      name: $t('Model'),
    },
    {
      key: 'sellerName',
      name: $t('Seller'),
    },
    {
      key: '',
      name: '',
      textAlign: 'right',
      onRender: (item: ISavedApplicationOverview) => {
        return (
          <Icon link name="delete" onClick={(e: React.MouseEvent) => {
            e.stopPropagation()

            modal.confirm({
              message: $t('DeleteModalMsg'),
              okButtonLabel: $t('DeleteModalOkLabel'),
              cancelButtonLabel: $t('DeleteModalCancelLabel'),
              onOk: () => onDelete(item.id)
            })
          }} />
        )
      }
    }
  ]
}