import { ApplicationApiClient } from 'api'
import { Application } from 'model'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect, useState } from 'react'
import { ApplicationType } from 'shared'

export default function useDefaultApplication(dealerId: number, type: ApplicationType){
    const applicationApiClient = useApiClient(x => new ApplicationApiClient(x))
    const [application, setApplication] = useState<Application>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        applicationApiClient.getDefaultApplication(dealerId, type)
            .then(x => setApplication(x!))
            .finally(() => setLoading(false))
    }, [])

    return [application, loading] as [ Application | undefined, boolean ]
}
