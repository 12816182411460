import { useEffect, useRef } from 'react'

interface IParameters {
    callback: () => void,
    delay: number,
    resetSignal?: any
}

export function useInterval({ callback, delay, resetSignal }: IParameters) {
    const savedCallback = useRef(callback)

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
  
    // Set up the interval.
    useEffect(() => {
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay, resetSignal])
}
