import { IRequestResult } from '@api'

export interface IValidationError {
  title: string,
  errors?: { [property: string]: string[] }
}

export interface IRequestConfiguration {
  /**
   * The suppress error property allow to turn off default error handling behavior,
   * the API client do not present error message to the user.
   */
  suppressError?: boolean,
  retries?: number,
}

export interface IApiErrorHandler {
  handle400BadRequest(data: IValidationError | string): void,
  handleError(responseUrl?: string, responseData?: string): void,
  handleUnauthenticated(): void,
  handleUnauthorized(): void,
}

export class ApiClient {
  private static refreshTokenSemaphore: Promise<void> | undefined = undefined

  constructor(
    private baseUrl: string,
    private errorHandler: IApiErrorHandler,
  ) {
  }

  public async get<T>(path: string, params?: {}, configuration?: IRequestConfiguration): Promise<IRequestResult<T>> {
    return this.handleCommunicationError(
      async () => {
        const url = this.createUrl(path, params)
        const request = this.createRequest(url)
        const response = await this.retryOnServerError(() => this.sendRequest(request), configuration?.retries)
        const result = await this.createResult<T>(response, configuration?.suppressError)
        return result
      })
  }

  public async post<T>(path: string, data?: any, configuration?: IRequestConfiguration): Promise<IRequestResult<T>> {
    return this.handleCommunicationError(
      async () => {
        const url = this.createUrl(path)
        const request =
          this.createRequest(
            url,
            {
              method: 'POST',
              headers: [
                ['Content-Type', 'application/json'],
              ],
              body: JSON.stringify(data || {}),
            })
        const response = await this.retryOnServerError(() => this.sendRequest(request), configuration?.retries)
        const result = await this.createResult<T>(response, configuration?.suppressError)
        return result
      }
    )
  }

  public async put<T>(path: string, data: any, configuration?: IRequestConfiguration): Promise<IRequestResult<T>> {
    return this.handleCommunicationError(
      async () => {
        const url = this.createUrl(path)
        const request =
          this.createRequest(
            url,
            {
              method: 'PUT',
              headers: [
                ['Content-Type', 'application/json'],
              ],
              body: JSON.stringify(data),
            })
        const response = await this.retryOnServerError(() => this.sendRequest(request), configuration?.retries)
        const result = await this.createResult<T>(response, configuration?.suppressError)
        return result
      }
    )
  }

  public async patch<T>(path: string, data: any, configuration?: IRequestConfiguration): Promise<IRequestResult<T>> {
    return this.handleCommunicationError(
      async () => {
        const url = this.createUrl(path)
        const request =
          this.createRequest(
            url,
            {
              method: 'PATCH',
              headers: [
                ['Content-Type', 'application/json'],
              ],
              body: JSON.stringify(data),
            })
        const response = await this.retryOnServerError(() => this.sendRequest(request), configuration?.retries)
        const result = await this.createResult<T>(response, configuration?.suppressError)
        return result
      }
    )
  }

  public async delete<T>(path: string, configuration?: IRequestConfiguration): Promise<IRequestResult<T>> {
    return this.handleCommunicationError(
      async () => {
        const url = this.createUrl(path)
        const request = this.createRequest(url, { method: 'DELETE' })
        const response = await this.retryOnServerError(() => this.sendRequest(request), configuration?.retries)
        const result = await this.createResult<T>(response, configuration?.suppressError)
        return result
      }
    )
  }

  private createUrl(path: string, params?: any) {
    const url = new URL(path, this.baseUrl)
    if(params){
      /*
        TODO Consider to remove implicit Date to string conversion.
        In my opinion conversion between Date and a string is a hack.
        URL params should contains only string and number.
        Additionally our domain ignores time part of date string.
        We could simplify our date management and sent in URL ISO strings like 2022-04-13.
      */
      Object
        .keys(params)
        .forEach(key => {
          if(params[key] instanceof Date){
            const date = params[key] as Date
            const isoString = date.toISOString()
            params[key] = isoString
          }
        })

      Object
        .keys(params)
        .filter(key => params[key] !== undefined && params[key] !== null)
        .sort()
        .forEach(key => {
          // Keep in mind that parameter is an array, the key has to be duplicated for each array item.
          // For example var objectTypes = [1, 2] has to be represented in URL as ?objectTypes=1&objectTypes=2.
          const value = params[key]
          const values = Array.isArray(value) ? value : [value]
          values.forEach(x => url.searchParams.append(key, x))
        })
    }
    return url.toString()
  }

  private createRequest(url: string, options?: RequestInit): Request {
    const headers: HeadersInit = [] // default headers should go here

    const defaults: RequestInit = {
      method: 'GET',
      credentials: 'include',
      redirect: 'manual',
      headers,
    }

    const mergedHeaders = options && options.headers
      ? [...(defaults.headers as string[][]), ...(options.headers as string[][])]
      : [...(defaults.headers as string[][])]

    const requestOptions = {
      ...defaults,
      ...options,
      headers: mergedHeaders
    }

    return new Request(url, requestOptions)
  }

  /**
   * Functions retries to send request N times, in case server returns 5xx error code.
   * If number of retires is not set or it's less than or equal to 1,
   * the request is send without retrial mechanism.
   * @param request Function representing request to send.
   * @param retries Max number of retrials.
   * @returns Response of first successful request or last failed requests (when max number of retrials has been reached).
   */
  private async retryOnServerError(request: () => Promise<Response>, retries?: number): Promise<Response> {
    const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    const retryDelay = 5 * 1000;
    let trial = 0;
    do{
      trial++

      const response = await request();
      if(response.status < 500){
        return response;
      }
      
      if(trial >= (retries ?? 0)){
        return response;
      }

      await wait(retryDelay)
    }
    while(true)
  }

  private async sendRequest(request: Request): Promise<Response> {
    // Important note!
    // It's necessary to make a copy of request object before sending it,
    // because the request object can only be used once.
    // If we don't clone it, then eventual retrial of request fails.

    let response = await fetch(request.clone())
    const unauthenticated = response.status === 403 || response.status === 401
    const authenticated = !unauthenticated

    if(authenticated){
      return response
    }

    // If request in unauthenticated try to refresh access token.
    // It's a tricky part because it's necessary to ensure that refresh endpoint is call only once.
    // To enforce this single call, a Promise is use as a semaphore.
    // When semaphore is undefined, there is no pending refresh request.
    // When semaphore is a Promise, there is a pending refresh request.
    if(ApiClient.refreshTokenSemaphore === undefined){
      ApiClient.refreshTokenSemaphore =
        new Promise<void>(async (resolve) => {
          // Call refresh endpoint to update cookies value.
          console.info('Refresh token: start')
          await fetch(
            this.baseUrl + 'refresh',
            {
              method: 'POST',
              credentials: 'include'
            })

          // Unlock semaphore by resolving promise, allow other request to run.
          console.info('Refresh token: end')
          resolve()
        })
    }

    // Await until semaphore is unlocked, unset it.
    await ApiClient.refreshTokenSemaphore
    ApiClient.refreshTokenSemaphore = undefined

    // Retry original request with updated cookies value.
    response = await fetch(request.clone())
    console.info(`Refresh token: retried request status code is ${response.status}`)

    return response
  }

  private async createResult<T>(response: Response, suppressError?: boolean){
    const contentType = response.headers.get('content-type')
    const responseContainsJson =
      contentType?.indexOf('application/json') !== -1 ||
      contentType?.indexOf('application/problem+json') !== -1

    // 200 - Ok
    if (response.status === 200) {
      const text = await response.clone().text()
      const data =
        responseContainsJson
          ? JSON.parse(text)
          : text
      return {
        success: true,
        data,
        response,
      } as IRequestResult<T>
    }
    
    // 204 - No content
    if (response.status === 204) {
      return {
        success: true,
        data: undefined,
        response,
      }
    }

    if(suppressError === true){
      return {
        success: false,
        data: undefined,
        response,
      }
    }

    // 400 - Bad Request
    if(response.status === 400){
      const data =
        responseContainsJson
          ? await response.json() as IValidationError
          : await response.text() as string
      this.errorHandler.handle400BadRequest(data)
      return {
        success: false,
        data: undefined,
        response,
      }
    }

    // 401 - Unauthorized
    if(response.status === 401){
      this.errorHandler.handleUnauthorized()
      return {
        success: false,
        data: undefined,
        response,
      }
    }

    // 403 - Unauthenticated
    if(response.status === 403){
      this.errorHandler.handleUnauthenticated()
      return {
        success: false,
        data: undefined,
        response,
      }
    }

    // 5xx - Unknown server error
    if (response.status >= 500) {
      this.errorHandler.handleError(response.url, await response.clone().text())
      return {
        success: false,
        data: undefined,
        response,
      }
    }

    return {
      success: false,
      data: undefined,
      response,
    }
  }

  private async handleCommunicationError<T>(action: () => Promise<IRequestResult<T>>){
    try {
      return await action()
    }
    catch (error) {
      this.errorHandler.handleError()
      return {
        success: false,
      } as IRequestResult<T>
    }
  }
}
