import { ReactNode } from "react";
import "./BusyIndicator.css"

interface Props {
    busy: boolean,
    children?: ReactNode,
}

const classNames = (...values :string[]) => values.filter(Boolean).join(" ");

export function BusyIndicator(props: Props){
    return (
        <div className={classNames("busy-indicator-wrapper", props.busy ? "busy" : "")}>
            {props.children}
            <div className="busy-indicator">
                <div className="ui loader active" />
            </div>
        </div>
    )
}