import { config } from "@config";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

const enabled = !(config.environment ?? "").toLowerCase().startsWith("prod");

export function FormDebugger(){
    const context = useFormikContext();
    const [show, setShow] = useState(false);

    const handleKeyDown = (e: KeyboardEvent) => {
        if(e.shiftKey && e.key.toLocaleLowerCase() === 'd'){
            setShow(x => !x);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    if(!enabled || !show){
        return null;
    }

    return <pre>{JSON.stringify(context.values, null, 2)}</pre>
}