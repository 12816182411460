import { IGridQuery, IGridResult } from '@api'
import { emptyGrid } from '@api'
import { ISubmittedApplicationOverview, IUserName, SubmittedApplicationApiClient } from 'api'
import { useAuthorization } from '@authentication'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect, useState } from 'react'

export interface ISearchParameters {
    salesPersonId: number
    status: number
    customer: string
    from: string
    to: string
}

export default function useSubmittedApplications(
    dealerId: number,
    submittedApplicationsTableState: IGridQuery | undefined,
    searchParameters: ISearchParameters,
    sellers: IUserName[]){
    const api = useApiClient(p => new SubmittedApplicationApiClient(p))
    
    const [applications, setApplications] = useState<IGridResult<ISubmittedApplicationOverview>>(emptyGrid)
    const [loading, setLoading] = useState(false)
    const [sellerLookup, setSellerLookup] = useState({} as { [id: number]: IUserName | undefined })
    const { hasAccessToAllSubmittedCases: hasAccessToAllSubmittedApplicationsOfDealer } = useAuthorization()

    const refresh = (configuration?: { suppressError: boolean }) => {
        if(submittedApplicationsTableState === undefined){
            setApplications(emptyGrid)
            return
        }

        setLoading(true)
        const request =
            hasAccessToAllSubmittedApplicationsOfDealer
                ?  api.getDealerApplications(
                    dealerId,
                    {...searchParameters, ...submittedApplicationsTableState, sellerAgentNo: sellerLookup[searchParameters.salesPersonId]?.sellerAgentNo },
                    configuration)
                : api.getMyApplications(
                    dealerId,
                    {...searchParameters, ...submittedApplicationsTableState},
                    configuration)
        request
            .then(x => {
                if(x.success){
                    setApplications(x.data!)
                    return
                }

                if(configuration?.suppressError){
                    return
                }

                setApplications(emptyGrid)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        const entries: { [id: number]: IUserName } = {}
        sellers.forEach(seller => {
            entries[seller.id] = seller
        })
        setSellerLookup(entries)
    }, [sellers])

    useEffect(() => {
        refresh()
    }, [submittedApplicationsTableState, searchParameters, dealerId])

    return { applications, loading, refresh }
}