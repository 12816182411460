import { ApplicationContext } from 'pages/application/state'
import { Agreement } from 'model'
import { useSession, useTranslation } from '@hooks'
import React, { useContext, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { Message, Segment } from 'semantic-ui-react'
import { OfferButton } from '../OfferButton'
import { PriceTagButton } from '../PriceTagButton'
import { CalculatorForm } from './components/CalculatorForm'
import { SellDetailsComponent } from './components/SellDetailsComponent'
import useDoNotUseKK15AgreementWarning from './useDoNotUseKK15AgreementWarning'
import { BusyIndicator } from '@framework'
import { AvailableObjectType } from 'pages/application/dataProviders/useAvailableObjectTypes'
import { PaymentPlanModalButton } from '..'
import { ApplicationType } from 'shared'
import { isFeatureEnabled } from '@config'

interface IProps {
  agreements: Agreement[],
  objectTypes: AvailableObjectType[],
  applicationType: ApplicationType,
  allowToProceed: boolean,
  setAllowToProceed: (value: boolean) => void,
}

export function CalculatorTab(props: IProps) {
  const context = useContext(ApplicationContext)
  const session = useSession()
  const $t = useTranslation('CalculatorComponent')
  const [busy, setBusy] = useState(false);
  const agreement = props.agreements.find(x => x.code == context.application.calculation.agreementCode);

  const doNotUseKK15AgreementWarning = useDoNotUseKK15AgreementWarning({
    agreementCode: context.application.calculation.agreementCode,
    calculations: context.application.calculation,
  })
  const indicativeCalculationsWarning =
    context.formConfiguration.hasIndicativeCalculationsWarning
      ? $t('We point out that this calculation is only indicative as no object has been selected ')
      : undefined

  const errors = useMemo(() => {
    if (!context.calculationResult?.validationErrors) {
      return []
    } else {
      return context.calculationResult?.validationErrors.map(p => p.text) || []
    }
  }, [context.calculationResult?.validationErrors])

  const [sellDetailsVisible, setSellDetailsVisible] = useState<boolean>(false)

  useHotkeys('alt+M', () => {
    setSellDetailsVisible(!sellDetailsVisible)
  }, [sellDetailsVisible])

  return (
    <BusyIndicator busy={busy}>
      <div style={{marginBottom: '0.75rem'}}>
        <PriceTagButton
          application={context.application}
          calculationResult={context.calculationResult}
        />
        <OfferButton
          application={context.application}
          agreement={agreement}
          calculationResult={context.calculationResult}
          disabled={!props.allowToProceed}
          setBusy={setBusy}
        />
        { isFeatureEnabled("payment-plan") &&
          <PaymentPlanModalButton
            dealerId={session.currentDealer?.id}
            calculationInput={context.application.calculation}
            hasCalculationResult={context.calculationResult !== undefined}
          />
        }
      </div>
      {doNotUseKK15AgreementWarning &&
        <Message
          warning
          content={doNotUseKK15AgreementWarning}
        />
      }
      {indicativeCalculationsWarning &&
        <Message
          warning
          content={indicativeCalculationsWarning}
        />
      }
      {errors.length > 0 &&
        <Message
          error
          visible={errors.length > 0}
          list={errors}
        />
      }
      {sellDetailsVisible &&
        <Segment>
          <SellDetailsComponent
            summary={context.calculationResult?.summary}
            input={context.calculationResult?.input}
            agreementCode={context.application.calculation.agreementCode}
            onClose={() => setSellDetailsVisible(false)}
          />
        </Segment>
      }
      <CalculatorForm
          agreements={props.agreements}
          objectTypes={props.objectTypes}
          areErrors={errors && errors.length > 0}
          toggleSellDetails={() => setSellDetailsVisible(!sellDetailsVisible)}
          applicationType={props.applicationType}
          onFormErrorsChange={hasErrors => props.setAllowToProceed(!hasErrors)}
        />
    </BusyIndicator>
  )
}
