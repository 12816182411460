import React, { createContext, ReactChild, useState } from 'react'

export interface IAuthenticationContext {
    authenticated: boolean
    logIn(): void
    logOut(): void
}

export const AuthenticationContext = createContext<IAuthenticationContext>(
    {} as IAuthenticationContext
)

interface IAuthenticationProviderProps {
    children: ReactChild[] | ReactChild
}

export function AuthenticationContextProvider({ children }: IAuthenticationProviderProps) {
    const key = 'authentication-context'
    const defaultContextSerialized = window.localStorage.getItem(key)
    const defaultContext =
        defaultContextSerialized != null
            ? JSON.parse(defaultContextSerialized)
            : { authenticated: false }
    const [context, setContext] = useState(defaultContext)


    return (
        <AuthenticationContext.Provider
            value={{
                authenticated: context.authenticated,
                logIn: () => {
                    const newContext = {
                        authenticated: true,
                    }
                    window.localStorage.setItem(key, JSON.stringify(newContext))
                    setContext(newContext)
                },
                logOut: () => {
                    const newContext = {
                        authenticated: false,
                    }
                    window.localStorage.setItem(key, JSON.stringify(newContext))
                    setContext(newContext)
                }
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    )
}
