import { useSearchParams } from 'react-router-dom'

export function useApplicationTimestamp() : string{
    const [searchParams] = useSearchParams()
    const value = searchParams.get('timestamp')

    if(!value){
        throw new Error('Unknown application timestamp, please ensure that given URL is correct.')
    }

    return value
}