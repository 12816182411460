import { LanguageContext } from '@translations'
import React, { useContext } from 'react'
import { Flag } from 'semantic-ui-react'
import styles from './LanguageSwitcher.module.css'

const LanguageButton = ({ language }: { language: string }) => {
    const context = useContext(LanguageContext)
    const changeLanguage = () => context.setLanguage(language)

    return (
        <Flag name={getCountry(language)}
            onClick={changeLanguage}
            className={context.language !== language ? styles.inactive : undefined}
        />
    )
}

function getCountry(language: string) {
    switch (language) {
        case 'en':
            return 'gb'
        case 'da':
            return 'dk'
        default:
            throw new Error('Unknown country')
    }
}

export const LanguageSwitcher = () => (
    <div className={styles.wrapper}>
        <LanguageButton language="da" />
        <LanguageButton language="en" />
    </div>
)