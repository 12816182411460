import messages_da from './da.json'
import messages_en from './en.json'

export interface IMessages {
    [key: string]: any,
}

export const messages: IMessages = {
    'da': messages_da,
    'en': messages_en,
}

export * from './LanguageProvider'