import { CustomerApiClient } from 'api'
import { ApplicationContext, PersonInformationSource } from 'pages/application/state'
import {
    AdultCodes,
    ApplicationCustomer,
    ApplicationCustomerFinancialInformation,
    ChildrenCodes,
    CivilStatusCodes,
    HousingCodes,
    IDropdownOption,
    MonthlyIncomeAfterExpencesCodes as MonthlyIncomeAfterExpensesCodes
} from 'model'
import {
    BusyIndicator,
    DPFormField,
    DPInputDate,
    DPInputDropdown,
    DPInputNumber,
    DPInputText,
    DPInputTextareaReadOnly,
    DPInputTextReadOnly,
    PageSectionHeader
} from '@framework'
import { useApiClient, useTranslation } from '@hooks'
import { Formik, FormikProps } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Divider, Form, Grid } from 'semantic-ui-react'
import * as Yup from 'yup'
import { ErrorMessage } from './ErrorMessage'
import { WarningMessage } from './WarningMessage'
import { currentDateString } from 'shared/dateTools'

interface ICustomerForm {
    idNumber: string | undefined,
    phoneNumber: string,
    email: string,
}

interface IBeneficialOwnersForm {
    beneficialOwner1?: string,
    beneficialOwner2?: string,
    beneficialOwner3?: string,
    beneficialOwner4?: string,
}

interface ICoBuyerForm {
    idNumber: string | undefined,
    phoneNumber: string,
    email: string,
}

interface IFinancialInformationForm {
    monthlyIncome: number | undefined
    monthlyIncomeAfterExpensesCode: string | undefined
    housing: string | undefined
    civilStatusCode: string | undefined
    adults: number | undefined
    children: number | undefined
    employedSince: string | undefined
    hasCreditCard: boolean | undefined
}

const emptyCustomer: ApplicationCustomer = {
    isCompany: false,
    street: '',
    zip: '',
    city: '',
    email: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    isSoleProprietorship: false,
    authorizedSignatory: ''
}

const emptyCustomerFinancial: ApplicationCustomerFinancialInformation = {
    monthlyIncome: 0,
    monthlyIncomeAfterExpensesCode: '',
    housing: '',
    civilStatusCode: '',
    adults: 0,
    children: -1,
    employedSince: undefined,
    hasCreditCard: false
}

export function CompanyCustomerForm() {
    const $t = useTranslation('CompanyCustomerForm')
    const customerApi = useApiClient((b) => new CustomerApiClient(b))
    const context = useContext(ApplicationContext)
    const [fetchingCustomer, setFetchingCustomer] = useState(false)
    const [fetchingCoBuyer, setFetchingCoBuyer] = useState(false)
    const { customer, coBuyer } = context.application

    const updateCustomer = (values: ICustomerForm) => {
        context.setCustomer({
            ...customer,
            idNumber: values.idNumber,
            phoneNumber: values.phoneNumber,
            email: values.email,
        })
    }

    const updateBeneficialOwners = (values: IBeneficialOwnersForm) => {
        if (customer) {
            context.setCustomer({
                ...customer,
                ...values
            })
        }
    }
    const updateCoBuyer = (values: ICoBuyerForm) => {
        context.setCoBuyer({
            ...(coBuyer ?? emptyCustomer),
            idNumber: values.idNumber,
            phoneNumber: values.phoneNumber,
            email: values.email,
        })
    }

    const updateFinancialInformation = (financialInformation: IFinancialInformationForm) => {
        context.setCoBuyer({
            ...(coBuyer ?? emptyCustomer),
            financialInformation: {
                monthlyIncome: financialInformation.monthlyIncome!,
                monthlyIncomeAfterExpensesCode: financialInformation.monthlyIncomeAfterExpensesCode!,
                housing: financialInformation.housing!,
                civilStatusCode: financialInformation.civilStatusCode!,
                adults: financialInformation.adults!,
                children: financialInformation.children!,
                employedSince: financialInformation.employedSince,
                hasCreditCard: financialInformation.hasCreditCard, 
            }
        })
    }

    function fetchCustomer(cvr: string) {
        setFetchingCustomer(true)
        customerApi.getCompany(cvr)
            .then(x => {
                setCustomerError(undefined)

                if(!x.success){
                    if(x.response!.status <= 499){
                        x.response!.text().then(y => setCustomerError(y))
                    }
                    return
                }

                const company =  x.data
                if(!company){
                    return
                }

                const {
                    cvr: idNumber,
                    companyName,
                    address,
                    zipCode: zip,
                    city,
                    isSoleProprietorship,
                    beneficialOwners,
                    authorizedSignatory,
                    warning
                } = company
        
        
                context.setCustomer({
                    idNumber,
                    isCompany: true,
                    lastName: companyName,
                    street: address,
                    zip,
                    city,
                    email: '',
                    phoneNumber: '',
                    financialInformation: emptyCustomerFinancial,
                    isSoleProprietorship,
                    beneficialOwner1: beneficialOwners?.at(0)?.personName,
                    beneficialOwner2: beneficialOwners?.at(1)?.personName,
                    beneficialOwner3: beneficialOwners?.at(2)?.personName,
                    beneficialOwner4: beneficialOwners?.at(3)?.personName,
                    authorizedSignatory,
                    warning
                })
            })
            .finally(() => setFetchingCustomer(false))
    }

    function clearCustomer() {
        setCustomerError(undefined)
        context.setCustomer({
            ...context.defaultApplication.customer,
            isCompany: true,
            version: new Date().getTime()
        })
    }

    function fetchCoBuyer(cpr: string) {
        setFetchingCoBuyer(true)
        context.setCoBuyerInformationSource(PersonInformationSource.ExternalService)
        context.setCoBuyer({
            ...coBuyer || {} as ApplicationCustomer,
            idNumber: cpr,
            firstName: '',
            lastName: '',
            zip: '',
            city: '',
            street: '',
            warning: '',
        })
        setCoBuyerError(undefined)

        customerApi.getPerson(cpr)
            .then(x => {
                if(!x.success){
                    context.setCoBuyerInformationSource(PersonInformationSource.Manual)
                    if(x.response!.status <= 499){
                        x.response!.text().then(y => setCoBuyerError(y))
                    }
                    return
                }

                const person =  x.data
                if(!person){
                  return
                }

                const { cpr, firstName, lastName, address, warning } = person
                const { zip, city, street } = address

                if(address.hidden){
                    context.setCoBuyerInformationSource(PersonInformationSource.Manual)
                }
    
                context.setCoBuyer({
                    ...coBuyer || {} as ApplicationCustomer,
                    idNumber: cpr,
                    isCompany: false,
                    firstName,
                    lastName,
                    zip,
                    street,
                    city,
                    warning,
                })
            })
            .finally(() => setFetchingCoBuyer(false))
    }

    function clearCoBuyer() {
        setCoBuyerError(undefined)
        context.setCoBuyer(null)
    }

    const [customerError, setCustomerError] = useState<string>()
    const [coBuyerError, setCoBuyerError] = useState<string>()

    const companySchema = createCompanySchema()
    const coBuyerSchema = createCoBuyerSchema()
    const financialInformationSchema = createFinancialInformationSchema($t)

    const customerInitialValues : ICustomerForm =
    {
        idNumber: customer.idNumber || undefined,
        phoneNumber: customer.phoneNumber ?? '',
        email: customer.email ?? '',
    }

    const beneficialOwnersInitialValues: IBeneficialOwnersForm = {
        beneficialOwner1: customer.beneficialOwner1 || '',
        beneficialOwner2: customer.beneficialOwner2 || '',
        beneficialOwner3: customer.beneficialOwner3 || '',
        beneficialOwner4: customer.beneficialOwner4 || '',
    }

    const coBuyerInitialValues : ICustomerForm =
    {
        idNumber: coBuyer?.idNumber || undefined,
        phoneNumber: coBuyer?.phoneNumber ?? '',
        email: coBuyer?.email ?? '',
    }

    const financialInformationInitialValues : IFinancialInformationForm = {
        monthlyIncome: coBuyer?.financialInformation?.monthlyIncome,
        monthlyIncomeAfterExpensesCode: coBuyer?.financialInformation?.monthlyIncomeAfterExpensesCode,
        housing: coBuyer?.financialInformation?.housing,
        civilStatusCode: coBuyer?.financialInformation?.civilStatusCode,
        adults: coBuyer?.financialInformation?.adults,
        children: coBuyer?.financialInformation?.children,
        employedSince: coBuyer?.financialInformation?.employedSince,
        hasCreditCard: coBuyer?.financialInformation?.hasCreditCard,
    }

    return (
        <BusyIndicator busy={fetchingCustomer || fetchingCoBuyer}>
            <ErrorMessage
                customerError={customerError}
                coBuyerError={coBuyerError}
            />
            <WarningMessage
                customerWarning={customer.warning}
                coBuyerWarning={coBuyer?.warning || undefined}
            />
            <Grid relaxed stackable>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <PageSectionHeader>
                            {$t('Customer')}
                        </PageSectionHeader>
                        <Formik
                            initialValues={customerInitialValues}
                            onSubmit={updateCustomer}
                            validationSchema={companySchema}
                            enableReinitialize
                            autoSubmit
                        >
                            {props =>
                                <Form>
                                    {/* CVR */}
                                    <DPFormField
                                        label={$t('CVR number')}
                                        name="idNumber"
                                        required
                                    >
                                        <DPInputText
                                            name="idNumber"
                                            autoComplete="off"
                                        />
                                    </DPFormField>
                                    {/* Name */}
                                    <DPFormField
                                        label={$t('Name')}
                                        name="name"
                                    >
                                        <DPInputTextReadOnly
                                        value={`${customer?.firstName || ''} ${customer?.lastName || ''}`}
                                        />
                                    </DPFormField>
                                    {/* Address */}
                                    <DPFormField
                                        label={$t('Address')}
                                        name="address"
                                    >
                                        <DPInputTextareaReadOnly
                                        value={`${customer?.street || ''}\r\n${customer?.zip || ''} ${customer?.city || ''}`}
                                        rows={2}
                                        />
                                    </DPFormField>
                                    {/* Phone number */}
                                    <DPFormField
                                        label={$t('Phone')}
                                        name="phoneNumber"
                                        required
                                    >
                                        <DPInputText
                                        name="phoneNumber"
                                        autoComplete="off"
                                        />
                                    </DPFormField>
                                    {/* Email */}
                                    <DPFormField
                                        label={$t('E-mail')}
                                        name="email"
                                        required
                                    >
                                        <DPInputText
                                        name="email"
                                        autoComplete="off"
                                        />
                                    </DPFormField>
                                    <AutoSubmitCustomer formik={props}/>
                                    <FetchCompany
                                        formik={props}
                                        fetch={fetchCustomer}
                                        clear={clearCustomer}
                                    />
                                </Form>
                            }
                        </Formik>
                    </Grid.Column>
                    {customer?.isSoleProprietorship === false && (
                        <Grid.Column>
                            <PageSectionHeader>
                                {$t('Beneficial Owners')}
                            </PageSectionHeader>
                            <Formik
                                initialValues={beneficialOwnersInitialValues}
                                onSubmit={updateBeneficialOwners}
                                enableReinitialize
                            >
                                {props =>
                                    <Form onSubmit={props.handleSubmit}>
                                        {
                                            [1, 2, 3, 4].map(x =>
                                            {
                                                const label = $t('Beneficial owner') + ` ${x}`
                                                const name = `beneficialOwner${x}`
                                                return (
                                                    <DPFormField
                                                        key={x}
                                                        label={label}
                                                        name={name}
                                                    >
                                                        <DPInputText
                                                            name={name}
                                                            autoComplete="off"
                                                        />
                                                    </DPFormField>
                                                )
                                            }
                                        )}

                                        <AutoSubmitBeneficialOwners
                                            formik={props}
                                        />
                                        <UpdateBeneficialOwners
                                            formik={props}
                                            customer={customer}
                                        />
                                    </Form>
                                }
                            </Formik>

                            {customer?.authorizedSignatory && (
                                <>
                                    <PageSectionHeader>
                                        {$t('Authorized Signatory')}
                                    </PageSectionHeader>
                                    <p>{customer.authorizedSignatory}</p>
                                </>
                            )}
                        </Grid.Column>
                    )}
                    {customer?.isSoleProprietorship === true && (
                        <Grid.Column>
                            <PageSectionHeader>
                                {$t('Co-Buyer')}
                            </PageSectionHeader>

                            <Formik
                                initialValues={coBuyerInitialValues}
                                onSubmit={updateCoBuyer}
                                validationSchema={coBuyerSchema}
                                enableReinitialize
                            >
                                {props => 
                                    <Form onSubmit={props.handleSubmit}>
                                    {/* CPR */}
                                    <DPFormField
                                        label={$t('CPR number')}
                                        name="idNumber"
                                        required
                                    >
                                        <DPInputText
                                        name="idNumber"
                                        autoComplete="off"
                                        />
                                    </DPFormField>

                                    { context.coBuyerInformationSource === PersonInformationSource.Manual &&
                                        <>
                                            {/* First name */}
                                            <DPFormField
                                            label={$t('FirstName')}
                                            name="firstName"
                                            required
                                            >
                                            <DPInputText
                                                name="firstName"
                                                autoComplete="off"
                                            />
                                            </DPFormField>

                                            {/* Last name */}
                                            <DPFormField
                                            label={$t('LastName')}
                                            name="lastName"
                                            required
                                            >
                                            <DPInputText
                                                name="lastName"
                                                autoComplete="off"
                                            />
                                            </DPFormField>

                                            {/* Street */}
                                            <DPFormField
                                            label={$t('Street')}
                                            name="street"
                                            required
                                            >
                                            <DPInputText
                                                name="street"
                                                autoComplete="off"
                                            />
                                            </DPFormField>

                                            {/* City */}
                                            <DPFormField
                                            label={$t('City')}
                                            name="city"
                                            required
                                            >
                                            <DPInputText
                                                name="city"
                                                autoComplete="off"
                                            />
                                            </DPFormField>

                                            {/* Zip */}
                                            <DPFormField
                                            label={$t('Zip')}
                                            name="zip"
                                            required
                                            >
                                            <DPInputText
                                                name="zip"
                                                autoComplete="off"
                                            />
                                            </DPFormField>
                                        </>
                                    }

                                    { context.coBuyerInformationSource === PersonInformationSource.ExternalService &&
                                        <>
                                            {/* Name */}
                                            <DPFormField
                                                label={$t('Name')}
                                                name="name"
                                            >
                                                <DPInputTextReadOnly
                                                value={`${coBuyer?.firstName || ''} ${coBuyer?.lastName || ''}`}
                                                />
                                            </DPFormField>
                                            {/* Address */}
                                            <DPFormField
                                                label={$t('Address')}
                                                name="address"
                                            >
                                                <DPInputTextareaReadOnly
                                                value={`${coBuyer?.street || ''}\r\n${coBuyer?.zip || ''} ${coBuyer?.city || ''}`}
                                                rows={2}
                                                />
                                            </DPFormField>
                                        </>
                                    }

                                    {/* Phone number */}
                                    <DPFormField
                                        label={$t('Phone')}
                                        name="phoneNumber"
                                        required
                                    >
                                        <DPInputText
                                        name="phoneNumber"
                                        autoComplete="off"
                                        />
                                    </DPFormField>

                                    {/* Email */}
                                    <DPFormField
                                        label={$t('E-mail')}
                                        name="email"
                                        required
                                    >
                                        <DPInputText
                                        name="email"
                                        autoComplete="off"
                                        />
                                    </DPFormField>

                                    <AutoSubmitCoBuyer
                                        formik={props}
                                    />
                                    
                                    <FetchCoBuyer
                                        formik={props}
                                        fetch={fetchCoBuyer}
                                        clear={clearCoBuyer}
                                    />
                                    </Form>
                                }
                            </Formik>
                            <Divider />
                            <PageSectionHeader>
                                {$t('Financing')}
                            </PageSectionHeader>
                            <Formik
                                initialValues={financialInformationInitialValues}
                                onSubmit={() => { return }}
                                validationSchema={financialInformationSchema}
                            >
                                {props =>
                                    <Form onSubmit={props.handleSubmit}>
                                        {/* Monthly income */}
                                        <DPFormField
                                            label={$t('Monthly income')}
                                            name="monthlyIncome"
                                            required
                                        >
                                            <DPInputNumber
                                                name="monthlyIncome"
                                                precision={2}
                                            />
                                        </DPFormField>

                                        {/* Monthly income after expenses */}
                                        <DPFormField
                                            label={$t('Monthly income after expenses')}
                                            name="monthlyIncomeAfterExpensesCode"
                                            required
                                        >
                                            <DPInputDropdown
                                                name="monthlyIncomeAfterExpensesCode"
                                                options={MonthlyIncomeAfterExpensesCodes}
                                                clearable
                                            />
                                        </DPFormField>

                                        {/* Housing */}
                                        <DPFormField
                                            label={$t('Housing')}
                                            name="housing"
                                            required
                                        >
                                            <DPInputDropdown
                                                name="housing"
                                                options={HousingCodes}
                                                clearable
                                            />
                                        </DPFormField>

                                        {/* Civil status */}
                                        <DPFormField
                                            label={$t('Civil status')}
                                            name="civilStatusCode"
                                            required
                                        >
                                            <DPInputDropdown
                                                name="civilStatusCode"
                                                options={CivilStatusCodes}
                                                clearable
                                            />
                                        </DPFormField>

                                        {/* Adults */}
                                        <DPFormField
                                            label={$t('Adults')}
                                            name="adults"
                                            required
                                        >
                                            <DPInputDropdown
                                                name="adults"
                                                options={AdultCodes}
                                                clearable
                                            />
                                        </DPFormField>

                                        {/* Adults */}
                                        <DPFormField
                                            label={$t('Children')}
                                            name="children"
                                            required
                                        >
                                            <DPInputDropdown
                                                name="children"
                                                options={ChildrenCodes}
                                                clearable
                                            />
                                        </DPFormField>

                                        {/* Employed since */}
                                        <DPFormField
                                            label={$t('Employed since')}
                                            name="employedSince"
                                        >
                                            <DPInputDate
                                                name="employedSince"
                                            />
                                        </DPFormField>

                                        {/* Has credit card */}
                                        <DPFormField
                                            label={$t('Has credit card')}
                                            name="hasCreditCard"
                                        >
                                            <DPInputDropdown
                                                name="hasCreditCard"
                                                options={
                                                    [
                                                        { key: '1', value: true, text: $t('Yes') },
                                                        { key: '0', value: false, text: $t('No') }
                                                    ] as Array<IDropdownOption<boolean>>
                                                }
                                                clearable
                                            />
                                        </DPFormField>

                                        <AutoSubmitFinancialInformation
                                            formik={props}
                                            update={updateFinancialInformation}
                                        />
                                    </Form>
                                }
                            </Formik>
                        </Grid.Column>
                    )}
                </Grid.Row>
            </Grid>
        </BusyIndicator>
    )
}

function AutoSubmitCustomer(props: { formik: FormikProps<ICustomerForm> }){
    const { dirty, values, submitForm } = props.formik

    useEffect(() => {
        if(!dirty){
        return
        }

        submitForm()
    }, [values.phoneNumber, values.email])

    return null
}

function FetchCompany(props: { formik: FormikProps<ICustomerForm>, fetch: (cpr: string) => void, clear: () => void }){
    const { dirty, values } = props.formik

    useEffect(() => {
        // Do nothing if user doesn't change data.
        if(!dirty){
            return
        }

        // If field is empty, clear the custom customer data.
        if(!values.idNumber){
            props.clear()
            return
        }

        // If 8 characters are typed, fetch data
        const cvr = values.idNumber
        if(cvr.length === 8){
            props.fetch(cvr)
        }
    }, [values.idNumber])

    return null
}

function AutoSubmitBeneficialOwners(props: { formik: FormikProps<IBeneficialOwnersForm> }){
    const { dirty, values, submitForm } = props.formik

    useEffect(() => {
        if(!dirty){
        return
        }

        submitForm()
    }, [values.beneficialOwner1, values.beneficialOwner2, values.beneficialOwner3, values.beneficialOwner4])

    return null
}

function UpdateBeneficialOwners(props: { formik: FormikProps<IBeneficialOwnersForm>, customer: ApplicationCustomer }){
    useEffect(() => {
        props.formik.setValues({
            beneficialOwner1: props.customer.beneficialOwner1 || '',
            beneficialOwner2: props.customer.beneficialOwner2 || '',
            beneficialOwner3: props.customer.beneficialOwner3 || '',
            beneficialOwner4: props.customer.beneficialOwner4 || '',
        })
    }, [
        props.customer.beneficialOwner1,
        props.customer.beneficialOwner2,
        props.customer.beneficialOwner3,
        props.customer.beneficialOwner4,
    ])

    return null
}

function AutoSubmitCoBuyer(props: { formik: FormikProps<ICoBuyerForm> }){
    const { dirty, values, submitForm } = props.formik

    useEffect(() => {
        if(!dirty){
        return
        }

        submitForm()
    }, [values.phoneNumber, values.email])

    return null
}

function FetchCoBuyer(props: { formik: FormikProps<ICoBuyerForm>, fetch: (cpr: string) => void, clear: () => void }){
    const { dirty, values } = props.formik
  
    useEffect(() => {
      // Do nothing if user doesn't change data.
      if(!dirty){
        return
      }
  
      // If field is empty, clear the custom customer data.
      if(!values.idNumber){
        props.clear()
        return
      }
  
      // If 10 characters are typed, fetch data
      const cpr = values.idNumber
      if(cpr.length === 10){
        props.fetch(cpr)
      }
    }, [values.idNumber])
  
    return null
}

function AutoSubmitFinancialInformation(props: {
    formik: FormikProps<IFinancialInformationForm>,
    update: (financialInformation: IFinancialInformationForm) => void
}){
    const { dirty, values, submitForm } = props.formik

    useEffect(() => {
        if(!dirty){
            return
        }

        props.update(values)

        // Submit the form to trigger validation.
        submitForm()
    }, [values])

    return null
}

function createCoBuyerSchema() {
    return Yup.object().strict(true)
        .shape({
            phoneNumber: Yup.string().phone(),
            email: Yup.string().email(),
            idNumber: Yup.string().cpr()
        })
}

function createCompanySchema() {
    return Yup.object().strict(true).shape({
        phoneNumber: Yup.string().phone(),
        email: Yup.string().email(),
        idNumber: Yup.string().cvr()
    })
}

function createFinancialInformationSchema($t: any) {
    return Yup.object().strict(true).shape({
        monthlyIncome: Yup.number().required().min(0).label($t('Monthly income')),
        monthlyIncomeAfterExpensesCode: Yup.string().required().label($t('Monthly income after expenses')),
        housing: Yup.string().required().label($t('Housing')),
        civilStatusCode: Yup.string().required().label($t('Civil status')),
        adults: Yup.number().required().label($t('Adults')),
        children: Yup.number().required().label($t('Children')),
        employedSince: Yup.string().nullable().optional().maxDate(currentDateString()).label($t('Employed since')),
        hasCreditCard: Yup.boolean().nullable().optional().label($t('Has credit card')),
    })
}
