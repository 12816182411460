import "./PublicCardLayout.css"
import { ReactNode } from "react";

interface Props {
    children: ReactNode,
}

export function PublicCardLayout(props: Props){
    return (
        <div id="card-container">
            <div id="card">
                <div id="card-header">
                    <div id="card-header-logo" />
                </div>

                <div id="card-content">
                    {props.children}
                </div>
            </div>
        </div>
    )
}