import { DealerApiClient, DealerMasterData } from 'api/DealerApiClient'
import { BusyIndicator, DPFormField, DPInputTextReadOnly, DPInputTextarea, FormikForm, PageHeader, useToast } from '@framework'
import { useApiClient } from '@hooks/useApiClient'
import { useTranslation } from '@hooks/useTranslation'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { DPButton } from 'shared'
import { useSession } from '@hooks/useSession'

interface FormModel extends DealerMasterData {
}

export function MasterDataPage() {
    const $t = useTranslation('MasterDataPage')
    const navigate = useNavigate()
    const dealerApiClient = useApiClient((x) => new DealerApiClient(x))
    const [isLoading, setIsLoading] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [initialValues, setInitialValues] = useState<FormModel>()
    const session = useSession();
    const dealerId = session?.currentDealer?.id;
    const toast = useToast();

    const load = () => {
        if(!dealerId){
            return
        }

        setIsLoading(true);
        dealerApiClient.getMasterData(dealerId)
            .then((result) => {
                if (!result.data) {
                    return
                }

                setInitialValues({...result.data})
            })
            .finally(() => setIsLoading(false))
    }

    const submit = (form: FormModel) => {
        if(!dealerId){
            return
        }

        setIsSaving(true)
        dealerApiClient.setMasterData(dealerId, {...form})
            .then((response) => {
                if(!response.success){
                    return
                }
                toast.success($t('saved'))
            })
            .finally(() => setIsSaving(false))
    };

    const cancel = () => {
        navigate(-1);
    };

    const validationSchema = Yup.object().shape({
        webAddress: Yup.string().nullable(),
        phone: Yup.string().nullable().phone(),
        email: Yup.string().nullable().email(),
        additionalEmails: Yup.string().nullable(),
    })

    const isBusy = isLoading || isSaving

    useEffect(load, []);

    return (
        <div style={{ width: '700px'}}>
            <PageHeader text={$t('editDealer')}/>
            <BusyIndicator busy={isBusy}>
                <FormikForm
                    initialValues={initialValues}
                    onSubmit={submit}
                    validationSchema={validationSchema}
                >
                    <FormFields />
                    <div style={{ display: 'flex' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DPButton type="button" size="mini" onClick={cancel}>
                                {$t('cancel')}
                            </DPButton>
                        </div>

                        <div style={{ flexGrow: 0 }}>
                            <DPButton type="submit" size="mini">
                                {$t('save')}
                            </DPButton>
                        </div>
                    </div>
                </FormikForm>
            </BusyIndicator>
        </div>
    )
}

function FormFields() {
    const $t = useTranslation('MasterDataPage')
    const session = useSession();
    const dealer = session?.currentDealer;

    if(!dealer){
        return null;
    }

    return (
        <>
            <DPFormField label={$t('dealer')} name="dealer">
                <DPInputTextReadOnly value={dealer.name} />
            </DPFormField>

            <DPFormField label={$t('address')} name="address">
                <DPInputTextReadOnly value={dealer.address} />
            </DPFormField>

            <DPFormField label={$t('webAddress')} name="webAddress">
                <DPInputText name="webAddress" />
            </DPFormField>

            <DPFormField label={$t('phone')} name="phone">
                <DPInputText name="phone" />
            </DPFormField>

            <DPFormField label={$t('email')} name="email">
                <DPInputText name="email" />
            </DPFormField>

            <DPFormField label={$t('additionalEmails')} name="additionalEmails">
                <DPInputTextarea name="additionalEmails" />
                <p style={{ color: '#999', marginTop: "0.5rem" }}>
                    Semikolonsepareret liste af e-mails. <br />
                    F.eks. navn@forhandler.dk;navn2@forhandler.dk […].<br />
                    Husk a trykke på 'Gem' efter du har indtastet emails.
                </p>

                <p style={{ color: '#999', marginTop: "0.5rem" }}>
                    Der vil blive sendt en notifikation til de e-mails du indsætter her, hver gang du får en online ansøgning.
                </p>
            </DPFormField>
        </>
    )
}
