import { IUserPermissions, UserPermissionsApiClient } from 'api'
import { useApiClient } from '@hooks/useApiClient'
import { useSession } from '@hooks/useSession'
import { createContext, ReactNode, useEffect, useState } from 'react'

export interface IAuthorizationContext extends IUserPermissions{
}

export const AuthorizationContext = createContext<IAuthorizationContext | undefined>(undefined)

export function AuthorizationProvider({ children } : { children: ReactNode }){
    const session = useSession()
    const userPermissionsApiClient = useApiClient(x => new UserPermissionsApiClient(x))
    const [context, setContext] = useState<IAuthorizationContext>()

    const dealerId = session.currentDealer?.id

    useEffect(() => {
        if(dealerId === undefined){
            setContext(undefined)
            return
        }

        userPermissionsApiClient.get(dealerId)
            .then(x => setContext(x))
    }, [dealerId])

    return (
        <AuthorizationContext.Provider value={context}>
            { children }
        </AuthorizationContext.Provider>
    )
}
