import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import React, { useMemo } from 'react'
import { LogOutComponent } from './LogOut'
import styles from './ProfileMenu.module.css'

export function ProfileMenu() {
    const $t = useTranslation('Shared.Layout')

    const session = useSession()

    const userName = useMemo(() => {
        return session.currentUser.firstName
    }, [session])

    return (
        <div className="profile-menu">
            <div className={styles['profile-menu-selector']}>
                {$t('Greeting')} {userName},&nbsp;
                <LogOutComponent />
            </div>
        </div>
    )
}
