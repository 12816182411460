import DPTable from './DPTable'
import { IGridResult, IPagination } from '@api'
import { useTranslation } from '@hooks/useTranslation'
import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown, Icon, Menu, Pagination } from 'semantic-ui-react'
import { BusyIndicator } from '../BusyIndicator'
import classNames from 'classnames'
import './DPDataTable.css'

export enum DataFormat {
    String,
    Boolean
}
export interface IDataTableColumn<T> {
    key: string
    name?: string
    textAlign?: 'left' | 'center' | 'right'
    onRender?: (item: T, index: number) => any
    customHeaderControl?: () => any
    format?: DataFormat | undefined
    width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16
}


interface IProps<T> {
    columns: Array<IDataTableColumn<T>>
    data: IGridResult<T>

    /**
     * Provide initial pagination value in order to enable pagination in DataTable
     */
    initialPagination?: IPagination
    selectedRow?: number | ((row: T) => boolean)
    isLoading?: boolean
    onRowSelect?(rowData: T, e: any): void
    onStateChange?(state: IPagination): void
}

const pageSizeOptions = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 },
    { text: '100', value: 100 }
]

export function DPDataTable<T>(props: IProps<T>) {
    const [pagination, setPagination] = useState(props.initialPagination)
    const paginationEnabled = !!props.initialPagination
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | undefined>()
    const [tableState, setTableState] = useState(props.initialPagination)
    const $t = useTranslation('DataTable')

    // reload data when page number, page size or sorting changed
    // reload when data version changed, meaning that current data are stale
    useEffect(() => {
        const stateChanged =
            JSON.stringify(tableState) !== JSON.stringify(pagination)

        if (stateChanged && paginationEnabled &&  props.onStateChange){
            props.onStateChange(pagination!)
            setTableState(pagination)
        }
    }, [pagination, props.data])

    // cancel selection if data changes
    useEffect(() => {
        setSelectedRowIndex(undefined)
    }, [props.data])

    // in case of loading less data than current page, change page to the current maximum
    useEffect(() => {
        if (props.data && pagination) {
            const maxPage = getTotalPages(props.data ? props.data.total : 0, pagination.pageSize)
            if (maxPage > 0 && maxPage < pagination.pageNumber) {
                setPagination({
                    ...pagination,
                    pageNumber: maxPage,
                })
            }
        }
    }, [props.data])

    const calculatedIndex = useMemo(() => {
        if (typeof props.selectedRow === 'function') {
            if (props.data && props.data.items) {
                for (let i = 0; i < props.data.items.length; i++) {
                    if (props.selectedRow(props.data.items[i])) {
                        return i
                    }
                }
            }
        }
    }, [props.selectedRow, props.data])

    useEffect(() => {
        setSelectedRowIndex(calculatedIndex)
    }, [calculatedIndex, props.data])

    useEffect(() => {
        if (typeof props.selectedRow !== 'function') {
            setSelectedRowIndex(props.selectedRow as number)
        }
    }, [props.selectedRow])

    function handlePageNumber(newPageNumber: number) {
        setSelectedRowIndex(undefined)
        const newVal = paginationEnabled ? {
            pageSize: pagination!.pageSize,
            pageNumber: newPageNumber
        } : undefined
        setPagination(newVal)
    }

    function handlePageSize(newPageSize: number): void {
        setSelectedRowIndex(undefined)
        setPagination({
            pageNumber: 1,
            pageSize: newPageSize
        })
    }

    const handleRowClick = (rowIndex: number, e: React.MouseEvent) => {
        if (props.onRowSelect && props.data && props.data.items) {
            setSelectedRowIndex(rowIndex)
            props.onRowSelect(props.data.items[rowIndex], e)
        }
    }

    const headers = props.columns.map((column, index) => (
        <th
            key={column.key}
            style={{'width': column.width}}
        >
            {column.customHeaderControl ? column.customHeaderControl() : column.name}
        </th>
    ))

    let rows: JSX.Element[] = []

    if (props.data && props.data.total) {
        rows = props.data.items.map((item: any, idx) => (
            <tr
                key={idx}
                className={
                    classNames({
                        active: selectedRowIndex === idx,
                        positive: item.positive,
                        negative: item.negative,
                        warning: item.highlighted,
                })}
                onClick={(e: React.MouseEvent) => handleRowClick(idx, e)}
                style={props.onRowSelect ? { cursor: 'pointer' } : {}}
            >
                {props.columns.map(c => {
                    return (
                        <td
                            className="cell"
                            style={{verticalAlign: 'middle', textAlign: c.textAlign}}
                            key={c.key}
                        >
                            {renderCell(item, idx, c, $t)}
                        </td>
                    )
                })}
            </tr>
        ))
    }

    return (
        <BusyIndicator busy={props.isLoading || false}>
            <DPTable selectable={!!props.onRowSelect}>
                <thead>
                    <tr>{headers}</tr>
                </thead>

                <tbody>
                    {rows}
                    {props.isLoading && rows.length === 0 &&
                    <tr>
                        <td colSpan={99}>
                            {/* Add some empty space to the table */}
                            <div style={{ height: "6rem" }} />
                        </td>
                    </tr>
                }
                    {!props.isLoading && rows.length === 0 && (
                        <tr>
                            <td className='no-data-cell' colSpan={props.columns.length}>
                                <div className='no-data-text'>
                                    <p>
                                        <Icon size="big" name="database" />
                                    </p>
                                    <p>
                                        {$t('data not found')}
                                        &nbsp;
                                        <Icon name="frown outline" />
                                    </p>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={1}>
                            {paginationEnabled && (
                                <Menu pagination>
                                    <Dropdown
                                        selection
                                        fluid
                                        options={pageSizeOptions}
                                        value={pagination!.pageSize}
                                        onChange={(e, d) => handlePageSize(d.value as number)}
                                    />
                                </Menu>
                            )}
                        </th>
                        <th colSpan={props.columns.length - 1} style={{'textAlign': 'right'}}>
                            {paginationEnabled && (
                                <Pagination
                                    onPageChange={(e, d) => handlePageNumber(d.activePage as number)}
                                    activePage={pagination!.pageNumber}
                                    totalPages={getTotalPages(props.data ? props.data.total : 0, pagination!.pageSize)}
                                />
                            )}
                        </th>
                    </tr>
                </tfoot>
            </DPTable>
        </BusyIndicator>
    )
}

function renderCell<T>(
    item: any,
    index: number,
    c: IDataTableColumn<T>,
    $t: (t: string) => string
) {
    if (c.onRender) {
        return c.onRender(item, index)
    } else {
        switch (c.format) {
            case DataFormat.Boolean:
                return item[c.key] ? $t('Yes') : $t('No')
        }

        return item[c.key]
    }
}

export function getTotalPages(total: number, pageSize: number): number {
    return Math.ceil(total / pageSize)
}
