export interface IGridQuery {
    pageNumber?: number,
    pageSize?: number,
}

export type IPagination = Required<IGridQuery>

export const defaultQuery: IPagination = {
    pageNumber: 1,
    pageSize: 10
}

export interface IGridResult<T> {
    total: number,
    items: T[]
}

export const emptyGrid = {
    total: 0,
    items: []
}
