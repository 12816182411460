import { routes } from 'routing'
import { AuthenticationContext } from '@authentication'
import { useTranslation } from '@hooks/useTranslation'
import React, { useContext, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router'
import { Icon } from 'semantic-ui-react'
import styles from './IdleWarning.module.css'

export default function IdleWarning(){
    // 15 minutes timeout.
    const timeout = 1000 * 60 * 15
    // Warn user 30 seconds before.
    const warnBefore = 1000 * 30
    const navigate = useNavigate()
    const $t = useTranslation('IdleWarning')
    const [remainingTime, setRemainingMs] = useState(timeout)

    // Logout user on timeout.
    const onIdle = () => {
        navigate(routes.logOut())
    }

    // Setup idle timer
    const { getRemainingTime } = useIdleTimer({
        timeout,
        onIdle,
        crossTab: true,
        debounce: 500,
      })

    // Refresh remaining value of idle timer every 1 second.
    // TODO It's possible to use setTimeout function to decrease number of refresh.
    // As long as performance doesn't suffer I suggest to use this simple approach.
    // The current approach is easy to understand and to manage.
    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingMs(getRemainingTime())
        }, 1000)
        return () => clearInterval(timer)
    }, [])

    const warn = remainingTime < warnBefore
    if(!warn){
        return null
    }

    const totalSeconds = Math.floor(remainingTime / 1000)
    return (
        <div className={styles.overlay}>
            <div className={styles.message}>
                <Icon
                    name="clock outline"
                    size="huge"
                    className={styles.icon}
                />
                <div className="text">
                    <div>{$t('youWillBeAutomaticallyLogoutIn')}</div>
                    <div className={styles.seconds}>{totalSeconds}s</div>
                </div>
            </div>
        </div>
    )
}