import { Application, CalculationResult } from 'model'
import { useSession, useTranslation } from '@hooks'
import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { PriceTagModal } from './PriceTagModal'
import { DPButton } from 'shared'

interface IProps {
    application: Application,
    calculationResult: CalculationResult | undefined,
}

export function PriceTagButton(props: IProps){
    const $t = useTranslation('PriceTagButton')
    const session = useSession()
    const [open, setOpen] = useState(false)
    const { object, calculation } = props.application
    const isObjectSet = object.make && object.model && object.variant
    const isCalculationSet = props.calculationResult !== undefined
    const hasMileage = object.mileage ?? 0 >= 500
    const isCurrentDealerSet = session.currentDealer !== undefined
    const isValid = ((isObjectSet && hasMileage) || isCalculationSet) && isCurrentDealerSet
    return (
        <>
            <DPButton
                size="mini"
                onClick={() => setOpen(true)}
                basic
                disabled={!isValid}
            >
                <Icon name="tag" /> {$t('PriceTag')} 
            </DPButton>
            {
                isValid &&
                <PriceTagModal
                    open={open}
                    setOpen={setOpen}
                    object={object}
                    calculation={calculation}
                    agreementCode={calculation.agreementCode}
                    calculationResult={props.calculationResult!}
                    brandCode={session.currentDealer!.brandCode}
                />
            }
        </>
    )
}