import { Application } from "model";

function timestampToKey(timestamp: string){
    return `applications/${timestamp}`
}

export function storeApplicationInSessionStorage(timestamp: string, application: Application){
    const key = timestampToKey(timestamp);
    const value = JSON.stringify(application);
    sessionStorage.setItem(key, value);
}

export function restoreApplicationFromSessionStorage(timestamp: string): Application | null{
    const key = timestampToKey(timestamp);
    const value = sessionStorage.getItem(key);

    if(value === null){
        return null
    }

    try{
        return JSON.parse(value) as Application
    }
    catch{
        return null;
    }
}