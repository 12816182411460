import { useApiClient } from '@hooks/useApiClient'
import { AuthApiClient } from 'infrastructure/authentication/AuthApiClient'
import React, { useEffect } from 'react'
import FullPageLoader from './FullPageLoader'
import { config } from '@config';

export default function LogInPage() {
    const authApi = useApiClient(x => new AuthApiClient(x))

    useEffect(() => {
        // The code below is a hack!
        // Our application can be access via multiple url like:
        // - https://new.dealer.santander24.dk/ (main url)
        // - https://dealer.santander24.dk/
        // - https://santander24.dk/
        // But only first of them, the main, is whitelisted in Curity and can be used in process of user authorization.
        // In authorization process we use cookies, that's why we always have to start the process from the main url.
        // To ensure that authorization doesn't start from another url,
        // the login page itself redirects user to login page under the main url, when it's needed.
        // TODO describe it in PBI and put link here.

        const accessFromMainDomain = window.location.href.startsWith(config.webUrl);
        if(!accessFromMainDomain){
            const mainDomainLogInUrl = config.webUrl + "log-in"
            window.location.href = mainDomainLogInUrl
            return
        }

        authApi.getAuthUri()
            .then(x => {
                if(x === undefined){
                    return
                }

                window.location.replace(x.redirectUri)
            })
    }, [])
    return <FullPageLoader />
}