import { useField } from 'formik'
import React, { ReactChild } from 'react'
import { Form as SemanticForm, Icon, Popup, Transition } from 'semantic-ui-react'
import './DPFormField.css'

interface IProps {
    label?: string
    name: string
    required?: boolean
    inline?: boolean,
    children?: ReactChild | ReactChild[]
    className?: string
    tooltip?: string
}

export function DPFormField({
    label,
    name,
    required,
    inline = true,
    children,
    className,
    tooltip = ''
}: IProps) {
    const [, meta] = useField(name)
    const showError =
        meta.error !== undefined &&
        meta.error !== '' &&
        meta.error.length !== 0 &&
        meta.touched

    const tooltipIcon = <Icon name="question circle" color="red" />

    const effectiveClassName = [
        'dp-form-field',
        inline ? 'inline' : undefined,
        !label ? 'nolabel' : undefined,
        className
    ].filter(x => x !== undefined).join(' ')

    return (
        <SemanticForm.Field
            inline={inline}
            required={required}
            error={showError}
            className={effectiveClassName}
        >
            {/* Label with Tooltip */}
            <div className="label-group">
                <label title={label}>
                    {label}

                    &nbsp;

                    {tooltip && <Popup content={tooltip} trigger={tooltipIcon}/>}
                </label>
                {
                    label && required &&
                    <span>*</span>
                }
            </div>

            <div className="input-group">
                {/* Input */}
                <div className="input-group-control">
                    {children}
                </div>

                {/* Validation */}
                <div className="input-group-validation">
                    <Transition visible={showError} animation="slide down" duration={500}>
                        <small>
                            {meta.error}
                        </small>
                    </Transition>
                </div>
            </div>


        </SemanticForm.Field>
    )
}
