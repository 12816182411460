import { BusyIndicator, DPDataTable, DPFormField, DPInputText, DPTableSearchForm, IDataTableColumn, PageHeader } from '@framework'
import { useTranslation } from '@hooks/useTranslation'
import React, { useEffect, useState } from 'react'
import { IGridResult, emptyGrid, IGridQuery, defaultQuery } from '@api/models'
import { useApiClient } from '@hooks/useApiClient'
import { DealerApiClient, IDealer, IDealerQuery } from 'api'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { routes } from 'routing'
import { DPButton } from 'shared'
import { isFeatureEnabled } from '@config'

interface ISearchParameters {
    dealerName: string
}

const initialValues: ISearchParameters = {
    dealerName: ''
}

export function DealerAdministrationPage() {
    const $t = useTranslation('Administration.DealerAdministration')
    const dealerApiClient = useApiClient((p) => new DealerApiClient(p))

    const [dealers, setDealers] = useState<IGridResult<IDealer>>(emptyGrid)
    const [dealersTableState, setDealersTableState] = useState<IGridQuery | undefined>(undefined)
    const [searchParams, setSearchParams] = useState<ISearchParameters>({ ...initialValues })

    const navigate = useNavigate()
    const [isLoadingDealers, setIsLoadingDealers] = useState(false)
    const [isUpdatingPricing, setIsUpdatingPricing] = useState(false)

    useEffect(() => {
        getDealerList()
    }, [dealersTableState, searchParams])

    const getDealerList = () => {
        setIsLoadingDealers(true)

        dealerApiClient
            .getDealers({ ...dealersTableState, ...searchParams } as IDealerQuery)
            .then((data) => {
                if (data === undefined) {
                    setDealers(emptyGrid)
                    return
                }

                setDealers(data)
            })
            .finally(() => setIsLoadingDealers(false))
    };

    const updatePricingFromPreview = () => {
        setIsUpdatingPricing(true)
        dealerApiClient.updatePricingFromPreview()
            .finally(() => setIsUpdatingPricing(false))
    }

    function handleSubmit(values: ISearchParameters) {
        setSearchParams(values)
    }

    return (
        <article>
            <PageHeader text={$t('Dealers')} />

            <DPTableSearchForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                debounce={{keys: ["dealerName"], delay: 500}}
                column1={
                    <DPFormField name="dealerName" label={$t('Dealer')} inline={false}>
                        <DPInputText name="dealerName" />
                    </DPFormField>
                }
                // It's a hack, we shouldn't put button inside the column but it's not worth to change it now. Works like a charm.
                column5={
                    isFeatureEnabled('update-pricing-from-preview') &&
                    <div style={{ display: 'flex', paddingBottom: '0.75rem', alignItems: "end", height: "100%" }}>
                        <BusyIndicator busy={isUpdatingPricing}>
                            <DPButton onClick={updatePricingFromPreview}>
                                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: "nowrap" }}>
                                    {$t('UpdatePricingFromPreview')}
                                </div>
                            </DPButton>
                        </BusyIndicator>
                    </div>
                }
            />

            <div className='mt-4'>
                <DPDataTable
                    columns={columns($t)}
                    onRowSelect={({ dealerNumber }) => navigate(routes.administrationDealerEdit(dealerNumber))}
                    data={dealers}
                    initialPagination={defaultQuery}
                    isLoading={isLoadingDealers}
                    onStateChange={setDealersTableState}
                />
            </div>
        </article>
    )
}

const columns = ($t: any): Array<IDataTableColumn<IDealer>> => {
    return [
        {
            key: 'name',
            name: $t('Dealer Name'),
            onRender: ({ dealerNumber, name, officialName }) => (
                <Link to={routes.administrationDealerEdit(dealerNumber)}>{name || officialName}</Link>
            )
        },
        {
            key: 'dealerNumber',
            name: $t('Dealer Number')
        },
        {
            key: 'cvr',
            name: $t('CVR')
        }
    ]
}