import { AgreementApiClient } from 'api/AgreementApiClient'
import { Agreement } from 'model'
import { useApiCall, useApiClient } from '@hooks'
import { useEffect, useMemo } from 'react'
import { ApplicationType } from 'shared'

export default function useAgreements(dealerId: number | undefined, applicationType: ApplicationType) {
    const agreementApiClient = useApiClient(x => new AgreementApiClient(x))
    const [allAgreements, isLoadingAgreements, getAgreements] = useApiCall(agreementApiClient.getAgreements, [] as Agreement[])

    useEffect(() => {
        if(!dealerId){
            return
        }

        getAgreements(dealerId)
    }, [dealerId])

    const agreements = useMemo(() => allAgreements.filter(x => {
        switch(applicationType){
            case ApplicationType.Private:
                return x.isPrivate && !x.isPersonnel;
            case ApplicationType.Corporate:
                return x.isCorporate;
            case ApplicationType.Personnel:
                return x.isPrivate && x.isPersonnel;
            case ApplicationType.Leasing:
                return false;
        }
    }), [allAgreements, applicationType])

    return { agreements, isLoadingAgreements }
}