import { ApiClientProvider } from '@api'
import ThemeProvider from 'themes/ThemeProvider'
import { AuthenticationContext, AuthenticationContextProvider, AuthorizationProvider } from '@authentication'
import { LanguageProvider } from '@translations'
import { ToastProvider } from 'infrastructure/framework'
import { ModalProvider } from 'infrastructure/modal/ModalProvider'
import { SessionContextProvider } from 'infrastructure/session/SessionContextProvider'
import YupValidationMessagesProvider from 'infrastructure/validation/YupValidationMessagesProvider'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from 'App'
import { LanguageCookieStore } from './shared/LanguageStore'
import * as serviceWorker from './serviceWorker'
import { IncomingCaseCounterProvider } from 'shared/incomingApplicationCounter'

// Get the application-wide store instance, pre-populating with state from the server where available.
const rootElement = document.getElementById('root')

ReactDOM.render(
    <LanguageProvider languageStore={new LanguageCookieStore()}>
        <YupValidationMessagesProvider>
            <ToastProvider>
                <ModalProvider>
                    <AuthenticationContextProvider>
                        <AuthenticationContext.Consumer>
                            {(authenticationContext) => (
                                <ApiClientProvider>
                                    <SessionContextProvider
                                        isAuthenticated={
                                            authenticationContext.authenticated
                                        }
                                    >
                                        <AuthorizationProvider>
                                            <ThemeProvider>
                                                <IncomingCaseCounterProvider>
                                                    <App />
                                                </IncomingCaseCounterProvider>
                                            </ThemeProvider>
                                        </AuthorizationProvider>
                                    </SessionContextProvider>
                                </ApiClientProvider>
                            )}
                        </AuthenticationContext.Consumer>
                    </AuthenticationContextProvider>
                </ModalProvider>
            </ToastProvider>
        </YupValidationMessagesProvider>
    </LanguageProvider>,
    rootElement
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
