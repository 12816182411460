import { CustomerApiClient } from 'api'
import { ApplicationContext, PersonInformationSource } from 'pages/application/state'
import { ApplicationCustomer } from 'model'
import { BusyIndicator, DPFormField, DPInputText, DPInputTextareaReadOnly, DPInputTextReadOnly, PageSectionHeader } from '@framework'
import { useApiClient, useTranslation } from '@hooks'
import { Formik, FormikProps, FormikTouched } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { Form, Grid } from 'semantic-ui-react'
import * as Yup from 'yup'
import { ErrorMessage } from './ErrorMessage'
import { WarningMessage } from './WarningMessage'
import { DPButton } from 'shared'

interface IPersonForm {
  idNumber: string | undefined,
  firstName: string,
  lastName: string
  zip: string,
  city: string,
  street: string,
  phoneNumber: string,
  email: string,
}

export function PersonCustomerForm() {
  const $t = useTranslation('PersonCustomerForm')
  const customerApi = useApiClient(b => new CustomerApiClient(b))
  const context = useContext(ApplicationContext)

  const { customer, coBuyer } = context.application

  // TODO With coCoBuyer field can be calculated based on coBuyer value.
  const [withCoBuyer, setWithCoBuyer] = useState(!!coBuyer)

  const [fetchingCustomer, setFetchingCustomer] = useState(false)
  const [fetchingCoBuyer, setFetchingCoBuyer] = useState(false)

  const [customerError, setCustomerError] = useState<string>()
  const [coBuyerError, setCoBuyerError] = useState<string>()

  const customerSchema =
    createCustomerSchema($t)
  const coBuyerSchema =
    createCoBuyerSchema(
      $t,
      customer.idNumber || ' ')

  const fetchCustomer = (cpr: string) => {
    setFetchingCustomer(true)
    context.setCustomerInformationSource(PersonInformationSource.ExternalService)
    context.setCustomer({
      ...customer,
      idNumber: cpr,
      firstName: '',
      lastName: '',
      zip: '',
      city: '',
      street: '',
      warning: '',
    })

    customerApi.getPerson(cpr)
      .then(x => {
        if(!x.success){
          context.setCustomerInformationSource(PersonInformationSource.Manual)
          if(x.response!.status <= 499){
            x.response!.text().then(y => setCustomerError(y))
          }
          return
        }

        const person =  x.data
        if(!person){
          return
        }

        const { cpr, firstName, lastName, address, warning } = person
        const { zip, city, street } = address

        if(address.hidden){
          context.setCustomerInformationSource(PersonInformationSource.Manual)
        }

        context.setCustomer({
          ...customer,
          idNumber: cpr,
          firstName,
          lastName,
          zip,
          city,
          street,
          warning,
        })
      })
      .finally(() => setFetchingCustomer(false))
  }
  const fetchCoBuyer = (cpr: string) => {
    setFetchingCoBuyer(true)
    context.setCoBuyerInformationSource(PersonInformationSource.ExternalService)
    context.setCoBuyer({
      ...coBuyer || {} as ApplicationCustomer,
      idNumber: cpr,
      firstName: '',
      lastName: '',
      zip: '',
      city: '',
      street: '',
      warning: '',
    })

    customerApi.getPerson(cpr)
      .then(x => {
        if(!x.success){
          context.setCoBuyerInformationSource(PersonInformationSource.Manual)
          if(x.response!.status <= 499){
            x.response!.text().then(y => setCoBuyerError(y))
          }
          return
        }

        const person =  x.data
        if(!person){
          return
        }

        const { cpr, firstName, lastName, address, warning } = person
        const { zip, city, street } = address

        if(address.hidden){
          context.setCoBuyerInformationSource(PersonInformationSource.Manual)
        }

        context.setCoBuyer({
          ...coBuyer || {} as ApplicationCustomer,
          idNumber: cpr,
          firstName,
          lastName,
          zip,
          city,
          street,
          warning,
        })
      })
      .finally(() => setFetchingCoBuyer(false))
  }
  const clearCustomer = () => {
    setCustomerError(undefined)
    context.setCustomerInformationSource(PersonInformationSource.ExternalService)
    context.setCustomer({
      ...customer,
      idNumber: '',
      firstName: '',
      lastName: '',
      zip: '',
      city: '',
      street: '',
      warning: undefined,
    })
  }
  const clearCoBuyer = () => {
    setCoBuyerError(undefined)
    context.setCoBuyerInformationSource(PersonInformationSource.ExternalService)
    context.setCoBuyer({
      ...customer,
      idNumber: '',
      firstName: '',
      lastName: '',
      zip: '',
      city: '',
      street: '',
      email: '',
      phoneNumber: '',
      warning: undefined,
    })
  }
  const enableCoBuyer = () => {
    setWithCoBuyer(true)
    context.setCoBuyer({} as ApplicationCustomer)
  }
  const removeCoBuyer = () => {
    setWithCoBuyer(false)
    context.setCoBuyer(null)
  }
  const updateCustomer = (values: IPersonForm) =>
    context.setCustomer({
      ...customer,
      idNumber: values.idNumber,
      phoneNumber: values.phoneNumber,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      city: values.city,
      zip: values.zip,
    })
  const updateCoBuyer = (values: IPersonForm) => 
    context.setCoBuyer({
      ...coBuyer || {} as ApplicationCustomer,
      idNumber: values.idNumber,
      phoneNumber: values.phoneNumber,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      street: values.street,
      city: values.city,
      zip: values.zip,
    })

  // Enables coBuyer when asynchronous call is made regarding session restore
  useEffect(() => {
    setWithCoBuyer(!!coBuyer)
  }, [coBuyer])

  // Incoming cases do not have all information about customers.
  // Fetch customer and co-buyer information on tab open, when CPR number is known but last name not.
  useEffect(() => {
    if(customer.idNumber && !customer.firstName && !customer.lastName){
      fetchCustomer(customer.idNumber)
    }

    if(coBuyer?.idNumber && !coBuyer.firstName && !coBuyer.lastName){
      fetchCoBuyer(coBuyer.idNumber)
    }
  }, [])

  return (
    <BusyIndicator busy={fetchingCustomer || fetchingCoBuyer}>
      <ErrorMessage
        customerError={customerError}
        coBuyerError={coBuyerError}
      />
      <WarningMessage
        customerWarning={customer.warning}
        coBuyerWarning={coBuyer?.warning || undefined}
      />
      <Grid relaxed stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <PageSectionHeader>
              {$t('Customer')}
            </PageSectionHeader>

            <PersonForm
              applicationCustomer={customer}
              validationSchema={customerSchema}
              informationSource={context.customerInformationSource}
              updatePerson={updateCustomer}
              fetchPerson={fetchCustomer}
              clearPerson={clearCustomer}
            />

            {!withCoBuyer &&
              <div className="text-end">
                <DPButton
                  size="mini"
                  onClick={enableCoBuyer}
                >
                  {$t('Add Co-Buyer')}
                </DPButton>
              </div>
            }
          </Grid.Column>
          {withCoBuyer &&
            <Grid.Column>
              <PageSectionHeader>
                {$t('Co-Buyer')}
              </PageSectionHeader>


              <PersonForm
                applicationCustomer={coBuyer}
                validationSchema={coBuyerSchema}
                informationSource={context.coBuyerInformationSource}
                updatePerson={updateCoBuyer}
                fetchPerson={fetchCoBuyer}
                clearPerson={clearCoBuyer}
              />

              <div className="text-end">
                <DPButton
                  size="mini"
                  onClick={removeCoBuyer}
                >
                  {$t('Remove Co-Buyer')}
                </DPButton>
              </div>
            </Grid.Column>
          }
        </Grid.Row>
      </Grid >
    </BusyIndicator>
  )
}

function PersonForm(props: {
  applicationCustomer: ApplicationCustomer | null | undefined,
  validationSchema: any,
  informationSource: PersonInformationSource,
  updatePerson: (values: IPersonForm) => void,
  fetchPerson: (cpr: string) => void,
  clearPerson: () => void
}){
  const $t = useTranslation('PersonCustomerForm')

  const initialValues : IPersonForm =
    {
      idNumber: props.applicationCustomer?.idNumber,
      firstName: props.applicationCustomer?.firstName ?? '',
      lastName: props.applicationCustomer?.lastName ?? '',
      zip: props.applicationCustomer?.zip ?? '',
      city: props.applicationCustomer?.city ?? '',
      street: props.applicationCustomer?.street ?? '',
      phoneNumber: props.applicationCustomer?.phoneNumber ?? '',
      email: props.applicationCustomer?.email ?? '',
    }

    const initialTouched: FormikTouched<IPersonForm> = { 
      idNumber: true,
      firstName: true,
      lastName: true,
      zip: true,
      city: true,
      street: true,
      phoneNumber: true,
      email: true
    }

  return (
    <Formik
      initialValues={initialValues}
      initialTouched={initialTouched}
      onSubmit={() => { return }}
      validationSchema={props.validationSchema}
      enableReinitialize
    >
      {formikProps => 
        <Form onSubmit={formikProps.handleSubmit}>
          {/* CPR */}
          <DPFormField
            label={$t('CPR number')}
            name="idNumber"
          >
            <DPInputText
              name="idNumber"
              autoComplete="off"
            />
          </DPFormField>

          { props.informationSource === PersonInformationSource.Manual &&
            <>
              {/* First name */}
              <DPFormField
                label={$t('FirstName')}
                name="firstName"
                required
              >
                <DPInputText
                  name="firstName"
                  autoComplete="off"
                />
              </DPFormField>

              {/* Last name */}
              <DPFormField
                label={$t('LastName')}
                name="lastName"
                required
              >
                <DPInputText
                  name="lastName"
                  autoComplete="off"
                />
              </DPFormField>

              {/* Street */}
              <DPFormField
                label={$t('Street')}
                name="street"
                required
              >
                <DPInputText
                  name="street"
                  autoComplete="off"
                />
              </DPFormField>

              {/* City */}
              <DPFormField
                label={$t('City')}
                name="city"
                required
              >
                <DPInputText
                  name="city"
                  autoComplete="off"
                />
              </DPFormField>

              {/* Zip */}
              <DPFormField
                label={$t('Zip')}
                name="zip"
                required
              >
                <DPInputText
                  name="zip"
                  autoComplete="off"
                />
              </DPFormField>
            </>
          }

          { props.informationSource === PersonInformationSource.ExternalService &&
            <>
              {/* Name */}
              <DPFormField
                label={$t('Name')}
                name="name"
              >
                <DPInputTextReadOnly
                  value={`${props.applicationCustomer?.firstName || ''} ${props.applicationCustomer?.lastName || ''}`}
                />
              </DPFormField>

              {/* Address */}
              <DPFormField
                label={$t('Address')}
                name="address"
              >
                <DPInputTextareaReadOnly
                  value={`${props.applicationCustomer?.street || ''}\r\n${props.applicationCustomer?.zip || ''} ${props.applicationCustomer?.city || ''}`}
                  rows={2}
                />
              </DPFormField>
            </>
          }

          {/* Phone number */}
          <DPFormField
            label={$t('Phone')}
            name="phoneNumber"
            required
          >
            <DPInputText
              name="phoneNumber"
              autoComplete="off"
            />
          </DPFormField>
          {/* Email */}
          <DPFormField
            label={$t('E-mail')}
            name="email"
            required
          >
            <DPInputText
              name="email"
              autoComplete="off"
            />
          </DPFormField>

          <AutoSubmit
            formik={formikProps}
            update={props.updatePerson}
          />
          <FetchPerson
            formik={formikProps}
            fetch={props.fetchPerson}
            clear={props.clearPerson}
          />
        </Form>
      }
    </Formik>
  )
}

function AutoSubmit(props: {
  formik: FormikProps<IPersonForm>,
  update: (values: IPersonForm) => void
}){
  const { dirty, values, submitForm } = props.formik

  useEffect(() => {
    if(!dirty){
      return
    }

    props.update(values)
  }, [values])

  return null
}

function FetchPerson(props: { formik: FormikProps<IPersonForm>, fetch: (cpr: string) => void, clear: () => void }){
  const { dirty, values } = props.formik

  useEffect(() => {
    // Do nothing if user doesn't change data.
    if(!dirty){
      return
    }

    // If field is empty, clear the custom customer data.
    if(!values.idNumber){
      props.clear()
      return
    }

    // If 10 characters are typed, fetch user data
    if(values.idNumber.length === 10){
      props.fetch(values.idNumber)
    }
  }, [values.idNumber])

  return null
}

function createCustomerSchema($t: (value: string) => string) {
  return Yup.object().strict(true).shape({
    idNumber: Yup.string().cpr(),
    firstName: Yup.string().label($t('FirstName')),
    lastName: Yup.string().label($t('LastName')),
    street: Yup.string().label($t('StreetName')),
    city: Yup.string().label($t('City')),
    zip: Yup.string().required().label($t('Zip')).zipCode(),
    phoneNumber: Yup.string().phone(),
    email: Yup.string().email(),
  })
}

function createCoBuyerSchema($t: (value: string) => string, customerCpr: string) {
  return Yup.object().strict(true).shape({
    idNumber: Yup.string().cpr().not([customerCpr], $t('Cpr Must Differ')),
    firstName: Yup.string().label($t('FirstName')),
    lastName: Yup.string().label($t('LastName')),
    street: Yup.string().label($t('StreetName')),
    city: Yup.string().label($t('CityName')),
    zip: Yup.string().required().label($t('Zip')).zipCode(),
    phoneNumber: Yup.string().phone(),
    email: Yup.string().email(),
  })
}

