import React from 'react'

interface IProps {
    value: string,
    rows?: number,
}

export function DPInputTextareaReadOnly(props: IProps) {
    return <textarea
        value={props.value}
        rows={props.rows}
        className="dp-input dp-input-textarea-read-only"
        readOnly
    />
}
