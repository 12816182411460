import { CalculatorApiClient } from 'api'
import { ApplicationContext } from 'pages/application/state'
import { AgreementCode, ApplicationType } from 'shared'
import { DPFormField, DPInputDropdown, DPInputNumber, DPInputTextReadOnly, PageSectionHeader } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import React, { useContext, useEffect } from 'react'
import styles from './LoanCalculationComponent.module.css'
import { formatNumber } from 'shared/utils'

type FieldName =
  'objectPrice' |
  'downPayment' |
  'paymentPerMonth' |
  'paymentTerms' |
  'interestType' | 
  'interestRate' |
  'documentFee' |
  'commitmentFee'

function getFields(agreementCode: number): FieldName[] {
  switch(agreementCode){
    case AgreementCode.PurchaseContract100:
      return ['objectPrice', 'downPayment', 'paymentPerMonth', 'paymentTerms', 'interestType', 'interestRate', 'commitmentFee']
    case AgreementCode.PurchaseContractWithoutSalesCommission:
    case AgreementCode.PurchaseContractMotorcycleWithoutSalesCommission:
    case AgreementCode.PurchaseContractCaravanWithoutSalesCommission:
      return ['objectPrice', 'downPayment', 'paymentPerMonth', 'paymentTerms', 'interestType', 'interestRate']
    case AgreementCode.PersonnelLoan:
    case AgreementCode.PersonnelLoanMotorcycle:
    case AgreementCode.PersonnelLoanCamping:
      return ['objectPrice', 'paymentTerms', 'interestType', 'interestRate']
    default:
      return ['objectPrice', 'downPayment', 'paymentPerMonth', 'paymentTerms', 'interestType', 'interestRate', 'documentFee', 'commitmentFee']
  }
}

type FieldDefinitions = {
  [key in FieldName] : () => JSX.Element
}

interface Props {
  applicationType: ApplicationType,
}

export function LoanCalculationComponent(props: Props) {
  const $t = useTranslation('LoanCalculationComponent')
  const session = useSession()
  const context = useContext(ApplicationContext)
  const dealerId = session.currentDealer?.id;
  const agreementCode = context.application.calculation.agreementCode
  const fields = getFields(context.application.calculation.agreementCode)
  const calculatorApiClient = useApiClient(a => new CalculatorApiClient(a))
  const [interestTypes, , getInterestTypes] = useApiCall(calculatorApiClient.getInterestTypes, [])
  const interestRateDisabled = props.applicationType == ApplicationType.Personnel;

  useEffect(() => {
    getInterestTypes(dealerId!, agreementCode)
  }, [dealerId, agreementCode])

  const fieldDefinitions : FieldDefinitions = {

    'objectPrice': () =>
      <DPFormField
        key="objectPrice"
        label={$t('Sales price including lev.')}
        name="objectPrice"
      >
        <DPInputNumber
          name="objectPrice"
          dataQa="calculation_object_price"
          label="kr"
        />
      </DPFormField>,

    'downPayment': () =>
        <DPFormField
          key="downPayment"
          name="downPayment"
          label={$t('Down payment')}
        >
          <DownPaymentInput/>
        </DPFormField>,

    'paymentPerMonth': () =>
      <DPFormField
        key="paymentPerMonth"
        label={$t('Payment per month')}
        name="paymentPerMonth"
      >
        <DPInputNumber
          name="paymentPerMonth"
          precision={2}
          dataQa="calculation_payment_per_month"
          label="kr"
        />
      </DPFormField>,

    'paymentTerms': () =>
      <DPFormField
        key="paymentTerms"
        label={$t('Number of months')}
        name="paymentTerms"
      >
        <DPInputNumber
          name="paymentTerms"
          dataQa="calculation_payment_terms"
        />
      </DPFormField>,

    'interestType': () =>
      <DPFormField
        key="interestType"
        label={$t('Interests type')}
        name="interestType"
      >
        <DPInputDropdown
          name="interestType"
          options={interestTypes}
          disabled={interestTypes.length < 2}
          dataQa="calculation_interest_type"
        />
      </DPFormField>,

    'interestRate': () =>
      <DPFormField
        key="interestRate"
        label={$t('Interest rate')}
        name="interestRate"
      >
        <DPInputNumber
          name="interestRate"
          precision={2}
          dataQa="calculation_interest_rate"
          label="%"
          disabled={interestRateDisabled}
        />
      </DPFormField>,

    'documentFee': () =>
      <DPFormField
        key="documentFee"
        label={$t('Document fee')}
        name="documentFee"
      >
        <DPInputNumber
          name="documentFee"
          dataQa="calculation_document_fee"
          label="kr"
        />
      </DPFormField>,

    'commitmentFee': () =>
      <DPFormField
        key="commitmentFee"
        label={$t('Commission fee')}
        name="commitmentFee"
      >
        <DPInputNumber
          name="commitmentFee"
          dataQa="calculation_commitment_fee"
          label="kr"
        />
      </DPFormField>,
  }

  const fieldComponents =
    fields
      .map(field => fieldDefinitions[field]())

  return <>
    <PageSectionHeader>
      {$t('Loan calculation')}
    </PageSectionHeader>
    {fieldComponents}
  </>
}

function DownPaymentInput() {
  return (
      <div className={styles['downpayment-input']}>

      <div className={styles['downpayment-value']}>
        <DPInputNumber
          name="downPayment"
          label="kr"
          dataQa="calculation_down_payment_amount"
        />
      </div>

      <div className={styles['downpayment-pct']}>
        <DPInputNumber
          name="downPaymentPct"
          precision={2}
          label="%"
          dataQa="calculation_down_payment_percent"
        />
      </div>
    </div>
  )
}