import { OwnPricingPage } from 'pages/administration/ownPricing/OwnPricingPage'
import { UserProfilePage } from 'pages/administration/userProfile'
import { ShowUsersPage } from 'pages/administration/users/showUsers'
import { GenerateContractPage } from 'pages/application'
import CorporateLoanPage from 'pages/application/CorporateLoanPage'
import { KycApplicationPage } from 'pages/application/KycApplicationPage'
import ObjectDeliveredPage from 'pages/application/ObjectDeliveredPage'
import PrivateLoanPage from 'pages/application/PrivateLoanPage'
import { SearchSavedPage } from 'pages/application/SearchSavedPage'
import { SearchSubmittedPage } from 'pages/application/SearchSubmittedPage'
import { SubmittedApplicationPage } from 'pages/application/SubmittedApplicationPage'
import { HomePage } from 'pages/homePage'
import AccessDeniedPage from 'shared/accessDenied/AccessDeniedPage'
import LogInPage from 'shared/authentication/LogInPage'
import LogOutPage from 'shared/authentication/LogOutPage'
import SignInCallbackPage from 'shared/authentication/SignInCallbackPage'
import WelcomePage from 'shared/authentication/WelcomePage'
import { NotImplementedPage } from 'shared/notImplemented'
import { isFeatureEnabled } from '@config'
import { ScrollToTop } from '@framework'
import React, { useMemo } from 'react'
import { Outlet, Route, Routes } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import './App.scss'
import { DealerAdministrationPage, EditDealerPage } from './pages/administration/dealerAdministration'
import {} from './pages/administration/dealerAdministration/EditDealerPage'
import { AddNews, EditNews } from './pages/administration/news'
import EditUserPage from './pages/administration/users/EditUserPage'
import SearchIncomingPage from './pages/application/SearchIncomingPage'
import { RemainingDebtPage } from './pages/dealer'
import NewsDetailsPage from './pages/homePage/NewsDetailsPage'
import { ProtectedRoute, RefreshAlways, routes } from 'routing'
import PageNotFoundPage from './shared/pages/PageNotFoundPage'
import './styles/SpacingUtilities.scss'
import './styles/TextUtilities.css'
import './styles/Variables.css'
import './styles/VisibilityUtilities.css'
import { StatisticsPage } from 'pages/reports'
import { PersonnelLoanPage, PlusLoanPage } from 'pages/application'
import { AuthorizedLayout } from 'shared/layout'
import { ContactPage } from 'pages/ContactPage'
import { ResetPasswordRequestPage } from 'pages/ResetPasswordRequestPage'
import { ResetPasswordPage } from 'pages/ResetPasswordPage'
import { ResetUsernameRequestPage } from 'pages/ResetUsernameRequestPage'
import { ResetUsernamePage } from 'pages/ResetUsernamePage'
import { NewUserRequestPage } from 'pages/NewUserRequestPage'
import { NewUserPage } from 'pages/NewUserPage'
import { MasterDataPage } from 'pages/administration/MasterDataPage'

export default function App() {
    const getBasename = () => {
        // The href value of base tag contains origin.
        // It's necessary to remove origin to get actual base.
        // For when href value of base tag returns https://dealerportal.com/abc/,
        // we want to convert it to /abc (no trailing slash!).
        const baseHref = document.getElementsByTagName('base')[0]?.href
        const origin = document.location.origin
        const path = baseHref.replace(origin, '')
        return path.endsWith('/') ? path.substring(0, path.length - 1) : path
    }

    // TODO PBI #34316: Define basename for react router in configuration.
    const basename = useMemo(getBasename, [])

    return (
        <Router basename={basename}>
            <ScrollToTop />
            <Routes>
                <Route path={routes.welcomePage()} element={<WelcomePage />} />
                <Route path={routes.logIn()} element={<LogInPage />} />
                <Route path={routes.logOut()} element={<LogOutPage />} />
                <Route path={routes.signInCallback()} element={<SignInCallbackPage />} />
                <Route path={routes.accessDenied()} element={<AccessDeniedPage />} />

                {/*
                    The <Outlet /> element renders children routes. This solution ensures,
                    that AuthorizedLayout is not rerender each time route change.
                    When route change, only content of <Outlet /> is replaced.
                    Documentation: https://reactrouter.com/en/main/components/outlet
                */}

                {/*
                    TODO Consider split protected route and conditional route.
                    We have to wrap AuthorizedLayout inside ProtectedRoute to handle situation when unauthenticated user try to open the page.
                */}
                <Route element={<ProtectedRoute children={<Outlet />} />}>
                    <Route element={<AuthorizedLayout children={<Outlet />} />}>
                        <Route path="/home" element={<HomePage />} />
                        <Route path="/news-archive" element={<HomePage archive />} />
                        <Route path="/news/:id" element={<NewsDetailsPage />}/>
                        <Route path="/private-loan" element={<PrivateLoanPage />} />
                        <Route path="/private-loan/:id" element={<PrivateLoanPage />} />
                        {isFeatureEnabled('corporate-loan') &&
                            <>
                                <Route path="/corporate-loan" element={<CorporateLoanPage />} />
                                <Route path="/corporate-loan/:id" element={<CorporateLoanPage />} />
                            </>
                        }
                        {isFeatureEnabled('personnel-loan') &&
                            <>
                                <Route path="/personnel-loan" element={<PersonnelLoanPage />} />
                                <Route path="/personnel-loan/:id" element={<PersonnelLoanPage />} />
                            </>
                        }
                        <Route path="/administration/user-profile" element={<UserProfilePage />} />
                        <Route path="/plus-loan" element={<PlusLoanPage />} />
                        <Route path="/application/search-saved" element={<SearchSavedPage />} />
                        <Route path="/application/search-submitted" element={<SearchSubmittedPage />} />
                        <Route path="/application/search-incoming" element={ <SearchIncomingPage /> } />
                        <Route path="/application/kyc/:id" element={<KycApplicationPage />} />
                        <Route path="/application/submitted/:id" element={<SubmittedApplicationPage />} />
                        <Route path="/application/generate-contract/:referenceId" element={<GenerateContractPage />} />
                        <Route path="/application/object-delivered/:referenceId" element={<ObjectDeliveredPage />} />
                        <Route path="/remaining-debt" element={<RefreshAlways><RemainingDebtPage /></RefreshAlways>} />
                        
                        {isFeatureEnabled('statistics') &&
                            <Route path="/reports/statistics" element={<StatisticsPage />} />
                        }
                        <Route path="/not-implemented" element={<NotImplementedPage />} />
                        <Route path="/contact" element={<ContactPage />} />

                        <Route element={<ProtectedRoute children={<Outlet />} condition={x => x.hasAccessToOwnDealersAdministration} />}>
                            <Route path="/administration/master-data" element={<MasterDataPage />} />
                        </Route>

                        <Route element={<ProtectedRoute children={<Outlet />} condition={x => x.hasAccessToNewsAdministration}/>}>
                            <Route path="/administration/news/add" element={<AddNews />} />
                            <Route path="/administration/news/:id/edit" element={ <EditNews /> } />
                        </Route>

                        <Route element={<ProtectedRoute children={<Outlet />} condition={x => x.hasAccessToUsersAdministration}/>}>
                            <Route path="/administration/users/show-users" element={<ShowUsersPage />} />
                            <Route path="/administration/users/edit-user/:id" element={<EditUserPage />} />
                        </Route>

                        <Route element={<ProtectedRoute children={<Outlet />} condition={x => x.hasAccessToAllDealersAdministration}/> }>
                            <Route path="/administration/dealers/show-dealers" element={<DealerAdministrationPage />} />
                            <Route path="/administration/dealers/edit-dealer/:id" element={<EditDealerPage />} />
                        </Route>

                        <Route element={<ProtectedRoute children={<Outlet />} condition={x => x.hasAccessToOwnPricing}/> }>
                            <Route path="/administration/own-pricing" element={<OwnPricingPage />} />
                        </Route>
                    </Route>
                </Route>

                <Route path={routes.resetPasswordRequest()} element={<ResetPasswordRequestPage />} />
                <Route path={routes.resetPassword()} element={<ResetPasswordPage />} />
                <Route path={routes.resetUsernameRequest()} element={<ResetUsernameRequestPage />} />
                <Route path={routes.resetUsername()} element={<ResetUsernamePage />} />
                <Route path={routes.newUserRequest()} element={<NewUserRequestPage />} />
                <Route path={routes.newUser()} element={<NewUserPage />} />
                <Route path="*" element={<PageNotFoundPage />} />
            </Routes>
        </Router>
    )
}
