import { ISubmittedApplication, SubmittedApplicationApiClient } from 'api/SubmittedApplicationApiClient'
import { IApplicationStatus } from 'pages/application/model/IApplicationStatus'
import { routes } from 'routing'
import { useApiClient, useSession, useTranslation  } from '@hooks'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './SubmittedApplicationPage.module.css'
import { ApplicationType, DPButton } from 'shared'
import { useAuthorization } from '@authentication'
import { config } from '@config'

interface IProps {
    referenceId: string,
    application: ISubmittedApplication,
    applicationStatus: IApplicationStatus,
    generateContract: () => void,
}

export function SubmittedApplicationButtons(props: IProps) {
    const $t = useTranslation('SubmittedApplicationPage')
    const session = useSession()
    const navigate = useNavigate()
    const authorization = useAuthorization();
    const submittedApplicationApi = useApiClient(x => new SubmittedApplicationApiClient(x))
    const [contractUploadLink, setContractUploadLink] = useState<string>();
    const [downloadingXml, setDownloadingXml] = useState(false);

    const {
        referenceId,
        application,
        applicationStatus,
        generateContract
        
    } = props
    const isPrivate = application.type === ApplicationType.Private
    const isLeasing = application.type === ApplicationType.Leasing

    const queueContractGeneration =
        isPrivate ||
        (isLeasing && application.isDealerIntegratorCase && config.queueContractGenerationForLeasingAgreementCodes.indexOf(application?.agreementCode) !== -1);

    const dealerId =
        session.currentDealer!.id;
    const hasCoBuyer =
        application.coBuyerFullName.trim().length > 0;
    const objectDeliveredButtonVisible =
        applicationStatus.canDeliverObject
    const uploadDocumentsForMainApplicantButtonVisible =
        isPrivate
            ? !applicationStatus.hasContractEverBeenGenerated
            : !applicationStatus.canGenerateContract
    const uploadDocumentsForCoBuyerButtonVisible =
        isPrivate
            ? !applicationStatus.hasContractEverBeenGenerated && hasCoBuyer
            : !applicationStatus.canGenerateContract && hasCoBuyer
    const contractUploadButtonVisible =
        isPrivate
            ? applicationStatus.hasContractEverBeenGenerated
            : applicationStatus.canGenerateContract
    const generateContractButtonVisible =
        queueContractGeneration
            ? applicationStatus.canQueueContractGeneration
            : applicationStatus.canGenerateContract;
    const getSubmittedApplicationXmlVisible = authorization.hasAccessToSeeXmlSentToPreViewInSubmittedCases;

    const getSubmittedApplicationXml = async () => { 
        setDownloadingXml(true);
        await submittedApplicationApi.getSubmittedApplicationXml(session.currentDealer!.id, referenceId!)
        setDownloadingXml(false);
    };

    const getLink = async (customer:"mainApplicant"|"cobuyer") => {        
        const response = await submittedApplicationApi.getDocumentUploadLink(session.currentDealer!.id, referenceId)
        if (customer == 'mainApplicant')
            window.open(response?.mainBuyerLink, "_blank");
        else
            window.open(response?.coBuyerLink, "_blank");
    };

    useEffect(() => {
        if(!contractUploadButtonVisible){
            setContractUploadLink(undefined)
            return
        }

        submittedApplicationApi.generateContractUploadLink(dealerId, referenceId)
            .then(x => {
                if(x.success){
                    setContractUploadLink(x.data?.url)
                }
                else{
                    setContractUploadLink(undefined)
                }
            })
    }, [contractUploadButtonVisible])

    return (
        <div className={styles['buttons-container']}>
            <div>
                <DPButton size="mini" onClick={() => navigate(-1)} >{$t('Back')}</DPButton>
            </div>
            <div>
                { objectDeliveredButtonVisible &&
                    <Link
                        to={routes.objectDeliveredPage(referenceId)}
                        className="ui mini button"
                    >
                        {$t('Object delivered')}
                    </Link>
                }
                { uploadDocumentsForMainApplicantButtonVisible &&
                    
                    <DPButton
                        size="mini"
                        onClick={() => getLink('mainApplicant')}
                    >
                        {$t('Upload documents for main applicant')}
                    </DPButton>
                    
                    // <a
                    //     href={docLink.mainBuyerLink}
                    //     className={`ui mini button ${!docLink.mainBuyerLink ? 'disabled' : ''}`}
                    //     target='_blank'
                    // >
                    //     {$t('Upload documents for main applicant')}
                    // </a>
                }
                { getSubmittedApplicationXmlVisible &&
                    <DPButton
                        size="mini"
                        onClick={getSubmittedApplicationXml}
                        disabled={downloadingXml}
                    >
                        {
                            $t('SeeXml')
                        }
                    </DPButton>
                }
                { uploadDocumentsForCoBuyerButtonVisible &&
                    
                    <DPButton
                        size="mini"
                        onClick={() => getLink('cobuyer')}
                    >
                        {$t('Upload documents for co applicant')}
                    </DPButton>
                    
                    // <a
                    //     href={docLink.coBuyerLink}
                    //     className={`ui mini button ${!docLink.coBuyerLink ? 'disabled' : ''}`}
                    //     target='_blank'
                    // >
                    //     {$t('Upload documents for co applicant')}
                    // </a>
                }
                { contractUploadButtonVisible &&
                    <a
                        href={contractUploadLink}
                        className={`ui mini button ${!contractUploadLink ? 'disabled' : ''}`}
                        target='_blank'
                    >
                        {
                            isPrivate
                                ? $t('Upload dealer documents for main applicant')
                                : $t('Upload contract')
                        }
                    </a>
                }
                { generateContractButtonVisible &&
                    <DPButton
                        size="mini"
                        onClick={generateContract}
                    >
                        {
                            queueContractGeneration
                                ? applicationStatus.hasContractEverBeenGenerated
                                    ? $t('Generate new contract')
                                    : $t('Generate contract')
                                : $t('DownloadDocument')
                        }
                    </DPButton>
                }
            </div>
        </div>
    )   
}