import { ApiClient, IGridResult } from '@api'
import { IDropdownOption } from 'model'

export interface IDealer {
    id: number,
    name: string,
    officialName: string,
    dealerNumber: string,
    cvr: string,
    salesClub: boolean,
    merrentePeriod: number | undefined,
    vatOnServicePricePerMonth: boolean,
    brandCode: number
}

export interface IDealerQuery {
    skip: number
    take: number
    dealerName: string | undefined
}

export interface UpdateDealerRequest {
    id: number,
    salesClub: boolean,
    commissionPeriod: number,
    hasServicePrMonthWithoutVat: boolean,
}

export interface DealerMasterData {
    webAddress: string,
    phone: string,
    email: string,
    additionalEmails: string,
}

export class DealerApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getDealersDropdown = () => {
        const url = 'api/dealers/dropdown';
        return this.apiClient.get<Array<IDropdownOption<number>>>(url).then(x => x.data)
    }

    public getDealers = (query: IDealerQuery) => {
        const url = 'api/dealers';
        return this.apiClient.get<IGridResult<IDealer>>(url, query).then(x => x.data)
    }

    public getDealer = (dealerNumber: string) => {
        const url = `api/dealers/${dealerNumber}`;
        return this.apiClient.get<IDealer>(url).then(x => x.data)
    }

    public updateDealer = (request: UpdateDealerRequest) => {
        const url = 'api/dealers';
        return this.apiClient.post(url, request)
    }

    public updateFromPreview(dealerNumber: string){
        const url =  `api/dealers/${dealerNumber}/update-from-preview`;
        return this.apiClient.post(url).then(x => x.data)
    }

    public getMasterData = (dealerId: number) => {
        const url = `api/dealers/${dealerId}/master-data`;
        return this.apiClient.get<DealerMasterData>(url)
    }

    public setMasterData = (dealerId: number, request: DealerMasterData) => {
        const url = `api/dealers/${dealerId}/master-data`;
        return this.apiClient.post(url, request)
    }

    public updatePricingFromPreview = () => {
        const url = `api/dealers/update-pricing-from-preview`
        return this.apiClient.post(url);
    }
}