/**
 * Date tools assumptions 
 * - Server return DateOnly data type as ISO string in format YYYY-mm-dd. For example 2023-07-28.
 * - Date always represents local dates.
 * - Date converted to JS Date object points to a start of the date in local time.
 * - Server return DateTime data type as ISO string in format YYYY-mm-ddTHH-MM-SS(Z?). 'Z' at the end is optional and means UTC time.
 * - Date time is sent either local or UTC time.
 * - Date time can be safety converted to JS Date using new Date(dateTimeIsoStringHere).
 */

function numberToString(value: number, length: number){
    return String(value).padStart(length, '0')
}

/** Returns local date as ISO date string (format YYYY-mm-dd). */
export function currentDateString(){
    return new Date().toISOString().substring(0, 10);
}

/** Converts ISO date string (format YYYY-mm-dd) to local DateTime. */
export function dateStringToLocalDate(value: string){
    // ISO date string is converted to UTC date time
    const utcDate = new Date(value);
    const localDate = new Date(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate());
    return localDate;
}

export function dateTimeStringToLocalDate(value: string){
    return new Date(value);
}

/** Converts DateTime to ISO date string (format YYYY-mm-dd). */
export function localDateToDateString(value: Date){
    // Convert local date (ex. 2023-07-26 00:00:00) to UTC date (ex. 2023-07-26 00:00:00) ignoring time zones.
    const utcDateTicks = Date.UTC(value.getFullYear(), value.getMonth(), value.getDate());
    const utcDate = new Date(utcDateTicks)
    return utcDate.toISOString().substring(0, 10);
}

export function dateToDanishDate(date: string) : string
export function dateToDanishDate(date: Date) : string
export function dateToDanishDate(date: Date | string){
    if(typeof date === 'string'){
        return dateToDanishDate(dateStringToLocalDate(date));
    }

    const year = numberToString(date.getFullYear(), 4)
    const month = numberToString(date.getMonth() + 1, 2)
    const day = numberToString(date.getDate(), 2)
    return `${day}-${month}-${year}`
}

export function localDateToDanishDateTimeString(date: Date){
    const year = numberToString(date.getFullYear(), 4)
    const month = numberToString(date.getMonth() + 1, 2)
    const day = numberToString(date.getDate(), 2)
    const hours = numberToString(date.getHours(), 2)
    const minutes = numberToString(date.getMinutes(), 2)
    return `${day}-${month}-${year} ${hours}:${minutes}`
}

export function addDays(date: string, shift: number): string{
    const localDateTime = dateStringToLocalDate(date)
    localDateTime.setDate(localDateTime.getDate() + shift);
    return localDateToDateString(localDateTime);
}

export function addMonths(date: string, shift: number) {
    const localDateTime = dateStringToLocalDate(date)
    localDateTime.setMonth(localDateTime.getMonth() + shift);
    return localDateToDateString(localDateTime);
}

export function addYears(date: string, shift: number): string{
    const localDateTime = dateStringToLocalDate(date)
    localDateTime.setFullYear(localDateTime.getFullYear() + shift);
    return localDateToDateString(localDateTime);
}

export function startOfMonth(date: string): string{
    const localDateTime = dateStringToLocalDate(date)
    localDateTime.setDate(1);
    return localDateToDateString(localDateTime);
}