import { useSession } from "@hooks/useSession";
import { ReactNode, createContext, useContext, useEffect } from "react";
import { KnownBrandCode } from "shared"

export type ThemeName = "" | "ford" | "nissan" | "hessel24" | "maxus" | "yamaha" | "p-christensen-finans" | "fisker" | "mazda"

export const ThemeContext = createContext<ThemeName | undefined>(undefined);

export default function ThemeProvider(props: { children: ReactNode }){
    const session = useSession()
    // We should probably use window.location.host instead of .href,
    // but using .href allow us to easily test if url contains given phrase.
    // For example we can add query string ?hessel at the end of URL to test it.
    const href = window.location.href;
    const theme = selectTheme(session.currentDealer?.brandCode, href)

    // This side effect should probably be moved to another component.
    // For simplicity I've decided to keep it here now.
    useEffect(() => {
        if(theme){
            document.body.classList.add(theme)
        }
        return () => {
            if(theme){
                document.body.classList.remove(theme)
            }
        }
    }, [theme])

    return (
        <ThemeContext.Provider value={theme}>
            { props.children }
        </ThemeContext.Provider>
    )
}

export function useTheme(){
    const context = useContext(ThemeContext);
    if(context === undefined){
        throw new Error("Cannot find ThemeContext provider.")
    }

    return context;
}

function selectTheme(brandCode: number | undefined, href: string): ThemeName {
  if(href.toLowerCase().indexOf("hessel") != -1){
    return 'hessel24';
  };

  switch(brandCode){
    case KnownBrandCode.Ford:
      return 'ford'
    case KnownBrandCode.Nissan:
      return 'nissan'
    case KnownBrandCode.Hessel:
      return 'hessel24'
    case KnownBrandCode.Maxus:
      return 'maxus'
    case KnownBrandCode.Yamaha:
      return 'yamaha'
    case KnownBrandCode.PChristensenFinans:
      return 'p-christensen-finans'
    case KnownBrandCode.Fisker:
      return 'fisker'
    case KnownBrandCode.Mazda:
      return 'mazda'
    default:
      return ''
  }
}
