import { Field } from 'formik'
import React, { RefObject } from 'react'

interface IProps {
    name: string,
    disabled?: boolean,
    /**
     * Obain reference to inner html element from higher order component.
     * Not used right now, but might become handy in future.
     * This was tested succesfully during work on scrollTo implementation in FormErrorSummaryComponent
     */
    innerRef?: RefObject<HTMLInputElement>,
    autoComplete?: 'off',
}

export function DPInputText(props: IProps) {
    return <Field
        name={props.name}
        disabled={props.disabled}
        innerRef={props.innerRef}
        className="dp-input dp-input-text"
        autoComplete={props.autoComplete}
    />
}
