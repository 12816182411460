import { useAuthorization } from '@authentication';
import { BusyIndicator, DPFormField, DPInputDate, DPTableSearchForm, DescriptionList, DescriptionListItem, PageHeader } from '@framework';
import { useApiClient } from '@hooks/useApiClient';
import { useSession } from '@hooks/useSession';
import { useTranslation } from '@hooks/useTranslation'
import { addYears, dateToDanishDate } from 'shared/dateTools';
import { formatMoney } from 'shared/utils';
import { currentDateString } from 'shared/dateTools';
import { StatisticsApiClient, Statistics, Sale } from 'api';
import { useEffect, useMemo, useState } from 'react';
import { Grid, Icon, Segment } from 'semantic-ui-react';
import { DPButton } from 'shared'
import DPTable from 'infrastructure/framework/DPForms/DPTable';

type SalesBySellerType = {
  [salesPersonNo: string] : Sale[],
}

type ContractName = string

type SearchForm = {
  start: string,
  end: string,
}

export function StatisticsPage(){
  const $t = useTranslation('StatisticsPage');
  const statisticsApi = useApiClient(x => new StatisticsApiClient(x));
  const session = useSession();
  const [statistics, setStatistics] = useState<Statistics>()
  const [expanded, setExpanded] = useState<Set<ContractName>>(new Set<ContractName>())
  const [loading, setLoading] = useState(false);
  const [downloadingCsv, setDownloadingCsv] = useState(false);
  const { hasAccessToAllStatistics } = useAuthorization()
  const end = currentDateString();
  const start = addYears(end, - 1)
  const [searchParams, setSearchParams] = useState<SearchForm>({
    start,
    end,
  })

  useEffect(() => {
    setLoading(true)
    const dealerId = session.currentDealer!.id;
    const start = searchParams.start;
    const end = searchParams.end;
    const request =
      hasAccessToAllStatistics
        ? statisticsApi.getDealerStatistics(dealerId, start, end)
        : statisticsApi.getMyStatistics(dealerId, start, end)
    request
      .then(x => {
        if(x.success){
          setStatistics(x.data);
        }
      })
      .finally(() => setLoading(false))
  }, [searchParams])

  const salesBySellerLookup = useMemo(() => {
    const lookup: SalesBySellerType = {}

    for(const sale of (statistics?.sales || [])){
      if(!lookup[sale.salesPersonNo]){
        lookup[sale.salesPersonNo] = []
      }

      lookup[sale.salesPersonNo].push(sale);
    }

    return lookup
  }, [statistics]);

  const salesPersonNoOrdered = useMemo(() => {
    const salesPersonNos = Object.keys(salesBySellerLookup)
    salesPersonNos.sort((noA, noB) => {
      const salesPersonA = salesBySellerLookup[noA][0].salesPerson
      const salesPersonB = salesBySellerLookup[noB][0].salesPerson
      return salesPersonA.localeCompare(salesPersonB);
    })
    return salesPersonNos
  }, [salesBySellerLookup]);

  const getSubmittedApplicationXml = async () => { 
    setDownloadingCsv(true);

    if (hasAccessToAllStatistics){
      await statisticsApi.getDealerStatisticsReport(session.currentDealer!.id, searchParams.start, searchParams.end)
    }
    else{
      await statisticsApi.getMyStatisticsReport(session.currentDealer!.id, searchParams.start, searchParams.end)
    }

    setDownloadingCsv(false);
  };

  useEffect(() => {
    setExpanded(new Set());
  }, [statistics])

  const sumOfEarnings = useMemo(() => {
    return statistics?.sales.reduce((x, y) => x + y.earnings, 0);
  }, [statistics?.sales])

  const sumOfFinancedAmount = useMemo(() => {
    return statistics?.sales.reduce((x, y) => x + y.financedAmount, 0);
  }, [statistics?.sales])
  

  return (
    <div>
      <PageHeader text={$t('Statistics')} />

      <div className='mb-3'>
        <DPTableSearchForm
          initialValues={searchParams}
          onSubmit={(values: SearchForm) => setSearchParams({...values})}
          column1={
            <DPFormField name='start' label={$t('StartDate')} inline={false}>
              <DPInputDate name='start' />
            </DPFormField>
          }
          column2={
            <DPFormField name='end' label={$t('EndDate')} inline={false}>
              <DPInputDate name='end' />
            </DPFormField>
          }
        />
      </div>

      <BusyIndicator busy={loading}>
      <div style={{ textAlign: 'right' }}>
          <p> 
            <DPButton
              size="mini"
              onClick={getSubmittedApplicationXml}
              disabled={downloadingCsv}
          >
              {
                  $t('SeeCsv')
              }
          </DPButton>
          </p>
          <p>{$t('TotalEarningsInPeriod')}: <b>{formatMoney(sumOfEarnings)}</b></p>
          <p>{$t('TotalLoanAmountInPeriod')}: <b>{formatMoney(sumOfFinancedAmount)}</b></p>
        </div>

        {salesPersonNoOrdered.map(salesPersonNo => (
          <div className='mb-3' key={salesPersonNo}>
            <p><b>{salesBySellerLookup[salesPersonNo][0].salesPerson}</b></p>

            <DPTable>
              <thead>
                <tr>
                  <th style={{ width: '2rem' }}/>
                  <th style={{ width: '10rem' }}>{$t('Date')}</th>
                  <th style={{ width: '10rem' }}>{$t('ContractNumber')}</th>
                  <th>{$t('Product')}</th>
                  <th style={{ width: '12rem', textAlign: 'right' }}>{$t('Earnings')}</th>
                  <th style={{ width: '12rem', textAlign: 'right' }}>{$t('LoanAmount')}</th>
                </tr>
              </thead>

              <tbody>
                {
                  salesBySellerLookup[salesPersonNo].map(sale => (
                    <>
                      <tr key={sale.contractName + '-overview'}>
                        <td
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const copy = new Set(expanded);
                            copy.has(sale.contractName) ? copy.delete(sale.contractName) : copy.add(sale.contractName)
                            setExpanded(copy)
                          }}>
                            {
                              expanded.has(sale.contractName) ? <Icon name='angle down' /> : <Icon name='angle right' />
                            }
                        </td>
                        <td>{dateToDanishDate(sale.date)}</td>
                        <td>{sale.contractName}</td>
                        <td>{sale.productName}</td>
                        <td style={{ textAlign: 'right' }}>{formatMoney(sale.earnings)}</td>
                        <td style={{ textAlign: 'right' }}>{formatMoney(sale.financedAmount)}</td>
                      </tr>
                      { expanded.has(sale.contractName) &&
                        <tr key={sale.contractName + '-details'}>
                          <td colSpan={99}>
                            <Segment>
                              <Grid>
                                <Grid.Row columns={2}>
                                  <Grid.Column>
                                    <DescriptionList>
                                      <DescriptionListItem label={$t('CaseNumber')}>{sale.caseId}</DescriptionListItem>
                                      <DescriptionListItem label={$t('Object')}>{sale.objectModelBrand}</DescriptionListItem>
                                      <DescriptionListItem label={$t('RegNo')}>{sale.objectLicense}</DescriptionListItem>
                                      <DescriptionListItem label={$t('NewUsed')}>{sale.newUsed}</DescriptionListItem>
                                      <DescriptionListItem label={$t('ModelYear')}>{sale.objectYear}</DescriptionListItem>
                                      <DescriptionListItem label={$t('PlateType')}>{sale.plateType}</DescriptionListItem>
                                      <DescriptionListItem label={$t('CustomerType')}>{sale.customerType}</DescriptionListItem>
                                      <DescriptionListItem label={$t('DealerName')}>{sale.dealerName}</DescriptionListItem>
                                      <DescriptionListItem label={$t('Seller')}>{sale.salesPerson}</DescriptionListItem>
                                      <DescriptionListItem label={$t('InternalSeller')}>{sale.internalSalesPerson}</DescriptionListItem>
                                    </DescriptionList>
                                  </Grid.Column>
                                  <Grid.Column>
                                  <DescriptionList>
                                      <DescriptionListItem label={$t('SalesPrice')}>{formatMoney(sale.objectValue)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('LoanAmount')}>{formatMoney(sale.financedAmount)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('LoanTerms')}>{sale.monthlyTerms}</DescriptionListItem>
                                      <DescriptionListItem label={$t('InterestType')}>{sale.interestType}</DescriptionListItem>
                                      <DescriptionListItem label={$t('TerminAmount')}>{formatMoney(sale.terminAmount)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('MoreInterest')}>{formatMoney(sale.moreInterestAmount)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('Subsidy')}>{formatMoney(sale.subsidyAmount)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('EstablishFee')}>{formatMoney(sale.establishFee)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('CommitmentFee')}>{formatMoney(sale.commitmentCommission)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('DocumentFee')}>{formatMoney(sale.documentFee)}</DescriptionListItem>
                                      <DescriptionListItem label={$t('DownPayment')}>{formatMoney(sale.downPayment)}</DescriptionListItem>
                                    </DescriptionList>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>
                            </Segment>
                          </td>
                        </tr>
                      }
                    </>
                  ))
                }
              </tbody>

              <tfoot>
                <tr>
                  <th colSpan={4} />
                  <th style={{ textAlign: 'right' }}>{formatMoney(salesBySellerLookup[salesPersonNo].reduce((sum, x) => sum + x.earnings, 0))}</th>
                  <th style={{ textAlign: 'right' }}>{formatMoney(salesBySellerLookup[salesPersonNo].reduce((sum, x) => sum + x.financedAmount, 0))}</th>
                </tr>
              </tfoot>
            </DPTable>
          </div>
        ))}
      </BusyIndicator>
    </div>
  )
}