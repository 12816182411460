// TODO Use 'setCookies' instead of 'setInfiniteCookie'
const setInfiniteCookie = (key : string , value : string) : void => {
    document.cookie = `${key}=${value};expires=Fri, 31 Dec 9999 23:59:59 GMT;samesite=strict`
}

const readCookie = (key : string) : string | undefined => {
    const cookies =  document.cookie.split('; ').filter(p => p.startsWith(`${key}=`))
    return cookies.length > 0 ? cookies[0].split('=')[1] : undefined
}

const setCookie = (key: string, value: string, expires?: Date) => {
    let cookieToSet = `${key}=${value}`
    if(expires){
        cookieToSet += ';expires=' + expires.toUTCString()
    }

    // Don't worry, it doesn't override all records in cookie property.
    document.cookie = cookieToSet
}

export {
    setInfiniteCookie,
    readCookie,
    setCookie,
}