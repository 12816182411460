import { useTranslation } from '@hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { IFavoriteModel } from './FavoriteModelsApiClient'
import styles from './FavoriteModelsComponent.module.css'
import { FavoriteModelsDropZone } from './FavoriteModelsDropZone'
import { IDropZone, IDropZones } from './Models'

export interface IFavoriteModelsComponentProps {
  loadingFavoriteModels: boolean,
  favoriteModels: IFavoriteModel[],
  saveFavoriteModels: (favoriteModels: IFavoriteModel[]) => void,
  onModelVariantIdSelected: (variantId: number) => void,
}

export function FavoriteModelsComponent(props : IFavoriteModelsComponentProps) {
  const $t = useTranslation('FavoriteModels')

  const [dropZones, setDropZones] = useState<IDropZones>(
    {
      favorites: {
        id: 'favorites',
        modelIds: [],
        title: $t('Favorites models'),
        icon: 'car',
      },
      recent: {
        id: 'recent',
        modelIds: [],
        title: $t('Recent searches'),
        icon: 'search',
      }
    })

  const models = useMemo(() => {
    return Object.assign({}, ...props.favoriteModels.map((x, idx) => ({ [idx]: { ...x, id: idx.toString() } })))
  }, [props.favoriteModels])

  useEffect(() => {
    setDropZones({
      favorites: {
        ...dropZones.favorites,
        modelIds: Object.entries(models).filter(m => (m[1] as IFavoriteModel).isFavorite).map(m => m[0])
      },
      recent: {
        ...dropZones.recent,
        modelIds: Object.entries(models).filter(m => !((m[1] as IFavoriteModel).isFavorite)).map(m => m[0])
      }
    })
  }, [models])

  const handleDragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId &&
      destination.index === source.index) {
      return
    }

    const start = dropZones[source.droppableId]
    const finish = dropZones[destination.droppableId]
    let newDropZones = { ...dropZones }

    if (start === finish) {
      const newModelsIds = [...start.modelIds]
      newModelsIds.splice(source.index, 1)
      newModelsIds.splice(destination.index, 0, draggableId)

      const newZone: IDropZone = {
        ...start,
        modelIds: newModelsIds
      }

      newDropZones = {
        ...dropZones,
        [newZone.id]: newZone
      }
    } else {
      const startModelIds = [...start.modelIds]
      startModelIds.splice(source.index, 1)
      const newStart: IDropZone = {
        ...start,
        modelIds: startModelIds
      }

      const finishModelIds = [...finish.modelIds]
      finishModelIds.splice(destination.index, 0, draggableId)

      const newFinish: IDropZone = {
        ...finish,
        modelIds: finishModelIds
      }

      newDropZones = {
        ...dropZones,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      }
    }

    setDropZones(newDropZones)

    const newFavorites =
      newDropZones.favorites.modelIds.filter(modelId => !!models[modelId]).map<IFavoriteModel>(modelId => ({
        ...models[modelId],
        isFavorite: true
      })).concat(
        newDropZones.recent.modelIds.filter(modelId => !!models[modelId]).map<IFavoriteModel>(modelId => ({
          ...models[modelId],
          isFavorite: false
        }))
      )

      props.saveFavoriteModels(newFavorites)
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div className={styles.container}>
        <FavoriteModelsDropZone
          key={'favorites'}
          dropZone={dropZones.favorites}
          models={models}
          loading={props.loadingFavoriteModels}
          onModelVariantIdSelected={props.onModelVariantIdSelected}
        />
        <FavoriteModelsDropZone
          key={'recent'}
          dropZone={dropZones.recent}
          models={models}
          loading={props.loadingFavoriteModels}
          onModelVariantIdSelected={props.onModelVariantIdSelected}
        />
      </div>
    </DragDropContext>
  )
}

