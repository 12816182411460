import { ApiClient, ApiClientContext } from '@api'
import { useContext, useMemo } from 'react'

export function useApiClient<T>(create: (apiClient: ApiClient) => T) {
  const apiClient = useContext(ApiClientContext)

  if(apiClient === undefined){
    throw new Error('ApiClientContext must be used within a ApiClientProvider.')
  }

  const customClient = useMemo(() => create(apiClient), [apiClient])
  return customClient
}
