import { IUserName, UserApiClient } from 'api'
import { useApiCall } from '@hooks/useApiCall'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect } from 'react'

export default function useSellers(dealerId: number){
    const api = useApiClient(p => new UserApiClient(p))
    const [sellers, , getSellers] = useApiCall(api.getUsersForDealer, [] as IUserName[])

    useEffect(() => {
        getSellers(dealerId)
    }, [dealerId])

    return [sellers]
}