import React from 'react'
import styles from './ClearApplication.module.css'

interface IProps {
  onButtonClick: () => void,
  buttonText: string,
}

export default function ClearApplication({ onButtonClick, buttonText }: IProps){
  return (
    <div className={styles.section}>
      <button
        onClick={onButtonClick}
        className={styles.button}
        type="button"
      >
        {buttonText}
      </button>
    </div>
  )
}