import { ApiClient } from '@api'
import { FileFromResponse } from '@api/models'

export interface Sale {
    id: number;
    date: string;
    dealerNumber: number;
    dealerCvr: string;
    dealerName: string;
    dealerGroup: string;
    customerType: string;
    salesPerson: string;
    salesPersonNo: string;
    residualDept: number | null;
    loanState: number | null;
    internalSalesPerson: string;
    productName: string;
    caseProductName: string;
    caseId: number;
    contractName: string;
    newUsed: string;
    plateType: string;
    interestType: string;
    financedAmount: number;
    earnings: number;
    objectValue: number;
    objectYear: number;
    objectModelBrand: string;
    objectLicense: string;
    documentFee: number;
    adminFee: number;
    establishFee: number;
    moreInterestAmount: number;
    subsidyAmount: number;
    monthlyTerms: number;
    terminAmount: number;
    downPayment: number;
    commitmentCommission: number;
}

export interface Statistics {
    sales: Sale[],
}

export class StatisticsApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getDealerStatistics = (dealerId: number, from: string, to: string) => {
        return this.apiClient.get<Statistics>(`api/dealers/${dealerId}/statistics?from=${from}&to=${to}`)
    }

    public getMyStatistics = (dealerId: number, from: string, to: string) => {
        return this.apiClient.get<Statistics>(`api/dealers/${dealerId}/statistics/my?from=${from}&to=${to}`)
    }

    public getDealerStatisticsReport = (dealerId: number, from: string, to: string) => {
        const url = `api/dealers/${dealerId}/statistics/dealerreport?from=${from}&to=${to}`
        return this.apiClient.get(url)
        .then(x => {
            if(!x.success){
            return
            }
            
            return new FileFromResponse(x.response!).download()
        })
    }

    public getMyStatisticsReport = (dealerId: number, from: string, to: string) => {
        const url = `api/dealers/${dealerId}/statistics/myreport?from=${from}&to=${to}`
        return this.apiClient.get(url)
        .then(x => {
          if(!x.success){
            return
          }
          
          return new FileFromResponse(x.response!).download()
        })
    }
}