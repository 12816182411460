import { CalculationInput } from 'model'
import { AgreementCode } from 'shared'
import { useTranslation } from '@hooks/useTranslation'

interface IProps {
    agreementCode: number
    calculations: CalculationInput
}

export default function useDoNotUseKK15AgreementWarning({ agreementCode, calculations }: IProps){
    const $t = useTranslation('UseDoNotUseKK15AgreementWarning')

    // TODO Consider moving it to backend in case we have more messages like this.
    // I don't think we should keep such logic at frontend side.
    const isKK15Agreement = agreementCode === AgreementCode.PurchaseContract15Years
    const months = (calculations.objectAgeMonths ?? 0) + (calculations.paymentTerms ?? 0)
    const warn = isKK15Agreement && months <= 144

    return warn ? $t('message') : undefined
}