import { ApiClient } from '@api'
import { CalculationInput, CalculationResult, IDropdownOption } from 'model'

export interface IChangedParameterCodes {
  none: number,
  objectPrice: number,
  paymentTerms: number,
  paymentPerMonth: number,
  interestRate: number,
  downPayment: number,
  downPaymentPct: number,
  interestType: number,
  mileage: number,
  firstRegistrationDate: number,
  deliveryDate: number,
  documentFee: number,
  commitmentFee: number,
  agreementCode: number,
  objectAgeMonths: number,
  insurance: number,
  serviceAgreementPrMonth: number,
  safeApplicant: number,
}

export class CalculatorApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getCalculation = (dealerId: number, calculationInput: CalculationInput) => {
    const url = `api/dealers/${dealerId}/calculations`
    return this.apiClient.get<CalculationResult>(url, calculationInput).then(x => x.data)
  }

  public getAgeCategories = (dealerId: number, objectType: number) => {
    const url = `api/dealers/${dealerId}/calculations/ageCategories?objectType=${objectType}`
    return this.apiClient.get<Array<IDropdownOption<number>>>(url).then(x => x.data)
  }

  public getKmPerYearCategories = (dealerId: number) => {
    const url = `api/dealers/${dealerId}/calculations/kmPerYears`
    return this.apiClient.get<Array<IDropdownOption<number>>>(url).then(x => x.data)
  }

  public getServiceGuarantorCategories = (dealerId: number) => {
    const url = `api/dealers/${dealerId}/calculations/serviceGuarantors`
    return this.apiClient.get<Array<IDropdownOption<number>>>(url).then(x => x.data)
  }

  public getInterestTypes = (dealerId: number, agreementCode: number) => {
    const url = `api/dealers/${dealerId}/calculations/interestTypes?agreementCode=${agreementCode}`
    return this.apiClient.get<Array<IDropdownOption<number>>>(url).then(x => x.data)
  }

  public getChangedParameterCodes = (dealerId: number) => {
    const url = `api/dealers/${dealerId}/calculations/changedParameterCodes`
    return this.apiClient.get<IChangedParameterCodes>(url).then(x => x.data)
  }

  public getPaymentPlanPrintout = (dealerId: number, calculationInput: CalculationInput) => {
    const url = `api/dealers/${dealerId}/calculations/payment-plan-printout`
    return this.apiClient.get<CalculationResult>(url, calculationInput)
  }
}