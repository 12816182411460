import { Application } from 'model'
import { useTranslation } from '@hooks'
import { currentDateString } from 'shared/dateTools'
import * as yup from 'yup'
import { ObjectSearchMode } from './state/useSearchObjectMode'
import { useContext } from 'react'
import { ApplicationContext } from './state'

/* The method below has to be a "hook" to use translation inside. */
const useCalculationValidationSchema = () => {
  const $t = useTranslation('ApplicationValidation')

  return yup.object().shape({
    serviceMonths: yup
      .number()
      .nullable()
      .label($t('serviceMonths'))
      .when('paymentTerms', (paymentTerms, schema) => {
        if(paymentTerms) {
          return schema.max(paymentTerms, $t("ServiceMonthsMinimum"))
        }
        
        return schema
      }
    ),
    commitmentFee: yup
      .number()
      .nullable()
      .label($t('commitmentFee'))
      .required(),
    paymentTerms: yup
      .number()
      .nullable()
      .label($t('paymentTerms'))
      .when('serviceMonths', (serviceMonths, schema) => {
        if(serviceMonths) {
          return schema.min(serviceMonths, $t("PaymentTermsMaximum"))
        }

        return schema
      }
    ),
  }, [['paymentTerms', 'serviceMonths']])
}

/* The method below has to be a "hook" to use translation inside. */
const useObjectValidationSchema = (purpose: 'form' | 'summary', searchMode?: ObjectSearchMode) => {
  const $t = useTranslation('ApplicationValidation')
  const context = useContext(ApplicationContext)
  const isBoat = () => [5,16].indexOf(context.application.objectType) > -1
  
  return yup.object().shape({
    registrationNumber: yup
      .string()
      .nullable(true)
      .registrationNumber(),
    vin: yup.string()
      .nullable(true)
      .vin(),
    firstRegistrationDate: yup
      .date()
      .nullable(true)
      .when('$objectType', {
        is: () => isBoat(),
        otherwise: schema => schema.required($t('FirstRegistrationDateIsRequired'))
      }),
    modelYear: yup
      .number()
      .integer()
      .nullable(true)
      .when('$objectType', {
        is: () => isBoat() || searchMode === 'manual',
        then: schema => schema  
          .required($t('ModelYearIsRequired'))
          .max(new Date().getFullYear() +  1, $t('ModelYearMax'))
          .min(new Date().getFullYear() - 50, $t('ModelYearMin')) 
      }),
    deliveryDate: yup.string()
      .minDate(currentDateString())
      .required($t('deliveryDateIsRequired')),
    mileage: yup.number()
      .nullable(true)
      .min(0),
    make: yup.string()
      .nullable(true)
      .when([], {
        is: () => searchMode === 'manual',
        then: schema => schema.max(25, $t('MakeMax25')),
      })
      .required($t('makeIsRequired')),
    model: yup.string()
      .nullable(true)
      .when([], {
        is: () => searchMode === 'manual',
        then: schema => schema.max(25, $t('ModelMax25')),
      })
      .required($t('modelIsRequired')),
    variant: yup.string()
      .nullable(true)
      .when([], {
        is: () => searchMode === 'manual',
        then: schema => schema.max(50, $t('VariantMax50')),
      })
      .required($t('variantIsRequired')),
    // Model variant is presented only on the object form, do not validate
    // it on summary tab validate make, model, variant instead.
    modelVariant:
      purpose === 'form'
        ? yup.string().required($t('modelVariantIsRequired'))
        : yup.string().optional(),
  })
}

/* The method below has to be a "hook" to use translation inside. */
const useCustomerValidationSchema = (application: Application) => {
  const $t = useTranslation('ApplicationValidation')
  const { isCompany } = application.customer;

  return yup.object().shape({
    idNumber: yup.string().nullable(true).required($t('CustomerCouldNotComplete')),
    // Companies doesn't use first name, they use last name only.
    firstName: isCompany
      ? yup.string().nullable(true).label($t('FirstName'))
      : yup.string().required().label($t('FirstName')),
    lastName: yup.string().required().label($t('LastName')),
    street: yup.string().required().label($t('Street')),
    city: yup.string().required().label($t('City')),
    zip: yup.string().required().label($t('Zip')).zipCode(),
    email: yup.string().nullable(true).when('idNumber', {
        is: (x?: string) => !!x,
        then: yup.string().nullable(true).email().required($t('CustomerEmailIsMissing')),
      }),
    phoneNumber: yup.string().nullable(true).when('idNumber', {
        is: (x?: string) => !!x,
        then: yup.string().nullable(true).phone().required($t('CustomerPhoneNumberIsMissing')),
      })
  })
}

/* The method below has to be a "hook" to use translation inside. */
const useCoBuyerValidationSchema = (application: Application) => {
  const $t = useTranslation('ApplicationValidation')
  const isSoleProprietorshipCompany =
    application.customer.isCompany && (application.customer.isSoleProprietorship ?? false)

  const financialInformationSchema =
    isSoleProprietorshipCompany
    ? yup
        .object()
        .required($t('CoBuyerFinancialInformationMissing'))
        .shape({
          monthlyIncome: yup.number().required($t('CoBuyerFinancialInformation.MonthlyIncomeMissing')),
          monthlyIncomeAfterExpensesCode: yup.string().required($t('CoBuyerFinancialInformation.IncomeAfterExpansesMissing')),
          housing: yup.string().required($t('CoBuyerFinancialInformation.HousingMissing')),
          civilStatusCode: yup.string().required($t('CoBuyerFinancialInformation.CivilStateMissing')),
          adults: yup.number().required($t('CoBuyerFinancialInformation.AdultsMissing')),
          children: yup.number().required($t('CoBuyerFinancialInformation.ChildrenMissing'))
        })
    : yup.object().default(null).nullable()
  
  return yup.object()
    .nullable()
    .optional()
    .test({
      test: (obj) => {
        if (!isSoleProprietorshipCompany) {
          return true
        }
        return !!obj
      },
      message: $t('CoBuyerDataIsMissing')
    })
    .shape({
      idNumber: yup
        .string()
        .required($t('CoBuyerCprIsMissing'))
        .not([application.customer.idNumber], $t('Cpr Must Differ')),
      email: yup
        .string()
        .email()
        .when('idNumber', {
          is: (x?: string) => !!x,
          then: yup.string().nullable(true).required($t('CoBuyerEmailIsMissing'))
        }),
      phoneNumber: yup
        .string()
        .phone()
        .when('idNumber', {
          is: (x?: string) => !!x,
          then: yup.string().nullable(true).required($t('CoBuyerPhoneNumberIsMissing'))
        }),
      financialInformation: financialInformationSchema
        // use this validation schema when company customer form situation is sorted out
        // see https://dev.azure.com/scbnordic/Auto%20ART/_boards/board/t/HoneyBadgers/Stories/?workitem=177283 for more details
        // .shape({
        //   monthlyIncome: yup.number().required(),
        //   monthlyIncomeAfterExpensesCode: yup.string().required().oneOf(MonthlyIncomeAfterExpencesCodes.map(x => x.value)),
        //   housing: yup.string().required().oneOf(HousingCodes.map(x => x.value)),
        //   civilStatusCode: yup.string().required().oneOf(CivilStatusCodes.map(x => x.value)),
        //   adults: yup.number().required().oneOf(AdultCodes.map(x => x.value)),
        //   children: yup.number().required().oneOf(ChildrenCodes.map(x => x.value))
        // })
    })
}

export {
  useCalculationValidationSchema,
  useCustomerValidationSchema,
  useCoBuyerValidationSchema,
  useObjectValidationSchema,
}