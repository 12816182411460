import { ErrorToast } from '@framework'
import { useFormikContext } from 'formik'
import { RefObject, useEffect, useState } from 'react'

interface IProps {
  scrollToElement?: RefObject<HTMLElement>
}

function scrollToY(scrollTo?: RefObject<HTMLElement>) {
  if (!scrollTo) {
    return
  }
  const boundingRect= scrollTo.current?.getBoundingClientRect()
  const destY = (boundingRect?.top ?? 0) + window.scrollY

  window.scrollTo({
    top: destY,
    behavior: 'smooth'
  })
}

/**
 * Uses list of errors and isSubmitting state from formik context to display errors toast if necessary
 */
export function FormErrorSummary({
  scrollToElement: scrollTo
}: IProps) {
  const context = useFormikContext()
  const [visible, setVisible] = useState(false)
  const errors = context.errors as {[key: string]: string}
  const hasErrors = Object.keys(errors).length > 0

  useEffect(() => {
    // init toast display only when form is submitted and form field errors are detected
    if (context.isSubmitting && hasErrors) {

      setVisible(true)

      scrollToY(scrollTo)

      setTimeout(() => {
        if(hasErrors) {
          setVisible(false)
        }
      }, 3800)
    }
  }, [context.isSubmitting, hasErrors])

  return (
    <>
      <ErrorToast visible={visible} errors={errors}/>
    </>
  )
}