import { PageHeader } from '@framework'
import { useSession, useTranslation } from '@hooks'
import React from 'react'
import { SearchSubmittedComponent } from './SearchSubmittedComponent'

export function SearchSubmittedPage() {

  const $t = useTranslation('SearchSubmittedPage')
  const session = useSession()

  return (
    <article>
      <PageHeader text={$t('Search submitted')} />

      <SearchSubmittedComponent
        dealerId={session.currentDealer?.id!}
      />
    </article>
  )
}