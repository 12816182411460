import React from 'react'
import classes from './StatusMessages.module.css'

export default function StatusMessages({ messages, dataQa }: { messages: string[], dataQa?: string }) {
  const errorComponents = messages.map((x, index) => <div key={index} className={classes.error}>{x}</div>)
  return (
    <div data-qa={dataQa}>
      { errorComponents.length === 0 && <>OK</>}
      { errorComponents }
    </div>
  )
}