import { ApiClient } from '@api'

export interface IFavoriteModel {
  brandName: string
  dealerId: number
  isFavorite: boolean
  modelName: string
  modelVariantIdExternalReference: number
  modelYear: number
  userId: number
  variantId: number
  variantName: string
  id: string
}

interface ISaveFavoriteModelsRequest {
  dealerId: number
  userId: number
  favoriteList: IFavoriteModel[]
}

interface IAddNewSearchToFavoritesRequest {
  dealerId: number
  userId: number
  objectType: number
  newSearchModel: IFavoriteModel
}

export class FavoriteModelsApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getMy = (dealerId: number, objectType: number) => {
    const url = `api/dealers/${dealerId}/favorite-models/my?objectType=${objectType}`
    return this.apiClient.get<IFavoriteModel[]>(url).then(x => x.data)
  }

  public saveMy = (dealerId: number, favoriteList: IFavoriteModel[]) => {
    const url = `api/dealers/${dealerId}/favorite-models/my`
    return this.apiClient.post<ISaveFavoriteModelsRequest>(url, favoriteList)
  }

  public addNewToMy = (dealerId: number, newSearchModel: IFavoriteModel, objectType: number) => {
    const url = `api/dealers/${dealerId}/favorite-models/my/new?objectType=${objectType}`
    return this.apiClient.post<IAddNewSearchToFavoritesRequest>(url, newSearchModel)
  }
}
