import { AuthenticationContext, IAuthorizationContext, useAuthorization } from "@authentication"
import { ReactNode, useContext } from "react"

interface IProps {
    children: ReactNode,
    condition?: (authorization: IAuthorizationContext) => boolean,
}

export function ProtectedPartial({children, condition}: IProps) {
    const authenticationContext = useContext(AuthenticationContext)
    const authorization = useAuthorization()

    if (!authenticationContext.authenticated) {
        return (<></>)
    }

    if (condition && !condition(authorization)) {
        return (<></>)
    }

    return (<>{children}</>)
}