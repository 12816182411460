export enum AgreementCode {
    /** Købekontrakt 20% */
    PurchaseContract20Percent = 100,
    /** Købekontrakt 100 */
    PurchaseContract100 = 103,
    /** Købekontrakt 15 år */
    PurchaseContract15Years = 109,
    /** Købekontrakt uden stift */
    PurchaseContractWithoutSalesCommission = 130,
    /** Blancolån */
    UnsecuredContract = 140,
    /** Købekontrakt MC uden stift */
    PurchaseContractMotorcycleWithoutSalesCommission = 129,
    /**  Købekontrakt Camping u. stift */
    PurchaseContractCaravanWithoutSalesCommission = 131,
    /**  Personalelån */
    PersonnelLoan = 160,
    /** Personalelån MC */
    PersonnelLoanMotorcycle = 161,
    /** Personalelån Camping */
    PersonnelLoanCamping = 162,
    /** Subleasing FL */
    SubleasingFL = 210,
    /** Subleasing FL - Fkex  */
    SubleasingFLFlex = 211,
    /** Forhandler Leasing  */
    DealerLeasing = 296,
    /** Forhandler Leasing Flex  */
    DealerLeasingFlex = 299,
}