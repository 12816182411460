import { IncomingApplicationApiClient } from 'api'
import { Application } from 'model'
import { useApiClient } from '@hooks'
import { useEffect, useState } from 'react'

export default function useIncomingApplication(
    incomingApplicationId: string | undefined,
    dealerId: number | undefined) {
    const apiClient = useApiClient(x => new IncomingApplicationApiClient(x))
    const [application, setApplication] = useState<Application>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(incomingApplicationId === undefined){
            return
        }

        setLoading(true)
        apiClient.getIncomingApplication(incomingApplicationId, dealerId!)
            .then(x => setApplication(x!))
            .finally(() => setLoading(false))
    }, [])

    return [ application, loading ] as [ Application | undefined, boolean ]
}