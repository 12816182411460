import { useField, useFormikContext } from 'formik'
import React, { ReactNode, useEffect, useState } from 'react'
import { Search } from 'semantic-ui-react'

interface IProps<T> {
    name: string
    titleSelector: (item: T) => string,
    onSearchChange: (search: string) => Promise<T[]>,
    onResultSelect: (item: T) => void,
    noResultsMessage?: ReactNode,
    defaultOpen?: boolean,
    /** Defines min number of character that has to typed to trigger onSearchChange callback. */
    minCharacters?: number,
    readOnly?: boolean,
    dataQa?: string,
}

export function DPInputSearch<T>(props: IProps<T>) {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props.name)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState<T[]>([])

    useEffect(() => {
        if(!props.defaultOpen){
            return
        }

        const value = field.value
        if(typeof value !== 'string'){
            return
        }

        refreshResults(value)
    }, [props.defaultOpen])

    const refreshResults = (value: string) => {
        if(props.minCharacters && value.length < props.minCharacters) {
            setResults([])
            return
        }

        setLoading(true)
        props.onSearchChange(value)
            .then(x => setResults(x))
            .finally(() => setLoading(false))
    }

    return (
        <Search
            name={props.name}
            className="dp-input dp-input-search"
            loading={loading}
            results={results.map(item => {
                return {
                    title: props.titleSelector(item),
                    value: item,
                }
            })}
            onResultSelect={(_, data) => props.onResultSelect(data.result.value as T)}
            onSearchChange={(_, data) => {
                // Returned data.value is never undefined,
                // have no idea why TS definition allows it.
                const value = data.value!

                setFieldValue(props.name, value)
                refreshResults(value)
            }}
            noResultsMessage={props.noResultsMessage}
            defaultOpen={props.defaultOpen}
            // Value cannot be null, check if changing it to empty string doesn't trigger formik change.
            value={field.value || ''}
            readOnly={props.readOnly}
        />
    )
}
