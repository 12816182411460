import { AvailableDocument, SubmittedApplicationApiClient } from 'api'
import { BusyIndicator, DPInputCheckbox, PageSectionHeader } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import { Formik, FormikProps } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { Form } from 'semantic-ui-react'
import styles from './SubmittedApplicationPage.module.css'
import { DPButton } from 'shared'

export interface IProps {
    referenceId: string,
    canDownloadDocuments: boolean | undefined,
    /**
     * It's not perfect solution but when integrator message is changed,
     * then it's signal to refresh list of available documents.
     */
    firstIntegratorMessage: string | undefined,
}

interface IForm {
    selectAll: boolean,
    documentTypeIds: string[]
}

export function SubmittedApplicationDocuments(props: IProps){
    const session = useSession()
    const $t = useTranslation('SubmittedApplicationPage')
    const submittedApplicationApiClient = useApiClient(a => new SubmittedApplicationApiClient(a))
    const [availableDocuments, loadingAvailableDocuments, getAvailableDocuments] = useApiCall(submittedApplicationApiClient.getAvailableDocuments, [])
    const [downloadingDocuments, setIsDownloadingDocuments] = useState(false);
    const busy = loadingAvailableDocuments || downloadingDocuments;

    const downloadDocuments = async ({ documentTypeIds }: IForm) => {
        if (documentTypeIds.length === 0){
            return
        }

        setIsDownloadingDocuments(true)
        const dealerId = session.currentDealer!.id
        await submittedApplicationApiClient.downloadDocuments(dealerId, props.referenceId, documentTypeIds.map(x => Number(x)))
        setIsDownloadingDocuments(false)
    }

    useEffect(() => {
        if (props.canDownloadDocuments) {
            const dealerId = session.currentDealer!.id
            getAvailableDocuments(dealerId, props.referenceId!)
        }
    }, [props.canDownloadDocuments, props.firstIntegratorMessage])

    const groups = useMemo(
        () => {
            const g = Array.from(new Set(availableDocuments.map(x => x.contractPageTypeGroup)))
            g.sort()
            return g.map(group => {
                const documents = availableDocuments.filter(x => x.contractPageTypeGroup === group)
                documents.sort((x, y) => (x.contractPageTypeOrder - y.contractPageTypeOrder))
                return documents
            })
        },
        [availableDocuments])

    return (
        <>
            <PageSectionHeader>
                {$t('Documents Download')}
            </PageSectionHeader>
            <BusyIndicator busy={busy}>
                <Formik
                    initialValues={{
                        selectAll: false,
                        documentTypeIds: []
                    } as IForm}
                    onSubmit={downloadDocuments}
                >
                    {formikProps =>
                        <Form onSubmit={formikProps.handleSubmit}>
                            {groups.length >= 2 &&
                                <DPInputCheckbox name="selectAll" label={$t('selectAll')} />
                            }
                            {groups.length > 0 &&
                                <div className={styles['document-types-container']}>
                                    {
                                        groups.map((group, groupIndex) => {
                                            return (
                                                <div key={groupIndex} className={styles['document-type-container']}>
                                                    {group.map((document, documentIndex) => {
                                                        return (
                                                            <div key={documentIndex}>
                                                                <DPInputCheckbox
                                                                    name="documentTypeIds"
                                                                    value={document.contractPageTypeId.toString()}
                                                                    label={document.contractPageTypeDescription}
                                                                />
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }

                            {!loadingAvailableDocuments && groups.length == 0 &&
                                <div className={styles['no-documents-container']}>Ingen dokumenter</div>
                            }

                            <DPButton
                                type="submit"
                                size="mini"
                                disabled={formikProps.values.documentTypeIds.length === 0}
                            >
                                {$t('Download documents')}
                            </DPButton>

                            <DownloadDocumentsFormBehaviors
                                formik={formikProps}
                                availableDocuments={availableDocuments}
                            />
                        </Form>
                    }
                </Formik>
            </BusyIndicator>
        </> 
    )
}

function DownloadDocumentsFormBehaviors(props: {
    formik: FormikProps<IForm>,
    availableDocuments: AvailableDocument[]
}){
    const selectAll = props.formik.values.selectAll
    useEffect(() => {
        if(selectAll){
            props.formik.setValues({
                selectAll,
                documentTypeIds: props.availableDocuments.map(x => x.contractPageTypeId.toString()),
            })
        }
        else{
            props.formik.setValues({
                selectAll,
                documentTypeIds: [],
            })
        }
    }, [selectAll])

    return null
}