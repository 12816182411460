import { useField, useFormikContext } from 'formik'
import { IDropdownOption } from 'model'
import React from 'react'
import { Dropdown } from 'semantic-ui-react'

interface IProps {
    name: string
    options: Array<IDropdownOption<any>>
    multiple?: boolean
    search?: boolean,
    clearable?: boolean,
    disabled?: boolean,
    placeholder?: string,
    dataQa?: string,
}

export function DPInputDropdown(props: IProps) {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props.name)
    return (
        <Dropdown
            name={props.name}
            value={field.value}
            onChange={(_, { value: newValue }) => setFieldValue(props.name, newValue)}
            onBlur={() => field.onBlur(props.name)}
            selection
            options={props.options}
            multiple={props.multiple}
            search={props.search}
            clearable={props.clearable}
            className="dp-input dp-input-dropdown"
            disabled={props.disabled}
            placeholder={props.placeholder}
            data-qa={props.dataQa}
        />
    )
}
