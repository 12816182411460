import { BrandModelCatalogApiClient } from 'api'
import { ApplicationContext } from 'pages/application/state'
import { useApiClient } from '@hooks/useApiClient'
import React, { useCallback, useContext, useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Icon, SemanticICONS } from 'semantic-ui-react'
import { IFavoriteModel } from './FavoriteModelsApiClient'

interface IProps {
    model: IFavoriteModel
    index: number
    icon: SemanticICONS,
    onClick: () => void,
}

export function FavoriteModelsModel({ model, index, icon, onClick }: IProps) {
    const modelVariant = useMemo(
        () => `${model.brandName} ${model.modelName} ${model.variantName} (${model.modelYear})`,
        [model]
    )

    return (
        <Draggable draggableId={model.id} key={model.id} index={index}>
            {(provided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    onClick={onClick}
                >
                    <div
                        style={{
                            padding: '1px 8px',
                            border: '1px solid lightgrey',
                            margin: '-1px -1px 0 -1px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            transition: 'background-color 0.2s ease',
                            backgroundColor: '#f3f3f3'
                        }}
                    >
                        {modelVariant}
                        <Icon
                            name={icon}
                            style={{ textAlign: 'right', color: 'var(--primary)' }}
                        />
                    </div>
                </div>
            )}
        </Draggable>
    )
}
