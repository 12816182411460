import { DeleteNews } from 'pages/administration/news'
import { ProtectedPartial, routes } from 'routing'
import { useAuthorization } from '@authentication'
import { DateSpan } from '@framework'
import { useTranslation } from '@hooks'
import { useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Icon, Label, Message, Pagination, PaginationProps } from 'semantic-ui-react'
import styles from './HomePageNews.module.css'
import { useNewsFeed } from 'infrastructure/hooks'
import { currentDateString } from 'shared/dateTools';

const PageNumberParamsKey = 'page'

interface Props {
    archive: boolean,
    setIsLoading: (value: boolean) => void
}

export function HomePageNews(props: Props){
    const today = currentDateString()
    const authorization = useAuthorization()
    const $t = useTranslation('HomePage')
    const [searchParams, setSearchParams] = useSearchParams()
    const pageNumber = Number(searchParams.get(PageNumberParamsKey) || 1)
    const { newsFeed, isLoading, refresh } = useNewsFeed(pageNumber, props.archive)

    const onPageChange = (data: PaginationProps) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        const activePage: number = Number(data.activePage || 1)
        setSearchParams({ [PageNumberParamsKey]: activePage.toString() })
    };

    useEffect(() => {
        props.setIsLoading(isLoading);
    }, [isLoading])

    return (
        <>
            {
                (newsFeed.data && newsFeed.data.length > 0) ?
                    <>
                        {newsFeed.data.map((news, idx) => { 
                            // if not in news admin role and news hasn't been published yet then don't display news
                            if (news.isFuture && !authorization.hasAccessToNewsAdministration) {
                                return (
                                    <></>
                                )
                            }

                            return (
                                <div className={styles.item} key={idx}>
                                    <div className={styles.header}>
                                        {news.isFuture ? <Label horizontal>{$t('FutureNews')}</Label>: ''}
                                        <Link to={routes.newsDetailsPage(news.id)} className={styles['title-link']}>
                                            {news.title}
                                        </Link>
                                    </div>
                                    <div className={styles.content}>
                                        {news.newsTeaser}
                                    </div>
                                    <div className={styles.footer}>
                                        <i>
                                            { news.isFuture ? $t('WillBePublishedAt') : $t('PublishedAt')}:{' '}
                                            <DateSpan value={news.publishDate} />
                                        </i>
                                        <ProtectedPartial condition={x => x.hasAccessToNewsAdministration}>
                                            <Link to={routes.editNews(news.id)}>
                                                {$t('Edit')}
                                            </Link>
                                            <DeleteNews
                                                news={news}
                                                navigateCallback={refresh}
                                            />
                                        </ProtectedPartial>
                                    </div>
                                </div>
                            )
                        })}

                        <br />

                        <Pagination
                            size="small"
                            defaultActivePage={pageNumber}
                            totalPages={newsFeed.pageCount}
                            onPageChange={(_, data) => onPageChange(data)}                                
                        />
                    </>
                    :
                    (!isLoading) && // make sure "no news" view is not visible during loading
                        <Message icon>
                            <Icon name="exclamation circle" />
                            <Message.Content className={styles['no-news-message']}>
                                {$t('NoNews')}<br/>
                                {
                                    !props.archive && 
                                    <span>
                                        {$t('ToViewExpiredNews')} <Link to={routes.newsArchive()}>{$t('NewsArchive')}.</Link>
                                    </span>
                                }
                            </Message.Content>
                        </Message>
            }
        </>
    )
}