export function formatNumber(value?: number | null) {
  return value?.toLocaleString('da-DK') ?? ''
}

export function formatMoney(value?: number | null | undefined) {
  if(value === null || value === undefined){
    return ''
  }

  return `kr. ${value.toLocaleString('da-DK')}`;
}