import { useMemo } from 'react'
import { Message } from 'semantic-ui-react'

export function ErrorMessage(props: { customerError?: string, coBuyerError?: string }){
    const errors = useMemo(() => {
      const result = []
  
      if (props.customerError) {
        result.push(props.customerError)
      }
      if (props.coBuyerError) {
        result.push(props.coBuyerError)
      }
  
      return result
    }, [props.customerError, props.coBuyerError])
  
    return errors.length > 0
      ? <Message error list={errors} />
      : null
  }