import { ApiClient } from '@api/ApiClient'
import { FileFromResponse } from '@api/models'

export interface IGetInformationRequest {
    redemptionDate: string,
    isNewFinancing: boolean,
    registrationOrVinNumber: string,
}

export interface IGetInformationResponse {
    object: IGetInformationResponseObject,
    consentStatus: IGetInformationResponseConsentStatus,
    applicationHasCoBuyer: boolean,
    requestDate: string,
    forecastOutstanding: number
}

export interface IGetInformationResponseObject {
    contractId: string
    registrationNumber: string,
    chassisNumber: string,
    make: string,
    model: string
}

export interface IGetInformationResponseConsentStatus {
    answered: boolean,
    created: boolean,
    given: boolean,
    consentUrl: string
}

export interface IRequestConsentRequest {
    redemptionDate: string,
    isNewFinancing: boolean,
    registrationOrVinNumber: string,
    customerEmail: string,
    coBuyerEmail: string,
}

export class RemainingDebtApiClient{
    constructor(protected apiClient: ApiClient) { }

    public getInformation(dealerId: number, request: IGetInformationRequest){
        const url = `api/dealers/${dealerId}/remaining-debt/information`
        return this.apiClient.get<IGetInformationResponse>(url, request)
    }

    public requestConsent(dealerId: number, request: IRequestConsentRequest) {
        const url = `api/dealers/${dealerId}/remaining-debt/request-consent`
        return this.apiClient.post(url, request)
    }

    public generatePdf = async(dealerId: number, request: IGetInformationRequest) => {
        const url = `api/dealers/${dealerId}/remaining-debt/pdf`
        return this.apiClient.get(url, request).then(x => {
          if(!x.success){
            return
          }
    
          return new FileFromResponse(x.response!).download()
        })
        
      }
}