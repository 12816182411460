import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Header, Icon } from 'semantic-ui-react'
import styles from './NotImplemented.module.css'

export function NotImplementedPage() {
  const $t = useTranslation('NotImplementedPage')

  return (
    <article className={styles.page}>
      <Header as="h2" icon>
        <Icon name="wrench" />
        {$t('Not implemented!')}
        <Header.Subheader>
          {$t('We are working hard to deliver this feature :)')}
        </Header.Subheader>
      </Header>
    </article>
  )
}
