import { ApiClient, IGridQuery, IGridResult } from '@api'
import { FileFromResponse } from '@api/models'
import { Application } from 'model'
import { ISession } from '@session'
import { ApplicationType } from 'shared'

export interface ISavedApplicationOverview {
  id: number
  date: string
  name: string
  product: string
  make: string
  model: string
  seller: string
  type: ApplicationType
}

export interface IGetDealerApplicationsRequest extends IGridQuery {
  salesPersonId: number
  customer: string
  from: string
  to: string
}

export interface IGetMyApplicationsRequest extends IGridQuery {
  customer: string
  from: string
  to: string
}

export class SavedApplicationsApiClient {
  constructor(protected apiClient: ApiClient) { }

  public getDealerApplications = async (dealerId: number, request: IGetDealerApplicationsRequest) => {
    const requestObj = {
      ...request,
    }

    const result = await this.apiClient.get<IGridResult<ISavedApplicationOverview>>(`api/dealers/${dealerId}/saved-applications`, requestObj).then(x => x.data)
    return result
  }

  public getMyApplications = async (dealerId: number, request: IGetDealerApplicationsRequest) => {
    const requestObj = {
      ...request,
    }

    const result = await this.apiClient.get<IGridResult<ISavedApplicationOverview>>(`api/dealers/${dealerId}/saved-applications/my`, requestObj).then(x => x.data)
    return result
  }

  public get = (dealerId: number, applicationId: number) => {
    return this.apiClient.get<Application>(`api/dealers/${dealerId}/saved-applications/${applicationId}`).then(x => x.data)
  }

  public save = (dealerId: number, session: ISession, application: Application) => {
    return this.apiClient.post(`api/dealers/${dealerId}/saved-applications`, {
      application,
      seller: {
        id: session.currentUser.id,
        agentNo: session.currentUser.sellerAgentNumber,
        firstName: session.currentUser.firstName,
        lastName: session.currentUser.lastName,
      },
    })
  }

  public delete = (dealerId: number, id: number) => this.apiClient.delete(`api/dealers/${dealerId}/saved-applications/` + id)

}