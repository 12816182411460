import { INews, NewsApiClient } from 'api/NewsApiClient'
import { DeleteNews } from 'pages/administration/news'
import { ProtectedPartial, routes } from 'routing'
import { BusyIndicator, DateSpan, DPFileInput, DPFormField, FormikForm, PageHeader } from '@framework'
import { useApiClient, useSession } from '@hooks'
import { useTranslation } from '@hooks'
import parse from 'html-react-parser'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Item } from 'semantic-ui-react'
import { currentDateString } from 'shared/dateTools'
import DOMPurify from 'dompurify'

const defaultNewsValues: INews = {
    id: 0,
    title: '',
    newsTeaser: '',
    content: '',
    publishDate: currentDateString(),
    expirationDate: undefined,
    isPopup: false,
    createdAt: new Date().toISOString(),
    lastUpdatedDate: new Date().toISOString(),
    createdBy: '',
    updatedBy: '',
    attachments: [],
    isArchived: null,
    isFuture: null,
    isCurrent: null,
}

export default function NewsDetailsPage() {
    const newsApi = useApiClient((a) => new NewsApiClient(a))

    const [news, setNews] = useState(defaultNewsValues as INews)
    const [isLoading, setIsLoading] = useState(false)

    const $t = useTranslation('NewsDetails')
    const { id } = useParams()

    const navigate = useNavigate()
    const session = useSession()

    useEffect(() => {
        if(!news.id){
            return
        }
        newsApi.seen(session.currentUser.id, news.id)
    }, [news])

    useEffect(() => {
        setIsLoading(true)
        newsApi.getNews(id as string).then((data: INews | undefined) => {
            if (data === undefined) {
                setIsLoading(false)
                return
            }
            setNews(data as INews)
            setIsLoading(false)
        })
    }, [])

    const downloadFileCallback = async (fileId: number): Promise<void> => {
        setIsLoading(true)
        const newsId = Number(id);
        await newsApi.getAttachment(newsId, fileId)
            .finally(() => setIsLoading(false))
    }

    const contentSanitized = useMemo(
        () => DOMPurify.sanitize(news.content),
        [news.content]);

    return (
        <BusyIndicator busy={isLoading}>
            <PageHeader text={news.title} />
            <Item.Group>
                <Item style={{ marginBottom: '3rem' }}>
                    <Item.Content>
                        <Item.Description>
                            {parse(`<div class="news-content">${contentSanitized}</div>`)}
                        </Item.Description>
                        </Item.Content>
                </Item>
                { news.attachments.length > 0 && 
                    <Item style={{ marginBottom: '3rem' }}>
                        <Item.Content>
                            <FormikForm
                                initialValues={news}
                                onSubmit={() => { return }}
                            >
                                <h4 className="mb-0">{$t('Attachments')}</h4>
                                <DPFormField name="attachments" inline={false}>
                                    <DPFileInput
                                        downloadFileCallback={downloadFileCallback}
                                        name="attachments"
                                        readonly
                                    />
                                </DPFormField>
                            </FormikForm>
                            </Item.Content>
                    </Item>
                }
                <Item style={{ marginBottom: '3rem' }}>
                    <Item.Content>
                        <Item.Extra>
                            <i>
                                {$t('PublishedAt')}:{' '}
                                <DateSpan value={ news.publishDate }/>
                            </i>
                            <Link to={news.isArchived ? routes.newsArchive() : routes.homePage()}>{$t('Back')}</Link>
                            {news.id !== undefined && news.id !== 0 &&
                                <ProtectedPartial condition={x => x.hasAccessToNewsAdministration}>
                                    <Link to={routes.editNews(news.id)}>
                                        {$t('Edit')}
                                    </Link>
                                    <DeleteNews
                                        news={{ id: news.id, title: news.title }}
                                        navigateCallback={() => navigate(news.isArchived ? routes.newsArchive() : routes.homePage())}
                                    />
                                </ProtectedPartial>
                            }
                        </Item.Extra>
                    </Item.Content>
                </Item>
            </Item.Group>
        </BusyIndicator>
    )
}
