export function toQueryString(obj: any): string {
    const search = new URLSearchParams();

    for(let key in obj){
      const value = obj[key];

      if(value === null || value === undefined || value === ""){
        continue;
      }

      if(typeof value == "number"){
        search.set(key, String(value));
        continue;
      }

      if(typeof value == "boolean"){
        search.set(key, String(value));
        continue;
      }

      if(Array.isArray(value)){
        value.forEach(x => search.append(key, x))
        continue;
      }

      search.set(key, String(value));
    }

    const url = search.toString();
    return url;
  }