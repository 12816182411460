import { useField, useFormikContext } from 'formik'
import { useEffect } from 'react'

interface IProps {
    name: string,
}

export function DPTextCapitalizeBehavior(props: IProps) {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props.name)

    useEffect(() => {
        const current = field.value
        if(typeof(current) !== 'string'){
            console.warn('')
            return
        }

        const capitalized = current?.toUpperCase()
        if(capitalized === current){
            return
        }
        
        setFieldValue(props.name, capitalized)
    }, [field.value])

    return null
}
