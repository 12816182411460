import { CalculatorApiClient } from 'api'
import { useApiCall, useApiClient } from '@hooks'
import { useEffect } from 'react'

export default function useChangedParameterCodes(dealerId: number | undefined) {
    const calculatorApiClient = useApiClient((api) => new CalculatorApiClient(api))
    const [changedParameterCodes, , getChangedParameterCodes] = useApiCall(calculatorApiClient.getChangedParameterCodes)

    useEffect(() => {
        if(dealerId === undefined){
            return
        }

        getChangedParameterCodes(dealerId)
    }, [dealerId])

    return { changedParameterCodes }
}