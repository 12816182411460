import { useIncomingApplicationCounter } from "./useIncomingApplicationCounter"

export function IncomingApplicationCounter() {
    const { count } = useIncomingApplicationCounter()

    if(!count){
        return null
    }

    return <div style={{ paddingLeft: "0.25rem"}}>({count})</div>
}