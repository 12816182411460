import { ApplicationApiClient, IncomingApplicationApiClient } from 'api'
import { SavedApplicationsApiClient } from 'api/SavedApplicationsApiClient'
import { routes } from 'routing'
import { Agreement, Application } from 'model'
import { BusyIndicator, PageHeader } from '@framework'
import { useApiCall, useApiClient, useSession, useTranslation } from '@hooks'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Divider, Menu, Message, Tab } from 'semantic-ui-react'
import { CalculatorTab, CustomerTab, ObjectTab, SummaryTab, useApplicationTimestamp } from '.'
import { ApplicationButtons } from './components'
import { FavoriteModelsApiClient } from './components/object/components/favoriteModels/FavoriteModelsApiClient'
import useAgreements from './dataProviders/useAgreements'
import useChangedParameterCodes from './dataProviders/useChangedParameterCodes'
import useDefaultApplication from './dataProviders/useDefaultApplication'
import useIncomingApplication from './dataProviders/useIncomingApplication'
import useAvailableObjectTypes from './dataProviders/useAvailableObjectTypes'
import useSavedApplication from './dataProviders/useSavedApplication'
import { ApplicationContext, ApplicationContextProvider, restoreApplicationFromSessionStorage, storeApplicationInSessionStorage } from './state'
import useSearchObjectMode from './state/useSearchObjectMode'
import { ApplicationType } from 'shared'
import { useHotkeys } from 'react-hotkeys-hook'
import { config } from '@config'
import { useIncomingApplicationCounter } from 'shared/incomingApplicationCounter'

export type ApplicationTab = 'object' | 'calculation' | 'customer' | 'submission'
const orderedApplicationTabs: ApplicationTab[] = ['object', 'calculation', 'customer', 'submission']

const getTabIndex = (tab: ApplicationTab) => orderedApplicationTabs.indexOf(tab)
const getTabName = (index: number): ApplicationTab => orderedApplicationTabs[index] ?? 'object'

interface IApplicationPageProps {
    type: ApplicationType
}

export function ApplicationPage({ type }: IApplicationPageProps) {
    const session = useSession()
    const location = useLocation()
    const { id } = useParams()
    const params = new URLSearchParams(location.search)
    const incomingApplicationId = params.get('incomingApplicationId') || undefined
    const savedApplicationId = isNaN(Number(id)) ? undefined : Number(id)
    const dealerId = session.currentDealer?.id
    const applicationTimestamp = useApplicationTimestamp()
    const { changedParameterCodes } = useChangedParameterCodes(dealerId)
    const { agreements, isLoadingAgreements } = useAgreements(dealerId, type)
    const [ incomingApplication, isLoadingIncomingApplication ] = useIncomingApplication(incomingApplicationId, dealerId)
    const [ savedApplication, isLoadingSavedApplication ] = useSavedApplication(savedApplicationId, dealerId)
    const [ defaultApplication, isLoadingDefaultApplication] = useDefaultApplication(dealerId!, type)
    
    // It's necessary to have application model before creation of context provider.
    // In other case unnecessary recalculations are triggered when we update
    // default application model to match external or saved application model.
    const application =
        (() => {
            if(incomingApplicationId !== undefined){
                return incomingApplication;
            }

            if(savedApplicationId !== undefined){
                return savedApplication;
            }
            
            const restoredApplication = restoreApplicationFromSessionStorage(applicationTimestamp);
            if(restoredApplication !== null){
                return restoredApplication
            }

            return defaultApplication;
        })();

    const isLoading =
        isLoadingAgreements ||
        isLoadingIncomingApplication ||
        isLoadingSavedApplication ||
        isLoadingDefaultApplication

    return (
        <article key={applicationTimestamp}>
            <BusyIndicator busy={isLoading}>
            {defaultApplication !== undefined && application !== undefined &&
                <ApplicationContextProvider
                    changedParameterCodes={changedParameterCodes}
                    application={application}
                    defaultApplication={defaultApplication}
                >
                    <ApplicationPageView
                        incomingApplicationId={incomingApplicationId}
                        savedApplicationId={savedApplicationId}
                        agreements={agreements}
                        applicationTimestamp={applicationTimestamp}
                        applicationType={type}
                    />
                </ApplicationContextProvider>
            }
            </BusyIndicator>
        </article>
    )
}

interface IApplicationPageViewProps {
    incomingApplicationId: string | undefined,
    savedApplicationId: number | undefined,
    agreements: Agreement[],
    applicationTimestamp: string,
    applicationType: ApplicationType,
}

function ApplicationPageView(props: IApplicationPageViewProps) {
    const context = useContext(ApplicationContext)
    const { application, isLoading: isLoadingContext, calculationResult } = context
    const $t = useTranslation('ApplicationPage')
    const location = useLocation()
    const session = useSession()
    const applicationApiClient = useApiClient((a) => new ApplicationApiClient(a))
    const savedApplicationsApiClient = useApiClient(x => new SavedApplicationsApiClient(x))
    const incomingApplicationsApiClient = useApiClient(x => new IncomingApplicationApiClient(x));
    const [isValid, setIsValid] = useState<boolean | undefined>()
    const [positiveMessages, setPositiveMessages] = useState<string[]>([])
    const params = new URLSearchParams(location.search)
    const favoriteModelsApi = useApiClient(b => new FavoriteModelsApiClient(b))
    const [favoriteModels, loadingFavoriteModels , getFavoriteModels] = useApiCall(favoriteModelsApi.getMy, [])
    const incomingApplicationCounter = useIncomingApplicationCounter()
    const dealerId = session.currentDealer?.id
    const objectTypes = useAvailableObjectTypes(props.agreements)
    const isIncomingApplication = props.incomingApplicationId !== undefined
    const isSavedApplication = props.savedApplicationId !== undefined

    const objectSearchMode =
        useSearchObjectMode(
            context.formConfiguration.hasBrandCatalogSource,
            application.object.variantId,
            application.object.variant,
            isIncomingApplication)
            
    const [ isSubmitting, setIsSubmitting ] = useState(false)

    const headerText = useMemo(() => {
        switch(application.type){
            case ApplicationType.Private:
                return $t('PrivateFinancing')
            case ApplicationType.Corporate:
                return $t('BusinessFinancing')
            case ApplicationType.Personnel:
                return $t('PersonnelLoan')
        }
    }, [application.type])
    const [isLoadingBrandModel, setIsLoadingBrandModel ] = useState(false)
    const [allowToProceed, setAllowToProceed] = useState(false);

    const isLoading =
        isLoadingContext ||
        isLoadingBrandModel

    const navigate = useNavigate()
    const tab = params.get('tab') as ApplicationTab
    const tabIndex = getTabIndex(tab)
    const setTabIndex = (index: number) => {
        switch(application.type){
            case ApplicationType.Private:
                navigate(routes.privateApplication({ tab: getTabName(index), timestamp: props.applicationTimestamp, incomingApplicationId: props.incomingApplicationId, applicationId: props.savedApplicationId }))
                return
            case ApplicationType.Corporate:
                navigate(routes.corporateApplication({ tab: getTabName(index), timestamp: props.applicationTimestamp, applicationId: props.savedApplicationId }))
                return
            case ApplicationType.Personnel:
                navigate(routes.personnelApplication({ tab: getTabName(index), timestamp: props.applicationTimestamp, applicationId: props.savedApplicationId }))
                return
        }
    }

    // Clear information about selected variant id, when search mode is changed to manual.
    useEffect(() => {
        if(objectSearchMode.searchMode === 'automatic'){
            return
        }

        context.setObject({
            ...context.application.object,
            variantId: null,
        })
    }, [objectSearchMode.searchMode])

    // When data are loaded and it's not context restore phase,
    // auto-save the application each time it's changed.
    useEffect(() => {
        if(isLoading){
            return
        }
        storeApplicationInSessionStorage(props.applicationTimestamp, application);
    }, [application, isLoading])


    useEffect(() => {
        let enabled: boolean = false
        const carSelected = application.object.model !== null
        const objectAgeMonthsSelected = Number.isInteger(application.calculation.objectAgeMonths)

        if(tab === 'object'){
            enabled = true
        }
        if(tab === 'calculation'){
            if(calculationResult && calculationResult?.validationErrors.length > 0){
                enabled = false
            }
            else if(carSelected || objectAgeMonthsSelected){
                enabled = true
            }           
            else {
                enabled = false
            }
        }
        if(tab === 'customer'){
            if(application.type === ApplicationType.Corporate) {
                if(application.customer.idNumber){
                    enabled = true
                }
                else {
                    enabled = false
                }
            }
            else {
                if((carSelected || objectAgeMonthsSelected) 
                    && application.customer.idNumber 
                    && application.customer.firstName
                    && application.customer.lastName
                    ) {
                        enabled = true
                    }
                    else {
                        enabled = false
                    }
            }
        }
        if(tab === 'submission'){
            enabled = true          
        }
        
        setAllowToProceed(enabled)
    }, [application, tabIndex])

    useEffect(() => {
        getFavoriteModels(session.currentDealer!.id, application.objectType)
    }, [context.application.objectType])
    
    const saveApplication = async (application: Application) => {
        const response = await savedApplicationsApiClient.save(dealerId!, session, application)
        if (response.success) {
            setPositiveMessages([$t('Application was saved')])
            setTimeout(() => setPositiveMessages([]), 1000 * 10)
        }
    }

    const submitApplication = async () => {
        setIsSubmitting(true)
        try{
            const response = await applicationApiClient.submitApplication({
                dealerId: dealerId!,
                brandCode: session.currentDealer?.brandCode!,
                incomingApplicationId: props.incomingApplicationId,
                application,
                configuration: { retries: 3 }
            })
            if (!response.success) {
                return
            }

            if(props.incomingApplicationId){
                // Fire and forget without checking result. Even if it fails, we don't want to break a submission process.
                await incomingApplicationsApiClient.moveToStateDone(dealerId!, props.incomingApplicationId)
                incomingApplicationCounter.refresh();
            }

            switch(application.type){
                case ApplicationType.Corporate:
                    navigate(routes.applicationSubmittedApplication(response.data!.externalReference))
                    return
                case ApplicationType.Private:
                case ApplicationType.Personnel:
                    navigate(routes.applicationKycApplication(response.data!.externalReference))
                    return
            }
        }
        finally{
            setIsSubmitting(false)
        }
    }

    const clearApplication = () => {
        const timestamp = Date.now().toString();
        const route = (() => {
            switch(props.applicationType){
                case ApplicationType.Private:
                    return routes.privateApplication({ tab: 'object', timestamp: timestamp });
                case ApplicationType.Corporate:
                    return routes.corporateApplication({ tab: 'object', timestamp: timestamp });
                case ApplicationType.Personnel:
                    return routes.personnelApplication({ tab: 'object', timestamp: timestamp });
                default:
                    throw new Error(`Cannot clear application of type: ${props.applicationType}`)
            }
        })();
        navigate(route);
    };

    const isTabEnabled = (tab: ApplicationTab): boolean => {
        const carSelected = application.object.model !== null
        const objectAgeMonthsSelected = Number.isInteger(application.calculation.objectAgeMonths)
        const hasCalculationErrors = calculationResult && calculationResult.validationErrors.length > 0
        
        if(['object', 'calculation'].includes(tab)){
            return true
        }
        if(tab === 'customer'){
            if((carSelected || objectAgeMonthsSelected) && !hasCalculationErrors ){
                return true
            }
            return false
        }
        if(tab === 'submission'){
            if(application.type === ApplicationType.Corporate) {
                if(application.customer.idNumber){
                    return true
                }
            } else {
                if((carSelected || objectAgeMonthsSelected) 
                    && application.customer.idNumber 
                    && application.customer.firstName
                    && application.customer.lastName
                    ) {
                        return true
                    }
            }
            
            return false
        }
        
        return true
    }

    const panes = [
        {
            menuItem: (
                <Menu.Item key="ObjectComponent">{$t('Object and accessories')}</Menu.Item>
            ),
            render: () => (
                <Tab.Pane key="ObjectComponent">
                    <BusyIndicator busy={isLoading}>
                        <ObjectTab
                            objectTypes={objectTypes}
                            isIncomingApplication={isIncomingApplication}
                            favoriteModels={favoriteModels}
                            loadingFavoriteModels={loadingFavoriteModels}
                            saveFavoriteModels={favoriteModels=> favoriteModelsApi.saveMy(session.currentDealer!.id, favoriteModels)}
                            getFavoriteModels={() => getFavoriteModels(session.currentDealer!.id, application.objectType)}
                            searchMode={objectSearchMode.searchMode}
                            setSearchMode={objectSearchMode.setSearchMode}
                            searchedBy={objectSearchMode.searchedBy}
                            setSearchedBy={objectSearchMode.setSearchedBy}
                            setIsLoadingBrandModel={setIsLoadingBrandModel}
                            agreements={props.agreements}
                            clearApplication={clearApplication}
                        />
                    </BusyIndicator>
                </Tab.Pane>
            )
        },
        {
            menuItem: <Menu.Item key="Calculation">{$t('Calculation')}</Menu.Item>,
            render: () => (
                <Tab.Pane key="Calculation">
                    <BusyIndicator busy={isLoading}>
                        <CalculatorTab
                            agreements={props.agreements}
                            objectTypes={objectTypes}
                            applicationType={props.applicationType}
                            allowToProceed={allowToProceed}
                            setAllowToProceed={setAllowToProceed}
                        />
                    </BusyIndicator>
                </Tab.Pane>
            )
        },
        {
            menuItem: <Menu.Item key="Customer" disabled={!isTabEnabled('customer')}>{$t('Customer')}</Menu.Item>,
            render: () => (
                <Tab.Pane key="Customer">
                    <BusyIndicator busy={isLoading}>
                        <CustomerTab applicationType={application.type} />
                    </BusyIndicator>
                </Tab.Pane>
            )
        },
        {
            menuItem: <Menu.Item key="Submitting" disabled={!isTabEnabled('submission')}>{$t('Finish application')}</Menu.Item>,
            render: () => (
                <Tab.Pane key="Submitting">
                    <BusyIndicator busy={isLoading}>
                        <SummaryTab
                            agreements={props.agreements}
                            objectTypes={objectTypes}
                            setIsValid={setIsValid}
                            isSubmitting={isSubmitting}
                            searchMode={objectSearchMode.searchMode}
                        />
                    </BusyIndicator>
                </Tab.Pane>
            )
        }
    ]

    return (
        <>
            <PageHeader text={headerText} />
            {
                positiveMessages.length > 0 &&
                <Message
                    positive
                    list={positiveMessages}
                    onDismiss={() => setPositiveMessages([])}
                />
            }
            <Tab
                menu={{ secondary: true, pointing: true }}
                renderActiveOnly={true}
                activeIndex={tabIndex}
                onTabChange={(e, d) => setTabIndex(d.activeIndex as number)}
                panes={panes}
            />
            <Divider />
            <ApplicationButtons
                tab={tab}
                allowToProceed={allowToProceed}
                isSavedApplication ={isSavedApplication}
                submitEnabled={isValid === true}
                applicationType={application.type}
                onPrevious={() => setTabIndex(tabIndex - 1)}
                onNext={() => setTabIndex(tabIndex + 1)}
                onSave={() => saveApplication(application)}
                onSubmit={() => submitApplication()}
            />

            <ApplicationDebugger />
        </>
    )
}

/**
 * Application debugger component that allows to peek current application state.
 * To toggle debugger press SHIFT+D.
 * It's disabled on production environment.
 * */
function ApplicationDebugger(){
    const enabled = !(config.environment ?? "").toLowerCase().startsWith("prod");
    const context = useContext(ApplicationContext);
    const [show, setShow] = useState(false);
    useHotkeys('shift+d', () => setShow(x => !x));
    return (show && enabled) ? <pre>{JSON.stringify(context.application, null, 2)}</pre> : null
}
