import { BusyIndicator } from '@framework'
import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { Icon } from 'semantic-ui-react'
import { IFavoriteModel } from './FavoriteModelsApiClient'
import styles from './FavoriteModelsDropZone.module.css'
import { FavoriteModelsModel } from './FavoriteModelsModel'
import { IDropZone } from './Models'

interface IProps {
  dropZone: IDropZone
  models: {
    [key: string]: IFavoriteModel
  },
  loading: boolean,
  onModelVariantIdSelected: (variantId: number) => void,
}

export function FavoriteModelsDropZone({ dropZone, models, loading, onModelVariantIdSelected }: IProps) {
  return (
    <>
      <div className={styles.header}>
        <Icon name={dropZone.icon} className={styles.icon} />
        {dropZone.title}
      </div>
      <BusyIndicator busy={loading}>
        <Droppable droppableId={dropZone.id} key={dropZone.id} >
          {(provided) => (
            <div
              ref={provided.innerRef}
              className={styles.body}
              {...provided.droppableProps}
            >
              {dropZone.modelIds.filter(modelId => !!models[modelId]).map((modelId, idx) => (
                <FavoriteModelsModel
                  key={idx}
                  model={models[modelId]}
                  index={idx}
                  icon={dropZone.icon}
                  onClick={() => onModelVariantIdSelected(models[modelId].modelVariantIdExternalReference)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </ BusyIndicator>
    </>
  )
}