import { ApplicationApiClient } from 'api'
import { useTranslation } from '@hooks'
import { useApiCall } from '@hooks/useApiCall'
import { useApiClient } from '@hooks/useApiClient'
import { useEffect } from 'react'
import { IDropdownOption } from 'model'

export default function useStatuses(dealerId: number){
    const api = useApiClient(x => new ApplicationApiClient(x))
    const [statuses, , getStatuses] = useApiCall(api.getStatuses, [] as Array<IDropdownOption<string>>)
    const $t = useTranslation('SearchSubmittedComponent')
    
    useEffect(() => {
        getStatuses(dealerId)
    }, [])

    useEffect(() => {
        statuses.forEach(st => st.text = $t(st.text))
    }, [statuses])

    return [statuses]
}