import { ApiClient } from '@api'

export interface IUserPermissions {
  canSubmitApplication: boolean,
  hasAccessToOwnSubmittedCases: boolean,
  hasAccessToAllSubmittedCases: boolean,
  hasAccessToOwnPendingCases: boolean,
  hasAccessToAllPendingCases: boolean,
  hasAccessToOwnSavedCases: boolean,
  hasAccessToAllSavedCases: boolean,
  hasAccessToOwnPricing: boolean,
  hasAccessToRemainingDebt: boolean,
  hasAccessToUsersAdministration: boolean,
  hasAccessToOwnDealersAdministration: boolean,
  hasAccessToAllDealersAdministration: boolean,
  hasAccessToNewsAdministration: boolean,
  hasAccessToOwnStatistics: boolean,
  hasAccessToAllStatistics: boolean,
  hasAccessToSeeXmlSentToPreViewInSubmittedCases: boolean,
}

export class UserPermissionsApiClient {
  constructor(protected apiClient: ApiClient) { }

  public get = async (dealerId: number) => {
    const url = `api/dealers/${dealerId}/user-permissions`
    return this.apiClient.get<IUserPermissions>(url).then(x => x.data)
  }
}
