import { useTranslation } from '@hooks/useTranslation'
import parse from 'html-react-parser'
import React, { ReactNode } from 'react'
import { Header, Modal } from 'semantic-ui-react'
import { DPButton } from 'shared'

interface IInfoModalProps {
    title: string | undefined
    message: ReactNode
    buttonContent?: ReactNode
    onClose(): void
}

export function InfoModal({ title, message, buttonContent, onClose }: IInfoModalProps) {
    const $t = useTranslation('Modal')
    return (<Modal open centered size="small" dimmer="inverted" onClose={onClose}>
        <Header content={title} />
        <Modal.Content>
            <p style={{'overflowY': 'auto', 'maxHeight': '60vh'}}>
                {parse(`<div class="modal-content">${message}</div>`)}
            </p>
        </Modal.Content>
        <Modal.Actions>
            <DPButton onClick={onClose}>
                { buttonContent || $t('Close') }
            </DPButton>
        </Modal.Actions>
    </Modal>)
}
