import { FileModel } from '@api/models'
import { INews, NewsApiClient } from 'api/NewsApiClient'
import { routes } from 'routing'
import { useApiClient } from '@hooks/useApiClient'
import { useTranslation } from '@hooks/useTranslation'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NewsForm } from './NewsForm'
import { currentDateString } from 'shared/dateTools'

const emptyNewsEdit = {
  id: 0,
  title: '',
  newsTeaser: '',
  content: '',
  publishDate: currentDateString(),
  isPopup: false,
  attachments: [] as FileModel[]
} as INews

export function AddNews() {
  const $t = useTranslation('News')
  const navigate = useNavigate()
  const newsApiClient = useApiClient((x) => new NewsApiClient(x))
  const [isBusy, setIsBusy] = useState(false)
  const [initialValues] = useState<INews>(emptyNewsEdit)

  const add = (form: INews) => {
    setIsBusy(true)
    newsApiClient
        .addNews(form)
        .then((response) => {
          if (!response.success) {
              return
          }

          navigate(routes.homePage())
        })
        .finally(() => setIsBusy(false))
  }

  return (
    <NewsForm
      headerText={$t('AddNews')}
      initialValues={initialValues}
      isBusy={isBusy}
      submit={add}
      submitText={$t('Add')}
    />
  )
}