import { useTranslation } from '@hooks'
import React from 'react'
import styles from './ApplicationButtons.module.css'
import { ApplicationType, DPButton } from 'shared'
import { ApplicationTab } from '..'

interface IButtonBarComponentProps {
    tab: ApplicationTab,
    allowToProceed: boolean,
    isSavedApplication: boolean,
    submitEnabled: boolean,
    applicationType: ApplicationType,
    onPrevious: () => void,
    onNext: () => void,
    onSave: () => void,
    onSubmit: () => void,
}

export function ApplicationButtons(props: IButtonBarComponentProps) {
    const $t = useTranslation('ButtonBarComponent')
    const key = `tab-${props.tab}`;
    const showSubmit = props.tab === 'submission';
    const previousEnabled = props.tab !== 'object';
    const nextEnabled = props.allowToProceed && props.tab !== 'submission';

    return (
        <div
            /*
                Ensure that navigation component is re-rendered each time tab change.
                It fixes problem with keeping focus on next / previous buttons after changing a tab.
            */
            key={key}
            className={styles['buttons-toolbar']}
        >
            <div className={styles.buttons}>
                <DPButton onClick={props.onSave} size="mini">
                    {$t('Save')}
                </DPButton>

                {showSubmit && (
                    <DPButton
                        onClick={props.onSubmit}
                        size="mini"
                        disabled={!props.submitEnabled}
                    >
                        {props.applicationType === ApplicationType.Corporate ? $t('Submit') : $t('Send to customer')}
                    </DPButton>
                )}
            </div>
            <div className={styles.buttons}>
                <DPButton
                    size="mini"
                    disabled={!previousEnabled}
                    onClick={props.onPrevious}
                >
                    {$t('Previous')}
                </DPButton>
                <DPButton
                    size="mini"
                    disabled={!nextEnabled}
                    onClick={props.onNext}
                >
                    {$t('Next')}
                </DPButton>
            </div>
        </div>
    )
}
