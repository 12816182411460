import { IChangedParameterCodes } from 'api'
import { CalculationInput } from 'model'

const calculateChangedParameterCode = (
    current: CalculationInput | undefined,
    next: CalculationInput,
    changedParameterCodes: IChangedParameterCodes) => {
    if(!current){
        return undefined
    }

    // Check agreement code at the beginning because it has the highest priority.
    // On agreement change calculation inputs are cleared, so keep this check at the top.
    if(current.agreementCode !== next.agreementCode){
        return changedParameterCodes.agreementCode
    }

    if(current.objectPrice !== next.objectPrice){
        return changedParameterCodes.objectPrice
    }

    if(current.downPayment !== next.downPayment){
        return changedParameterCodes.downPayment
    }

    if(current.downPaymentPct !== next.downPaymentPct){
        return changedParameterCodes.downPaymentPct
    }

    if(current.paymentTerms !== next.paymentTerms){
        return changedParameterCodes.paymentTerms
    }

    if(current.paymentPerMonth !== next.paymentPerMonth){
        return changedParameterCodes.paymentPerMonth
    }

    if(current.interestRate !== next.interestRate){
        return changedParameterCodes.interestRate
    }

    if(current.interestType !== next.interestType){
        return changedParameterCodes.interestType
    }

    // Check object age months before checking:
    // - mileage,
    // - first registration date.
    // Those two values are changed together wth object age month.
    if(current.objectAgeMonths !== next.objectAgeMonths){
        return changedParameterCodes.objectAgeMonths
    }

    if(current.mileage !== next.mileage){
        return changedParameterCodes.mileage
    }

    if(current.firstRegistrationDate !== next.firstRegistrationDate){
        return changedParameterCodes.firstRegistrationDate
    }

    if(current.deliveryDate !== next.deliveryDate){
        return changedParameterCodes.deliveryDate
    }

    if(current.documentFee !== next.documentFee){
        return changedParameterCodes.documentFee
    }

    if(current.commitmentFee !== next.commitmentFee){
        return changedParameterCodes.commitmentFee
    }

    if(current.insurance !== next.insurance){
        return changedParameterCodes.insurance
    }

    if(current.serviceAgreementPrMonth !== next.serviceAgreementPrMonth){
        return changedParameterCodes.serviceAgreementPrMonth
    }

    // safeApplicant is not part of calculation input.

    return undefined
}

export { calculateChangedParameterCode }