import { ApplicationObject, CalculationInput, CalculationResult } from 'model'
import { BusyIndicator, DPFormField, FormikForm, FormSubmit } from '@framework'
import { useApiClient, useSession, useTranslation } from '@hooks'
import { DPInputText } from 'infrastructure/framework/DPForms/DPInputText'
import { DPInputTextarea } from 'infrastructure/framework/DPForms/DPInputTextarea'
import React, { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { ApplicationApiClient } from '..'
import styles from './PriceTagModal.module.css'

interface IForm {
    engine: string,
    horsePower: string,
    gearbox: string,
    fuelEfficiency: string,
    greenOwnerTax: string,
    equipment: string,
    modelYear: string
}

const initialValues: IForm = {
    engine: '',
    horsePower: '',
    gearbox: '',
    fuelEfficiency: '',
    greenOwnerTax: '',
    equipment: '',
    modelYear: '',
}

interface IProps{
    open: boolean,
    setOpen: (value: boolean) => void,
    calculationResult: CalculationResult,
    brandCode: number,
    object: ApplicationObject,
    calculation: CalculationInput,
    agreementCode: number
}

export function PriceTagModal(props: IProps){
    const $t = useTranslation('PriceTagModal')
    const applicationApi = useApiClient(x => new ApplicationApiClient(x))
    const session = useSession()
    const [busy, setBusy] = useState(false)

    const submit = (form: IForm) => {
        setBusy(true)
        const { object, calculation, agreementCode, calculationResult } = props
        const request = {
            agreementCode,
            brandCode: props.brandCode,
            car: {
                ...form,
                firstRegistrationDate: object.firstRegistrationDate,
                mileage: object.mileage,
                make: object.make,
                model: object.model,
                variant: object.variant,
            },
            loan: {
                aop: calculationResult.summary?.aopBeforeTax,
                baseAmount: calculationResult.baseAmount,
                downPayment: calculation.downPayment,
                financedAmount: calculationResult.summary?.financedAmount,
                interestType: calculation.interestType,
                nominalInterestRate: calculationResult.summary?.nominalInterest,
                objectPrice: calculation.objectPrice,
                paymentPerMonth: calculation.paymentPerMonth,
                paymentTerms: calculation.paymentTerms,
                totalLoanCost: calculationResult.summary?.totalLoanCosts,
                totalRepay: calculationResult.summary?.totalRepay,
            }
        }
       applicationApi.generatePriceTag(session.currentDealer?.id!, request)
            .finally(() => setBusy(false))
    }

    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
            size="mini"
            closeIcon
        >
            <Modal.Header>
                {$t('PriceTag')}
            </Modal.Header>
            <Modal.Content>
                <BusyIndicator busy={busy}>
                    <FormikForm
                        initialValues={initialValues}
                        onSubmit={submit}
                    >
                        <DPFormField
                            label={$t('engine')}
                            name="engine"
                        >
                            <DPInputText name="engine" />
                        </DPFormField>

                        <DPFormField
                            label={$t('horsePower')}
                            name="horsePower"
                        >
                            <DPInputText name="horsePower" />
                        </DPFormField>

                        <DPFormField
                            label={$t('gearbox')}
                            name="gearbox"
                        >
                            <DPInputText name="gearbox" />
                        </DPFormField>

                        <DPFormField
                            label={$t('fuelEfficiency')}
                            name="fuelEfficiency"
                        >
                            <DPInputText name="fuelEfficiency" />
                        </DPFormField>

                        <DPFormField
                            label={$t('modelYear')}
                            name="modelYear"
                        >
                            <DPInputText name="modelYear" />
                        </DPFormField>

                        <DPFormField
                            label={$t('greenOwnerTax')}
                            name="greenOwnerTax"
                        >
                            <DPInputText name="greenOwnerTax" />
                        </DPFormField>

                        <DPFormField
                            label={$t('equipment')}
                            name="equipment"
                        >
                            <DPInputTextarea
                                name="equipment"
                                rows={5}
                            />
                        </DPFormField>

                        <FormSubmit submitName={$t('Generate')} />
                    </FormikForm>

                    <div className={styles.footer}>
                        Program for at åbne prisskilt dokumentet kan hentes her: <a href="https://get.adobe.com/no/reader/">Adobe Reader</a>
                    </div>
                </BusyIndicator>
            </Modal.Content>
        </Modal>
    )
}