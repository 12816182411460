import { CalculatorApiClient, IChangedParameterCodes } from 'api'
import { Application, ApplicationCustomer, ApplicationObject, CalculationInput } from 'model'
import { useApiCall, useApiClient, useSession } from '@hooks'
import React, { ReactChild, useEffect, useReducer, useState } from 'react'
import { IApplicationContext, IFormConfiguration } from '../model/IApplicationContext'
import { IApplicationContextState } from '../model/IApplicationContextState'
import { ApplicationAction } from './ApplicationAction'
import { applicationReducer } from './ApplicationReducer'
import { calculateChangedParameterCode } from './calculateChangedParameterCode'
import { ApplicationType, ObjectType } from 'shared'

export const ApplicationContext = React.createContext<IApplicationContext>(
    {} as IApplicationContext
)

interface IApplicationDataProviderProps {
    children?: ReactChild | ReactChild[]
    applicationId?: number
    changedParameterCodes: IChangedParameterCodes | undefined,
    application: Application,
    defaultApplication: Application,
}

export enum PersonInformationSource {
    ExternalService,
    Manual
}

export function ApplicationContextProvider({
    children,
    changedParameterCodes,
    application,
    defaultApplication,
}: IApplicationDataProviderProps) {
    const session = useSession()

    const calculatorApiClient = useApiClient((api) => new CalculatorApiClient(api))
    
    const defaultApplicationState: IApplicationContextState = { application }

    const [state, dispatch] = useReducer(
        (s: IApplicationContextState, a: ApplicationAction) => {
            // Put console.log(s, a) here, to see information about each dispatched action.
            return applicationReducer(s, a)
        },
        defaultApplicationState)
    const [customerInformationSource, setCustomerInformationSource] = useState(PersonInformationSource.ExternalService)
    const [coBuyerInformationSource, setCoBuyerInformationSource] = useState(PersonInformationSource.ExternalService)
    const [calculationResult, isLoadingCalculation, getCalculationResult] = useApiCall(calculatorApiClient.getCalculation)

    // Application form configuration
    const isPersonnelLoan =
        state.application.type == ApplicationType.Personnel
    const hasBrandCatalogSource = (objectType: ObjectType) => 
        [ObjectType.WhitePlate, ObjectType.YellowPlate].indexOf(objectType) !== -1
    const hasServiceAgreements = (objectType: ObjectType) =>
        [ObjectType.WhitePlate, ObjectType.YellowPlate].indexOf(objectType) !== -1 && !isPersonnelLoan
    const hasRegistrationNumber = (objectType: ObjectType) =>
        [ObjectType.Boat, ObjectType.BoatAccessories].indexOf(objectType) === -1
    const hasFirstRegistrationDate = (objectType: ObjectType) =>
        [ObjectType.WhitePlate, ObjectType.YellowPlate, ObjectType.Motorcycle, ObjectType.Camping].indexOf(objectType) !== -1
    const hasModelYear = (objectType: ObjectType) => 
        [ObjectType.Boat, ObjectType.BoatAccessories].indexOf(objectType) !== -1
    const hasMileage = (objectType: ObjectType) =>
        [ObjectType.WhitePlate, ObjectType.YellowPlate, ObjectType.Motorcycle].indexOf(objectType) !== -1
    const hasIsNewObject = (objectType: ObjectType) =>
        [ObjectType.Boat, ObjectType.BoatAccessories].indexOf(objectType) !== -1
    const formConfiguration: IFormConfiguration = {
        hasServiceAgreements: hasServiceAgreements(state.application.objectType),
        hasBrandCatalogSource: hasBrandCatalogSource(state.application.objectType),
        hasRegistrationNumber: hasRegistrationNumber(state.application.objectType),
        hasFirstRegistrationDate: hasFirstRegistrationDate(state.application.objectType),
        hasModelYear: hasModelYear(state.application.objectType),
        hasMileage: hasMileage(state.application.objectType),
        hasIsNewObject: hasIsNewObject(state.application.objectType),
        hasObjectTypeOnCalculationTab: !state.application.object.make || !state.application.object.model,
        hasObjectAgeMonthsOnCalculationTab: state.application.object.firstRegistrationDate === null,
        hasIndicativeCalculationsWarning: hasFirstRegistrationDate(state.application.objectType) && state.application.object.firstRegistrationDate === null
    }

    const objectAgeMonthsIsSelected =
        Number.isInteger(state.application.calculation.objectAgeMonths)
    useEffect(() => {
        // Do not request, if object age month is selectable but not selected.
        if(formConfiguration.hasObjectAgeMonthsOnCalculationTab && !objectAgeMonthsIsSelected){
            return
        }

        // Please notice that calculation result from server already has information about:
        // - object type,
        // - agreement code,
        // - if it's private or corporate loan.
        const previousInput = state.calculationResult?.input
        // Current (new) calculation input has to be extended by information about:
        // - object type,
        // - if it's private or corporate loan.
        // Missing information is already kept in application model, to avoid managing
        // same information twice, the decision has been made to keep the information only in
        // application model and use it when performing calculations.
        const currentInput = {
            ...state.application?.calculation,
            objectType: state.application.objectType,
            isCorporate: state.application.type === ApplicationType.Corporate
        }

        // Do not request, if input doesn't change since recent calculation.
        // Clear changed parameter code, if other fields have same values, it's not necessary to recalculate.
        const previousInputSerialized = previousInput ? JSON.stringify({...previousInput, changedParameter: undefined }) : undefined
        const currentInputSerialized = currentInput ? JSON.stringify({...currentInput, changedParameter: undefined }) : undefined

        if(previousInputSerialized === currentInputSerialized){
            return
        }

        if(changedParameterCodes === undefined){
            return
        }

        const changedParameterCode =
            calculateChangedParameterCode(
                previousInput || undefined,
                currentInput,
                changedParameterCodes)
        
        getCalculationResult(
            session.currentDealer?.id!,
            {
                ...currentInput,
                changedParameter: changedParameterCode,
            },
        )
    }, [formConfiguration.hasObjectAgeMonthsOnCalculationTab, objectAgeMonthsIsSelected, state.application.calculation])

    useEffect(() => {
        if (calculationResult) {
            if (calculationResult.summary !== null && calculationResult.input !== null) {
                dispatch({ type: 'setCalculationResult', result: calculationResult, default: defaultApplication })
            } else {
                dispatch({ type: 'handleValidationOfCalculationInput', result: calculationResult, default: defaultApplication })
            }
        }
    }, [calculationResult])

    const applicationContext: IApplicationContext = {
        ...state,
        isLoading: isLoadingCalculation,
        defaultApplication,
        customerInformationSource,
        coBuyerInformationSource,
        formConfiguration,
        setCustomerInformationSource,
        setCoBuyerInformationSource,
        /**
         * Object type has to be set with calculation input (mainly agreement code) to avoid problem of eventual consistency.
         * If set independently, object type may not match selected agreement for few ticks.
         */
        setCalculation: (input: CalculationInput, objectType: number) => {
            const modifiedInput = JSON.parse(JSON.stringify(input)) as CalculationInput;

            // If newly selected object type doesn't support service agreement, remove them from input.
            if(!hasServiceAgreements(objectType)){
                modifiedInput.serviceMonths = null
                modifiedInput.pricePerKm = null
                modifiedInput.serviceAgreementPrMonth = null
                modifiedInput.kmPerYear = null
                modifiedInput.serviceGuarantor = null
            }

            dispatch({ type: 'setCalculationInput', input, objectType })
        },
        setCoBuyer: (coBuyer: ApplicationCustomer | null) => dispatch({ type: 'setCoBuyer', coBuyer }),
        setCustomer: (customer: ApplicationCustomer) => dispatch({ type: 'setCustomer', customer }),
        setObject: (object: ApplicationObject) => {
            const onlyFirstRegistrationDateChanged =
                object.firstRegistrationDate !== state.application.object.firstRegistrationDate
                && object.variantId === state.application.object.variantId
            
            if(onlyFirstRegistrationDateChanged) {
                dispatch({ type: 'setFirstRegistrationDate', object })
            }
            else{
                dispatch({ type: 'setObject', object })
            }
        },
        setNotes: (notes: string) => dispatch({ type: 'setNotes', notes }),
    }

    return (
        <ApplicationContext.Provider value={applicationContext}>
            {children}
        </ApplicationContext.Provider>
    )
}
