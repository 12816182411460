import { useTranslation } from '@hooks/useTranslation'
import React from 'react'
import { Icon, Modal } from 'semantic-ui-react'
import { DPButton } from 'shared'

interface IErrorModalProps {
    content: JSX.Element
    errorData: string
    onOk(): void
}

export function ErrorModal({ content, errorData, onOk }: IErrorModalProps) {
    const $t = useTranslation('Modal')
    return (<Modal open centered size="small" dimmer="inverted" onClose={onOk} closeOnDimmerClick={false} closeOnEscape={false}>
        <Modal.Header>
            {$t('Error')}
        </Modal.Header>
        <Modal.Content>
            { content }
        </Modal.Content>
        <Modal.Actions>
            <DPButton onClick={() => navigator.clipboard.writeText(errorData)}>
                <Icon name="copy outline" /> {$t('CopyToClipboard')}
            </DPButton>
            <DPButton onClick={onOk}>
                <Icon name="checkmark" /> {$t('OK')}
            </DPButton>
        </Modal.Actions>
    </Modal>)
}
