import { routes } from 'routing'
import { AuthenticationContext } from '@authentication'
import React, { useContext } from 'react'
import { Link, Navigate } from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import './WelcomePage.css'
import { PublicCardLayout } from 'shared/layout'
import { useTheme } from 'themes/ThemeProvider'

export default function WelcomePage() {
    const theme = useTheme();
    const authenticationContext = useContext(AuthenticationContext)

    if(authenticationContext?.authenticated === true){
        return <Navigate to="/home" />
    }

    const secondaryButtons = [
        { label: 'Glemt din adgangskode', to: routes.resetPasswordRequest() },
        { label: 'Glemt brugernavn', to: routes.resetUsernameRequest() },
        { label: 'Nyoprettet bruger', to: routes.newUserRequest() },
    ];

    const brandName = theme === 'hessel24' ? "Hessel 24" : "Santander 24";

    return (
        <PublicCardLayout>
            <p className="welcome-p">Velkommen til</p>
            <p className="welcome-p" id="welcome-page-brand">{brandName}</p>
            <p className="welcome-p">Forhandlerens hurtige vej til flere muligheder</p>

            <div id="login-button-container">
                <Link id="login-button" to={routes.logIn()} className="big ui button">Login</Link>
            </div>

            <div id="secondary-buttons-container">
                {secondaryButtons.map(x => <Link to={x.to}>{x.label}</Link>)}
            </div>
        </PublicCardLayout>
    )
}
