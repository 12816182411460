import { ApiClient } from '@api'

export interface IBrandModel {
  brandId: number,
  modelId: number,
  variantId: number,
  modelVariantIdExternalReference: number,
  make: string,
  model: string,
  variant: string,
  isUsed: boolean,
  price: number,
  priceWithoutVat: number,
  modelYear: number | null,
  registrationNumber: string,
  vin: string,
  firstRegistrationDate: string | null,
  objectType: number,
}

export class BrandModelCatalogApiClient {
  constructor(protected apiClient: ApiClient) { }

  public searchBrands = (key: string, objectType: number, mileage: number | null, firstRegistrationDate: string | null) => {
    return this.apiClient.get<IBrandModel[]>(
      'api/brandModelCatalog/search',
      { key, objectType, mileage, firstRegistrationDate }).then(x => x.data)
  }

  public searchByRegNum = (key: string, objectType: number, mileage: number | null, firstRegistrationDate: string | null) => {
    return this.apiClient.get<IBrandModel[]>(
      'api/brandModelCatalog/registration',
      { key, objectType, mileage, firstRegistrationDate }).then(x => x.data)
  }

  public searchByVin = (key: string, objectType: number, mileage: number | null, firstRegistrationDate: string | null) => {
    return this.apiClient.get<IBrandModel[]>(
      'api/brandModelCatalog/vin',
      { key, objectType, mileage, firstRegistrationDate }).then(x => x.data)
  }

  public searchByVariantId = (key: number, objectType: number, mileage: number | null, firstRegistrationDate: string | null) => {
    return this.apiClient.get<IBrandModel[]>(
      'api/brandModelCatalog/variantId',
      { key, objectType, mileage, firstRegistrationDate }).then(x => x.data)
  }
}
