import { Navigation } from 'routing'
import { AuthorizationContext, IAuthorizationContext } from '@authentication'
import { useSession } from '@hooks'
import { ISession } from '@session'
import { ReactNode, useContext } from 'react'
import FullPageLoader from '../authentication/FullPageLoader'
import { Header } from './Header'
import styles from './AuthorizedLayout.module.css'
import { ErrorBoundary } from './ErrorBoundary'
import { Footer } from './Footer'
import IdleWarning from './IdleWarning'
import { NavigationProvider } from './NavigationProvider'

/**
 * High level overview of authorized layout.
 * The whole layout is divided into following containers:
 * - header-container
 * - main-container
 *   - navigation-container (left)
 *   - content-container (right)
 * - footer-container
 * 
 * The header-container:
 * - stretches to 100% of the page width (to ensure that background is correctly applied),
 * - has fixed height.
 * The main-container:
 * - is centered and stretches only to layout width,
 * - has not fixed height,
 * - is divided from footer and header by gap.
 * The footer-container:
 * - is centered and stretches only to layout width,
 * - has fixed height.
 * 
 * Main idea behind this layout is to place fixed header and footer respectively
 * on the top and bottom of the page and allow menu and content to fulfil the rest of it.
*/

export function AuthorizedLayout(props: { children: ReactNode }) {
    const session = useSession()
    const authorization = useContext(AuthorizationContext)

    return (
        <SessionGuard
            session={session}
            authorization={authorization}
        >
            <NavigationProvider>
                <div className={styles['layout']}>
                    <div className={styles['header-container']}>
                        <Header />
                    </div>

                    <div className={styles['main-container']}>
                        <div className={styles['navigation-container']}>
                            <Navigation />
                        </div>

                        <div className={styles['content-container']}>
                            <ErrorBoundary>
                                {props.children}
                            </ErrorBoundary>
                        </div>
                    </div>

                    <div className={styles['footer-container']}>
                        <Footer />
                    </div>

                    <IdleWarning />
                </div>
            </NavigationProvider>
        </SessionGuard>
    )
}

/** Component wrapper that ensures the session (SessionContext) is initialized. */
function SessionGuard({ children, session, authorization } : { children: ReactNode, session: ISession, authorization: IAuthorizationContext | undefined }){
    const ok = session.isInitialized && authorization !== undefined
    return (
        <>
            {ok && children}
            {!ok && <FullPageLoader />}
        </>
    )
}
