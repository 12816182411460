import { ApplicationContext } from 'pages/application/state/ApplicationContext'
import { Agreement } from 'model'
import { useTranslation } from '@hooks/useTranslation'
import { Divider, Grid, Message } from 'semantic-ui-react'
import { LoanCalculationComponent } from './LoanCalculationComponent'
import { LoanInformationComponent } from './LoanInformationComponent'
import { ProductComponent } from './ProductComponent'
import { ServiceAgreementComponent } from './ServiceAgreementComponent'
import { AvailableObjectType } from 'pages/application/dataProviders/useAvailableObjectTypes'
import { Form } from 'semantic-ui-react'
import { Formik, FormikProps } from 'formik'
import { useCalculationValidationSchema } from 'pages/application/ApplicationValidationSchema'
import { CalculationInput } from 'model'
import React, { useContext, useEffect } from 'react'
import { ApplicationType } from 'shared'

interface IProps {
  agreements: Agreement[],
  objectTypes: AvailableObjectType[],
  areErrors: boolean
  toggleSellDetails(): void,
  applicationType: ApplicationType,
  /** Callback triggered every time when number of form errors change. */
  onFormErrorsChange: (hasErrors: boolean) => void,
}

export function CalculatorForm(props: IProps): JSX.Element {
  const context = useContext(ApplicationContext)
  const $t = useTranslation('CalculatorForm')
  const showCalculations =
    context.application.calculation.objectAgeMonths != null ||
    context.calculationResult  

  return (
    <Formik
      initialValues={context.application.calculation || {} as CalculationInput}
      enableReinitialize={true}
      onSubmit={values => context.setCalculation(values, context.application.objectType)}
      validationSchema={useCalculationValidationSchema()} 
    >
      { formikProps => 
        <Form onSubmit={formikProps.handleSubmit}>   
          <Grid relaxed stackable>
            <Grid.Row columns={2}>
              <Grid.Column>
                <ProductComponent
                  agreements={props.agreements}
                  objectTypes={props.objectTypes}
                  onTitleClick={props.toggleSellDetails}
                />
              </Grid.Column>
              <Grid.Column>
                {context.formConfiguration.hasServiceAgreements &&
                  <ServiceAgreementComponent />
                }
              </Grid.Column>
            </Grid.Row>
            <Divider />
            { 
              showCalculations &&
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <LoanCalculationComponent applicationType={props.applicationType}/>
                  </Grid.Column>
                  <Grid.Column>
                    <LoanInformationComponent />
                  </Grid.Column>
                </Grid.Row>
            }
            {
              !showCalculations &&
                <Grid.Row>
                  <Grid.Column>
                    <Message warning>
                      { $t('selectAgeToSeeTheCalculations') }
                    </Message>
                  </Grid.Column>
                </Grid.Row>
            }
          </Grid>
          <FormBehavior
            formik={formikProps}
            onFormErrorsChange={props.onFormErrorsChange}
          />
        </Form>
      }
    </Formik>
  )
}

function FormBehavior(props: {
  formik: FormikProps<CalculationInput>,
  onFormErrorsChange: (hasErrors: boolean) => void,
}){
  const { dirty, values, submitForm, validateForm } = props.formik

  useEffect(() => {
    if(!dirty){
      return
    }

    validateForm(values)
      .then(errors => props.onFormErrorsChange(Object.values(errors).length > 0))

    submitForm()
  }, [values])

  return null
}