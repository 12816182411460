import { routes } from 'routing'
import { useSession } from '@hooks/useSession'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'

export function DealerSelector() {
  const navigate = useNavigate()
  const session = useSession()

  const options =
    session.dealers.map(x => ({
      key: x.id,
      value: x.id,
      text: x.name || x.officialName
    }))

  return (
    <Dropdown
      options={options}
      value={session.currentDealer?.id}
      scrolling
      selectOnBlur={false}
      onChange={(e, data) => {
        session.changeDealer(data.value as number)
        navigate(routes.homePage())
      }}
    />
  )
}