import { routes } from 'routing'
import { useAuthorization } from '@authentication'
import { config, isFeatureEnabled } from '@config'
import { useSession } from '@hooks/useSession'
import { useTranslation } from '@hooks/useTranslation'
import React, { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './Navigation.css'
import {
    AddNewsIcon,
    BusinessFinancingIcon,
    ContactIcon,
    DealerAdministrationIcon,
    EditProfileIcon,
    HomePageIcon,
    MoveLeasingIcon,
    NewsArchiveIcon,
    OwnPricingIcon,
    PersonnelFinancingIcon,
    PlusLoanIcon,
    PrivateFinancingIcon,
    RemainingDebtIcon,
    SearchIncomingIcon,
    SearchSavedIcon,
    SearchSubmittedIcon,
    StatisticsIcon, 
    UserAdministrationIcon,
    DealerMasterDataIcon,
} from './NavigationIcons'
import { IncomingApplicationCounter } from 'shared/incomingApplicationCounter'
import { useNavigation } from 'shared/layout'
import { classNames } from 'shared'

interface MenuGroup {
    label: string,
    items: MenuItem[]
}

interface MenuItem {
    icon: ReactNode,
    label: string,
    url: string,
    /**
     * When set to true react-router doesn't handle this link.
     * Regular <a /> tag is used that open given URL in new tab.
     */
    external?: boolean,
    /**
     * All items are visible by default.
     * Item is visible when it's undefined or true.
     * Item is hidden when its' false.
     */
    visible?: boolean,
    counter?: ReactNode
}


export function Navigation() {
    const $t = useTranslation('Navigation')
    const authorization = useAuthorization()
    const session = useSession()
    const location = useLocation();
    const navigation = useNavigation()

    const timestamp = useMemo(() => {
        return Date.now().toString();
    }, [location])

    const menuGroups = [
        {
            label: $t('Application'),
            items: [
                {
                    icon: <HomePageIcon />,
                    label: $t('Homepage'),
                    url: routes.homePage()
                },
                { 
                    icon: <PrivateFinancingIcon />,
                    label: $t('Private financing'),
                    url: routes.privateApplication({ tab: 'calculation', timestamp: timestamp })
                },
                { 
                    icon: <BusinessFinancingIcon />,
                    label: $t('Business financing'),
                    url: routes.corporateApplication({ tab: 'calculation', timestamp: timestamp }),
                    visible: isFeatureEnabled('corporate-loan')
                },
                {
                    icon: <SearchSavedIcon />,
                    label: $t('Search saved'),
                    url: routes.applicationSearchSaved(),
                },
                {
                    icon: <SearchSubmittedIcon />,
                    label: $t('Search submitted'),
                    url: routes.applicationSearchSubmitted(),
                },
                {
                    icon: <SearchIncomingIcon />,
                    label: $t('Search incoming'),
                    url: routes.applicationSearchIncoming(),
                    counter: <IncomingApplicationCounter />
                },
                {
                    icon: <MoveLeasingIcon />,
                    label: $t('MoveLeasing'),
                    url: config.moveUrl,
                    external: true,
                    visible: session?.currentUser?.sellerNumber
                },
                {
                    icon: <PlusLoanIcon />,
                    label: $t('PlusLoan'),
                    url: routes.plusLoan(),
                },
            ]
        },
        {
            label: $t('Reports'),
            items: [
                {
                    icon: <StatisticsIcon />,
                    label: $t('Statistics'),
                    url: routes.statistics(),
                    visible: isFeatureEnabled('statistics') && (authorization.hasAccessToOwnStatistics || authorization.hasAccessToAllStatistics),
                }
            ]
        },
        {
            label: $t('dealer'),
            items: [
                {
                    icon: <PersonnelFinancingIcon />,
                    label: $t('PersonnelLoan'),
                    url: routes.personnelApplication({ tab: 'calculation', timestamp: timestamp }),
                    visible: isFeatureEnabled('personnel-loan'),
                },
                {
                    icon: <RemainingDebtIcon />,
                    label: $t('remainingDebt'),
                    url: routes.remainingDebt(),
                    visible: authorization.hasAccessToRemainingDebt,
                },
            ]
        },
        {
            label: $t('Information'),
            items: [
                {
                    icon: <AddNewsIcon />,
                    label: $t('AddNews'),
                    url: routes.addNews(),
                    visible: authorization.hasAccessToNewsAdministration,
                },
                {
                    icon: <NewsArchiveIcon />,
                    label: $t('NewsArchive'),
                    url: routes.newsArchive(),
                    visible: authorization.hasAccessToNewsAdministration,
                },
                {
                    icon: <ContactIcon />,
                    label: $t('contact'),
                    url: routes.contact(),
                },
            ]
        },
        {
            label: $t('Administration'),
            items: [
                {
                    icon: <EditProfileIcon />,
                    label: $t('Edit profile'),
                    url: routes.administrationUserProfile(),
                },
                {
                    icon: <OwnPricingIcon />,
                    label: $t('ownPricing'),
                    url: routes.administrationOwnPricing(),
                    visible: authorization.hasAccessToOwnPricing,
                },
                {
                    icon: <UserAdministrationIcon />,
                    label: $t('User administration'),
                    url: routes.administrationUsers(),
                    visible: authorization.hasAccessToUsersAdministration,
                },
                {
                    icon: <DealerAdministrationIcon />,
                    label: $t('Dealer administration'),
                    url: routes.administrationDealers(),
                    visible: authorization.hasAccessToAllDealersAdministration,
                },
                {
                    icon: <DealerMasterDataIcon />,
                    label: $t('masterData'),
                    url: routes.administrationMasterData(),
                    visible: authorization.hasAccessToOwnDealersAdministration,
                },
            ]
        }
    ] as MenuGroup[];

    return (
        <div className={classNames("navigation", navigation.open ? "open" : undefined)}>
            {menuGroups.filter(group => group.items.filter(x => x.visible !== false).length !== 0).map(group => (
                <div className='navigation-group'key={group.label}>
                    <div className='navigation-header'>{group.label}</div>

                    {group.items.filter(item => item.visible !== false).map(item => (
                        <div className='navigation-item' key={item.label} title={item.label}>
                            {item.external !== true &&
                                <LinkInternal to={item.url}>
                                    <span className='icon'>{item.icon}</span> <span className='label'>{item.label}</span> <span className='counter'>{item.counter}</span>
                                </LinkInternal>
                            }
                            {item.external === true &&
                                <LinkExternal href={item.url}>
                                    <span className='icon'>{item.icon}</span> <span className='label'>{item.label}</span> <span className='counter'>{item.counter}</span>
                                </LinkExternal>
                            }
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}

function LinkInternal(props: { children: ReactNode, to: string }){
    const navigation = useNavigation()
    const location = useLocation();
    const active = location.pathname === '/' ? props.to === location.pathname : props.to.indexOf(location.pathname) === 0;
    return (
        <Link
            to={props.to}
            className={active ? 'active' : undefined}
            onClick={() => navigation.setOpen(false)}
        >
            {props.children}
        </Link>
    )
}

function LinkExternal(props: { children: ReactNode, href: string }){
    const navigation = useNavigation()
    return (
        <a
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => navigation.setOpen(false)}
        >
            {props.children}
        </a>
    )
}