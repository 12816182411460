import { useEffect, useState } from 'react'

export type ObjectSearchMode =
  'manual' |
  'automatic'

export type ObjectSearchedBy =
  'registration number' |
  'vin' |
  'model-variant'

const getDefaultSearchObjectMode = (
  objectTypeHasBrandCatalogSource: boolean,
  variantId: number | null | undefined,
  variant: string | undefined,
  isIncomingApplication: boolean,
): ObjectSearchMode => {
  if(isIncomingApplication){
    return 'automatic'
  }

  // This check covers situation of restoring saved application with manually typed data.
  if(variant && !variantId){
    return 'manual'
  }

  return objectTypeHasBrandCatalogSource ? 'automatic' : 'manual'
}

export default function useObjectSearchMode(
  objectTypeHasBrandCatalogSource: boolean,
  variantId: number | null | undefined,
  variant: string | undefined,
  isIncomingApplication: boolean
){
  const defaultSearchObjectmode =
    getDefaultSearchObjectMode(
      objectTypeHasBrandCatalogSource,
      variantId,
      variant,
      isIncomingApplication)
  const [searchedBy, setSearchedBy] = useState<ObjectSearchedBy | undefined>()
  const [searchMode, setSearchMode] = useState(defaultSearchObjectmode)

  useEffect(() => {
    setSearchMode(
      getDefaultSearchObjectMode(
        objectTypeHasBrandCatalogSource,
        variantId,
        variant,
        isIncomingApplication))
  }, [objectTypeHasBrandCatalogSource])

  // Reset searched by on search mode change.
  useEffect(() => {
    setSearchedBy(undefined)
  }, [searchMode])

  return {
    searchMode,
    setSearchMode,
    searchedBy,
    setSearchedBy,
  }
}