import { ApiClient, IGridQuery, IGridResult } from '@api'

export interface IKycBuyer {
    name: string
    phoneNumber: string
    email: string
    status: KycStatus
}

export interface IKycApplicationModel {
    buyer: IKycBuyer
    coBuyer: IKycBuyer
    name: string
    phoneNumber: string
    email: string
    createdDate: string
    objectPrice: number
    financedAmount: number
    aopBeforeTaxRaw: number
    nominalInterest: number
    downPayment: number
    paymentPerMonth: number
    paymentTerms: number
    maker: string
    status: KycStatus
    clientLink: string,
    isLeasing: boolean,
}

export interface IKycApplicationOverviewModel {
    caseId: number
    referenceId: string
    dateReceived: string
    contractName: string
    agreementName: string
    status: string,
    salesPerson: string
    vin: string
    cancellable: boolean,
}

export enum KycStatus {
    unknown = 0,
    awaitingCustomer = 1,
    awaitingCobuyer = 2,
    completeAwaitingSantander = 3,
    submitError = 4
}

export class KycApplicationApiClient {
    constructor(protected apiClient: ApiClient) { }

    public getApplication = (dealerId: number, referenceId: string) => {
        const url = `api/dealers/${dealerId}/kyc-applications/${referenceId}`
        return this.apiClient.get<IKycApplicationModel>(url).then(x => x.data)
    }

    public getDealerApplications = (dealerId: number, params: IGridQuery, configuration?: { suppressError: boolean }) => {
        const url = `api/dealers/${dealerId}/kyc-applications/`
        return this.apiClient.get<IGridResult<IKycApplicationOverviewModel>>(url, params, configuration)
    }

    public getMyApplications = (dealerId: number, params: IGridQuery, configuration?: { suppressError: boolean }) => {
        const url = `api/dealers/${dealerId}/kyc-applications/my`
        return this.apiClient.get<IGridResult<IKycApplicationOverviewModel>>(url, params, configuration)
    }
}