import { useTranslation } from '@hooks/useTranslation'
import { dateStringToLocalDate, dateToDanishDate } from 'shared/dateTools'
import { LanguageContext } from '@translations'
import React, { ReactChild, useContext, useEffect } from 'react'
import { setLocale } from 'yup'
import { LocaleObject } from 'yup/lib/locale'
import { addMethods } from './CustomMethods'
import { ICustomLocaleObject } from './ICustomLocaleObject'

export default function YupValidationMessagesProvider({ children }: { children: ReactChild[] | ReactChild }) {
    const $t = useTranslation('YupValidationMessagesProvider')
    const languageContext = useContext(LanguageContext)

    useEffect(() => {
        const standardLocale : LocaleObject = {
            mixed: {
                default: $t('InvalidValue'),
                required: '${path} ' + $t('FieldIsRequired'),       // need to build message template like this because ${path} interferes with parameter resoultion during translation
                notType: '${path} ' + $t('FieldHasInvalidValue'),
            },
            number: {
                min: ({ min }) => $t('valueCannotBeLessThan', { min }),
                max: ({ max }) => $t('valueCannotBeGreaterThan', { max }),
            },
            string: {
                email: $t('invalidEmail'),
                min: ({ path, min }) => $t('TextCannotBeShorterThanX', {path, min}),
                max: ({ path, max }) => $t('TextCannotBeLongerThanX', {path, max}),
                length: ({ path, length }) => $t('TextMustBeExactlyXCharacters', {path, length}),
            },
        }
    
        const customLocale : ICustomLocaleObject = {
            string: {
                phone: $t('invalidPhone'),
                vin: $t('invalidVin'),
                cpr: $t('invalidCpr'),
                cvr: $t('invalidCvr'),
                registrationNumber: $t('invalidRegistrationNumber'),
                zipCode: $t('invalidZipCode'),
                minDate: ({ path, minDate }) => $t('dateCannotBeBefore', { min: dateToDanishDate(dateStringToLocalDate(minDate)), path }),
                maxDate: ({ path, maxDate }) => $t('dateCannotBeAfter', { max: dateToDanishDate(dateStringToLocalDate(maxDate)), path })
            }
        }

        // https://github.com/jquense/yup#error-message-customization
        setLocale(standardLocale)
        addMethods(customLocale)
    }, [languageContext.language])

    return <>{children}</>
}